var CSConfigUtil = 
{
    gAppId_Settings         : 'SETTINGS',
    gAppId_Calendar         : 'CONFIGURATION_CALENDAR',
    gAppId_Notifications    : 'NOTIFICATION_SUMMARY',
    gPageSize               : 25,
    
/*
    mAppCode                : 'EZ_USE',
    mProductCode            : 'EZ Use',
    mProductCaption         : 'EZ Use',
    mProductTitle           : 'EZ Use',
*/
    

    mAppCode                : 'SBVDS',
    mProductCode            : 'EZ Use',
    mProductCaption         : 'EZ Use',
    mProductTitle           : 'EZ Use',


/*
    mAppCode                : '99V',
    mProductCode            : '99 Ventures',
    mProductCaption         : '99 Ventures',
    mProductTitle           : '99 Ventures',
*/
/*
    mAppCode                : 'SVCO',
    mProductCode            : 'Service Collaborator',
    mProductCaption         : 'Service Collaborator',
    mProductTitle           : 'Service Collaborator',
*/
/*
    mAppCode                : 'FIVE9_ED_TEST',
    mProductCode            : 'Easy Dispatch',
    mProductCaption         : 'Easy Dispatch',
    mProductTitle           : 'Easy Dispatch',
*/
/*
    mAppCode                : 'FIVE9',
    mProductCode            : 'Able MES',
    mProductCaption         : 'Able MES',
    mProductTitle           : 'Able MES',
*/
/*
    mAppCode                : 'AGRONEX',
    mProductCode            : 'Agronex',
    mProductCaption         : 'Agronex',
    mProductTitle           : 'Agronex',
*/
/*
    mAppCode                : 'CSPL',
    mProductCode            : 'CS Enterprise',
    mProductCaption         : 'CS Enterprise',
    mProductTitle           : 'CS Enterprise',
*/
/*
    mAppCode                : 'SVU',
    mProductCode            : 'S V University',
    mProductCaption         : 'S V University',
    mProductTitle           : 'S V University',
*/
/*
    mAppCode                : 'VENOBLE_INFRA',
    mProductCode            : 'CS Reality',
    mProductCaption         : 'CS Reality',
    mProductTitle           : 'CS Reality',
*/
/*
    mAppCode                : 'CROSOL',
    mProductCode            : 'CS Enterprise',
    mProductCaption         : '',
    mProductTitle           : '',
*/
/*
    mAppCode                : 'NJ',
    mProductCode            : 'Donor Management',
    mProductCaption         : 'Donor Management',
    mProductTitle           : 'Donor Management',
*/
/*
    mAppCode                : 'DESIGNER_STUDIO',
    mProductCode            : 'Designer Studio',
    mProductCaption         : 'Designer Studio',
    mProductTitle           : 'Designer Studio',
*/
/*
    mAppCode                : 'CS_MKS',
    mProductCode            : 'Lead Management',
    mProductCaption         : '',
    mProductTitle           : 'Lead Management System',
*/
/*
    mAppCode                : 'CS_HRM',
    mProductCode            : 'NexaHR',
    mProductCaption         : '',
    mProductTitle           : 'Human Resource Management',
*/

/*
    mAppCode                : 'BMS',
    mProductCode            : 'Budget Management System',
    mProductCaption         : '',
    mProductTitle           : 'Budget Management System',
*/
/*
    mAppCode                : 'M360',
    mProductCode            : 'Management 360',
    mProductCaption         : '',
    mProductTitle           : 'Management 360',
*/
/*
    mAppCode                : 'CCMP',
    mProductCode            : 'CCMP',
    mProductCaption         : '',
    mProductTitle           : 'Cold Chain Market Place',
*/
/*
    mAppCode                : 'VIVID',
    mProductCode            : 'VIVID',
    mProductCaption         : '',
    mProductTitle           : 'ViVid Innovations',
*/
/*
    mAppCode                : 'SZ_INAN_GJC_PROD',
    mProductCode            : 'Institute Analyzer',
    mProductCaption         : 'Institute Analyzer',
    mProductTitle           : 'Institute Analyzer',
*/

    mUserId                 : '',
    mSessionId              : '',
    mDomainId               : 'CS_DAO',
    mUserType               : 'EMPLOYEE',
    mLoginFormTitle         : 'Chinmmai Systemss',
    mLoginFormLogo          : require('../assets/images/cs_log_latest_1.png'),
    mProductLogo            : require('../assets/images/cs_log_latest_1.png'),
    mCompanyLogo            : require('../assets/images/cs_log_latest_1.png'),

    mStudentName            : null,
    mAdmissionNo            : null,
    mClassCode              : null,
    mSectionCode            : null,
    mBranchCode             : null,
    mBoardCode              : null,

    mReceiptHeading         : {},
    mReceiptSubHeading1     : {},
    mReceiptSubHeading2     : {},
    mReceiptHeadingList     : null,
    mReceiptSubHeading1List : null,
    mReceiptSubHeading2List : null,
    mReportHeading          : {},
    mReportSubHeading1      : {},
    mReportSubHeading2      : {},
    mReportHeadingList      : null,
    mReportSubHeading1List  : null,
    mReportSubHeading2List  : null,
    mDataFolder             : null,
    mDataFolderMap          : {},

    /**
     * Employee Details
     */
    mEmployee               : null,
    mEmployeeId             : null,
    mEmployeeName           : null,
    mManagerId              : null,
    mManagerName            : null,		
    mOfficeCode             : null,
    mOfficeName             : null,

    mDeptCode               : null,
    mDeptName               : null,
    mCountry                : 'India',
    mState                  : 'Telangana',
    mCity                   : 'Hyderabad',

    mAYCode                 : null,
    mActiveAcademicYearObj  : null,
    mCurrentAYCode          : null,
    mCurrentAYName          : null,

    mBranchObj              : null,
    mBranchName             : null,
    mBranchType             : null,
    mBranchTypeCode         : null,
    mBranchEmail            : null,

    mCompanyObj             : null,    
    mCompanyCode            : null,
    mCompanyName            : null,

    mFYCode                 : null,
    mActiveFinancialYearObj : null,
    mCurrentFYCode          : null,
    mCurrentFYName          : null,

    mPettycashAccount       : null,
    mDateFormat             : 'DD-MM-YYYY',
    mDateSaveFormat         : 'YYYY-MM-DD',
    mLoginType              : '2',
    mAppType                : 'EDU'
}

export default CSConfigUtil;