import React from "react"
import Swal from 'sweetalert2';
import {Button} from 'primereact/button';

import CSUtil from "../util/CSUtil";
import { fnDateAdd, fnGetStringToDate, fnGetDateToString, fnGetTimeFromDate } from "../util/CSDateTimeUtil";
import CSServiceUtil from "../util/CSServiceUtil";
import CSRequestUtil from "../util/CSRequestUtil";
import { CSConstantUtil, cfGetConstantValue } from "../util/CSConstantUtil";
import CSControl from "../controls/CSControl"
import CSCOVerticalSection from '../components/CSCOVerticalSection'

class CSFormUtil
{
    mFormUtil = null;
    
    static cfGetInstance()
    {
        if(this.mFormUtil === undefined)
        {
            this.mFormUtil = new CSFormUtil();
        }

        return this.mFormUtil;
    }
		
    static cfInitializeYearList()
    {
        if(!CSConstantUtil.gYearList)
        {
            CSConstantUtil.gYearList = [];
            for(var lIndex=0; lIndex<100; lIndex++)
            {
                CSConstantUtil.gYearList.addItem(2000 + lIndex);
            }
        }
    }

    cfGetControl(lFormItem, fOnChange, lFormItemMap, lClassName, fOnPopout)
    {
        var lFormGrid = null;
        var lMinHeight;
        if(lFormItemMap)
        {
            if(lFormItem.control_type_ === 'cs_grid')
            {
                lFormGrid = lFormItemMap[lFormItem.id_]; 
                if(lFormGrid)
                {
                    for(var lGInd=0; lGInd<lFormGrid.length; lGInd++)
                    {
                        var lFormGridItem = lFormGrid[lGInd];
                        lFormGridItem.column_name_ = lFormGridItem.display_label_;
                    }
                }

                lMinHeight = '40px'
            }
        }
       
        return(
            <CSControl 
                key                 = {lFormItem.id_}
                pMaxWidth           = {(lFormItem.max_width_) ? lFormItem.max_width_ : '340px'}
                pMaxWidthWithPopout = {(lFormItem.max_width_) ? lFormItem.max_width_ : '262px'}
                pFormGrid           = {lFormGrid}
                pMinHeight          = {lMinHeight}
                process_change_     = {fOnChange}
                pFormItem           = {lFormItem}
                pOnPopout           = {fOnPopout}
                pFormLabelClassName = {lClassName}
                pEnableBrowse       = {lFormItem.pEnableBrowse}
                pEnableCamera       = {lFormItem.pEnableCamera}
                pLabelWidth         = {(lFormItem.label_width_) ? lFormItem.label_width_ : '100px'}
                {...lFormItem}/>
        )
    }

    cfGetActionItem(lFormActions, lActionRefId)
    {
        var lActionItem;
        if(lFormActions)
        {
            for(var lInd=0; lInd<lFormActions.length; lInd++)
            {
                var lAitem = lFormActions[lInd];
                if(lAitem.id_ === lActionRefId)
                {
                    lActionItem = lAitem;
                    break;
                }
            }
        }

        return lActionItem;
    }

    cfGetChildActionItems(lFormActions, lActionRefId)
    {
        var lActionItems;
        if(lFormActions)
        {
            for(var lInd=0; lInd<lFormActions.length; lInd++)
            {
                var lAitem = lFormActions[lInd];
                if(lAitem.parent_ref_id_ === lActionRefId)
                {
                    if(!lActionItems)
                    {
                        lActionItems = [];
                    }

                    lActionItems.push(lAitem);
                }
            }
        }

        return lActionItems;
    }

    cfGetFormItems(lFormItems, lActionId, lDetailsFlag = false)
    {
        var lFormItemList = [];
        if(lActionId && lFormItems)
        {
            for(var lInd=0; lInd < lFormItems.length; lInd++)
            {
                var lFormItem = lFormItems[lInd];
                if(lDetailsFlag)
                {
                    if(lFormItem.details_action_ref_id_ === lActionId)
                    {
                        lFormItemList.push(lFormItem);
                    }
                }
                else
                {
                    if(lFormItem.action_ref_id_ === lActionId)
                    {
                        lFormItemList.push(lFormItem);
                    }
                }
            }
        }
        
        return lFormItemList;
    }

    cfGetFormProperties(lFormProperties, lFormCode)
    {
        var lFormPropertiesList = [];
        if(lFormProperties && lFormCode)
        {
            for(var lInd=0; lInd<lFormProperties.length; lInd++)
            {
                var lFormProperty = lFormProperties[lInd];
                if(lFormProperty.cs_form_code_ === lFormCode)
                {
                    lFormPropertiesList.push(lFormProperty);
                }
            }
        }
        
        return lFormPropertiesList;
    }
    
    cfGetDetailsSections(lFormItems, lMinSectionItems, fOnChange, lClassName, fOnPopout, lNumSect)
    {
        var lFormUtil = CSFormUtil.cfGetInstance();
        var lSections = [];
        var lNumSections = 1;
        var lNumItemsPerSection = 0;
        if(lFormItems)
        {
            var lFormItemList = []
            for(var lfind=0; lfind<lFormItems.length; lfind++)
            {
                if(lFormItems[lfind].visible_ === '1')
                {
                    lFormItemList.push(lFormItems[lfind]);
                }
            }

            if(!lNumSect)
            {
                if(lFormItemList.length > (lMinSectionItems) * 2)
                {
                    lNumSections = 3;
                }
                else if(lFormItemList.length > lMinSectionItems)
                {
                    lNumSections = 2;
                }
            }
            else
            {
                lNumSections = lNumSect;
            }

            lNumItemsPerSection = Math.ceil(lFormItemList.length/lNumSections);

            var lSectionNumControlMap = {};
            var lMaxSectionItems = 0;
            CSUtil.cfSort(lFormItemList, 'sequence_')
            for(var ind=0; ind<lFormItemList.length; ind++)
            {
                var lFormItem = lFormItemList[ind];
                var lSequence = parseInt(lFormItem.sequence_, 10);
                var lIndex = Math.ceil(lSequence/lNumItemsPerSection) - 1;
                var lSectionControlList = null;
                lSectionControlList = lSectionNumControlMap[lIndex];
                if(lSectionControlList == null)
                {
                    lSectionControlList = [];
                    lSectionNumControlMap[lIndex] = lSectionControlList;
                }
                
                var lCtl = lFormUtil.cfGetControl(lFormItem, fOnChange, null, lClassName, fOnPopout);
                if(lCtl)
                {
                    lSectionControlList.push(lCtl);
                }

                lMaxSectionItems = Math.max(lMaxSectionItems, lSectionControlList.length);
            }

            if(lNumSections > 2)
            {
                lMaxSectionItems = lMaxSectionItems + 0.25;
            }
            else
            {
                if(lFormItemList.length > 2)
                {
                    lMaxSectionItems = lMaxSectionItems + 1;
                }
                else
                {
                    lMaxSectionItems = lMaxSectionItems + 0.25;
                }
            }
 
            for(var lSectionNum=0; lSectionNum<lNumSections; lSectionNum++)
            {
                var lSection = null;
                var lBkgColor = "";
                if((lSectionNum % 2) === 0)
                {
                    lBkgColor = "#FAFAFA";
                }
                else
                {
                    lBkgColor = "#FFFFFF";
                }
/*                lSection = 
                    <div className="p-fluid">
                        { lSectionNumControlMap[lSectionNum] }
                    </div>
*/
                lSection = <CSCOVerticalSection 
                    pBackgroundColor    = {lBkgColor} 
                    pId                 = {lSectionNum} 
                    key                 = {lSectionNum}
                    pNumSections        = {lNumSections}
                    pMaxSections        = {lNumSect}
                    pMaxSectionItems    = {lMaxSectionItems}
                    pIsCriteriaSection  = {false}
                    pChildren           = {lSectionNumControlMap[lSectionNum]}/>

                lSections.push(lSection);
            }
        }

        return lSections;
    }
    
    cfGetSections(lFormItemList, lMinSectionItems, lMaxSections, fOnChange, lSectionWidth, lPadding, lHeight, fOnPopout, lSectionFormFlag)
    {
        var lFormUtil = CSFormUtil.cfGetInstance();   
        var lSections = [];
        var lNumSections = 0;
        if(lFormItemList)
        {
            var lFormItemList_0 = [];
            var lFormItemMap = {};
            var lParentFormItemObjectMap = {};
            var lNumCollectionSections = 0;
            CSUtil.cfSort(lFormItemList, 'parent_id_', 'sequence_');
            for(var ind_0=0; ind_0<lFormItemList.length; ind_0++)
            {
                var lFormItem_0 = lFormItemList[ind_0];
                if((lFormItem_0.control_type_ === 'heading') || (lFormItem_0.control_type_ === 'cs_grid')  || (lFormItem_0.control_type_ === 'accordion') )
                {
                    lParentFormItemObjectMap[lFormItem_0.id_] = lFormItem_0;
                    lNumSections++;
                    lNumCollectionSections++;
                    if(!lFormItemMap[lFormItem_0.id_])
                    {
                        lFormItemMap[lFormItem_0.id_] = [];
                    }
                }
                else if(lFormItem_0.sequence_ !== '0')
                {
                    if(lFormItem_0.parent_id_)
                    {
                        var lFormItemList_1 = lFormItemMap[lFormItem_0.parent_id_];
                        if(!lFormItemList_1)
                        {
                            lFormItemList_1 = [];
                            lFormItemMap[lFormItem_0.parent_id_] = lFormItemList_1;
                        }

                        lFormItemList_1.push(lFormItem_0);
                    }
                    else
                    {
                        if(lFormItem_0.visible_ === '1')
                        {
                            lFormItemList_0.push(lFormItem_0);
                        }
                    }
                }
            }

            if(lFormItemList_0.length > lMinSectionItems)
            {
                var lSectionCount =  Math.ceil(lFormItemList_0.length/lMinSectionItems);
                if((lSectionCount + lNumSections) >= lMaxSections)
                {
                    lNumSections = lMaxSections;
                }
                else
                {
                    lNumSections = lSectionCount + lNumSections;
                }
            }
            else if(lFormItemList.length <= lMinSectionItems)
            {
                lNumSections = 1;
            }

            var lSectionNumControlMap = {};
            var lMaxSectionItems = 0;
            CSUtil.cfSort(lFormItemList_0, 'sequence_')
            for(var ind=0; ind<lFormItemList_0.length; ind++)
            {
                var lFormItem = lFormItemList_0[ind];
                var lSequence = parseInt(lFormItem.sequence_, 10);
                var lIndex = Math.ceil(lSequence/lMinSectionItems) - 1;
                var lSectionControlList = null;
                lSectionControlList = lSectionNumControlMap[lIndex + lNumCollectionSections];
                if(!lSectionControlList)
                {
                    lSectionControlList = [];
                    lSectionNumControlMap[lIndex + lNumCollectionSections] = lSectionControlList;
                }
                
                lSectionControlList.push(lFormUtil.cfGetControl(lFormItem, fOnChange, null, null, fOnPopout));
                lMaxSectionItems = Math.max(lMaxSectionItems, lSectionControlList.length);
            }

            var lInd = 0;
            for(var lId in lFormItemMap)
            {
                var lParentObj = lParentFormItemObjectMap[lId];
                if(lParentObj)
                {
                    var lSectionControlList_0 = null;
                    lSectionControlList_0 = lSectionNumControlMap[lInd];
                    if(!lSectionControlList_0)
                    {
                        lSectionControlList_0 = [];
                        lSectionNumControlMap[lInd] = lSectionControlList_0;
                    }

                    if(lParentObj.control_type_ !== 'cs_grid')
                    {
                        lSectionControlList_0.push(lFormUtil.cfGetControl(lParentObj, fOnChange, null, null, fOnPopout));
                    }
                    else
                    {
                        var lpcfObj = lFormUtil.cfGetControl(lParentObj, fOnChange, lFormItemMap, null, fOnPopout);
                        if(lpcfObj)
                        {
                            lSectionControlList_0.push(lpcfObj);
                        }
                    }

                    var lCollectionList = lFormItemMap[lId];
                    if(lCollectionList)
                    {
                        var lNumRows = 0;
                        for(var lclInd=0; lclInd<lCollectionList.length; lclInd++)
                        {
                            var lChildObj = lCollectionList[lclInd];
                            if((lParentObj.control_type_ !== 'cs_grid') && (lParentObj.control_type_ !== 'accordion'))
                            {
                                var lcfObj = lFormUtil.cfGetControl(lChildObj, fOnChange, lFormItemMap, null, fOnPopout);
                                if(lcfObj)
                                {
                                    lSectionControlList_0.push(lcfObj);
                                }
                            }
                            else if((lParentObj.control_type_ === 'accordion') && (lChildObj.control_type_ !== 'summary'))
                            {
                                var lcfObj_0 = lFormUtil.cfGetControl(lChildObj, fOnChange, lFormItemMap, null, fOnPopout);
                                if(lcfObj_0)
                                {
                                    lSectionControlList_0.push(lcfObj_0);
                                }
                            }
                        }

                        lMaxSectionItems = Math.max(lMaxSectionItems, lSectionControlList_0.length + lNumRows * 0.85);
                    }

                    lInd++;
                }
            }

            for(var lSectionNum=0; lSectionNum<lNumSections; lSectionNum++)
            {
                var lSection = null;
                var lBkgColor = "";
                if((lSectionNum % 2) === 0)
                {
                    lBkgColor = "#FFFFFF";
                }
                else
                {
                    lBkgColor = "#FFFFFF";
                }

                lSection = <CSCOVerticalSection  
                    pBackgroundColor    = {lBkgColor} 
                    pId                 = {lSectionNum} 
                    key                 = {lSectionNum}
                    pNumSections        = {lNumSections}
                    pMaxSections        = {lMaxSections}
                    pSectionFlag        = {lSectionFormFlag}
                    pMaxSectionItems    = {lMaxSectionItems + 1}
                    pIsCriteriaSection  = {false}
                    pHeight             = {lHeight}
                    pPadding            = {lPadding}
                    pChildren           = {lSectionNumControlMap[lSectionNum]}/>

                lSections.push(lSection);
            }
        }

        return lSections;
    }

    cfGetDataCollection(lFormData)
    {
        var lRetCol = null;
        if(Array.isArray(lFormData))
        {
            for(var lInd=0; lInd<lFormData.length; lInd++)
            {
                var lFormItemData = lFormData[lInd];
                var lCol = this.cfGetDataFieldCollection(lFormItemData);
                if(!lRetCol)
                {
                    lRetCol = lCol;
                }
                else
                {
                    const lKeys = Object.keys(lCol);
                    if(lKeys && (lKeys.length > 0))
                    {
                        for(var lKeyInd=0; lKeyInd<lKeys.length; lKeyInd++)
                        {
                            var lKey = lKeys[lKeyInd];
                            if(lRetCol.hasOwnProperty(lKey))
                            {
                                Array.prototype.push.apply(lRetCol[lKey], lCol[lKey]);
                            }
                        }
                    }
                }
            }
        }
        else
        {
            lRetCol = this.cfGetDataFieldCollection(lFormData);
        }

        return lRetCol;
    }

    cfGetDataFieldCollection(lFormData)
    {
        var lRetCol = null;
        const lKeys = Object.keys(lFormData);
        if(lKeys && (lKeys.length > 0))
        {
            lRetCol = {};

            for(var lInd=0; lInd<lKeys.length; lInd++)
            {
                var lKey = lKeys[lInd];
                if(!(lFormData[lKey] instanceof Array))
                {
                    var lVal = [];
                    lVal.push(lFormData[lKey]);                            
                    lRetCol[ lKey ] = lVal;
                }
                else
                {
                    var lColl = lFormData[lKey];
                    if(lColl.length > 0)
                    {
                        var lColObj = {}
                        var lColKeys = Object.keys(lColl[0]);
                        for(var lcInd=0; lcInd<lColKeys.length; lcInd++)
                        {
                            var lCKey = lColKeys[lcInd];
                            var lCVal = [];
                            for(var lvInd=0; lvInd<lColl.length; lvInd++)
                            {
                                var lColVal = lColl[lvInd];
                                if(lColVal[lCKey] == null)
                                {
                                    lCVal.push('');
                                }
                                else
                                {
                                    lCVal.push(lColVal[lCKey]);
                                }
                            }

                            lColObj[lCKey] = (lCVal) ? lCVal : '';
                        }
                        
                        lRetCol[ lKey ] = lColObj;
                    }
                }
            }
        }

        return lRetCol;
    }

    cfPromptSave(lActionItem, lResponseFn)
    {
        if(lActionItem.hasOwnProperty('cs_form_validation_'))
        {
            var lValObj = null;
            var lValidationColl = lActionItem.cs_form_validation_;
            for(var lFv_0=0; lFv_0<lValidationColl.length; lFv_0++)
            {
                lValObj = lValidationColl[lFv_0];
                if(lValObj.trigger_point_ === "BEFORE_SAVE")
                {
                    break;
                }
            }

            if(lValObj)
            {
                var lTitle = lValObj.title_;
                if(!lTitle)
                {
                    lTitle = lActionItem.title_;
                }

                Swal.fire(
                    {
                        title               : lTitle,
                        text                : lValObj.message_,
                        icon                : 'warning',
                        showCancelButton    : true,
                        cancelButtonText    : 'No',
                        confirmButtonText   : 'Yes',
                        allowOutsideClick   : false,
                        showLoaderOnConfirm : true,
                        willClose : (lResponse) => {}
                    }
                ).then((lResult) => 
                    {
                        if(lResponseFn)
                        {
                            lResponseFn(lResult); 
                        }
                    }
                );
            }
        }
    }

    cfSaveValidation(lActionItem, lValidationMessage)
    {
        if(lValidationMessage)
        {
            var lTitle = lActionItem.title_;
            Swal.fire(
                {
                    title               : lTitle,
                    text                : lValidationMessage,
                    icon                : 'warning',
                    allowOutsideClick   : false
                }
            );
        }
    }

    cfPromptDelete(lActionItem, lResponseFn)
    {
        if(lActionItem.hasOwnProperty('cs_form_validation_'))
        {
            var lValObj = null;
            var lValidationColl = lActionItem.cs_form_validation_;
            for(var lFv_0=0; lFv_0<lValidationColl.length; lFv_0++)
            {
                lValObj = lValidationColl[lFv_0];
                if(lValObj.trigger_point_ === "BEFORE_DELETE")
                {
                    break;
                }
            }

            if(lValObj)
            {
                var lTitle = lValObj.title_;
                if(!lTitle)
                {
                    lTitle = lActionItem.title_;
                }

                Swal.fire(
                    {
                        title               : lTitle,
                        text                : lValObj.message_,
                        icon                : 'warning',
                        showCancelButton    : true,
                        cancelButtonText    : 'No',
                        confirmButtonText   : 'Yes',
                        allowOutsideClick   : false,
                        showLoaderOnConfirm : true,
                        willClose : (lResponse) => {}
                    }
                ).then((lResult) => 
                    {
                        if(lResponseFn)
                        {
                            lResponseFn(lResult); 
                        }
                    }
                );
            }
        }
    }

    cfProcessSave(lActionItem, lFormData, fCallBack, lCriteria)
    {
        var lFormUtil = CSFormUtil.cfGetInstance();
        var lServiceUtil = CSServiceUtil.cfGetInstance();
        if(lActionItem && lFormData && lFormUtil && lServiceUtil)
        {
            var lServiceCode = lActionItem.on_click_service_code_;
            var lRequestId = lActionItem.request_id_;
            if(lActionItem.hasOwnProperty('cs_form_action_properties_'))
            {
                var lLength = lActionItem.cs_form_action_properties_.length
                for(var lFapInd=0; lFapInd<lLength; lFapInd++)
                {
                    var lFapObj = lActionItem.cs_form_action_properties_[lFapInd];
                    if((lFapObj.type_ === 'save_request') && (lFapObj.property_ === 'request_id') && lFapObj.value_)
                    {
                        if(lFapObj.selection_property_ && lFormData.hasOwnProperty(lFapObj.selection_property_))
                        {
                            var lData = lFormData[lFapObj.selection_property_];
                            if(lData)
                            {
                                if(lFapObj.selection_value_ === '#EMPTY#')
                                {
                                    if((lData instanceof Array) && (lData.length === 0))
                                    {
                                        lRequestId = lFapObj.value_;
                                    }
                                }
                                else if(lFapObj.selection_value_ === '#NOT_EMPTY#')
                                {
                                    if((lData instanceof Array) && (lData.length > 0))
                                    {
                                        lRequestId = lFapObj.value_;
                                    }
                                }
                            }
                        }
                    }
                }    
            }
            
            if(lServiceCode || lRequestId)
            {
                var lServiceObject = lServiceUtil.cfGetServiceObject(lServiceCode);
                if(lServiceObject)
                {
                    var lRequestUtil = null;
                    if(lActionItem.action_ === 'generate_progress_card')
                    {
                        lRequestUtil = new CSRequestUtil('GENERATE', lServiceCode, lServiceObject.type_, lRequestId, lServiceObject);
                    }
                    else
                    {
                        lRequestUtil = new CSRequestUtil('SAVE', lServiceCode, lServiceObject.type_, lRequestId, lServiceObject);
                    }

                    if(lRequestUtil)
                    {
                        var lFormSaveData;
                        if(lFormData instanceof Array)
                        {
                            if(!lRequestId)
                            {
                                lFormSaveData = this.cfGetDataCollection(lFormData);
                            }
                            else
                            {
                                lFormSaveData = this.cfGetDataFieldCollection(lFormData);
                            }
                        }
                        else
                        {
                            lFormSaveData = this.cfGetDataFieldCollection(lFormData);
                        }
                        
                        if(lFormSaveData)
                        {
                            if(lCriteria)
                            {
                                lRequestUtil.cfSetRequestCriteria(lCriteria);
                            }
                            else if(lActionItem.action_ === 'generate_progress_card')
                            {
                                lRequestUtil.cfSetRequestCriteria(lFormData);
                            }
                            
                            lRequestUtil.cfSetRequestData(lFormSaveData);
                            lRequestUtil.cfProcessRequest(fCallBack);
                        }
                    }
                }
            }
        }
    }

    cfProcessError (lErrorMsg) {
        Swal.fire(
            {
                title               : "Error",
                text                : lErrorMsg,
                icon                : 'error',
                allowOutsideClick   : false
            }
        );
    }

    cfProcessSaveResponse(lCode, lMsg, lActionItem, lResponse)
    {
        var lPostSaveData = null;
        if(lActionItem)
        {
            var lRetValueMsgMap = {};
            var lValidationColl = lActionItem.cs_form_validation_;
            for(var lFv_0=0; lFv_0<lValidationColl.length; lFv_0++)
            {
                var lValObj = lValidationColl[lFv_0];
                if((lValObj.trigger_point_ === "AFTER_SAVE") && lValObj.ret_val_)
                {
                    lRetValueMsgMap[lValObj.ret_val_] = lValObj;
                }
                else if(lValObj.trigger_point_ === "POST_SAVE")
                {
                    if(!lPostSaveData)
                    {
                        lPostSaveData = []
                    }

                    lPostSaveData.push(lValObj);
                }
            }

            var lObj = lRetValueMsgMap[lCode];
            if(lObj)
            {
                var lTitle = lObj.title_;
                if(!lTitle)
                {
                    lTitle = lActionItem.title_;
                }

                if(((lCode === '1') || (lCode === 1)) && (lObj.ret_val_ === '1'))
                {
                    if (!lPostSaveData)
                    {
                        Swal.fire(
                            {
                                title               : lTitle,
                                text                : lObj.message_,
                                icon                : 'success',
                                allowOutsideClick   : false
                            }
                        );
                    }
                    else
                    {
                        Swal.fire(
                            {
                                title               : lTitle,
                                text                : (lPostSaveData[0].message_) ? lPostSaveData[0].message_ : lObj.message_,
                                icon                : 'success',
                                allowOutsideClick   : false
                            }
                        );
                    }
                }
                else
                {
                    Swal.fire(
                        {
                            title               : lTitle,
                            text                : lObj.message_,
                            icon                : 'error',
                            allowOutsideClick   : false
                        }
                    );
                }
            }
            else
            {
                if(lCode === 1)
                {
                    if(!lMsg)
                    {
                        lMsg = 'Save Successful !';
                    }

                    if(!lPostSaveData)
                    {
                        Swal.fire(
                            {
                                title               : lActionItem.title_,
                                text                : lMsg,
                                type                : 'success',
                                allowOutsideClick   : false
                            }
                        );
                    }
                }
                else
                {
                    if(!lMsg)
                    {
                        lMsg = 'Internal Server Error !';
                    }

                    Swal.fire(
                        {
                            title               : lActionItem.title_,
                            text                : lMsg,
                            type                : 'error',
                            allowOutsideClick   : false
                        }
                    );
                }
            }
        }

        return lPostSaveData;
    }

    cfProcessDelete(lActionItem, lCriteria, fCallBack)
    {
        var lFormUtil = CSFormUtil.cfGetInstance();
        var lServiceUtil = CSServiceUtil.cfGetInstance();
        if(lActionItem && lFormUtil && lServiceUtil)
        {
            var lServiceCode = lActionItem.on_click_service_code_;
            if(lServiceCode)
            {
                var lServiceObject = lServiceUtil.cfGetServiceObject(lServiceCode);
                if(lServiceObject)
                {
                    var lRequestUtil = new CSRequestUtil('DELETE', lServiceCode, lServiceObject.type_);
                    if(lRequestUtil)
                    {
						if(lCriteria)
						{
							lRequestUtil.cfSetRequestCriteria(lCriteria);
						}
						         
						lRequestUtil.cfProcessRequest(fCallBack);
                    }
                }
            }
        }
    }

    cfProcessDeleteResponse(lCode, lMsg, lActionItem, lResponse)
    {
        if(lActionItem)
        {
            var lRetValueMsgMap = {};
            var lValidationColl = lActionItem.cs_form_validation_;
            for(var lFv_0=0; lFv_0<lValidationColl.length; lFv_0++)
            {
                var lValObj = lValidationColl[lFv_0];
                if((lValObj.trigger_point_ === "AFTER_DELETE") && lValObj.ret_val_)
                {
                    lRetValueMsgMap[lValObj.ret_val_] = lValObj;
                }
            }

            var lObj = lRetValueMsgMap[lCode];
            if(lObj)
            {
                var lTitle = lObj.title_;
                if(!lTitle)
                {
                    lTitle = lActionItem.title_;
                }

                if(((lCode === '1') || (lCode === 1)) && (lObj.ret_val_ === '1'))
                {
                    Swal.fire(
                        {
                            title               : lTitle,
                            text                : lObj.message_,
                            icon                : 'success',
                            allowOutsideClick   : false
                        }
                    );
                }
                else
                {
                    Swal.fire(
                        {
                            title               : lTitle,
                            text                : lObj.message_,
                            icon                : 'error',
                            allowOutsideClick   : false
                        }
                    );
                }
            }
            else
            {
                if(lCode === 1)
                {
                    if(!lMsg)
                    {
                        lMsg = 'Delete Successful !';
                    }
                    Swal.fire(
                        {
                            title               : lTitle,
                            text                : lMsg,
                            type                : 'success',
                            allowOutsideClick   : false
                        }
                    );
                 }
                else
                {
                    if(!lMsg)
                    {
                        lMsg = 'Internal Server Error !';
                    }

                    Swal.fire(
                        {
                            title               : lTitle,
                            text                : lMsg,
                            type                : 'error',
                            allowOutsideClick   : false
                        }
                    );
                }
            }
        }
    }

    cfGetGroupCollection(lCollection, lGroupFields, lKeyField) 
    {
        var lDataFieldObjectMap = {};
        var lRetValue = {};

        if(!lKeyField)
        {
            lKeyField = 'id_';
        }

        if(lCollection && lGroupFields && (lGroupFields.length > 0))
        {
            for(var lInd_1=0; lInd_1<lGroupFields.length; lInd_1++)
            {   
                var lGroupingName = lGroupFields[lInd_1];
                for(var lInd_0=0; lInd_0<lCollection.length; lInd_0++)
                {
                    var lDataItem = lCollection[lInd_0];

                    var lTreeItem = {};
                    lTreeItem.key = lDataItem[lKeyField];
                    lTreeItem.data = lDataItem;
                    if(!lRetValue.keyObjectMap)
                    {
                        lRetValue.keyObjectMap = {};
                    }

                    lRetValue.keyObjectMap[lTreeItem.key] = lTreeItem;

                    var lObj = lDataFieldObjectMap[lDataItem[lGroupingName]];
                    if(!lObj)
                    {
                        lDataFieldObjectMap[lDataItem[lGroupingName]] = lTreeItem;
                        if(!lRetValue.collection)
                        {
                            lRetValue.collection = [];
                        }

                        lRetValue.collection.push(lTreeItem);
                    }
                    else
                    {
                        if(!lObj.children)
                        {
                            lObj.children = [];
                        }

                        lObj.children.push(lTreeItem);
                    }
                }
            }
        }

        return lRetValue;
    }
    
    cfGetTreeCollection(lCollection, lGroupField, lKeyField)
    {
        var lRetVal = {}
        var lKeyFieldObjectMap = {};
        var lGroupFieldChildrenMap = {};
        if(lCollection && lGroupField && lKeyField)
        {
            for(var lInd_0=0; lInd_0<lCollection.length; lInd_0++)
            {
                var lDataObj = lCollection[lInd_0];

                var lkeyObj  = {};
                lkeyObj.key  = 10001 + lInd_0;
                lkeyObj.data = {};
                lkeyObj.data = lDataObj;
                lkeyObj.data['__NAME__'] = lDataObj[lKeyField];

                lKeyFieldObjectMap[lDataObj[lKeyField]] = lkeyObj;
                var lChildren = null;
                var lParentObject = lKeyFieldObjectMap[ lDataObj[lGroupField] ];
                if(lParentObject)
                {
                    if(!lParentObject.children)
                    {
                        lChildren = lGroupFieldChildrenMap[ lDataObj[lGroupField] ];
                        if(lChildren)
                        {
                            lParentObject.children = lChildren;
                        }
                        else
                        {
                            lParentObject.children = [];    
                            lChildren = lParentObject.children;
                            lGroupFieldChildrenMap[ lDataObj[lGroupField] ] = lChildren;
                        }
                    }
                    else
                    {
                        lChildren = lParentObject.children;
                    }
                }
                else
                {
                    lChildren = lGroupFieldChildrenMap[lDataObj[lGroupField]];
                    if(!lChildren)
                    {
                        lChildren = [];
                        lGroupFieldChildrenMap[lDataObj[lGroupField]] = lChildren;
                    }
                }

                if(lChildren)
                {
                    lChildren.push(lkeyObj);
                }

                if(!lkeyObj.children)
                {
                    if(lGroupFieldChildrenMap[lDataObj[lKeyField]])
                    {
                        lkeyObj.children = lGroupFieldChildrenMap[lDataObj[lKeyField]];
                    }
                }
            }

            for(var lInd_1=0; lInd_1<lCollection.length; lInd_1++)
            {
                var lDataObj1 = lCollection[lInd_1];
                if(!lKeyFieldObjectMap[ lDataObj1[lGroupField] ] || !lDataObj1[lGroupField])
                {
                    if(!lRetVal.collection)
                    {
                        lRetVal.collection = [];
                    }

                    lRetVal.collection.push(lKeyFieldObjectMap[lDataObj1[lKeyField]])
                }
            }

            lRetVal.keyObjectMap = lKeyFieldObjectMap;
        }

        return lRetVal;
    }

    cfGetGroupedCollection(lCollection, lGroupFields, lGroupFieldGridItemObjectMap)
    {
        let lRetValue = {};
        let lKeyIndexVal = 100001;
        let lL1KeyObjectMap = {}        
        if(lCollection && lGroupFields && (lGroupFields.length > 0))
        {
            let lGroupFieldLength = lGroupFields.length;
            let lDataFieldObjectMap = {};
            let lGroupField = lGroupFields[0];
            let lDataField = null;
            for(let lInd_0=0; lInd_0<lCollection.length; lInd_0++)
            {
                let lDataObj = lCollection[lInd_0];
                lDataField = lDataObj[lGroupField];
                let lKeyObj1 = lL1KeyObjectMap[lDataField];
                if(!lKeyObj1)
                {
                    var lkey1 = lKeyIndexVal++;
                    lKeyObj1 = {};
                    lKeyObj1.key = lkey1;
                    lKeyObj1.data = {};
                    lKeyObj1.data[lGroupField] = lDataField;
                    lKeyObj1.data['__NAME__'] = lDataField;
                    lKeyObj1.children = [];

                    lL1KeyObjectMap[lDataField] = lKeyObj1;
                    if(!lRetValue.collection)
                    {
                        lRetValue.collection = [];
                    }
            
                    lRetValue.collection.push(lKeyObj1);
                }

                if(lGroupFieldLength === 1)
                {
                    let lEntityObject = {}
                    lEntityObject.key = (lKeyIndexVal++).toString();
                    lEntityObject.data = lDataObj;
                    lDataFieldObjectMap[lEntityObject.key] = lEntityObject;

                    lKeyObj1.children.push(lEntityObject);
                }
                else
                {
                    if(!lKeyObj1.collection)
                    {
                        lKeyObj1.collection = [];
                    }

                    lKeyObj1.collection.push(lDataObj);
                }
            }

            if(lGroupFieldLength > 1)
            {
                lGroupField = lGroupFields[1];
                let lKeys = Object.keys(lL1KeyObjectMap);
                if(lKeys)
                {
                    for(let lkInd=0; lkInd<lKeys.length; lkInd++)
                    {
                        let lKey = lKeys[lkInd];
                        let lL1Object = lL1KeyObjectMap[lKey];
                        if(lL1Object.hasOwnProperty('collection'))
                        {
                            let lL1Children = lL1Object.collection;
                            if(lL1Children)
                            {
                                let lL2KeyObjectMap = {}
                                for(let lc1Ind=0; lc1Ind<lL1Children.length; lc1Ind++)
                                {
                                    let lL2Object = lL1Children[lc1Ind];
                                    lDataField = lL2Object[lGroupField];
                                    let lKeyObj2 = lL2KeyObjectMap[lDataField];
                                    if(!lKeyObj2)
                                    {
                                        let lkey2 = lkey1 + (lkInd + 1).toString();
                                        lKeyObj2 = {};
                                        lKeyObj2.key = lkey2;
                                        lKeyObj2.data = {};
                                        lKeyObj2.data[lGroupFields[0]] = lL2Object[lGroupFields[0]];
                                        lKeyObj2.data[lGroupField] = lDataField;
                                        lKeyObj2.data['__NAME__'] = lDataField;
                                        lKeyObj2.children = [];
                                        if(lGroupFieldLength === 2)
                                        {
                                            lKeyObj2.data = CSUtil.cfGetObjectCopy(lL2Object);
                                            lKeyObj2.data[lGroupFields[0]] = lL2Object[lGroupFields[0]];
                                            lKeyObj2.data[lGroupField] = lDataField;
                                            lKeyObj2.data['__NAME__'] = lDataField;
                                        }
                    
                                        lL2KeyObjectMap[lDataField] = lKeyObj2;

                                        if(!lL1Object.children)
                                        {
                                            lL1Object.children = [];
                                        }

                                        lL1Object.children.push(lKeyObj2);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        else
        {
            lRetValue = lCollection;
        }

        return lRetValue;
    }

    fnGetRequestObject = (lFormItem, lDataCollection, lFormData, lRequestData, lIdFieldObjectMap, lActionItem) =>
    {
        var lRetObj;
        var lIdSelIdMap = null;
        var lFormItemProperties = lFormItem.cs_form_item_properties_;
        if(lFormItemProperties)
        {
            for(var lfipInd=0; lfipInd<lFormItemProperties.length; lfipInd++)
            {
                var lfipObj = lFormItemProperties[lfipInd];
                if(lfipObj.type_ === 'request_data')
                {
                    if((lfipObj.property_ === '#CONSTANT#') && (lFormItem.control_type_ !== 'aggregation'))
                    {
                        if(lFormItem.control_type_ !== 'collection')
                        {
                            if(!lRetObj)
                            {
                                lRetObj = {}
                            }
        
                            lRetObj[lfipObj.selection_property_] = cfGetConstantValue(lfipObj.selection_value_);
                        }
                        else
                        {
                            if(!lRetObj)
                            {
                                lRetObj = lDataCollection;
                            }
        
                            if(lRetObj)
                            {
                                for(var lcInd=0; lcInd<lRetObj.length; lcInd++)
                                {
                                    var lcObj = lRetObj[lcInd];
                                    lcObj[lfipObj.selection_property_] = cfGetConstantValue(lfipObj.selection_value_);
                                }
                            }
                        }
                    }
                    else
                    {
                        var lSelectedData = null;
                        if(lfipObj.value_)
                        {
                            if(lFormData && lFormData.hasOwnProperty(lfipObj.value_))
                            {
                                lSelectedData = lFormData[lfipObj.value_];
                            }
                        }
                        else if(lfipObj.property_ === 'collection_data')
                        {
                            lSelectedData = CSUtil.cfGetObjectCopy(lDataCollection);
                        }

                        if(lFormItem.control_type_ === 'collection')
                        {
                            if(lSelectedData && (lSelectedData instanceof Array))
                            {
                                if(!lRetObj)
                                {
                                    lRetObj = [];
                                }

                                if(!lIdSelIdMap)
                                {
                                    lIdSelIdMap = [];
                                }

                                for(var lcInd1=0; lcInd1< lSelectedData.length; lcInd1++)
                                {
                                    var lAddFlag = true;
                                    var lSelObject = lSelectedData[lcInd1];
                                    if(lfipObj.dependent_item_ref_id_ && lIdFieldObjectMap)
                                    {
                                        var lDependentItem = lIdFieldObjectMap[lfipObj.dependent_item_ref_id_];
                                        if(lDependentItem)
                                        {
                                            if(lSelObject[lDependentItem.data_field_] === lDependentItem.constant_)
                                            {
                                                lAddFlag = true;
                                            }
                                            else
                                            {
                                                lAddFlag = false;
                                            }
                                        }
                                    }

                                    if(lAddFlag)
                                    {
/*                                        var lRId = lIdSelIdMap[lcInd1];
                                        if(lRId === undefined)
                                        {
                                            lRId = lRetObj.length;
                                            lIdSelIdMap[lRetObj.length] = lcInd1;
                                        }
*/

                                        var lcObj1 = lIdSelIdMap[lcInd1];
                                        if(!lcObj1)
                                        {
                                            lcObj1 = {}
                                            lRetObj.push(lcObj1);
                                            lIdSelIdMap[lcInd1] = lcObj1;
                                        }
    
                                        lcObj1[lfipObj.selection_property_] = lSelObject[lfipObj.selection_value_];
                                    }
                                }
                            }
                            else
                            {
                                if(!lRetObj)
                                {
                                    lRetObj = lDataCollection;
                                }

                                if(lRetObj && lSelectedData)
                                {
                                    for(var lcInd0=0; lcInd0<lRetObj.length; lcInd0++)
                                    {
                                        var lcObj0 = lRetObj[lcInd0];
                                        lcObj0[lfipObj.selection_property_] = (lSelectedData[lfipObj.selection_value_]) ? lSelectedData[lfipObj.selection_value_] : '';
                                    }
                                }
                            }
                        }
                        else if(lFormItem.control_type_ === 'aggregation')
                        {
                            if(lfipObj.property_ === 'form_data')
                            {
                                if(lRetObj && lSelectedData)
                                {
                                    if(lRetObj instanceof Array)
                                    {
                                        for(var lrInd=0; lrInd<lRetObj.length; lrInd++)
                                        {
                                            var lrSelObj = lRetObj[lrInd];
                                            lrSelObj[lfipObj.selection_property_] = lSelectedData[lfipObj.selection_value_];
                                        }
                                    }
                                }
                            }
                            else
                            {
                                var lSelection = null;
                                if(lfipObj.property_ === '#CONSTANT#')
                                {
                                    lSelection = cfGetConstantValue(lfipObj.selection_value_);
                                }
                                else if(lActionItem && (lfipObj.property_ === '#STATE#'))
                                {
                                    lSelection = lActionItem.action_code_;
                                }
                                else if(lSelectedData)
                                {
                                    lSelection = lSelectedData[lfipObj.selection_value_];
                                }

                                if(lSelection)
                                {
                                    if((lSelection instanceof Array) && (lSelection.length > 0))
                                    {
                                        if(!lRetObj)
                                        {
                                            lRetObj = [];
                                        }
        
                                        for(var lsInd=0; lsInd<lSelection.length; lsInd++)
                                        {
                                            var lSelObj = lSelection[lsInd];
        
                                            var lObj = {}
                                            lObj[lfipObj.selection_property_] =  lSelObj[lfipObj.property_];
                                            lRetObj.push(lObj);
                                        }
                                    }
                                    else
                                    {
                                        if(lRequestData && !lRetObj)
                                        {
                                            lRetObj = lRequestData[lFormItem.data_field_];
                                        }

                                        if(!lRetObj)
                                        {
                                            lRetObj = {};
                                        }

                                        var lObj1 = null;
                                        if(lRetObj.hasOwnProperty(lfipObj.selection_property_))
                                        {
                                            lObj1 = lRetObj[lfipObj.selection_property_];
                                        }

                                        if(!lObj1)
                                        {
                                            lObj1 = [];
                                            lRetObj[lfipObj.selection_property_] = lObj1;
                                        }

                                        lObj1.push(lSelection);
                                    }
                                }
                            }
                        }
                        else
                        {
                            if(!lRetObj)
                            {
                                lRetObj = {}
                            }
        
                            if(lfipObj.dependent_item_ref_id_ && lIdFieldObjectMap)
                            {
                                if((lfipObj.property_ === 'concat_data') && lSelectedData)
                                {
                                    var lDepDataField = null;
                                    var lDependentFormItem = lIdFieldObjectMap[lfipObj.dependent_item_ref_id_];
                                    if(lDependentFormItem)
                                    {
                                        lDepDataField = lDependentFormItem.reference_field_;
                                        if(lDepDataField)
                                        {
                                            var lFiData = lSelectedData[lfipObj.selection_value_];
                                            if(lFiData instanceof Array)
                                            {
                                                var lFidataObj = null;
                                                for(var lfidIn=0; lfidIn<lFiData.length; lfidIn++)
                                                {
                                                    if(!lFidataObj)
                                                    {
                                                        lFidataObj = "'" + lFiData[lfidIn][lDepDataField] + "'";
                                                    }
                                                    else
                                                    {
                                                        lFidataObj = lFidataObj + ", '" + lFiData[lfidIn][lDepDataField] + "'";
                                                    }
                                                }

                                                lRetObj[lfipObj.selection_property_] = lFidataObj;
                                            }
                                        }
                                    }
                                }
                            }
                            else
                            {
                                if(lActionItem && (lfipObj.property_ === '#STATE#'))
                                {
                                    lRetObj[lfipObj.selection_property_] = lActionItem.action_code_;
                                }
                                else if(lSelectedData)
                                {
                                    lRetObj[lfipObj.selection_property_] = lSelectedData[lfipObj.selection_value_];
                                }
                            }
                        }
                    }                        
                }
            }
        }

        return lRetObj;
    }

    static cfIncludeAction(lActionItem, lSelectedData)
    {
        var lInclude = true;
        if(lSelectedData && lActionItem && lActionItem.hasOwnProperty('cs_form_action_properties_') && lActionItem.cs_form_action_properties_)
        {
            var lLength = lActionItem.cs_form_action_properties_.length
            for(var lFapInd=0; lFapInd<lLength; lFapInd++)
            {
                var lFapObj = lActionItem.cs_form_action_properties_[lFapInd];
                if((lFapObj.type_ === 'form_initialize') && (lFapObj.property_ === 'include') && lFapObj.selection_property_ && lFapObj.selection_value_)
                {
                    if(lSelectedData.hasOwnProperty(lFapObj.selection_property_))
                    {
                        var lSelectedPropertyValue = lSelectedData[lFapObj.selection_property_];
                        if((lFapObj.selection_value_ === '#NOT_NULL#') || (lFapObj.selection_value_ === '#NULL#'))
                        {
                        }
                        else
                        {
                            var lSelectionValue =  cfGetConstantValue(lFapObj.selection_value_);
                            if(lFapObj.value_ === 'true')
                            {
                                lInclude = lInclude && (lSelectedPropertyValue === lSelectionValue);
                            }
                            else
                            {
                                lInclude = lInclude && (lSelectedPropertyValue !== lSelectionValue);
                            }

                            if(!lInclude)
                            {
                                break;
                            }
                        }
                    }
                }
            }
        }

        return lInclude;
    }

    static cfIsDoubleClickEnabled(lActionItem)
    {
        var lRetVal = false;
        if(lActionItem.hasOwnProperty('cs_form_action_properties_'))
        {
            var lLength = lActionItem.cs_form_action_properties_.length
            for(var lFapInd=0; lFapInd<lLength; lFapInd++)
            {
                var lProperty = lActionItem.cs_form_action_properties_[lFapInd];
                if((lProperty.type_ === 'item_selection') && (lProperty.property_ === 'double_click') && (lProperty.value_ === 'true'))
                {
                    lRetVal = true;
                    break;
                }
            }
        }

        return lRetVal;
    }

    static cfEnableAction(lActionItem, lSelectedData, lFormItems, lFormDataList)
    {
        var lEnabled = false;
        if(lActionItem)
        {
            var lActionType = lActionItem.component_type_;
            lEnabled = (lActionItem.enabled_ === true) || (lActionItem.enabled_ === '1');
            var lIsEnabled = true;
            if(lActionType === 'CRITERIA_ACTION')
            {
                if(lFormItems && lSelectedData)
                {
                    var lIsEnabled0 = true;
                    for(var lFiInd=0; lFiInd< lFormItems.length; lFiInd++)
                    {
                        var lFormItem = lFormItems[lFiInd];
                        if(lFormItem.required_ === '1')
                        {
                            if(lSelectedData.hasOwnProperty(lFormItem.criteria_field_))
                            {
                                var lSelectedFieldValue = lSelectedData[lFormItem.criteria_field_];
                                if(!lSelectedFieldValue)
                                {
                                    lIsEnabled0 = false;
                                    break;
                                }
                            }
                            else
                            {
                                lIsEnabled0 = false;
                                break;
                            }
                        }    
                    }
                
                    lEnabled = lIsEnabled0;
                }
            }
            else if((lActionType === 'SEARCH_ACTION') || (lActionType === 'SAVE_MULTIPLE_ACTION'))
            {
                if(lActionItem.hasOwnProperty('cs_form_action_properties_'))
                {
                    lIsEnabled = false;
                    var lLength = lActionItem.cs_form_action_properties_.length
                    for(var lFapInd=0; lFapInd<lLength; lFapInd++)
                    {
                        var lSelectedProperty = null;;
                        var lFapObj = lActionItem.cs_form_action_properties_[lFapInd];
                        if(((lFapObj.type_ === 'item_selection') || (lFapObj.type_ === 'constant') || (lFapObj.type_ === 'or_selection') || (lFapObj.type_ === 'form_initialize')) && (lFapObj.selection_property_) && (lFapObj.selection_value_))
                        {
                            if(lSelectedData)
                            {
                                lSelectedProperty = lSelectedData[lFapObj.selection_property_];
                            }

                            if(!lSelectedProperty && (lFapObj.type_ === 'constant'))
                            {
                                lSelectedProperty = cfGetConstantValue(lFapObj.selection_property_);
                            }

                            if(lFapObj.selection_value_ === '#NOT_NULL#')
                            {
                                if(lSelectedProperty)
                                {
                                    lIsEnabled = (lFapObj.value_ === 'true');
                                }
                                else
                                {
                                    lIsEnabled = !(lFapObj.value_ === 'true');
                                }
                            }
                            else if(lFapObj.selection_value_ === '#NULL#')
                            {
                                if(!lSelectedProperty)
                                {
                                    lIsEnabled = (lFapObj.value_ === 'true');
                                }
                                else
                                {
                                    lIsEnabled = !(lFapObj.value_ === 'true');
                                }
                            }
                            else
                            {
                                if(lSelectedProperty)
                                {   
                                    var lValue = lFapObj.selection_value_;
                                    var lEqual = (lValue.includes(lSelectedProperty));
                                    if(lEqual)
                                    {
                                        if(lFapObj.value_ === 'true')
                                        {
                                            lIsEnabled = true;
                                        }
                                        else
                                        {
                                            lIsEnabled = false;
                                        }
                                    }
                                    else
                                    {
                                        if(lFapObj.value_ === 'true')
                                        {
                                            lIsEnabled = false;
                                        }
                                        else
                                        {
                                            lIsEnabled = true;
                                        }
                                    }
                                }
                            }
                        }
                        else if(lFapObj.type_ === 'item_details')
                        {
                            lIsEnabled = true;
                        }
                        
                        if(lFapInd === 0)
                        {
                            lEnabled = lIsEnabled;
                        }
                        else
                        {
                            if(lFapObj.type_ === 'or_selection')
                            {
                                lEnabled = lEnabled || lIsEnabled;
                            }
                            else
                            {
                                lEnabled = lEnabled && lIsEnabled;
                                
                                if(!lEnabled)
                                {
                                    break;
                                }
                            }
                        }
                    }
                }
            }
            else if((lActionType === 'DETAILS_ACTION') || (lActionType === 'DETAILS_ITEM_DETAILS_ACTION'))
            {
                if(lFormItems && (lSelectedData || lFormDataList))
                {
                    for(var lFiInd0=0; lFiInd0< lFormItems.length; lFiInd0++)
                    {
                        var lFormItem0 = lFormItems[lFiInd0];
                        if(lFormItem0.required_ === '1')
                        {
                            if(lFormDataList && (lFormDataList instanceof Array))
                            {
                                var lEnableFlag0 = false;
                                for(var lFdInd=0; lFdInd<lFormDataList.length; lFdInd++)
                                {
                                    var lFormDataObj = lFormDataList[lFdInd];
                                    if(lFormDataObj.hasOwnProperty(lFormItem0.data_field_))
                                    {
                                        var lSelectedValue = lFormDataObj[lFormItem0.data_field_];
                                        if(lSelectedValue)
                                        {
                                            lEnableFlag0 = true;
                                            break;
                                        }
                                    }
                                }

                                if(!lEnableFlag0)
                                {
                                    lIsEnabled = false;
                                    break;
                                }
                            }
                            else if(lSelectedData)
                            {
                                if(lSelectedData.hasOwnProperty(lFormItem0.data_field_))
                                {
                                    var lSelectedFieldValue0 = lSelectedData[lFormItem0.data_field_];
                                    if(!lSelectedFieldValue0 || ((lSelectedFieldValue0 instanceof Array) && lSelectedFieldValue0.length === 0))
                                    {
                                        lIsEnabled = false;
                                        break;
                                    }
                                }
                                else
                                {
                                    lIsEnabled = false;
                                    break;
                                }
                            }
                        }    
                    }

                    if(lActionItem.hasOwnProperty('cs_form_action_properties_'))
                    {
                        for(var lFapInd0 = 0; lFapInd0<lActionItem.cs_form_action_properties_.length; lFapInd0++)
                        {
                            var lFapObj0 = lActionItem.cs_form_action_properties_[lFapInd0];
                            if((lFapObj0.type_ === 'item_selection') && (lFapObj0.selection_property_) && (lFapObj0.selection_value_))
                            {
                                if(lSelectedData.hasOwnProperty(lFapObj0.selection_property_))
                                {
                                    lSelectedProperty = lSelectedData[lFapObj0.selection_property_];
                                    if(lFapObj0.selection_value_ === '#NOT_NULL#')
                                    {
                                        if(lSelectedProperty)
                                        {
                                            lIsEnabled = (lFapObj0.value_ === 'true');
                                        }
                                        else
                                        {
                                            lIsEnabled = !(lFapObj0.value_ === 'true');
                                        }
                                    }
                                    else if(lFapObj0.selection_value_ === '#NULL#')
                                    {
                                        if(!lSelectedProperty)
                                        {
                                            lIsEnabled = (lFapObj0.value_ === 'true');
                                        }
                                        else
                                        {
                                            lIsEnabled = !(lFapObj0.value_ === 'true');
                                        }
                                    }
                                    else if(lFapObj0.selection_value_ === '#POSITIVE#')
                                    {
                                        lIsEnabled = (Number(lSelectedProperty) > 0) ? true : false;
                                    }
                                    else
                                    {
                                        if(lSelectedProperty && lFapObj0.selection_value_)
                                        {
                                            var lEqual0 = (lSelectedProperty === lFapObj0.selection_value_);
                                            if(lEqual0)
                                            {
                                                if(lFapObj0.value_ === 'true')
                                                {
                                                    lIsEnabled = true;
                                                }
                                                else
                                                {
                                                    lIsEnabled = false;
                                                }
                                            }
                                            else
                                            {
                                                if(lFapObj0.value_ === 'true')
                                                {
                                                    lIsEnabled = false;
                                                }
                                                else
                                                {
                                                    lIsEnabled = true;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            if(lFapInd0 === 0)
                            {
                                lEnabled = lIsEnabled;
                            }
                            else
                            {
                                lEnabled = lEnabled && lIsEnabled;
                                    
                                if(!lEnabled)
                                {
                                    lIsEnabled = false;
                                    break;
                                }
                            }
                        }
                    }

                    lEnabled = lIsEnabled;
                }
            }
            else if(lActionType === 'DETAILS_ITEM_SEARCH_ACTION')
            {
                if(lSelectedData)
                {
                    lEnabled = true;
                }
            }
            else if(lActionType === 'IMPORT_ACTION')
            {
                if(lSelectedData && (lSelectedData.length > 0))
                {
                    lEnabled = true;
                }
            }
        }

        return lEnabled;
    }

    cfSetDependentFieldData(lFormItem, lIdFormItemMap)
    {
        if(lFormItem && lFormItem.dependent_id_ && lIdFormItemMap)
        {
            var lDependentItem = lIdFormItemMap[lFormItem.dependent_id_];
            if(lDependentItem)
            {
                var lDependentField = lFormItem.dependent_field_;
                if(lDependentField)
                {
                    lFormItem.dependent_field_ = lDependentField;

                    var lDependentData = null;
                    if(lDependentItem.control_type_ === 'combo_box')
                    {
                        lDependentData = lDependentItem.selected_data_;
                        if( lDependentData && (lDependentData instanceof Object))
                        {
                            if((lFormItem.control_type_ === 'combo_box') || (lFormItem.control_type_ === 'multi_select_combo_box'))
                            {
                                lFormItem.dependent_value_ = lDependentData[lDependentField];
                            }
                            else
                            {
                                if(lDependentItem.mValue)
                                {
                                    lFormItem.mValue = lDependentData[lDependentField];
                                }
                                else
                                {
                                    lFormItem.mValue = null;
                                }
                            }
                        }
                        else
                        {
                            if(lDependentData && (lFormItem.control_type_ === 'combo_box'))
                            {
                                lFormItem.dependent_value_ = lDependentData;
                            }
                            else
                            {
                                if(lDependentData && lDependentItem.mValue)
                                {
                                    lFormItem.mValue = lDependentData;
                                }
                                else
                                {
//                                    lFormItem.mValue = null;
                                }
                            }
                        }
                    }
                    else
                    {
                        lDependentData = lDependentItem.mValue;
                        if(lDependentData)
                        {
                            if(lDependentData instanceof Object)
                            {
                                if((lFormItem.control_type_ === 'combo_box') || (lFormItem.control_type_ === 'multi_select_combo_box'))
                                {
                                    lFormItem.dependent_value_ = lDependentData[lDependentField];
                                }
                                else
                                {
                                    lFormItem.mValue = lDependentData[lDependentField];
                                }
                            }
                            else
                            {
                                if((lFormItem.control_type_ === 'combo_box') || (lFormItem.control_type_ === 'multi_select_combo_box'))
                                {
                                    lFormItem.dependent_value_ = lDependentData;
                                }
                                else
                                {
                                    lFormItem.mValue = lDependentData;
                                }
                            }
                        }
                    }
                }
                else
                {
                    lFormItem.mValue = lDependentData;
                }

                if((lFormItem.control_type_ === 'combo_box') || (lFormItem.control_type_ === 'multi_select_combo_box'))
                {
                    CSFormUtil.cfGetInstance().cfSetDependentFieldData(lDependentItem, lIdFormItemMap);
                }
            }
        }
    }

    static cfSetDependentFieldsData(lFormItems, lFormData, lChangedFormItem, lSelectedData, lIdFormItemMap)
    {
        var lRetData = lFormData;
        if(lRetData && lFormItems && lChangedFormItem)
        {
            for(var lInd=0; lInd<lFormItems.length; lInd++)
            {
                var lFormItem = lFormItems[lInd];
                if(lFormItem.dependent_id_ && (lFormItem.dependent_id_ === lChangedFormItem.id_))
                {
                    if(lSelectedData)
                    {
                        if(lChangedFormItem.control_type_ === 'combo_box')
                        {
                            if(!lFormItem.reference_field_)
                            {
                                lRetData.dependent_value_ = lSelectedData[lFormItem.dependent_field_];
                                lFormItem.dependent_value_ = lSelectedData[lFormItem.dependent_field_];
                                lFormItem.mValue = lSelectedData[lFormItem.dependent_field_];
                                lRetData[lFormItem.data_field_] = lRetData.dependent_value_;
                                CSFormUtil.cfSetDependentFieldsData(lFormItems, lRetData, lFormItem, lSelectedData[lFormItem.dependent_field_], lIdFormItemMap)
                            }
                            else
                            {
                                lRetData.dependent_value_ = lSelectedData[lFormItem.reference_field_];
                                lFormItem.dependent_value_ = lSelectedData[lFormItem.reference_field_];
                                lFormItem.mValue = lSelectedData[lFormItem.reference_field_];
                                lRetData[lFormItem.data_field_] = lRetData.dependent_value_;
                                CSFormUtil.cfSetDependentFieldsData(lFormItems, lRetData, lFormItem, lSelectedData[lFormItem.reference_field_], lIdFormItemMap)
                            }
                        }
                        else
                        {
                            lRetData[lFormItem.data_field_] = lSelectedData[lFormItem.dependent_field_];
                            lFormItem.mValue = lSelectedData[lFormItem.dependent_field_];
                        }
                    }
                }
            }
        }

        return lRetData;
    }

    static cfGetGridComputeFieldsData(lRowData, lFormGrid, lFormGridItemProperties)
    {
		if(lFormGridItemProperties)
        {
            var lComputeList = null;
            for(var lInd=0; lInd<lFormGridItemProperties.length; lInd++)
            {
                var lProperty = lFormGridItemProperties[lInd];
                if(lProperty.property_ === 'compute')
                {
                    if(!lComputeList)
                    {
                        lComputeList = {}
                    }

                    var lGridRefIdList = lComputeList[lProperty.cs_form_grid_ref_id_];
                    if(!lGridRefIdList)
                    {
                        lGridRefIdList = []
                        lComputeList[lProperty.cs_form_grid_ref_id_] = lGridRefIdList;
                    }

                    lGridRefIdList.push(lProperty);
                }
            }

            if(lComputeList)
            {
                var lGridItemMap = {}
                for(var lgItemIndex=0; lgItemIndex<lFormGrid.length; lgItemIndex++)
                {
                    lGridItemMap[lFormGrid[lgItemIndex].id_] = lFormGrid[lgItemIndex];
                }

                var lKeys = Object.keys(lComputeList);
                for(var lKeyInd=0; lKeyInd<lKeys.length; lKeyInd++)
                {
                    var lKey = lKeys[lKeyInd];
                    var lCompList = lComputeList[lKey];
                    if(lCompList)
                    {
                        var lColumnValue = null;
                        var lDataField = null;
                        CSUtil.cfSort(lCompList, 'sequence_');
                        for(var lcInd=0; lcInd<lCompList.length; lcInd++)
                        {
                            var lcProperty = lCompList[lcInd];
                            if(lcInd === 0)
                            {
                                if(lcProperty.type_ === 'data_field')
                                {
                                    lColumnValue = lRowData[lcProperty.param_]
                                }
                                else if(lcProperty.type_ === 'constant')
                                {
                                    lColumnValue = lcProperty.value_;
                                }

                                lDataField = lGridItemMap[lcProperty.cs_form_grid_ref_id_]['data_field_'];
                            }
                            else
                            {
                                var lVal2 = null;
                                if(lcProperty.type_ === 'data_field')
                                {
                                    lVal2 = lRowData[lcProperty.param_]
                                }
                                else if(lcProperty.type_ === 'constant')
                                {
                                    lVal2 = lcProperty.value_;
                                }

                                if(lcProperty.selection_property_ === 'multiply')
                                {
                                    lColumnValue = Number(lColumnValue) * Number(lVal2);
                                }
                                else if(lcProperty.selection_property_ === 'divide')
                                {
                                    lColumnValue = Number(lColumnValue) / Number(lVal2);
                                }
                                else if(lcProperty.selection_property_ === 'add')
                                {
                                    lColumnValue = Number(lColumnValue) + Number(lVal2);
                                }
                                else if(lcProperty.selection_property_ === 'difference')
                                {
                                    lColumnValue = Number(lColumnValue) - Number(lVal2);
                                }
                                else if(lcProperty.selection_property_ === 'is_empty')
                                {
                                    if(lVal2 === "")
                                    {
                                        lColumnValue = 'empty';
                                    }
                                }
                                else if(lcProperty.selection_property_ === 'is_equal')
                                {
                                    lColumnValue = (Number(lColumnValue) === Number(lVal2));
                                }
                                else if(lcProperty.selection_property_ === 'true')
                                {
                                    if(lColumnValue === true)
                                    {
                                        lColumnValue = lVal2;
                                    }
                                }
                                else if(lcProperty.selection_property_ === 'false')
                                {
                                    if(lColumnValue === false)
                                    {
                                        lColumnValue = lVal2;
                                    }
                                }
                                else if(lcProperty.selection_property_ === 'empty')
                                {
                                    if(lColumnValue === 'empty')
                                    {
                                        lColumnValue = lVal2;
                                    }
                                }
                            }
                        }

                        if(lDataField)
                        {
                            lRowData[lDataField] = lColumnValue;
                        }
                    }
                }
            }
        }

        return lRowData;
    }

    static cfGetComputedFieldsData(lRowData, lFormItems, lIdFormItemMap, lFormData)
    {
        var lVariableValueMap = {};
        if(lRowData && lFormItems && lIdFormItemMap)
        {
            if(!lFormData)
            {
                lFormData = lRowData;
            }
            
            for(var lInd=0; lInd<lFormItems.length; lInd++)
            {
                var lFormItem = lFormItems[lInd];
                if(lFormItem.hasOwnProperty('cs_form_item_computation_'))
                {
                    var lFiCompList = lFormItem.cs_form_item_computation_;
                    if(lFiCompList && lIdFormItemMap)
                    {
                        if(lFormItem.cs_form_component_code_ !== "CS_DETAILS_FORM_GRID_1")
                        {
                            var lSelData = null;
                            for(var lFicInd=0; lFicInd<lFiCompList.length; lFicInd++)
                            {
                                var lFiCompObj0 = lFiCompList[lFicInd];
                                if((lFiCompObj0.field_1_type_ === 'collection') || (lFiCompObj0.field_2_type_ === 'collection'))
                                {
                                    if(lFiCompObj0.operation_ === 'equal')
                                    {
                                        var lDataCollection0 = null;
                                        if(lFormData)
                                        {
                                            var lfItem = lIdFormItemMap[lFiCompObj0.field_1_ref_id_];
                                            if(!lfItem)
                                            {
                                                lfItem = lIdFormItemMap[lFiCompObj0.field_2_ref_id_];
                                            }

                                            if(lfItem)
                                            {

                                                if(lFormData.hasOwnProperty(lfItem.data_field_))
                                                {
                                                    lDataCollection0 = lFormData[lfItem.data_field_];
                                                }
                                            }
                                        }

                                        if(lDataCollection0)
                                        {
                                            for(var ldInd0=0; ldInd0<lDataCollection0.length; ldInd0++)
                                            {
                                                var lDataObj0 = lDataCollection0[ldInd0];
                                                if(lDataObj0.hasOwnProperty(lFiCompObj0.field_1_data_field_) && (lDataObj0[lFiCompObj0.field_1_data_field_] === lFiCompObj0.field_2_constant_))
                                                {
                                                    lSelData = lDataObj0;
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                    else if(lFiCompObj0.operation_ === 'total')
                                    {
                                        lSelData = lFormData;
                                    }
                                }

                                CSFormUtil.cfProcessCompute(lVariableValueMap, lRowData, lIdFormItemMap, lFiCompObj0, lSelData)

                                if(lVariableValueMap.hasOwnProperty(lFormItem.data_field_))
                                {
                                    lFormItem.mValue = lVariableValueMap[lFormItem.data_field_];
                                } 
                            }
                        }
                        else if(lFormData)
                        {
                            var lDataCollection = null;
                            var lParentItem = lIdFormItemMap[lFormItem.parent_id_];
                            if(lParentItem)
                            {
                                lDataCollection = lFormData[lParentItem.data_field_];
                            }
                            else
                            {
                                lDataCollection = lFormData[lFormItem.data_field_];
                            }

                            if(lDataCollection)
                            {
                                var lSelObjFIcListMap = {}
                                for(var lFic0Ind=0; lFic0Ind<lFiCompList.length; lFic0Ind++)
                                {
                                    var lFic0Obj = lFiCompList[lFic0Ind];
                                    if(lFic0Obj.data_object_)
                                    {
                                        var lSelectedFicCollection = lSelObjFIcListMap[lFic0Obj.data_object_];
                                        if(!lSelectedFicCollection)
                                        {
                                            lSelectedFicCollection = [];
                                            lSelObjFIcListMap[lFic0Obj.data_object_] = lSelectedFicCollection;
                                        }
                                    
                                        lSelectedFicCollection.push(lFic0Obj);
                                    }
                                }

                                for(var lKey in lSelObjFIcListMap)
                                {
                                    var lSelectedFicList = lSelObjFIcListMap[lKey];
                                    var lSelectedObj = null;
                                    var lRetObj0 = {}
                                    for(var ldInd1=0; ldInd1<lSelectedFicList.length; ldInd1++)
                                    {
                                        var lFiCompObj = lSelectedFicList[ldInd1];
                                        if(lFiCompObj.operation_ === 'equal')
                                        {
                                            lSelectedObj = null;
                                            for(var ldInd=0; ldInd<lDataCollection.length; ldInd++)
                                            {
                                                var lDataObj = lDataCollection[ldInd];
                                                if(lDataObj.hasOwnProperty(lFiCompObj.field_1_data_field_) && (lDataObj[lFiCompObj.field_1_data_field_] === lFiCompObj.field_2_constant_))
                                                {
                                                    if(lFiCompObj.data_field_ && lSelectedObj)
                                                    {
                                                        lDataObj[lFiCompObj.data_field_] = lSelectedObj[lFiCompObj.data_field_];
                                                    }

                                                    lSelectedObj = lDataObj;
                                                    break;
                                                }
                                            }
                                        }
                                        else
                                        {
                                            if(lSelectedObj)
                                            {
                                                CSFormUtil.cfProcessCompute(lRetObj0, lSelectedObj, lIdFormItemMap, lFiCompObj);
                                                lSelectedObj[lFiCompObj.variable_] = lRetObj0[lFiCompObj.variable_];
                                            }
                                        }
                                    }
                                }            
                            }                        
                        }
                    }
                }
            }
        }

        return lVariableValueMap;
    }

    static cfProcessCompute(lRetObj, lData, lIdFormItemMap, lFiCompObj, lFormData)
    {
        let lComputedValue = null;
        let lField1Obj;
        let lField2Obj;
        let lField1Value;
        let lField2Value;
        let lDataVal;
                
        if(lFiCompObj)
        {
            if(lFiCompObj.field_1_type_)
            {
                if(lFiCompObj.field_1_constant_ === 'constant')
                {
                    lDataVal = lFiCompObj.field_1_constant_;
                }
                else if (lFiCompObj.field_1_data_field_)
                {
                    if(lFiCompObj.field_1_type_ === 'control')
                    {
                        lField1Obj = lIdFormItemMap[lFiCompObj.field_1_ref_id_];
                        if(lField1Obj)
                        {
                            if((lField1Obj.control_type_ === 'combo_box') && lField1Obj.hasOwnProperty('selected_data_') && lField1Obj.selected_data_)
                            {
                                var lSelData = lField1Obj.selected_data_;
                                lField1Value = lSelData[lFiCompObj.field_1_data_field_];
                            }
                            else
                            {
                                if((lFiCompObj.data_type_ === 'summary_data') && lFiCompObj.data_field_ && lField1Obj.hasOwnProperty(lFiCompObj.data_field_))
                                {
                                    lField1Value = lField1Obj[lFiCompObj.data_field_];
                                }
                                else
                                {
                                    if(lData && lData.hasOwnProperty(lFiCompObj.field_1_data_field_))
                                    {
                                        lField1Value = lData[lFiCompObj.field_1_data_field_];
                                    }
                                    else if(lField1Obj.mValue)
                                    {
                                        lField1Value = lField1Obj.mValue;
                                    }
                                }
                            }
                        }
                    }
                    else if(lFiCompObj.field_1_type_ === 'collection')
                    {
                        if(lFormData)
                        {
                            lField1Value = lFormData[lFiCompObj.field_1_data_field_]
                        }
                    }
                    else if(lFiCompObj.field_1_type_ === 'computed')
                    {
                        if(lRetObj.hasOwnProperty(lFiCompObj.field_1_data_field_))
                        {
                            lField1Value = lRetObj[lFiCompObj.field_1_data_field_];
                        }
                        else if(lFiCompObj.field_1_ref_id_ && lIdFormItemMap)
                        {
                            var lField1Obj0 = lIdFormItemMap[lFiCompObj.field_1_ref_id_];
                            if(lField1Obj0)
                            {
                                lField1Value = lField1Obj0.mValue;
                                lRetObj[lFiCompObj.field_1_data_field_] = lField1Obj0.mValue;
                            }
                        }
                    }
                    else if(lData && lFiCompObj.field_1_type_ === 'reference')
                    {
                        lField1Value = lData[lFiCompObj.field_1_data_field_];
                    }
                }
            }
                
            if(lFiCompObj.field_2_type_)
            {
                if(lFiCompObj.field_2_type_ === 'constant')
                {
                    lField2Value = lFiCompObj.field_2_constant_;
                }
                else if(lFiCompObj.field_2_data_field_)
                {
                    if(lFiCompObj.field_2_type_ === 'control')
                    {
                        lField2Obj = lIdFormItemMap[lFiCompObj.field_2_ref_id_];
                        if(lField2Obj)
                        {
                            if((lField2Obj.control_type_ === 'combo_box') && lField2Obj.hasOwnProperty('selected_data_') && lField2Obj.selected_data_)
                            {
                                var lSelData1 = lField2Obj.selected_data_;
                                lField2Value = lSelData1[lFiCompObj.field_2_data_field_];
                            }
                            else
                            {
                                if((lFiCompObj.data_type_ === 'summary_data') && lFiCompObj.data_field_ && lField2Obj.hasOwnProperty(lFiCompObj.data_field_))
                                {
                                    lField2Value = lField2Obj[lFiCompObj.data_field_];
                                }
                                else
                                {
                                    if(lData && lData.hasOwnProperty(lFiCompObj.field_2_data_field_))
                                    {
                                        lField2Value = lData[lFiCompObj.field_2_data_field_];
                                    }
                                    else if(lField2Obj.mValue)
                                    {
                                        lField2Value = lField2Obj.mValue;
                                    }
                                }
                            }
                        }
                    }
                    else if(lFiCompObj.field_2_type_ === 'collection')
                    {
                        if(lFormData)
                        {
                            lField2Value = lFormData[lFiCompObj.field_2_data_field_]
                        }
                    }
                    else if(lFiCompObj.field_2_type_ === 'computed')
                    {
                        lField2Value = lRetObj[lFiCompObj.field_2_data_field_];
                    }
                    else if(lData && (lFiCompObj.field_2_type_ === 'reference'))
                    {
                        lField2Value = lData[lFiCompObj.field_2_data_field_];
                    }
                    
                    if(lFiCompObj.data_field_ && lData && lData.hasOwnProperty(lFiCompObj.data_field_))
                    {
                        lDataVal = lData[lFiCompObj.data_field_];
                    }
                    else if(lFiCompObj.data_val_)
                    {
                        lDataVal = lFiCompObj.data_val_;
                    }
                }
            }
            
            if (lFiCompObj.operation_ === 'total')
            {
                if(lFiCompObj.data_field_)
                {
                    if(Array.isArray(lData))
                    {
                        var lTotalValue = 0;

                        for(var ldInd=0; ldInd<lData.length; ldInd++)
                        {
                            var lDObj = lData[ldInd];
                            if(lDObj.hasOwnProperty(lFiCompObj.data_field_))
                            {
                                var lF2Val = Number(lDObj[lFiCompObj.data_field_]);
                                lTotalValue += lF2Val;
                            }
                        }

                        lRetObj[lFiCompObj.variable_] = lTotalValue;
                    }
                }
            }
            else
            {
                lComputedValue = CSFormUtil.cfGetComputedValue(lField1Value, lField2Value, lFiCompObj.operation_, lDataVal);
                lRetObj[lFiCompObj.variable_] = lComputedValue;
            }
        }
    }

    static cfGetComputedValue(lField1Value, lField2Value, lOperation, lDataVal)
    {
        var lComputedVal = null;
        switch(lOperation)
        {
            case 'date_add':
                if(lField1Value && lField2Value)
                {
                    var lDate1 = fnGetStringToDate(lField1Value);
                    var lTime = Number(lField2Value);
                    lComputedVal = fnDateAdd(lDate1, 'minute', lTime)
                }
            break;
            
            case 'date_diff':
                if(lField1Value && lField2Value)
                {
                    var lDate11 = fnGetStringToDate(lField1Value);
                    var lDate12 = fnGetStringToDate(lField2Value);
                    if(lDate11 && lDate12)
                    {
                        const lDiffTime = Math.abs(lDate12 - lDate11);
                        lComputedVal = Math.ceil(lDiffTime / (1000 * 60 * 60 * 24)); 
                    }
//                    var lTime11 = Number(lField2Value) * -1;
//                    lComputedVal = fnDateAdd(lDate11, 'minute', lTime11)
                }
            break;

            case 'time_add_min':
                if(lField1Value && lField2Value)
                {
                    var lDate13 = new Date();
                    var lDateStr = fnGetDateToString(lDate13);
                    lDateStr += ':' + lField1Value;
                    var lTime2 = Number(lField2Value);
                    var lNewTime = fnDateAdd(fnGetStringToDate(lDateStr), 'minute', lTime2);
                    lComputedVal = fnGetTimeFromDate(lNewTime);
                }
            break;
              
            case 'difference':
                if(!lField1Value)
                {
                    lField1Value = 0;
                }

                if(!lField2Value)
                {
                    lField2Value = 0;
                }

                lComputedVal = (Number(lField1Value) - Number(lField2Value)).toString();
            break;
            
            case 'divide':
                if(!lField1Value)
                {
                    lField1Value = 0;
                }

                if(!lField2Value)
                {
                    lField2Value = 0;
                }

                if(Number(lField2Value) > 0)
                {
                    lComputedVal = (Number(lField1Value) / Number(lField2Value)).toFixed(0).toString();
                }
            break;
            
            case 'equal':
                if(lDataVal)
                {
                    lComputedVal = (lField1Value === lField2Value) ? Number(lDataVal).toString() : Number(lComputedVal).toString();
                }
                else
                {
                    lComputedVal = (lField1Value === lField2Value);
                }
            break;
            
            case 'greater':
                if(!lField1Value)
                {
                    lField1Value = 0;
                }

                if(!lField2Value)
                {
                    lField2Value = 0;
                }

                lComputedVal = (Number(lField1Value) > Number(lField2Value)) ? Number(lField1Value) :  Number(lField2Value);
            break;
            
            case 'lesser':
                if(!lField1Value)
                {
                    lField1Value = 0;
                }

                if(!lField2Value)
                {
                    lField2Value = 0;
                }

                lComputedVal = (Number(lField1Value) < Number(lField2Value)) ? Number(lField1Value).toString() : Number(lField2Value).toString();
            break;
            
            case 'minimum':
                if(!lField1Value)
                {
                    lField1Value = 0;
                }

                if(!lField2Value)
                {
                    lField2Value = 0;
                }

                lComputedVal = (Number(lField1Value) <= Number(lField2Value)) ? (lField1Value).toString() : (lField2Value).toString();
            break;
            
            case 'floor':
                if(!lField1Value)
                {
                    lComputedVal = 0;
                }
                else
                {
                    lComputedVal = Math.floor(Number(lField1Value)).toString();
                }
            break;
            
            case 'ceil':
                if(!lField1Value)
                {
                    lComputedVal = 0;
                }
                else
                {
                    lComputedVal = Math.ceil(Number(lField1Value)).toString();
                }
            break;
            
            case 'multiply':
                if(!lField1Value)
                {
                    lField1Value = 0;
                }

                if(!lField2Value)
                {
                    lField2Value = 0;
                }

                lComputedVal = (Number(lField1Value) * Number(lField2Value)).toString();
            break;
            
            case 'neg_val':
                if(!lField1Value)
                {
                    lField1Value = 0;
                }

                if(!lField2Value)
                {
                    lField2Value = 0;
                }

                if(lField1Value < 0)
                {
                    lComputedVal = Number(lField2Value).toString();
                }
                else
                {
                    if(lDataVal)
                    {
                        lComputedVal = Number(lDataVal).toString();
                    }
                    else
                    {
                        lComputedVal = (lField1Value).toString();
                    }
                }
            break;
            
            case 'not_equal':
            break;
            
            case 'num_days':
            break;
            
            case 'pos_val':
                if(!lField1Value)
                {
                    lField1Value = 0;
                }

                if(!lField2Value)
                {
                    lField2Value = 0;
                }

                if(lField1Value > 0)
                {
                    lComputedVal = Number(lField2Value).toString();
                }
                else
                {
                    if(lDataVal)
                    {
                        lComputedVal = Number(lDataVal).toString();
                    }
                    else
                    {
                        lComputedVal = Number(lField1Value).toString();
                    }
                }
            break;
            
            case 'sum':
                if(!lField1Value)
                {
                    lField1Value = 0;
                }

                if(!lField2Value)
                {
                    lField2Value = 0;
                }

                lComputedVal = (Number(lField1Value) + Number(lField2Value)).toString();
            break;

            case 'concat':
                if(!lField1Value)
                {
                    lField1Value = '';
                }

                if(!lField2Value)
                {
                    lField2Value = '';
                }
                
                lComputedVal = lField1Value.concat(lField2Value);
            break;
            
            default:
            break;
        }

        return lComputedVal;
    }

    static cfGetFormItemInputString(lSelectedFormItem, lFormData)
    {
        var lRetVal;
        if(lSelectedFormItem && (lSelectedFormItem.data_type_ === 'input_string') && lFormData && lSelectedFormItem.hasOwnProperty('cs_form_item_properties_'))
        {
            CSUtil.cfSort(lSelectedFormItem.cs_form_item_properties_, 'sequence_')
            for(var lFipInd=0; lFipInd<lSelectedFormItem.cs_form_item_properties_.length; lFipInd++)
            {
                var lFipObj = lSelectedFormItem.cs_form_item_properties_[lFipInd];
                if((lFipObj.type_ === 'data_selection') && lFipObj.value_)
                {
                    if(!lRetVal)
                    {
                        lRetVal = '';
                    }

                    var lStr = ""
                    if(lFipObj.property_ === 'data_field')
                    {
                        lStr = lFipObj.value_ + lFormData[lFipObj.selection_property_];
                    }
                    else if(lFipObj.property_ === 'constant')
                    {
                        lStr = lFipObj.value_;
                    }

                    if(lStr)
                    {
                        if(lRetVal)
                        {
                            lRetVal = lRetVal + lStr;
                        }
                        else
                        {
                            lRetVal = lStr;
                        }
                    }
                }
            }
        }

        return lRetVal;
    }

    static cfGetGridItemInputString(lSelectedGridItem, lFormData)
    {
        var lRetVal;
        if(lSelectedGridItem && lFormData && lSelectedGridItem.hasOwnProperty('cs_form_grid_item_properties_'))
        {
            CSUtil.cfSort(lSelectedGridItem.cs_form_grid_item_properties_, 'sequence_')
            for(var lFipInd=0; lFipInd<lSelectedGridItem.cs_form_grid_item_properties_.length; lFipInd++)
            {
                var lFipObj = lSelectedGridItem.cs_form_grid_item_properties_[lFipInd];
                if((lFipObj.type_ === 'data_selection') && lFipObj.value_)
                {
                    if(!lRetVal)
                    {
                        lRetVal = '';
                    }

                    var lStr = ""
                    if(lFipObj.property_ === 'data_field')
                    {
                        lStr = lFipObj.value_ + lFormData[lFipObj.selection_property_];
                    }
                    else if(lFipObj.property_ === 'constant')
                    {
                        lStr = lFipObj.value_;
                    }

                    if(lStr)
                    {
                        if(lRetVal)
                        {
                            lRetVal = lRetVal + lStr;
                        }
                        else
                        {
                            lRetVal = lStr;
                        }
                    }
                }
            }
        }

        return lRetVal;
    }

    static cfGetButton(lActionItem, lType, fnOnClick)
    {
        var lButton = null;
        if(lActionItem)
        {
            var lWidth = null;
            var lHeight = null;
            var lLabel = null;
            var lMinWidth = null;
            if(lType === 'SEARCH_ACTION_BAR')
            {
                lWidth = '32px';
                lHeight = '32px';
            }
            else
            {
                lLabel = lActionItem.label_;
            }

            lButton = (
                <Button
                    icon={CSUtil.mNameIconMap[lActionItem.icon_name_]} 
                    style=
                    {
                        {
                            // fontSize        : '13.2px', 
                            margin          : '2px', 
                            padding         : '8px',
                            width           : lWidth, 
                            height          : lHeight, 
                            minWidth        : lMinWidth,
                            verticalAlign   : 'middle'
                        }
                    }
                    key         = { lActionItem.id_ }
                    tooltip     = { lActionItem.tool_tip_ }
                    id          = { lActionItem.id_ }
                    label       = { lLabel }
                    disabled    = { !lActionItem.mEnabled }
                    onClick     = 
                        { 
                            (event) => fnOnClick(event, lActionItem) 
                        }
                />
            );
        }

        return lButton;
    }

}

export default CSFormUtil;
