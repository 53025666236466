import React from "react"
import {Button} from 'primereact/button';

import CSUtil               from "./CSUtil";
import CSCOFormLayout       from "../layout/form/CSCOFormLayout";
import CSCOFormLayout7      from "../layout/form/CSCOFormLayout7";
import CSCOFormLayout8      from "../layout/form/CSCOFormLayout8";
import CSCOFormLayout11     from "../layout/form/CSCOFormLayout11";
import CSCOFormLayout14     from "../layout/form/CSCOFormLayout14";
import CSCOFormLayout15     from "../layout/form/CSCOFormLayout15";
//import CSCOFormLayout51     from "../layout/form/CSCOFormLayout51";
import CSCOFormLayout21     from "../layout/form/CSCOFormLayout21";

import CSCODetailsSection1  from "../components/CSCODetailsSection1";
import CSCODetailsSection2  from "../components/CSCODetailsSection2";

import CSCODetailsForm      from "../layout/details/CSCODetailsForm"
//import CSCODetailsForm0     from "../layout/details/CSCODetailsForm0"
import CSCODetailsForm2     from "../layout/details/CSCODetailsForm2";
import CSCODetailsForm3     from "../layout/details/CSCODetailsForm3";
import CSCODetailsForm4     from "../layout/details/CSCODetailsForm4";
import CSCODetailsForm6     from "../layout/details/CSCODetailsForm6";
//import CSCODetailsForm7     from "../layout/details/CSCODetailsForm7";
import CSCODetailsForm8     from "../layout/details/CSCODetailsForm8";
import CSCODetailsForm9     from "../layout/details/CSCODetailsForm9";
import CSCODetailsForm10    from "../layout/details/CSCODetailsForm10";
import CSCODetailsForm11    from "../layout/details/CSCODetailsForm11";
import CSCODetailsForm12    from "../layout/details/CSCODetailsForm12";
import CSCODetailsForm13    from "../layout/details/CSCODetailsForm13";
import CSCODetailsForm14    from "../layout/details/CSCODetailsForm14";
import CSCODetailsForm15    from "../layout/details/CSCODetailsForm15";
import CSCODetailsForm16    from "../layout/details/CSCODetailsForm16";
import CSCODetailsForm17    from "../layout/details/CSCODetailsForm17";
import CSCODetailsForm18    from "../layout/details/CSCODetailsForm18";
import CSCODetailsForm19    from "../layout/details/CSCODetailsForm19";
import CSCODetailsForm20    from "../layout/details/CSCODetailsForm20";
import CSCODetailsForm21    from "../layout/details/CSCODetailsForm21";
import CSCODetailsForm22    from "../layout/details/CSCODetailsForm22";
import CSCODetailsForm23    from "../layout/details/CSCODetailsForm23";
import CSCODetailsForm24    from "../layout/details/CSCODetailsForm24";
import CSSolutionServices   from "../business/CSSolutionServices";

import CSCODetailsTabForm1  from "../layout/details/CSCODetailsTabForm1";
import CSCOBreadCrumbForm1  from "../layout/details/CSCOBreadCrumbForm1";

import CSCOAdvancedFormLayout3  from "../layout/form/CSCOAdvancedFormLayout3";
import CSCOAdvancedDetailsForm3 from "../layout/details/CSCOAdvancedDetailsForm3";
import CSCODashboardLayout1     from "../layout/analysis/CSCODashboardLayout1";

import CSImportExcel from "../common/CSImportExcel";
import CSImportXml   from "../common/CSImportXml";
import CSImportTemplate from "../common/CSImportTemplate";
import CSDeliveryChallanForm1 from "../common/CSDeliveryChallanForm1";
import CSDeliveryChallanForm2 from "../common/CSDeliveryChallanForm2";
import CSDeliveryChallanForm3 from "../common/CSDeliveryChallanForm3";
import CSReceiptForm0 from "../common/CSReceiptForm0";
import CSReceiptForm1 from "../common/CSReceiptForm1";
import CSReceiptForm2 from "../common/CSReceiptForm2";
import CSReceiptForm3 from "../common/CSReceiptForm3";
import CSReceiptForm4 from "../common/CSReceiptForm4";
import CSMarkSheetForm1 from "../common/CSMarkSheet";
import CSTransferNote from "../common/CSTransferNote";
import CSReceiptForm5 from "../common/CSReceiptForm5";
import CSVoucherForm0 from "../common/CSVoucherForm0";
import CSBonafideCertificate1 from "../common/CSBonafideCertificate1";
import CSBonafideCertificate2 from "../common/CSBonafideCertificate2";
import CSRecordSheet1 from "../common/CSRecordSheet1";
import CSSalesOrder1 from "../common/CSSalesOrder1";
import CSPurchaseOrder1 from "../common/CSPurchaseOrder1";
import CSGoodsReceipt1 from "../common/CSGoodsReceipt1";
import CSReceiptForm7 from "../common/CSReceiptForm7";
import VNLayout from "../common/VNLayout";
import CSCODashboardLayout2 from "../layout/analysis/CSCODashboardLayout2";
import ApplicationForm from "../business/GMS/ApplicationForm";
import CSCODetailsForm5 from "../layout/details/CSCODetailsForm5";


function fnGetFormHeader(lHeading, lButtons)
{
    var lHeader = null;
    if(lHeading)
    {
        lHeader = 
            <div style={{display: 'flex', width: '100%'}}>
                <div className="card card-w-heading"  style={{width: '100%'}}> 
                    <h2> {lHeading} </h2> 
                </div>
                {lButtons}                    
            </div>
    }
    
    return lHeader;
}

function fnGetFormShortHeader(heading)
{
    return (
        <div className="menu-card card-w-heading">
            <h3 style={{width: '100%'}}>{heading}</h3>
            <div style={{width: '120px'}}>
                <Button icon={CSUtil.mNameIconMap['PI_INFO']} 
                        style={{ fontSize : '13.2px', marginRight:'.3em', top: '10%', width: '20px', height: '20px', verticalAlign : 'middle' }}
                    />
                <Button icon={CSUtil.mNameIconMap['PI_PRINT']} 
                        style={{ fontSize : '13.2px', marginRight:'.3em', top: '10%', width: '20px', height: '20px', verticalAlign : 'middle' }}
                    />
                <Button icon={CSUtil.mNameIconMap['PI_FILE_PDF']} 
                        style={{ marginRight:'.3em', top: '10%', width: '20px', height: '20px', verticalAlign : 'middle' }}
                    />
                <Button icon={CSUtil.mNameIconMap['PI_FILE_EXCEL']} 
                        style={{ marginRight:'.3em', top: '10%', width: '20px', height: '20px', verticalAlign : 'middle' }}
                    />
            </div>
        </div>
    );
}

function fnGetForm(lMetaData, lSelectedData, lProcessClose)
{
    var lForm = null;

    if(lMetaData && lMetaData.pFormType)
    {
        lMetaData.pSelectedData = (lMetaData.selected_data_) ? lMetaData.selected_data_ : (lMetaData.pSelectedData) ? lMetaData.pSelectedData : {};
        lMetaData.pSelectedCriteria = (lMetaData.selected_criteria_) ? lMetaData.selected_criteria_ : lMetaData.pSelectedCriteria;

        var lProps = {...lMetaData, pShow : true, pSelectedData : lSelectedData, fnProcessClose : lProcessClose};
        switch(lMetaData.pFormType)
        {
            case 'CS_DETAILS_FORM_0':
            case 'CS_DETAILS_FORM_1':
                lForm = <CSCODetailsForm {...lProps}/>
            break;
            
            case 'CS_DETAILS_FORM_2':
                lForm = <CSCODetailsForm2 {...lProps}/>
            break;
            
            case 'CS_DETAILS_FORM_3':
                lForm = <CSCODetailsForm3 {...lProps}/>
            break;
            
            case 'CS_DETAILS_FORM_4':
                lForm = <CSCODetailsForm4 {...lProps}/>
            break;
            
            case 'CS_DETAILS_FORM_5':
                lForm = <CSCODetailsForm5 {...lProps}/>
            break;
            
            case 'CS_DETAILS_FORM_6':
                lForm = <CSCODetailsForm6 {...lProps}/>
            break;
            
            case 'CS_DETAILS_FORM_7':
            case 'CS_DETAILS_FORM_LAYOUT_7':
                    lForm = <CSCODetailsForm {...lProps}/>
            break;

            case 'CS_DETAILS_FORM_8':
            case 'CS_DETAILS_FORM_LAYOUT_8':
                lForm = <CSCODetailsForm8 {...lProps}/>
            break;
            
            case 'CS_DETAILS_FORM_9':
            case 'CS_DETAILS_FORM_LAYOUT_9':
                lForm = <CSCODetailsForm9 {...lProps}/>
            break;

            case 'CS_DETAILS_FORM_10':
            case 'CS_DETAILS_FORM_LAYOUT_10':
                lForm = <CSCODetailsForm10 {...lProps}/>
            break;

            case 'CS_DETAILS_FORM_11':
                lForm = <CSCODetailsForm11 {...lProps}/>
            break;

            case 'CS_DETAILS_FORM_12':
                lForm = <CSCODetailsForm12 {...lProps}/>
            break;

            case 'CS_DETAILS_FORM_13':
            case 'CS_DETAILS_FORM_LAYOUT_13':
                lForm = <CSCODetailsForm13 {...lProps}/>
            break;

            case 'CS_DETAILS_FORM_LAYOUT_14':
                lForm = <CSCODetailsForm14 {...lProps}/>
            break;

            case 'CS_DETAILS_FORM_15':
            case 'CS_DETAILS_FORM_LAYOUT_15':
                lForm = <CSCODetailsForm15 {...lProps}/>
            break;

            case 'CS_DETAILS_FORM_16':
            case 'CS_DETAILS_FORM_LAYOUT_16':
                lForm = <CSCODetailsForm16 {...lProps}/>
            break;
            
            case 'CS_DETAILS_FORM_17':
                lForm = <CSCODetailsForm17 {...lProps}/>
            break;

            case 'CS_DETAILS_FORM_18':
                lForm = <CSCODetailsForm18 {...lProps}/>
            break;

            case 'CS_DETAILS_FORM_19':
                lForm = <CSCODetailsForm19 {...lProps}/>
            break;

            case 'CS_DETAILS_FORM_20':
                lForm = <CSCODetailsForm20 {...lProps}/>
            break;

            case 'CS_DETAILS_FORM_21':
                lForm = <CSCODetailsForm21 {...lProps}/>
            break;

            case 'CS_DETAILS_FORM_22':
                lForm = <CSCODetailsForm22 {...lProps}/>
            break;

            case 'CS_DETAILS_FORM_23':
                lForm = <CSCODetailsForm23 {...lProps}/>
            break;

            case 'CS_DETAILS_FORM_24':
                lForm = <CSCODetailsForm24 {...lProps}/>
            break;
            
            case 'CS_IMPORT_FORM_1':
                lForm = <CSImportExcel {...lProps}/>
            break;

            case 'CS_IMPORT_FORM_2':
                lForm = <CSImportXml {...lProps}/>
            break;

            case 'CS_DETAILS_FORM_TAB_LAYOUT_1':
                lForm = <CSCODetailsTabForm1 {...lProps}/>
            break;

            case 'CS_BREAD_CRUMB_LAYOUT_1':
                lForm = <CSCOBreadCrumbForm1 {...lProps}/>
            break;

            case 'CS_ADV_DETAILS_FORM_3':
                lForm = <CSCOAdvancedDetailsForm3 {...lProps}/>
            break;

            case 'CS_DASHBOARD_LAYOUT_1':
                lForm = <CSCODashboardLayout1 {...lProps}/>
            break;

            case 'CS_IMPORT_TEMPLATE_1':
                lForm = <CSImportTemplate {...lProps}/>
            break;

            case 'SOLUTION_SERVICES':
                lForm = <CSSolutionServices {...lProps}/>
            break;

            case 'DELIVERY_CHALLAN':
                lForm = <CSDeliveryChallanForm1 {...lProps}/>
            break;

            case 'CS_SALES_ORDER_1':
                lForm = <CSSalesOrder1 {...lProps}/>
            break;

            case 'CS_PURCHASE_ORDER_1':
                lForm = <CSPurchaseOrder1 {...lProps}/>
            break;

            case 'CS_GOODS_RECEIPT_1':
                lForm = <CSGoodsReceipt1 {...lProps}/>
            break;

            case 'CS_DELIVERY_CHALLAN_2':
                lForm = <CSDeliveryChallanForm2 {...lProps}/>
            break;

            case 'CS_DELIVERY_CHALLAN_3':
                lForm = <CSDeliveryChallanForm3 {...lProps}/>
            break;

            case 'CS_RECEIPT_FORM_0':
                lForm = <CSReceiptForm0 {...lProps}/>
            break;

            case 'CS_RECEIPT_FORM_1':
                lForm = <CSReceiptForm1 {...lProps}/>
            break;

            case 'CS_RECEIPT_FORM_2':
                lForm = <CSReceiptForm2 {...lProps}/>
            break;

            case 'CS_RECEIPT_FORM_3':
                lForm = <CSReceiptForm3 {...lProps}/>
            break;

            case 'CS_RECEIPT_FORM_4':
                lForm = <CSReceiptForm4 {...lProps}/>
            break;

            case 'CS_RECEIPT_FORM_5':
                lForm = <CSReceiptForm5 {...lProps}/>
            break;

            case 'CS_RECEIPT_FORM_7':
                lForm = <CSReceiptForm7 {...lProps}/>
            break;

            case 'CS_VOUCHER_FORM_0':
                lForm = <CSVoucherForm0 {...lProps}/>
            break;

            case 'CS_TRANSFER_NOTE_1':
                lForm = <CSTransferNote {...lProps}/>
            break;

            case 'CS_MARKS_SHEET_1':
                lForm = <CSMarkSheetForm1 {...lProps}/>
            break;            

            case 'CS_BONAFIDE_CERTIFICATE_1':
                lForm = <CSBonafideCertificate1 {...lProps}/>
            break;

            case 'CS_BONAFIDE_CERTIFICATE_2':
                lForm = <CSBonafideCertificate2 {...lProps}/>
            break;
            
            case 'CS_RECORD_SHEET_1':
                lForm = <CSRecordSheet1 {...lProps}/>
            break;
            
            case 'CS_AVAILABILITY_LAYOUT_1':
                lForm = <VNLayout {...lProps}/>
            break;
            case 'CS_GMS_APPLICATION_1':
                lForm = <ApplicationForm {...lProps}/>
            break;
            
            default: 
            break;
        }
    }
     
    return lForm;    
}

function fnGetLayout(lType, lProps)
{
    var lForm = null;
    if(lType === 'CS_FORM_LAYOUT')
    {
        lForm = <CSCOFormLayout {...lProps}/>;
    }
    else if((lType === 'CS_FORM_LAYOUT_1') || (lType === 'CS_FORM_LAYOUT_9'))
    {
        lForm = <CSCOFormLayout {...lProps}/>;
    }
    else if(lType === 'CS_FORM_LAYOUT_7')
    {
        lForm = <CSCOFormLayout7 {...lProps}/>;
    }
    else if(lType === 'CS_FORM_LAYOUT_8')
    {
        lForm = <CSCOFormLayout8 {...lProps}/>;
    }
    else if((lType === 'CS_FORM_LAYOUT_11') || (lType === 'CS_FORM_LAYOUT_12'))
    {
        lForm = <CSCOFormLayout11 {...lProps}/>;
    }
    else if(lType === 'CS_FORM_LAYOUT_14')
    {
        lForm = <CSCOFormLayout14 {...lProps}/>;
    }
    else if(lType === 'CS_FORM_LAYOUT_15')
    {
        lForm = <CSCOFormLayout15 {...lProps}/>;
    }
    else if(lType === 'CS_FORM_LAYOUT_21')
    {
        lForm = <CSCOFormLayout21 {...lProps}/>;
    }
    else if(lType === 'CS_PROGRESS_CARD_1')
    {
    }
    else if(lType === 'CS_DETAILS_FORM_SECTION_2')
    {
        lForm = <CSCODetailsSection2 {...lProps}/>;
    }
    else if(lType === 'CS_DETAILS_FORM_SECTION_1')
    {
        lForm = <CSCODetailsSection1 {...lProps}/>;
    }
    else if(lType === 'CS_DETAILS_FORM_LAYOUT_7')
    {
        var lFormTitle = null;
        var lMenuItem = CSUtil.cfGetInstance().cfGetMenuItem(lProps.pFeatureRefId);
        if(lMenuItem != null)
        {
            if(lMenuItem.title_)
            {
                lFormTitle = lMenuItem.title_;
            }
            else
            {
                lFormTitle = lMenuItem.layout_item_name_;
            }
        }
        
        lProps.pFormType = lType;
        lForm = 
            <>
                { fnGetFormHeader(lFormTitle) }
                <CSCODetailsForm {...lProps}/>
            </>
    }
    else if(lType === 'CS_DETAILS_FORM_LAYOUT_8')
    {
        lForm = <CSCODetailsForm8 {...lProps}/>;
    }
    else if(lType === 'CS_DETAILS_FORM_LAYOUT_13')
    {
        lForm = <CSCODetailsForm13 {...lProps}/>;
    }
    else if(lType === 'CS_DETAILS_FORM_LAYOUT_14')
    {
        lForm = <CSCODetailsForm14 {...lProps}/>;
    }
    else if(lType === 'CS_FORM_LAYOUT_51')
    {
        lForm = <CSCOFormLayout {...lProps}/>;
    }
    else if(lType === 'CS_AD_FORM_LAYOUT_3')
    {
        lForm = <CSCOAdvancedFormLayout3 {...lProps}/>;
    }
    else if(lType === 'CS_DASHBOARD_LAYOUT_1')
    {
        lForm = <CSCODashboardLayout1 {...lProps}/>;
    }
    else if(lType === 'CS_DETAILS_FORM_21')
    {
        lForm = <fnInitializeZoom {...lProps}/>
    }
    else if(lType === 'CS_DETAILS_FORM_23')
    {
        lForm = <CSCODetailsForm23 {...lProps}/>
    }
    else if(lType === 'CS_DETAILS_FORM_24')
    {
        lForm = <CSCODetailsForm24 {...lProps}/>
    }
    else if(lType === 'CS_DASHBOARD_LAYOUT_2')
    {
        lForm = <CSCODashboardLayout2 {...lProps}/>;
    }
    else if(lType === 'CS_AVAILABILITY_LAYOUT_1')
    {
        lForm = <VNLayout {...lProps}/>
    }
    else if(lType === 'CS_ADV_DETAILS_FORM_3')
    {
        lForm = <CSCOAdvancedDetailsForm3 {...lProps}/>
    }
    
    return lForm;
}

export { fnGetForm, fnGetFormHeader, fnGetFormShortHeader, fnGetLayout };
