import React, { Component } from "react"

import CSCODetailsHeader1 from "../../components/CSCODetailsHeader1";
// import CSCOFormLayout9 from "../form/CSCOFormLayout9";
import CSFormUtil from "../../util/CSFormUtil"
import CSUtil from "../../util/CSUtil";
import { cfGetConstantValue} from "../../util/CSConstantUtil";
import CSCOAdvancedFormLayout3 from "../form/CSCOAdvancedFormLayout3";

class CSCODetailsForm5 extends Component 
{
	mNumSections 		= 3;
    mMinSectionItems 	= 7;
    mFormUtil = CSFormUtil.cfGetInstance();
    
    constructor(props)
    {
        super(props);
        this.state = 
        {
            mDisplay                : this.props.pShow,
            mAppId                  : this.props.pAppId,
            mFormHeading            : (this.props.pFormHeading !== null) ? this.props.pFormHeading : "",
            mFormCriteria           : this.props.pFormCriteria,
            mFormGrid               : this.props.pFormGrid,
            mFormItems              : this.props.pFormItems,
            mFormActions            : this.props.pFormActions,
            mFormProperties         : this.props.pFormProperties,
            mFormData               : (this.props.pFormData !== null) ? this.props.pFormData : {},
            mControlType            : this.props.pControlType,
            mEditable               : this.props.pEditable,
            mWidth                  : this.props.pWidth,
            mHeight                 : this.props.pHeight,
            mValue                  : this.props.pValue,
            mSelectedActionItem     : this.props.pSelectedActionItem,
            mSelectedTabActionItem  : this.props.pSelectedTabActionItem,

            fnProcessChange         : this.props.fnProcessChange,
            fnProcessSubmit         : this.props.fnProcessSubmit,
            fnProcessClose          : this.props.fnProcessClose
        }

        this.cfProcessChange    = this.cfProcessChange.bind(this);
        this.cfSetControlValue  = this.cfSetControlValue.bind(this);

        this.cfCloseForm        = this.cfCloseForm.bind(this);        
        this.process_save       = this.process_save.bind(this);
        this.process_cancel     = this.process_cancel.bind(this);
        this.process_close      = this.process_close.bind(this);
        this.process_reset      = this.process_reset.bind(this);

        if(!this.props.pFormData)
        {
            this.state.mFormData = {};
        }
        else
        {
            this.state.mFormData = CSUtil.cfGetObjectCopy(this.props.pFormData);
        }

        if(this.props.pSelectedData && (this.props.pSelectedData !== this.props.pFormData))
        {
            this.state.mFormData['selected_data_'] = this.props.pSelectedData;
        }

        if(this.props.pSelectedCriteria)
        {
            this.state.mFormData['selected_criteria_'] = this.props.pSelectedCriteria;
        }

        if(this.props.pFormProperties)
        {
            for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
            {
                var lFormProperty = this.props.pFormProperties[lInd_0];
                if((lFormProperty.type_ === 'layout') && (lFormProperty.property_ === 'min_section_items_'))
                {
                    this.mMinSectionItems = lFormProperty.value_;
                }

                if((lFormProperty.type_ === 'initialize') && (lFormProperty.property_ === 'criteria_field_'))
                {
                    if(!this.state.mCriteriaFields)
                    {
                        this.state.mCriteriaFields = [];
                    }

                    this.state.mCriteriaFields.push(lFormProperty.value_);
                }
            }
        }
    }

    componentDidMount(){}

    componentDidUpdate(lPrevProps, lPrevState){}

    cfProcessChange(lData)
    {
        if(lData)
        {
            if(this.state.mFormData)
            {
                var lFormData = this.state.mFormData;
                lFormData[lData.data_field_] = lData.value_;
                this.setState ({mFormData : lFormData});
            }
        }
    }

    cfSetControlValue()
    {
        if(this.state && this.state.mFormItems)
        {
            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var form_item = this.state.mFormItems[ind];
                var form_data = this.state.mFormData;
                if(form_item.control_type_ === 'constant')
                {
                    form_item.mValue = cfGetConstantValue(form_item.constant_);
                    if(form_data)
                    {
                        form_data[form_item.data_field_] = form_item.mValue;
                    }
                }
                else if(form_item.control_type_ === 'check_box')
                {
                    if(form_item.constant_ && form_data && ((form_item.constant_ === form_data[form_item.data_field_])))
                    {
                        form_item.mValue = '1';
                    }
                    else
                    {
                        form_item.mValue = '0';
                    }
                }
                else if(form_data)
                {
                    if(form_item.control_type_ === 'text_input')
                    {
                        if(form_data[form_item.data_field_])
                        {
                            form_item.mValue = form_data[form_item.data_field_];        
                        }
                        else if(form_item.constant_)
                        {
                            form_item.mValue = cfGetConstantValue(form_item.constant_);
                        }
                    }
                    else
                    {
                        form_item.mValue = form_data[form_item.data_field_];
                    }
                }
                else
                {
                    if(form_item.control_type_ === 'text_input')
                    {
                        form_item.mValue = cfGetConstantValue(form_item.constant_);
                    }
                    else
                    {
                        form_item.mValue = '';
                    }
                }
            }
        }
    }

    cfCloseForm()
    {
        this.setState({mDisplay : false});
    }

    process_save(event, lParent, lActionItem)
    {
        if(lActionItem && (lActionItem.on_click_service_code_ || lActionItem.on_click_request_ref_id_))
        {
        }
    }

    process_cancel(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_close(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_reset()
    {
//        this.setState ({mFormData : CSUtil.cfGetObjectCopy(this.state.mFormOrigData)});
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    render() 
    {
        this.cfSetControlValue();
        var lFormItemList = [];
        if(this.state.mFormItems && this.state.mFormItems.length > 0)
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if(lFormItem.visible_ === '1')
                {
                    if(lFormItem.action_ref_id_ === this.state.mSelectedActionItem.id_)
                    {
                        lFormItemList.push(lFormItem);
                    }
                }
            }
        }

        var lForm =
            <>
                <CSCODetailsHeader1 
                    pFormItems={lFormItemList} 
                    pFormProperties={this.state.mFormProperties} 
                    pFormData={this.state.mFormData}/>

                <CSCOAdvancedFormLayout3 
                    pAppId              = {this.props.pAppId}
                    pFeatureRefId       = {this.state.pFeatureRefId} 
                    pFormItems          = {this.state.mFormItems} 
                    pFormProperties     = {this.state.mFormProperties} 
                    pFormData           = {this.state.mFormData} 
                    pFormGrid           = {this.state.mFormGrid} 
                    pFormGridProperties = {this.props.pFormGridProperties} 
                    pFormCriteria       = {this.state.mFormCriteria} 
                    pSelectedData       = {this.props.pSelectedData}
                    pFormActions        = {this.state.mFormActions}/>
            </>
        return lForm;
    }
}

export default CSCODetailsForm5;
