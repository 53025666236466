import React, { useState, useEffect, useRef, useCallback } from 'react';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';

import CSCOHorizontalActionBar from "../../components/CSCOHorizontalActionBar"
import { CSConstantUtil, cfGetConstantValue } from "../../util/CSConstantUtil";
import CSCODetailsSection from "../../components/CSCODetailsSection";
import CSCODetailsHeader from "../../components/CSCODetailsHeader";
import CSCOBackdrop from '../../components/CSCOBackdrop';
import CSRequestUtil from "../../util/CSRequestUtil"
import CSServiceUtil from "../../util/CSServiceUtil"
import { fnGetForm } from "../../util/CSLayoutUtil"
import CSFormUtil from "../../util/CSFormUtil"
import CSUtil from "../../util/CSUtil"

const mFormUtil = CSFormUtil.cfGetInstance();
const mServiceUtil = CSServiceUtil.cfGetInstance();

const CSCODetailsForm = (gProps) =>
{
    const mMinSectionItems      = 7;
    const mDataCollection       = gProps.pFormData;
    const mNotificationState    = 
    {
        enabled_              : false,
        trigger_state_        : '',
        contact_service_code_ : '',
        service_handler_      : '',
        event_service_code_   : '',
        type_                 : '',
        params_               : {},
        criteria_             : {},
    };

    const mDataFieldTypeMap     = useRef({});
    const mIdFormItemMap        = useRef({});

    const [mFormData, setFormData]                          = useState((gProps.pFormData) ? gProps.pFormData : gProps.pSelectedData);
    const [mDataChange, setDataChange]                      = useState(false);
    const [mDisplay, setDisplay]                            = useState(gProps.pShow);
    const [mDetailsFlag, setDetailsFlag]                    = useState(null);
    const [mDetailsMetaData, setDetailsMetaData]            = useState(null);
    const [mPreviewForm, setPreviewForm]                    = useState(null);           
    const [mEnablePreview, setEnablePreview]                = useState(false);
    const [mEnablePrint, setEnablePrint]                    = useState(false);
    const [mPrintForm, setPrintForm]                        = useState(false);
    const [mNotification, setNotification]                  = useState(mNotificationState);
    const [mCompCodeFormItemsMap, setCompCodeFormItemsMap]  = useState({});
    const [mDataFieldObjectMap, setDataFieldObjectMap]      = useState(gProps.pDataFieldObjectMap);
    const [mDetailsActions, setDetailsActions]              = useState(null);
    const [mApplicationWidth, setApplicationWidth]          = useState(CSUtil.mApplicationWidth);
    const [mShowBackdrop, setBackdrop]                      = useState(false);
    const [mFormNameDatafieldMap, setFormNameDatafieldMap]  = useState(null);

    let mDefaultDataField     = useRef('selected_data_');
    let mParentChildrenMap    = useRef(null);
    let mFormActions          = useRef(null);
    let mFormItems            = useRef(null);
    let mFormProperties       = useRef(null);

    useEffect(()=>
        {
            setApplicationWidth(gProps.pApplicationWidth);
        }, [gProps.pApplicationWidth]
    );

    const fnProcessNotification = (lSelectedData, lActionItem) => {
        if(mNotification.enabled_ && ((mNotification.trigger_state_ === 'ALL') || (lActionItem.action_code_ === mNotification.trigger_state_)))
        {
            if(lSelectedData)
            {
                let lNotificationData = {};
                let lKeys = Object.keys(mNotification.params_);
                if(lKeys)
                {
                    for(let lkInd=0; lkInd<lKeys.length; lkInd++)
                    {
                        let lKey = lKeys[lkInd];
                        if(lSelectedData.hasOwnProperty(mNotification.params_[lKey]))
                        {
                            lNotificationData[lKey] = lSelectedData[mNotification.params_[lKey]];
                        }
                        else
                        {
                            lNotificationData[lKey] = cfGetConstantValue(mNotification.params_[lKey]);
                        }
                    }
                }

                let lNotificationCriteria = {};
                let lcKeys = Object.keys(mNotification.criteria_);
                if(lcKeys)
                {
                    for(let lckInd=0; lckInd<lcKeys.length; lckInd++)
                    {
                        let lcKey = lcKeys[lckInd];
                        if(lSelectedData.hasOwnProperty(mNotification.criteria_[lcKey]))
                        {
                            lNotificationCriteria[lcKey] = lSelectedData[mNotification.criteria_[lcKey]];
                        }
                        else
                        {
                            lNotificationCriteria[lcKey] = cfGetConstantValue(mNotification.criteria_[lcKey]);
                        }
                    }
                }

                let lRequestUtil = null;
                if(mNotification.service_handler_ === 'CS_NOTFN') {
                    if(mNotification.event_service_code_ && lNotificationData)
                    {
                        let lServiceUtil = CSServiceUtil.cfGetInstance();
                        let lServiceObject = lServiceUtil.cfGetServiceObject(mNotification.event_service_code_);
                        if(lServiceObject) {
                            lRequestUtil = new CSRequestUtil('SAVE', mNotification.event_service_code_, lServiceObject.type_, null, lServiceObject);
                            lRequestUtil.cfSetRequestData(lNotificationData);
                            lRequestUtil.cfProcessRequest();
                        }
                    }
                }
                else {
                    lRequestUtil = new CSRequestUtil();
                    lRequestUtil.cfNotification(mNotification.type_, mNotification.category_, '', mNotification.contact_service_code_, lNotificationCriteria, lNotificationData, (lResult)=> {console.log(lResult)});
                }
            }
        }
    }

    const fnProcessSave = (lActionItem) => 
    {
        if(lActionItem)
        {
            mFormUtil.cfPromptSave(lActionItem, (lResult) =>
                {
                    if(lResult)
                    {
                        if(lResult.value)
                        {
                            let lRequestData = null;
                            let lStatus = null;
                            let lStatusDataField = null;
                            let lFileUploadRetVal = true;
                            if(gProps.pFormItems)
                            {
                                for(let lInd=0; lInd<gProps.pFormItems.length; lInd++)
                                {
                                    let lFormItem = gProps.pFormItems[lInd];
                                    if(lFormItem.control_type_ === 'file')
                                    {
                                        if(lFormItem.hasOwnProperty('mFileList') && lFormItem.mFileList)
                                        {
                                            let lRequestUtil = new CSRequestUtil();
                                            lRequestUtil.cfUploadFile(lFormItem.mFileList, lFormItem.constant_, (lResponseData) =>
                                                {
                                                    if(lResponseData instanceof Object)
                                                    {

                                                    }
                                                    else if(lResponseData === -1)
                                                    {
                                                        lFileUploadRetVal = false;
                                                    }
                                                }
                                            )
                                        }
                                    }

                                    if(lFileUploadRetVal)
                                    {
                                        if(lFormItem.action_ref_id_ === lActionItem.parent_ref_id_)
                                        {
                                            if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                                            {
                                                let lRequestObj = mFormUtil.fnGetRequestObject(lFormItem, mDataCollection, mFormData, lRequestData, mIdFormItemMap.current, lActionItem);
                                                if(lRequestObj)
                                                {
                                                    if(!lRequestData)
                                                    {
                                                        lRequestData = {}
                                                    }
                            
                                                    lRequestData[lFormItem.data_field_] = lRequestObj;
                                                }
                                            }
                                            else if(lFormItem.control_type_ === 'state')
                                            {
                                                lStatus = lActionItem.action_code_;
                                                lStatusDataField = lFormItem.data_field_;
                                            }
                                        }
                                    }
                                }
                            }

                            if(lFileUploadRetVal)
                            {
                                if(!lRequestData)
                                {
                                    if(mFormData && mFormData.hasOwnProperty(mDefaultDataField.current) && mFormData[mDefaultDataField.current])
                                    {
                                        lRequestData = mFormData[mDefaultDataField.current];
                                    }
                                    else
                                    {
                                        lRequestData = mFormData;
                                    }

                                    if(lStatus)
                                    {
                                        lRequestData[lStatusDataField] = lStatus;
                                    }
                                }

                                let lCriteria = null;
                                for (let lInd=0; lInd<mFormItems.current.length; lInd++) {
                                    let lFormItem = mFormItems.current[lInd];
                                    if(lFormItem.criteria_field_ && (lFormItem.criteria_field_ === 'Y')) {
                                        if(!lCriteria) {
                                            lCriteria = {}
                                        }

                                        lCriteria[lFormItem.data_field_] = lFormItem.mValue;
                                    }
                                }

                                setBackdrop(true);
                                mFormUtil.cfProcessSave(lActionItem, lRequestData, 
                                    (lResponse, lResponseData, lReqData) =>
                                    {
                                        setBackdrop(false);
                                        let lCode = null;
                                        let lMsg = null;
                                        if(lResponse && lResponse.code)
                                        {
                                            if(lResponse.code instanceof Array)
                                            {
                                                lCode = lResponse.code[0];
                                                if(lResponse.message)
                                                {
                                                    lMsg = lResponse.message[0];
                                                }
                                            }
                                            else
                                            {
                                                lCode = lResponse.code;
                                                if(lResponse.message)
                                                {
                                                    lMsg = lResponse.message;
                                                }
                                            }
                                        }
                                        else
                                        {
                                            lCode = -1;
                                        }
        
                                        let lPostSaveData = mFormUtil.cfProcessSaveResponse(lCode, lMsg, lActionItem, lResponse)
                                        if((lCode === 1) || (lCode === '1'))
                                        {
                                            let lSelectedData = null;
                                            if(mFormData)
                                            {
                                                lSelectedData = mFormData['selected_data_'];
                                            }

                                            let lNavigateToForm = lActionItem.navigate_to_form_;
                                            if( lNavigateToForm && lPostSaveData && mFormData)
                                            {
                                                let lSelectedCriteria = null;
                            
                                                let lDetailsMetaData = 
                                                {
                                                    pParent                 : this,
                                                    pFormType               : lActionItem.navigate_to_form_component_code_,
                                                    pFormHeading            : lActionItem.title_,
                                                    pFormActions            : mFormUtil.cfGetChildActionItems(gProps.pFormActions, lActionItem.id_),
                                                    pFormItems              : mFormUtil.cfGetFormItems(gProps.pFormItems, lActionItem.id_),
                                                    pFormProperties         : gProps.pFormProperties,
                                                    pSelectedActionItem     : lActionItem,
                                                    pSelectedData           : lSelectedData,
                                                    pSelectedCriteria       : lSelectedCriteria,
                                                    pCriteria               : lSelectedCriteria,
                                                    fnProcessClose          : fnCloseForm,
                                                    pProcessClose           : fnProcessClose,
                                                    pParentFlag             : true
                                                };

                                                for(let lFv_0=0; lFv_0<lPostSaveData.length; lFv_0++)
                                                {
                                                    let lP1Obj = lPostSaveData[lFv_0];
                                                    if(lP1Obj.data_field_1_type_)
                                                    {
                                                        if(lP1Obj.data_field_1_type_ === 'data')
                                                        {
                                                            if(lP1Obj.data_object_ && mFormData.hasOwnProperty(lP1Obj.data_object_))
                                                            {
                                                                lSelectedData = mFormData[lP1Obj.data_object_]
                                                            }
                                                        }
                                                    }

                                                    if(lP1Obj.data_field_1_type_ === 'data')
                                                    {
                                                        if(lP1Obj.data_object_ && mFormData.hasOwnProperty(lP1Obj.data_object_))
                                                        {
                                                            lDetailsMetaData[lP1Obj.data_field_1_] = mFormData[lP1Obj.data_object_]
                                                        }
                                                    }
                                                    else if(lP1Obj.data_field_1_type_ === 'collection')
                                                    {
                                                        if(lP1Obj.data_object_ && mFormData.hasOwnProperty(lP1Obj.data_object_))
                                                        {
                                                            lDetailsMetaData[lP1Obj.data_field_1_] = mFormData[lP1Obj.data_object_]
                                                        }
                                                    }
                                                    else if(lP1Obj.data_field_1_type_ === 'request')
                                                    {
                                                        if(lP1Obj.data_object_ && lReqData && lReqData.hasOwnProperty(lP1Obj.data_object_))
                                                        {
                                                            lDetailsMetaData[lP1Obj.data_field_1_] = lReqData[lP1Obj.data_object_]
                                                        }
                                                    }
                                                    else if(lP1Obj.data_field_1_type_ === 'response')
                                                    {
                                                        if(lP1Obj.data_object_ && lP1Obj.data_field_2_ && lResponseData.hasOwnProperty(lP1Obj.data_object_))
                                                        {                                                           
                                                            if(lDetailsMetaData.hasOwnProperty(lP1Obj.data_field_1_))
                                                            {
                                                                let lSelData1 = lDetailsMetaData[lP1Obj.data_field_1_];
                                                                let lRespVal = lResponseData[lP1Obj.data_object_];
                                                                if(lRespVal && lRespVal.hasOwnProperty(lP1Obj.data_field_2_) && Array.isArray(lRespVal[lP1Obj.data_field_2_]))
                                                                {
                                                                    lSelData1[lP1Obj.data_field_2_] = lRespVal[lP1Obj.data_field_2_][0];
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                if(lSelectedData)
                                                {
                                                    for(let lFv_1=0; lFv_1<lPostSaveData.length; lFv_1++)
                                                    {    
                                                        let lP2Obj = lPostSaveData[lFv_1];
                                                        if(lP2Obj.data_field_1_type_ === 'criteria')
                                                        {
                                                            if(lP2Obj.data_field_1_ && lSelectedData.hasOwnProperty(lP2Obj.data_field_1_))
                                                            {
                                                                if(!lSelectedCriteria)
                                                                {
                                                                    lSelectedCriteria = {}
                                                                }

                                                                lSelectedCriteria[lP2Obj.data_field_1_] = lSelectedData[lP2Obj.data_field_1_]
                                                            }
                                                        }
                                                    }
                                                }

                                                fnProcessNotification(lSelectedData, lActionItem);
                                                setDetailsMetaData(lDetailsMetaData);
                                                setDetailsFlag('DETAILS');
                                            }
                                            else
                                            {
                                                fnProcessNotification(lSelectedData, lActionItem);
                                                
                                                let lCloseFlag = (lActionItem && (lActionItem.new_state_ === 'list')) ? true : false;
                                                if(lCloseFlag)
                                                {
                                                    gProps.fnProcessClose(lCloseFlag, lCloseFlag);
                                                }
                                            }
                                        }
                                    }, lCriteria
                                );
                            }
                        }
                    }
                }
            );
        }
    }

    const fnAddToCollection = (lActionItem) =>
    {
        if(gProps.pResponseFn)
        {
            var lDataObj = null;
            if(gProps.pFormItems && mDataFieldObjectMap && (gProps.pFormItems.length > 0))
            {
                lDataObj = mDataFieldObjectMap[gProps.pFormItems[0].data_field_];
            }

            gProps.pResponseFn(lDataObj, true, lActionItem);
        }
    }
    
    const fnUpdateCollection = (lActionItem) =>
    {
        if(gProps.pResponseFn)
        {
            var lDataObj = null;
            if(gProps.pFormItems && mDataFieldObjectMap && (gProps.pFormItems.length > 0))
            {
                lDataObj = mDataFieldObjectMap[gProps.pFormItems[0].data_field_];
            }

            gProps.pResponseFn(lDataObj, true, lActionItem);
        }
    }

    const fnRemoveFromCollection = () =>
    {
    }

    const fnCloseForm = (lCloseFlag) => 
    {
        setDetailsFlag(null);
        setDetailsMetaData(null);

        if(gProps.fnProcessClose)
        {
            gProps.fnProcessClose(lCloseFlag);
        }        
    }

    const fnProcessClose = (lCloseFlag) => 
    {
        if(lCloseFlag)
        {
            setDetailsFlag(null);
            setDetailsMetaData(null);

            if(gProps.fnProcessClose)
            {
                gProps.fnProcessClose(lCloseFlag);
            }
        }
        else
        {
            initializeData.current(mFormItems, mDataFieldObjectMap, gProps, mFormData, mFormNameDatafieldMap);
        }
    }

    const fnProcessClick = useCallback((lEvent, lActionItem) =>
        {
            if(lActionItem)
            {
                if((lActionItem.action_ === 'process_cancel') || (lActionItem.action_ === 'process_close'))
                {
                    if(lActionItem.action_ === 'process_cancel')
                    {
                        setDisplay(false);
                    }
                    else
                    {
                        if(gProps.fnProcessClose)
                        {
                            gProps.fnProcessClose();
                        }
                    }
                }
                else if(lActionItem.action_ === 'process_save')
                {
                    fnProcessSave(lActionItem);
                }
                else if(lActionItem.action_ === 'generate_progress_card')
                {
                    fnProcessSave(lActionItem);
                }
                else if(lActionItem.action_ === 'process_update')
                {
                    if(gProps.pSelectedActionItem && (gProps.pSelectedActionItem.action_ === 'process_add'))
                    {
                        fnAddToCollection(gProps.pSelectedActionItem);
                    }
                    else if(gProps.pSelectedActionItem && (gProps.pSelectedActionItem.action_ === 'process_modify'))
                    {
                        fnUpdateCollection(gProps.pSelectedActionItem);
                    }
                    else if(gProps.pSelectedActionItem && (gProps.pSelectedActionItem.action_ === 'process_delete'))
                    {
                        fnRemoveFromCollection(gProps.pSelectedActionItem);
                    }
                }
                else
                {
                    var lFormItems = null;
                    var lFormActions = null;
                    if(gProps.hasOwnProperty('pFormMetaData'))
                    {
                        if(gProps.pFormMetaData.hasOwnProperty('pFormItems'))
                        {
                            lFormItems = gProps.pFormMetaData.pFormItems;
                        }
                        else
                        {
                            lFormItems = gProps.pFormItems;
                        }

                        if(gProps.pFormMetaData.hasOwnProperty('pFormActions'))
                        {
                            lFormActions = gProps.pFormMetaData.pFormActions;
                        }
                        else
                        {
                            lFormActions = gProps.pFormActions;
                        }
                    }
                    else
                    {
                        lFormItems = gProps.pFormItems;
                        lFormActions = gProps.pFormActions;
                    }                    

                    var lDetailsMetaData = 
                    {
                        pParent                 : this,
                        pFormType               : lActionItem.navigate_to_form_component_code_,
                        pFormHeading            : lActionItem.title_,
                        pFormActions            : mFormUtil.cfGetChildActionItems(lFormActions, lActionItem.id_),
                        pFormItems              : mFormUtil.cfGetFormItems(lFormItems, lActionItem.id_),
                        pFormProperties         : gProps.pFormProperties,
                        pSelectedActionItem     : lActionItem,
                        pParentFlag             : true
                    };

                    setDetailsMetaData(lDetailsMetaData);
                    if(lActionItem.action_ === 'process_add')
                    {
                        setDetailsFlag('ADD');
                    }
                    else if((lActionItem.action_ === 'process_modify') || (lActionItem.action_ === 'process_details'))
                    {
                        setDetailsFlag('MODIFY');
                    }
                }
            }
        }, [mFormData, gProps]
    );

    useEffect(() =>
        {
            /*
             * Search Actions
             */
            const fnInitializeActions = () =>
            {
                let lDetailsActionList = null;
                let lIncludeFlag = true;
                let lSelectedData = null;
                if(mFormData) {
                    lSelectedData = mFormData['selected_data_'];
                }

                if(gProps.pFormActions && (gProps.pFormActions.length > 0))
                {
                    for(let lDaInd=0; lDaInd<gProps.pFormActions.length; lDaInd++)
                    {
                        let lDetailsActionItem = gProps.pFormActions[lDaInd]; 
                        if((lDetailsActionItem.component_type_ === 'DETAILS_ACTION') || (lDetailsActionItem.component_type_ === 'DETAILS_ITEM_DETAILS_ACTION'))
                        {
                            if(!lDetailsActionList)
                            {
                                lDetailsActionList = [];
                            }
                                
                            if(gProps.pSelectedActionItem && (!lDetailsActionItem.parent_ref_id_ || (lDetailsActionItem.parent_ref_id_ === gProps.pSelectedActionItem.id_)))
                            {
                                if(lSelectedData && lDetailsActionItem.hasOwnProperty('cs_form_action_properties_')) {
                                    for(let lInd=0; lInd<lDetailsActionItem.cs_form_action_properties_.length; lInd++) {
                                        let lProperty = lDetailsActionItem.cs_form_action_properties_[lInd];
                                        if((lProperty.type_ === 'form_initialize') && (lProperty.property_ === 'include')) {
                                            if(lSelectedData && lSelectedData.hasOwnProperty(lProperty.selection_property_)) {
                                                if(lProperty.selection_value_ === '#NULL#') {
                                                    if(lSelectedData[lProperty.selection_property_] === '') {
                                                        if(lProperty.value_ === 'true') {
                                                            lIncludeFlag = true;
                                                        }
                                                        else {
                                                            lIncludeFlag = false;
                                                        }
                                                    }
                                                }
                                                else if(lProperty.selection_value_ === '#NOT_NULL#') {
                                                    if(lSelectedData[lProperty.selection_property_]) {
                                                        if(lProperty.value_ === 'true') {
                                                            lIncludeFlag = true;
                                                        }
                                                        else {
                                                            lIncludeFlag = false;
                                                        }
                                                    }
                                                }
                                                else if((lSelectedData[lProperty.selection_property_] === cfGetConstantValue(lProperty.selection_value_))) {
                                                    if(lProperty.value_ === 'true') {
                                                        lIncludeFlag = true;
                                                    }
                                                    else {
                                                        lIncludeFlag = false;
                                                    }    
                                                }
                                            }        
                                        }
                                    }
                                }

                                if(lDetailsActionItem.action_type_ === '1') {
                                    if(!mFormActions.current) {
                                        mFormActions.current = [];
                                    }

                                    mFormActions.current.push(lDetailsActionItem);
                                }
                                else {
                                    lDetailsActionItem.mEnabled = (lDetailsActionItem.enabled_ === '1') ? true : false;
                                }
                                
                                if(lDetailsActionItem.action_) {
                                    lDetailsActionItem.fnProcessClick = fnProcessClick;
                                }
        
                                if(lIncludeFlag) {
                                    lDetailsActionList.push(lDetailsActionItem);
                                }
                            }
                        }
                    }
        
                    CSUtil.cfSort(lDetailsActionList, 'sequence_');

                    setDetailsActions(lDetailsActionList);
                }

                setDisplay(gProps.pShow);
            }

            fnInitializeActions();
        }, [gProps.pFormActions, gProps.pSelectedActionItem, fnProcessClick, mDetailsFlag]
    );

    useEffect(() => {
            const fnInitializeForm = () => {
                let lFormItemList = [];
                let lFormCodeList = null;
                let lFormNameDatafieldMap = {}
                let lComponentCodeFormItemsMap = {};
                let lFormItemsWithoutParent = null;
                mParentChildrenMap.current = null;

                let lProperties = CSUtil.cfClone(gProps);
                if(gProps.pFormItems && Array.isArray(gProps.pFormItems) && gProps.pFormItems.length > 0)
                {
                    let lSelectedActionItem = null;
                    if(gProps.pParentActionItem)
                    {
                        lSelectedActionItem = gProps.pParentActionItem;
                    }
                    else
                    {
                        lSelectedActionItem = gProps.pSelectedActionItem;
                    }
                    
                    if(gProps.pFormProperties)
                    {
                        if(!lSelectedActionItem)
                        {
                            lSelectedActionItem = {}
                            mFormProperties.current = gProps.pFormProperties;
                            for(let lpfpInd=0; lpfpInd<gProps.pFormProperties.length; lpfpInd++)
                            {
                                let lpfpProperty = gProps.pFormProperties[lpfpInd];
                                if(lpfpProperty.property_ === 'selected_action_item_ref_id_')
                                {
                                    lSelectedActionItem.id_ = lpfpProperty.value_;
                                }
                                else if(lpfpProperty.property_ === 'on_click_service_code_')
                                {
                                    lSelectedActionItem.on_click_service_code_ = lpfpProperty.value_;
                                }
                                    
                                if(lpfpProperty.type_ === 'criteria')
                                {
                                    if(!lProperties.pSelectedCriteria)
                                    {
                                        lProperties.pSelectedCriteria = {}
                                    }
    
                                    lProperties.pSelectedCriteria[lpfpProperty.property_] = cfGetConstantValue(lpfpProperty.value_);
                                }
                            }
                        }
                        
                        let lNotificationData = mNotificationState;
                        for(let lpfpInd=0; lpfpInd<gProps.pFormProperties.length; lpfpInd++)
                        {
                            let lpfpProperty = gProps.pFormProperties[lpfpInd];
                            if(lpfpProperty.type_ === 'notification')
                            {
                                if((lpfpProperty.property_ === 'enable_') && lpfpProperty.value_)
                                {
                                    lNotificationData.enabled_ = (lpfpProperty.value_ === '1');
                                }
                                else if((lpfpProperty.property_ === 'trigger_state_') && lpfpProperty.value_)
                                {
                                    lNotificationData.trigger_state_ = lpfpProperty.value_;
                                }
                                else if((lpfpProperty.property_ === 'contact_service_code_') && lpfpProperty.value_)
                                {
                                    lNotificationData.contact_service_code_ = lpfpProperty.value_;
                                }
                                else if((lpfpProperty.property_ === 'type_') && lpfpProperty.value_)
                                {
                                    lNotificationData.type_ = lpfpProperty.value_;
                                }
                                else if((lpfpProperty.property_ === 'category_') && lpfpProperty.value_)
                                {
                                    lNotificationData.category_ = lpfpProperty.value_;
                                }
                                else if((lpfpProperty.property_.includes('param_') || (lpfpProperty.group_ === 'param')) && lpfpProperty.value_)
                                {
                                    lNotificationData.params_[lpfpProperty.property_] = lpfpProperty.value_;
                                }
                                else if(lpfpProperty.property_.includes('service_handler_') && lpfpProperty.value_)
                                {
                                    lNotificationData.service_handler_ = lpfpProperty.value_;
                                }
                                else if(lpfpProperty.property_.includes('event_service_code_') && lpfpProperty.value_)
                                {
                                    lNotificationData.event_service_code_ = lpfpProperty.value_;
                                }
                                
                                if(lpfpProperty.group_ === 'criteria')
                                {
                                    lNotificationData.criteria_[lpfpProperty.property_] = lpfpProperty.value_;
                                }                                
                            }
                        }

                        setNotification(lNotificationData);
                    }

                    if(lSelectedActionItem)
                    {
                        lProperties.pSelectedActionItem = lSelectedActionItem;

                        CSUtil.cfSort(gProps.pFormItems, 'sequence_');
                        for(var lInd=0; lInd < gProps.pFormItems.length; lInd++)
                        {
                            var lFormItem = gProps.pFormItems[lInd];
                            lFormItem.mValue = '';
                            mIdFormItemMap.current[lFormItem.id_] = lFormItem;
                            var lActionRefId = (lFormItem.details_action_ref_id_) ? lFormItem.details_action_ref_id_ : lFormItem.action_ref_id_;
                            if(lActionRefId === lSelectedActionItem.id_)
                            {
                                if(!lFormCodeList)
                                {
                                    lFormCodeList = [];
                                }

                                if(!lFormCodeList.includes(lFormItem.cs_form_code_))
                                {
                                    lFormCodeList.push(lFormItem.cs_form_code_);
                                }

                                let lCompCode = null;
                                if(!lCompCode) {
                                    if(lFormItem.cs_form_component_code_ === 'CS_DETAILS_FORM_3'){
                                        lCompCode = 'CS_DETAILS_FORM_1';
                                    }
                                    else {
                                        lCompCode = lFormItem.cs_form_component_code_;
                                    }        
                                }

                                let lComponentFormItems = lComponentCodeFormItemsMap[lCompCode];
                                if(!lComponentFormItems)
                                {
                                    lComponentFormItems = [];
                                    lComponentCodeFormItemsMap[lCompCode] = lComponentFormItems;
                                }
                                
                                if(lFormItem.parent_id_)
                                {
                                    if(!mParentChildrenMap.current)
                                    {
                                        mParentChildrenMap.current = {}
                                    }

                                    var lChildren = mParentChildrenMap.current[lFormItem.parent_id_];
                                    if(!lChildren)
                                    {
                                        lChildren = [];
                                        mParentChildrenMap.current[lFormItem.parent_id_] = lChildren;
                                    }

                                    lChildren.push(lFormItem);
                                }
                                else
                                {
                                    if(!lFormItemsWithoutParent)
                                    {
                                        lFormItemsWithoutParent = [];
                                    }

                                    lFormItemsWithoutParent.push(lFormItem);
                                }

                                if((lFormItem.visible_ === '1') && !lFormItem.hasOwnProperty('is_dependent_item_'))
                                {
                                    lComponentFormItems.push(lFormItem);
                                }

                                if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                                {
                                    for(var lfiIndex=0; lfiIndex<lFormItem.cs_form_item_properties_.length; lfiIndex++)
                                    {
                                        var lfiProperty = lFormItem.cs_form_item_properties_[lfiIndex];
                                        if((lfiProperty.type_ === 'data_selection') 
                                                && (lfiProperty.property_ === 'form_item') && (lfiProperty.value_ === 'selected_criteria_')
                                                && (gProps.hasOwnProperty('pFormMetaData') && gProps.pFormMetaData.hasOwnProperty('pFormCriteria')))
                                        {
                                            var lFormCriteria = gProps.pFormMetaData.pFormCriteria;
                                            if(lFormCriteria.length > 0)
                                            {
                                                for(var lfcInd=0; lfcInd<lFormCriteria.length; lfcInd++)
                                                {
                                                    var lfcObj = lFormCriteria[lfcInd];
                                                    if((lFormItem.data_field_ === lfiProperty.selection_property_) && (lfcObj.criteria_field_ === lfiProperty.selection_value_))
                                                    {
                                                        lFormItem.mCriteriaValue = lfcObj.mValue;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                
                                lFormItemList.push(lFormItem);
                            }
                        }
                    }
                }

                var lFormData = gProps.pFormData;
                if(!lFormData)
                {
                    lFormData = {}
                }

                var lDataFieldObjectMap = mDataFieldObjectMap;
                var lDefaultDataField = mDefaultDataField.current;

                if(gProps.pFormProperties)
                {
                    for(var lInd_0=0; lInd_0<gProps.pFormProperties.length; lInd_0++)
                    {
                        var lFormProperty = gProps.pFormProperties[lInd_0];
                        if(lFormCodeList)
                        {
                            if((lFormProperty.type_ === 'initialize') && (lFormCodeList.includes(lFormProperty.cs_form_code_)))
                            {
                                lFormNameDatafieldMap[lFormProperty.cs_form_code_] = lFormProperty.value_;
                                if (lFormProperty.property_ === 'default_data_field_')
                                {
                                    mDefaultDataField.current = lFormProperty.value_;
                                    lDefaultDataField = lFormProperty.value_;
                                }
                                else if(lFormProperty.property_ === 'data_')
                                {
                                    mDataFieldTypeMap.current[lFormProperty.value_] = 'OBJECT';
                                    lFormData[lFormProperty.value_] = {};
                                }
                                else if(lFormProperty.property_ === 'collection_data_')
                                {
                                    mDataFieldTypeMap.current[lFormProperty.value_] = 'COLLECTION';
                                }
                            }
                        }

                        if (lFormProperty.property_ === 'enable_print')
                        {
                            setEnablePrint(true);
                        }
                        else if (lFormProperty.property_ === 'print_form')
                        {
                            setEnablePreview(true);
                            setPrintForm(lFormProperty.value_);
                        }        
                    }
                }

                if(gProps.pSelectedData)
                {
                    if(gProps.pSelectedData.hasOwnProperty('selected_data_'))
                    {
                        lFormData[lDefaultDataField] = gProps.pSelectedData.selected_data_;
                    }
                    else
                    {
                        lFormData[lDefaultDataField] = gProps.pSelectedData;
                    }
                }
                else
                {
                    lFormData[lDefaultDataField] = {}
                }

                var lParentList = null;
                if(mParentChildrenMap.current)
                {
                    lParentList = Object.keys(mParentChildrenMap.current);
                    for(var lInd0=0; lInd0<lParentList.length; lInd0++)
                    {
                        var lParentId       = lParentList[lInd0];
                        var lParentFormItem = mIdFormItemMap.current[lParentId];
                        var lChildren0      = mParentChildrenMap.current[lParentId];
                        var lDataObj        = null;
                        if(lChildren0 && (lChildren0.length > 0))
                        {
                            if(lParentFormItem)
                            {
                                lDataObj = lFormData[lParentFormItem.data_field_];
                                if(!lDataObj)
                                {
                                    if(mDataFieldTypeMap.current[lParentFormItem.data_field_])
                                    {
                                        if(mDataFieldTypeMap.current[lParentFormItem.data_field_] === 'COLLECTION')
                                        {
                                            lDataObj = []
                                        }
                                        else
                                        {
                                            lDataObj = {}
                                        }
                                    }
                                    else
                                    {
                                        lDataObj = {}
                                    }

                                    lFormData[lParentFormItem.data_field_] = lDataObj;
                                }
                            }

                            if(!lDataObj)
                            {
                                var lDatafieldName = (lParentFormItem) ? lFormNameDatafieldMap[lParentFormItem.cs_form_code_] : null;
                                if(lDatafieldName)
                                {
                                    lDataObj = lFormData[lDatafieldName];
                                }
                                else
                                {
                                    lDataObj = lFormData[lDefaultDataField];
                                }
                            }

                            for(var lcInd=0; lcInd<lChildren0.length; lcInd++)
                            {
                                var lChild = lChildren0[lcInd];
                                if(!lDataFieldObjectMap)
                                {
                                    lDataFieldObjectMap = {};
                                }
                
                                if(lChild.data_field_)
                                {
                                    lDataFieldObjectMap[lChild.data_field_] = lDataObj;
                                }
                            }
                        }
                    }
                }

                if(lFormItemsWithoutParent)
                {
                    for(var lpInd=0; lpInd<lFormItemsWithoutParent.length; lpInd++)
                    {
                        var lpFormItem  = lFormItemsWithoutParent[lpInd];
                        var lDObj       = null;
                        var lDfieldName = lFormNameDatafieldMap[lpFormItem.cs_form_code_]
                        if(lDfieldName)
                        {
                            lDObj = lFormData[lDfieldName];
                        }
                        else
                        {
                            lDObj = lFormData[lDefaultDataField];
                        }
        
                        if(!lParentList || (lParentList && !lParentList.includes(lpFormItem.id_)))
                        {
                            if(!lDataFieldObjectMap)
                            {
                                lDataFieldObjectMap = {}
                            }
                        
                            lDataFieldObjectMap[lpFormItem.data_field_] = lDObj;
                        }
                    }
                }

                if(gProps.pSelectedCriteria)
                {
                    lFormData['selected_criteria_'] = gProps.pSelectedCriteria;
                }
                else if (gProps.pSelectedData && gProps.pSelectedData.hasOwnProperty('selected_criteria_'))
                {
                    lFormData['selected_criteria_'] = gProps.pSelectedData['selected_criteria_'];
                    lProperties.pSelectedCriteria = gProps.pSelectedData['selected_criteria_'];
                }

                mFormItems.current = lFormItemList;
                setFormData(lFormData);
                setDataFieldObjectMap(lDataFieldObjectMap);
                setCompCodeFormItemsMap(lComponentCodeFormItemsMap);
                setFormNameDatafieldMap(lFormNameDatafieldMap);
                setDisplay(true);
                initializeData.current(lFormItemList, lDataFieldObjectMap, lProperties, lFormData, lFormNameDatafieldMap);
            }

            fnInitializeForm();
        }, [gProps.pShow]
    );

    const fnEnableSave = (lSaveActionItem, lFormItems, lDataFieldObjectMap) =>
    {
        if(lSaveActionItem && lFormItems && lDataFieldObjectMap)
        {
            if(lSaveActionItem.action_type_ === '1')
            {
                var lEnable = true;
                for(var lFiInd0=0; lFiInd0< lFormItems.length; lFiInd0++)
                {
                    var lFormItem0 = lFormItems[lFiInd0];
                    var lDataObj = lDataFieldObjectMap[lFormItem0.data_field_];
                    if(lSaveActionItem.cs_form_action_properties_)
                    {
                        var lIsEnabled = true;
                        for(var lFapInd0 = 0; lFapInd0<lSaveActionItem.cs_form_action_properties_.length; lFapInd0++)
                        {
                            var lFapObj0 = lSaveActionItem.cs_form_action_properties_[lFapInd0];
                            if((lFapObj0.type_ === 'item_selection') && (lFapObj0.selection_property_) && (lFapObj0.selection_value_))
                            {
                                if(lDataObj && lDataObj.hasOwnProperty(lFapObj0.selection_property_))
                                {
                                    var lSelectedProperty = lDataObj[lFapObj0.selection_property_];
                                    if(lFapObj0.selection_value_ === '#NOT_NULL#')
                                    {
                                        if(lSelectedProperty)
                                        {
                                            lIsEnabled = (lFapObj0.value_ === 'true');
                                        }
                                        else
                                        {
                                            lIsEnabled = !(lFapObj0.value_ === 'true');
                                        }
                                    }
                                    else if(lFapObj0.selection_value_ === '#NULL#')
                                    {
                                        if(!lSelectedProperty)
                                        {
                                            lIsEnabled = (lFapObj0.value_ === 'true');
                                        }
                                        else
                                        {
                                            lIsEnabled = !(lFapObj0.value_ === 'true');
                                        }
                                    }
                                    else if(lFapObj0.selection_value_ === '#POSITIVE#')
                                    {
                                        if(lFapObj0.value_ === 'true')
                                        {
                                            lIsEnabled = (Number(lSelectedProperty) > 0) ? true : false;
                                        }
                                        else
                                        {
                                            lIsEnabled = (Number(lSelectedProperty) > 0) ? false : true;
                                        }
                                    }
                                    else
                                    {
                                        if(lFapObj0.selection_value_)
                                        {
                                            if(lSelectedProperty)
                                            {
                                                var lEqual0 = (lSelectedProperty === lFapObj0.selection_value_);
                                                if(lEqual0)
                                                {
                                                    if(lFapObj0.value_ === 'true')
                                                    {
                                                        lIsEnabled = true;
                                                    }
                                                    else
                                                    {
                                                        lIsEnabled = false;
                                                    }
                                                }
                                                else
                                                {
                                                    if(lFapObj0.value_ === 'true')
                                                    {
                                                        lIsEnabled = false;
                                                    }
                                                    else
                                                    {
                                                        lIsEnabled = true;
                                                    }
                                                }
                                            }
                                            else
                                            {
                                                lIsEnabled = false;
                                            }
                                        }
                                    }
                                }
                            }

                            if(lFapInd0 === 0)
                            {
                                lEnable = lIsEnabled;
                            }
                            else
                            {
                                lEnable = lEnable && lIsEnabled;
                            }
                        }
                                    
                        if(!lEnable)
                        {
                            break;
                        }
                    }

                    if(lFormItem0.required_ === '1')
                    {
                        if(lDataObj && lEnable)
                        {
                            if(!lDataObj[lFormItem0.data_field_])
                            {
                                if(lFormItem0.constant_ && (lFormItem0.control_type_ !== 'file'))
                                {
                                    lDataObj[lFormItem0.data_field_] = cfGetConstantValue(lFormItem0.constant_);
                                    lEnable = true;
                                }
                                else
                                {
                                    lEnable = false;
                                    break;
                                }
                            }
                            else if((lDataObj[lFormItem0.data_field_] instanceof Array) && (lDataObj[lFormItem0.data_field_].length === 0))
                            {
                                lEnable = false;
                                break;
                            }
                        }
                        else
                        {
                            lEnable = false;
                            break;
                        }
                    }
                }

                lSaveActionItem.mEnabled = lEnable;
            }
            else
            {
                lSaveActionItem.mEnabled = (lSaveActionItem.enabled_ === '1') ? true : false;
            }
        }
    }

    const fnSetControlValue = useRef((lFormItems, lDataFieldObjectMap, lSaveActionItems) =>
        {
            if(!lFormItems)
            {
                lFormItems = mFormItems.current;
            }

            if(!lDataFieldObjectMap)
            {
                lDataFieldObjectMap = mDataFieldObjectMap;
            }

            if(lFormItems && lDataFieldObjectMap)
            {
                for(let lInd=0; lInd<lFormItems.length; lInd++)
                {
                    let lFormItem = lFormItems[lInd];
                    let lFormData = lDataFieldObjectMap[lFormItem.data_field_];
                    if(lFormData)
                    {
                        if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                        {
                            let lProperties = lFormItem.cs_form_item_properties_;
                            let lSequence = '0';
                            let lRequired = '0';
                            let lEditable = '0';
                            let lVisible = '0';
                            for(let lPInd=0; lPInd<lProperties.length; lPInd++)
                            {
                                let lProperty = lProperties[lPInd];
                                if(lProperty.type_ === 'form_item')
                                {
                                    if(lProperty.property_ === 'visible')
                                    {
                                        if(lFormData.hasOwnProperty(lProperty.selection_property_))
                                        {
                                            if(lFormData[lProperty.selection_property_] === cfGetConstantValue(lProperty.selection_value_))
                                            {
                                                lFormItem.visible_ = lProperty.value_;
                                                lVisible = lProperty.value_;
                                            }
                                            else
                                            {
                                                lFormItem.visible_ = lVisible;
                                            }
                                        }
                                    }
                                 
                                    if(lProperty.property_ === 'sequence')
                                    {
                                        if(lFormData.hasOwnProperty(lProperty.selection_property_))
                                        {
                                            if(lFormData[lProperty.selection_property_] === cfGetConstantValue(lProperty.selection_value_))
                                            {
                                                lFormItem.sequence_ = lProperty.value_;
                                                lSequence = lProperty.value_;
                                            }
                                            else
                                            {
                                                lFormItem.sequence_ = lSequence;
                                            }
                                        }
                                    }
                                 
                                    if(lProperty.property_ === 'required')
                                    {
                                        if(lFormData[lProperty.selection_property_] === cfGetConstantValue(lProperty.selection_value_))
                                        {
                                            lFormItem.required_ = lProperty.value_;
                                            lRequired = lProperty.value_;
                                        }
                                        else
                                        {
                                            lFormItem.required_ = lRequired;
                                        }
                                    }
                                 
                                    if(lProperty.property_ === 'editable')
                                    {
                                        if(lFormData[lProperty.selection_property_] === cfGetConstantValue(lProperty.selection_value_))
                                        {
                                            lFormItem.editable_ = lProperty.value_;
                                            lEditable = lProperty.value_;
                                        }
                                        else
                                        {
                                            lFormItem.editable_ = lEditable;
                                        }
                                    }
                                }
                            }
                        }
    
                        if(lFormItem.constant_ && ((lFormItem.control_type_ === 'constant') || (lFormItem.control_type_ === 'text_input')))
                        {
                            if(lFormData[lFormItem.data_field_])
                            {
                                lFormItem.mValue = lFormData[lFormItem.data_field_];
                            }
                            else
                            {
                                lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                                lFormData[lFormItem.data_field_] = lFormItem.mValue;
                            }
                        }
                        else if(lFormItem.control_type_ === 'check_box')
                        {
                            lFormItem.mValue = (lFormData[lFormItem.data_field_] === lFormItem.constant_) ? '1' : '0';
                        }
                        else if(lFormItem.control_type_ === 'multi_select_combo_box')
                        {
                            if(lFormItem.cs_form_item_properties_)
                            {
                                for(var lfipInd=0; lfipInd<lFormItem.cs_form_item_properties_.length; lfipInd++)
                                {
                                    var lfipObj = lFormItem.cs_form_item_properties_[lfipInd];
                                    if(lfipObj.type_ === 'data_selection')
                                    {
                                        if(lfipObj.property_ === 'selection_key_')
                                        {
                                            if(lFormData.hasOwnProperty(lfipObj.selection_property_) && (lFormData[lfipObj.selection_property_] instanceof Array) && lfipObj.selection_value_)
                                            {
                                                let lDataCol = lFormData[lfipObj.selection_property_];
                                                let lDataVal = null;
                                                for(let lInd=0; lInd<lDataCol.length; lInd++)
                                                {
                                                    if(lInd === 0)
                                                    {
                                                        lDataVal = "'" + lDataCol[lInd][lfipObj.selection_value_] + "'";
                                                    }
                                                    else
                                                    {
                                                        lDataVal = lDataVal + ", '" + lDataCol[lInd][lfipObj.selection_value_] + "'";
                                                    }
                                                }

                                                lFormItem.pData = lDataVal;
                                                lFormData[lFormItem.data_field_] = lDataVal;
                                            }
                                            else
                                            {
                                                lFormItem.pData = lFormData[lfipObj.selection_property_];
                                            }

                                            lFormItem.pSelectionKey = lfipObj.selection_value_;
                                        }
                                    }
                                }
                            }
                            else
                            {
                                lFormItem.mValue = lFormData[lFormItem.data_field_];
                            }
                            
                            mFormUtil.cfSetDependentFieldData(lFormItem, mIdFormItemMap.current);
                        }
                        else 
                        {
                            if(!lFormItem.hasOwnProperty('is_dependent_item_')) {
                                if(lFormItem.dependent_id_ && mFormUtil)
                                {
                                    if(lFormItem.control_type_ === 'combo_box') {
                                        if(lFormItem.data_provider_source_ === '#COLLECTION#') {
                                            lFormItem.mDataProvider = gProps.pDataCollection;
                                        }

                                        lFormItem.mValue = lFormData[lFormItem.data_field_];
                                    }
                                    else {
                                        if(!lFormItem.mValue) {
                                            lFormItem.mValue = lFormData[lFormItem.data_field_];
                                        }
                                        else {
                                            lFormData[lFormItem.data_field_] = lFormItem.mValue;
                                        }
                                    }
                                }
                                else
                                {
                                    if(lFormItem.hasOwnProperty('mClearSelection') && (lFormItem.mClearSelection === true))
                                    {
                                        lFormItem.mValue = null;
                                    }
                                    else
                                    {
                                        if(lFormItem.control_type_ === 'combo_box') {
                                            if(lFormItem.data_provider_source_ === '#COLLECTION#') {
                                                lFormItem.mDataProvider = gProps.pDataCollection;
                                            }
    
                                            if(lFormItem.mValue) {
                                                lFormData[lFormItem.data_field_] = lFormItem.mValue;
                                            } 
                                            else if(lFormItem.constant_) {
                                                lFormData[lFormItem.data_field_] = cfGetConstantValue(lFormItem.constant_);
                                            }
                                        }

                                        if(!lFormData[lFormItem.data_field_]) {
                                            lFormItem.mValue = lFormItem.mCriteriaValue;
                                            lFormData[lFormItem.data_field_] = lFormItem.mValue;
                                        }
                                        else {
                                            lFormItem.mValue = lFormData[lFormItem.data_field_];
                                        }
                                    }
                                }

                                mFormUtil.cfSetDependentFieldData(lFormItem, mIdFormItemMap.current);
                            }
                            else {
                                lFormData[lFormItem.data_field_] = lFormItem.mValue;
                            }

                            var lCompData = CSFormUtil.cfGetComputedFieldsData(lFormData, mFormItems.current, mIdFormItemMap.current);
                            if(lCompData)
                            {
                                var lKeys = Object.keys(lCompData);
                                for(var lKind=0; lKind<lKeys.length; lKind++)
                                {
                                    var lKey = lKeys[lKind];
                                    if(lFormData.hasOwnProperty(lKey))
                                    {
                                        lFormData[lKey] = lCompData[lKey];
                                    }
                                }
                            }
                        }
                    }
                    else
                    {
                        if(!lFormItem.mCriteriaValue)
                        {
                            lFormItem.mValue = '';
                        }
                        else
                        {
                            lFormItem.mValue = lFormItem.mCriteriaValue;
                        }                        
                    }
                }
            }

            if(lSaveActionItems)
            {
                for(var lInd1=0; lInd1<lSaveActionItems.length; lInd1++)
                {
                    var lSaveActionItem = lSaveActionItems[lInd1];
                    fnEnableSave(lSaveActionItem, lFormItems, lDataFieldObjectMap);
                }    
            }
        }
    );
    
    useEffect(() =>
        {
            fnSetControlValue.current(mFormItems.current, mDataFieldObjectMap, mFormActions.current);
            setDataChange(true);
        }, [mDataChange, mDataFieldObjectMap, mFormData]
    );

    const initializeData = useRef((lFormItemList, lDataFieldObjectMap, lProperties, lFormData, lFormNameDatafieldMap) =>
        {
            var lCriteria;
            var lServiceCode;
            var lRequestObject;
            if(lProperties.pSelectedCriteria)
            {
                lCriteria = lProperties.pSelectedCriteria;
            }

            if(lProperties.pSelectedTabActionItem || lProperties.pSelectedActionItem)
            {
                var lRequestRefId = (lProperties.pSelectedTabActionItem) ? lProperties.pSelectedTabActionItem.on_click_request_ref_id_ : lProperties.pSelectedActionItem.on_click_request_ref_id_;
                if(lRequestRefId)
                {
                    lRequestObject = mServiceUtil.cfGetRequestObject(lRequestRefId);
                }

                lServiceCode = (lProperties.pSelectedTabActionItem) ? lProperties.pSelectedTabActionItem.on_click_service_code_ : lProperties.pSelectedActionItem.on_click_service_code_;
                if(lServiceCode || lRequestObject)
                {
                    var lServiceObject = mServiceUtil.cfGetServiceObject(lServiceCode);
                    if(lServiceObject)
                    {
                        var lRequestId = null;
                        if(lRequestObject && lRequestObject.hasOwnProperty('request_id_'))
                        {
                            lRequestId = lRequestObject.request_id_;
                        }
        
                        if(lCriteria)
                        {
                            var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, lRequestId, lServiceObject);
                            if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                            {
                                var lServiceProperties = lServiceObject.cs_service_properties_;
                                if(lServiceProperties !== null)
                                {
                                    for(var lInd=0; lInd<lServiceProperties.length; lInd++)
                                    {
                                        if(lServiceProperties[lInd].type_ === 'criteria')
                                        {
                                            if(!lCriteria)
                                            {
                                                lCriteria = {}
                                            }

                                            lCriteria[lServiceProperties[lInd].property_] = cfGetConstantValue(lServiceProperties[lInd].value_);
                                        }
                                    }
                                }
                            }

                            setBackdrop(true);
                            lRequestUtil.cfSetRequestCriteria(lCriteria);
                            lRequestUtil.cfProcessRequest((lResponse) =>
                                {
                                    if(lResponse)
                                    {
                                        var lKeys = Object.keys(lResponse);
                                        if(!lFormData)
                                        {
                                            lFormData = {}
                                        }

                                        for (var lKeyInd=0; lKeyInd<lKeys.length; lKeyInd++)
                                        {
                                            var lKey = lKeys[lKeyInd];
                                            if(!lFormData.hasOwnProperty(lKey))
                                            {
                                                lFormData[lKey] = {}
                                            }

                                            var lDataObj = null;
                                            if(Array.isArray(lFormData[lKey]))
                                            {
                                                lDataObj = lResponse[lKey];
                                            }
                                            else
                                            {
                                                if(Array.isArray(lResponse[lKey]))
                                                {
                                                    lDataObj = lResponse[lKey][0];
                                                }
                                                else
                                                {
                                                    lDataObj = lResponse[lKey];
                                                }
                                            }

                                            if((lKey === 'collection_') && lDataObj)
                                            {
                                                if(mDefaultDataField.current === 'section_data_')
                                                {
                                                    if(Array.isArray(lDataObj))
                                                    {
                                                        lFormData[mDefaultDataField.current] = lDataObj[0];
                                                    }
                                                    else
                                                    {
                                                        lFormData[mDefaultDataField.current] = lDataObj;
                                                    }
                                                }
                                                else
                                                {
                                                    lFormData[mDefaultDataField.current] = lDataObj;
                                                }
                                            }
                                            else if((lKey !== 'status') && lDataObj)
                                            {
                                                var ldKeys = Object.keys(lDataObj);
                                                if(ldKeys)
                                                {
                                                    for(var ldInd=0; ldInd<ldKeys.length; ldInd++)
                                                    {
                                                        lFormData[lKey][ldKeys[ldInd]] = lDataObj[ldKeys[ldInd]];
                                                    }
                                                }
                                                else
                                                {
                                                    lFormData[lKey] = lDataObj;
                                                }
                                            }
                                        }

                                        if(mParentChildrenMap.current)
                                        {
                                            var lpKeys = Object.keys(mParentChildrenMap.current);
                                            for(var lpInd=0; lpInd<lpKeys.length; lpInd++)
                                            {
                                                var lpKey = lpKeys[lpInd];
                                                var pFormItem = mIdFormItemMap.current[lpKey];
                                                if(pFormItem && pFormItem.data_field_)
                                                {
                                                    var lpDataObj = (!lFormData[pFormItem.data_field_]) ? {} : lFormData[pFormItem.data_field_];
                                                    var lChildren = mParentChildrenMap.current[lpKey];
                                                    for(var lcInd=0; lcInd<lChildren.length; lcInd++)
                                                    {
                                                        var cFormItem = lChildren[lcInd];
                                                        lDataFieldObjectMap[cFormItem.data_field_] = lpDataObj;
                                                    }
                                                }
                                            }
                                        }
                                        else if(mFormItems)
                                        {
                                            for(var lfiInd=0; lfiInd<mFormItems.current.length; lfiInd++)
                                            {
                                                var lfItem = mFormItems.current[lfiInd];
                                                var lfDataObj = null;
                                                var lDatafieldName = lFormNameDatafieldMap[lfItem.cs_form_code_];
                                                if(lDatafieldName)
                                                {
                                                    lfDataObj = lFormData[lDatafieldName];
                                                }
                                                else
                                                {
                                                    lfDataObj = lFormData[mDefaultDataField.current];
                                                }

                                                lDataFieldObjectMap[lfItem.data_field_] = lfDataObj;
                                            }
                                        }

                                        setFormData(lFormData);
                                        if(mFormActions.current)
                                        {
                                            fnSetControlValue.current(lFormItemList, lDataFieldObjectMap, mFormActions.current);
                                        }
                                        else
                                        {
                                            fnSetControlValue.current(lFormItemList, lDataFieldObjectMap);
                                        }
                                    }
                                    
                                    setDataChange(!mDataChange);
                                    setBackdrop(false);
                                }
                            );
                        }
                        else
                        {
                            if(mFormActions.current)
                            {
                                fnSetControlValue.current(lFormItemList, lDataFieldObjectMap, mFormActions.current);
                            }
                            else
                            {
                                fnSetControlValue.current(lFormItemList, lDataFieldObjectMap);
                            }
                        }
                    }
                }
                else
                {
                    if(mFormActions.current)
                    {
                        fnSetControlValue.current(lFormItemList, lDataFieldObjectMap, mFormActions.current);
                    }
                    else
                    {
                        fnSetControlValue.current(lFormItemList, lDataFieldObjectMap);
                    }
                }
            }
        }
    );

    const fnProcessChange = (lData) =>
    {
        if(lData && mDataFieldObjectMap && lData.hasOwnProperty('data_field_') && lData.data_field_) {
            let lFormItem = mIdFormItemMap.current[lData.id_];
            let lDataObj = mDataFieldObjectMap[lData.data_field_];
            if(lDataObj) {
                if(lData.hasOwnProperty('data_object_')) {
                    let lDataObj1 = lDataObj[lData.data_object_];
                    if(!lDataObj1) {
                        lDataObj1 = {}
                        lDataObj[lData.data_object_] = lDataObj1;
                    }

                    lDataObj1[lData.data_field_] = lData.value_;
                }
                else {
                    if(lFormItem && lFormItem.cs_form_item_properties_) {
                        for(var lfipInd=0; lfipInd<lFormItem.cs_form_item_properties_.length; lfipInd++) {
                            var lfipObj = lFormItem.cs_form_item_properties_[lfipInd];
                            if(lfipObj.type_ === 'data_selection')
                            {
                                if(lfipObj.property_ === 'selection_key_')
                                {
                                    if(lData.value_)
                                    {
                                        let lDataCol = lData.value_;
                                        let lDataVal = null;
                                        for(let lInd=0; lInd<lDataCol.length; lInd++)
                                        {
                                            if(lInd === 0)
                                            {
                                                lDataVal = "'" + lDataCol[lInd][lfipObj.selection_value_] + "'";
                                            }
                                            else
                                            {
                                                lDataVal = lDataVal + ", '" + lDataCol[lInd][lfipObj.selection_value_] + "'";
                                            }
                                        }

                                        lFormItem.pData = lDataVal;
                                        lDataObj[lFormItem.data_field_] = lDataVal;
                                    }
                                    else
                                    {
                                        lFormItem.pData = lDataObj[lfipObj.selection_property_];
                                    }

                                    lFormItem.pSelectionKey = lfipObj.selection_value_;
                                }
                            }
                        }
                    }
                    else if(lData.control_type_ !== 'check_box') {
                        lDataObj[lData.data_field_] = lData.value_;
                    }
                }

                if(lFormItem) {
                    if(lData.hasOwnProperty('selected_data_')) {
                        lFormItem.selected_data_ = lData.selected_data_;
                    }

                    if(lFormItem.control_type_ === 'constant') {
                        lDataObj[lFormItem.data_field_] = cfGetConstantValue(lFormItem.constant_);
                        lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                    }
                    else if(lFormItem.control_type_ === 'check_box') {
                        if(lData.control_type_ === 'check_box') {
                            lDataObj[lData.data_field_] = (lData.value_ === '1') ? lFormItem.constant_ : '';
                            lFormItem.mValue = lData.value_;
                        }        
                    }
                    else if(lData.control_type_ === 'file') {
                        lFormItem.mValue = lData.value_;
                        lFormItem.mFileList = lData.file_list_;
                    }
                    else {
                        lFormItem.mValue = lData.value_;
                    }
                }

                setDataChange(!mDataChange);
            }
        }
    }

    let lPreviewButton = null;
    if(mEnablePreview && gProps.pSelectedActionItem && (gProps.pSelectedActionItem.action_ !== 'process_add'))
    {
        var lCriteria = null;
        if(gProps.pSelectedCriteria)
        {
            lCriteria = gProps.pSelectedCriteria;
        }
        else if(gProps.pFormData && gProps.pFormData.hasOwnProperty('selected_criteria_'))
        {
            lCriteria = gProps.pFormData.selected_criteria_;
        }
        else if(gProps.pSelectedData && gProps.pSelectedData.hasOwnProperty('selected_criteria_'))
        {
            lCriteria = gProps.pSelectedData.selected_criteria_;
        }

        lPreviewButton = 
            <Button
                key = "20"
                onClick = 
                { 
                    (event) => 
                    {
                        let lFormProperties = null;
                        let lFormType = null;
                        if(gProps.pFormProperties)
                        {
                            for(var lfpInd=0; lfpInd<gProps.pFormProperties.length; lfpInd++)
                            {
                                var lfpObj = gProps.pFormProperties[lfpInd];
                                if(lfpObj.cs_form_code_ === mPrintForm)
                                {
                                    if(!lFormProperties)
                                    {
                                        lFormProperties = [];
                                    }
                
                                    if(lfpObj.property_ === 'form_type')
                                    {
                                        lFormType = lfpObj.value_;
                                    }

                                    lFormProperties.push(lfpObj);
                                }
                            }
                        }

                        var lMetaData = 
                        {
                            pFormProperties : lFormProperties,
                            pFormType       : lFormType,
                            fnEnablePrint   : mEnablePrint,
                            pCriteria       : lCriteria
                        }

                        setPreviewForm(fnGetForm(lMetaData));
                    } 
                } 
                icon    = { CSUtil.mNameIconMap['PI_BARS'] } 
                style   = 
                    {
                        {
                            verticalAlign : 'middle',
                           
                            marginRight   : '5px' 
                        }
                    }
            />
    }

    let lForm = null;
    let lDetailsActions = null;
    if(mDetailsActions)
    {
        lDetailsActions = <CSCOHorizontalActionBar align = "right" pType = 'DETAILS_ACTION_BAR' actionList = { mDetailsActions }/>;
    }

    let lDetailsForm = null;
    if(mDetailsFlag)
    {
        var lDetailsData = (mDetailsFlag === 'ADD') ? null : mFormData;
        lDetailsForm = fnGetForm(mDetailsMetaData, lDetailsData, fnCloseForm);
    }

    let lHeaderActions = 
        <div style={{verticalAlign: 'middle'}}>            

            { lPreviewButton }

            <Button
                key = "21"
                style=
                    {
                        {
                            verticalAlign   : 'middle'
                        }
                    } 
                type="Button" 
                icon={CSUtil.mNameIconMap['PI_TIMES']}
                onClick= { () => fnCloseForm(true) }
            />
        </div>

    let lHeader = 
        <div className='flex justify-content-between'>
            <div className="p-toolbar-group-left">
                <h2>{ gProps.pFormHeading || '.' }</h2>
            </div>

            <div className="p-toolbar-group-right">
                { lHeaderActions }
            </div>
        </div>

    if(gProps.pFormType === 'CS_DETAILS_FORM_1')
    {
        if(mCompCodeFormItemsMap)
        {
            var lForm1 = null;
            var lHeaderComponentFlag = false; 
            if(mCompCodeFormItemsMap.hasOwnProperty('CS_DETAILS_FORM_SECTION_1') || mCompCodeFormItemsMap.hasOwnProperty('CS_DETAILS_HEADING_SECTION_1') )
            {
                var lHeaderItems = mCompCodeFormItemsMap[CSConstantUtil.gComponentMap['DETAILS_HEADER']];
                var lSectionItems = mCompCodeFormItemsMap[CSConstantUtil.gComponentMap['DETAILS_SECTION']];
                if((lHeaderItems && (lHeaderItems.length > 0))|| (lSectionItems && (lSectionItems.length > 0)))
                {
                    lHeaderComponentFlag = true;
                    lForm1 = 
                        <div style={{width : mApplicationWidth}}>
                            <CSCOBackdrop pShow = {mShowBackdrop}/>
                            
                            <CSCODetailsHeader
                                pFormItems          = { lHeaderItems }
                                pFormProperties     = { gProps.pFormProperties }
                                pProcessChange      = { fnProcessChange }/>

                            <CSCODetailsSection
                                pFormItems          = { lSectionItems }
                                pProcessChange      = { fnProcessChange }
                                pFormProperties     = { gProps.pFormProperties }
                                pFormItemProperties = { (gProps) ? gProps.pFormItemProperties : null }/>
                        </div>
                }
            }

            if(!lHeaderComponentFlag)
            {
                let lCompCodeFormItemsMap = mCompCodeFormItemsMap[gProps.pFormType];
                if(!lCompCodeFormItemsMap)
                {
                    lCompCodeFormItemsMap = mCompCodeFormItemsMap[CSConstantUtil.gComponentMap['DETAILS_FORM']];
                }

                lForm1 = 
                    <div className="grid flex-nowrap">
                        { mFormUtil.cfGetDetailsSections(lCompCodeFormItemsMap, mMinSectionItems, fnProcessChange) }
                    </div>
            }
            
            lForm = 
                <Dialog visible={mDisplay}
                        modal={true}
                        style=
                        {
                            {
                                minheight : 'auto', 
                                paddingBottom: -40,
                            }
                        }
                        appendTo    = { document.body }
                        maximizable = { gProps.pMaximizable }
                        header      = { lHeader }
                        closable    = { false }
                        padding     = '5px'
                        onHide      = { () => fnCloseForm(true) }
                        footer      = { lDetailsActions }
                    >
                    <CSCOBackdrop pShow = {mShowBackdrop}/>

                    { lDetailsForm }

                    { lForm1 }

                    { mPreviewForm }
                </Dialog>
        }
    }
    else if((gProps.pFormType === 'CS_DETAILS_FORM_7') || (gProps.pFormType === 'CS_DETAILS_FORM_LAYOUT_7'))
    {
        if(mCompCodeFormItemsMap)
        {
            lForm = 
                <div style={{width : mApplicationWidth}}>
                    <CSCOBackdrop pShow = {mShowBackdrop}/>

                    <CSCODetailsHeader
                        pFormItems          = { mCompCodeFormItemsMap[CSConstantUtil.gComponentMap['DETAILS_HEADER']] }
                        pFormProperties     = { gProps.pFormProperties }
                        pProcessChange      = { fnProcessChange }/>

                    <CSCODetailsSection
                        pFormItems          = {  mCompCodeFormItemsMap[CSConstantUtil.gComponentMap['DETAILS_SECTION']] }
                        pProcessChange      = { fnProcessChange }
                        pFormProperties     = { gProps.pFormProperties }
                        pFormItemProperties = { (gProps) ? gProps.pFormItemProperties : null }/>

                    { lDetailsActions }

                    { lDetailsForm }
                </div>
        }
    }

    return lForm;
}

export default CSCODetailsForm;
