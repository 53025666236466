import CSRequestUtil from "./CSRequestUtil";
import CSConfigUtil from "./CSConfigUtil";

class CSUtil
{
    mAccessPrivilegeList         = {};
    mActionPrivilegeList         = {};
    mAppLayoutList               = {};
    mLayoutList                  = null;
    mComponentList               = null;
    mLayoutCodeTypeMap           = {};
    mComponentCodeTypeMap        = {};
    mAppMenuList                 = {};
    mAppIcons                    = {};
    mDataProviderMap             = {};
    mUtil                        = null;
    mFeatureRefIdObjectMap       = {};
    mMenuIdObjectMap             = {};
    mFeatureCodeObjectMap          = {};
    mMenuIdChildrenMap           = {};
    mFloatingMenuMap             = null;
    mRootId                      = null;
    mServiceList                 = null;
    mServiceCodeObjectMap        = {};
    mMenu                        = {};
    mApplicationHeight           = null;
    mApplicationWidth            = null;

    static mCSLogo               = require('../assets/images/cs_log_latest_1.png');
    static mEmployee             = require('../assets/images/employee_1_256.png');
    static mLayoutMapIdIconMap   = {};
    static mNameIconMap          = {};
    static mNameImageMap         = {};
    static mDashboard            = [];
    static mAcademicYearList     = null;
    static mSelectedAYObj        = null;
    static mInstituteList        = null;
    static mSelectedInstituteObj = null;
    static mFinancialYearList    = null;
    static mSelectedFYObj        = null;
    static mCompanyList          = null;
    static mSelectedCompanyObj   = null;

    static cfGetInstance()
    {
        if(this.mUtil === undefined)
        {
            this.mUtil = new CSUtil();
        }

        return this.mUtil;
    }

    static cfGetObjectCopy(lObject)
    {
        var lRetVal = {};
        if ((typeof lObject === "object") && (lObject !== null))
        {
            lRetVal = JSON.parse(JSON.stringify(lObject));
        }

        return lRetVal;
    }

    static cfClone(obj) 
    {
        if (null == obj || "object" != typeof obj) 
        {
            return obj;
        }

        var copy = obj.constructor();
        for (var attr in obj) 
        {
            if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
        }
        
        return copy;
    }

    static cfSort(lCollection, lField1, lField2)
    {
        var lRetVal = null;
        if(lCollection && lField1)
        {
            if(lField2)
            {
                lRetVal = lCollection.sort(function(a, b){return (a[lField1] -b[lField1] || a[lField2] -b[lField2] )})
            }
            else
            {
                lRetVal = lCollection.sort(function(a, b){return (a[lField1] -b[lField1])})
            }
        }

        return lRetVal;
    }

    static cfSortInteger(lCollection, lField)
    {
        var lRetVal = null;
        if(lCollection && lField)
        {
            lRetVal = lCollection.sort(function(a, b)
                {
                    return (a[parseInt(a[lField], 10)] -b[parseInt(b[lField], 10)])
                }
            )
        }

        return lRetVal;
    }

    static cfSerialize(lObj)
    {
        var lKeyOrderArr = ["name", "role", "email", "lang", "branch_code_", "class_code_", "section_", "app_code_", "user_id", "session_id"]; 
//        var lKeyOrderArr = ["name", "mn", "email", "pwd", "role", "lang", "signature"]; 
        Array.intersect = function () 
        {
            var lResult = [];
            var lObj0 = {};
            for (var lArgIndex = 0; lArgIndex < arguments.length; lArgIndex++) 
            {
                for (var lArgIndex1 = 0; lArgIndex1 < arguments[lArgIndex].length; lArgIndex1++) 
                {
                    var lStr = arguments[lArgIndex][lArgIndex1];
                    if (!lObj0[lStr]) 
                    {
                        lObj0[lStr] = 1;
                    } 
                    else 
                    {
                        lObj0[lStr]++;
                        if (lObj0[lStr] === arguments.length) 
                        {
                            lResult.push(lStr);
                        }
                    }
                }
            }
            
            return lResult;
        };

        var lTmpInterArr = Array.intersect(lKeyOrderArr, Object.keys(lObj));
        var lSortedObj0 = [];
        lKeyOrderArr.forEach(function (lKey0) 
            {
                if (lTmpInterArr.includes(lKey0)) 
                {
                    lSortedObj0.push([lKey0, lObj[lKey0]]);
                }
            }
        );
        
        Object.keys(lObj).sort().forEach(function (lKey1) {
                if (!lTmpInterArr.includes(lKey1)) 
                {
                    lSortedObj0.push([lKey1, lObj[lKey1]]);
                }
            }
        );
        
        var lRetVal = (function (lSortedObj1) 
            {
                var str = [];
                for (var p in lSortedObj1) 
                {
                    if (typeof lSortedObj1[p][1] !== "undefined")
                    {
                        str.push(encodeURIComponent(lSortedObj1[p][0]) + "=" + encodeURIComponent(lSortedObj1[p][1]));
                    }
                }
                    return str.join("&");
            }
        ) (lSortedObj0);
        
        return lRetVal;
    }

    static cfParseQuery () 
    {
        return (function () 
            {
                var lHref = window.location.href;
                var lQueryString = lHref.substr(lHref.indexOf("?"));
                var lQuery = {};
                var lPairs = (lQueryString[0] === "?" ? lQueryString.substr(1) : lQueryString).split("&");
                
                for (var i = 0; i < lPairs.length; i += 1) 
                {
                    var lPair = lPairs[i].split("=");
                    lQuery[decodeURIComponent(lPair[0])] = decodeURIComponent(lPair[1] || "");
                }
                
                return lQuery;
            }
        )();
    };

    cfReset()
    {
        this.mAccessPrivilegeList   = {};
        this.mActionPrivilegeList   = {};
        this.mAppLayoutList         = {};
        this.mAppMenuList           = {};
        this.mAppIcons              = {};
        this.mDataProviderMap       = {};
        this.mUtil                  = null;
        this.mMenuIdObjectMap       = {};
        this.mFeatureCodeObjectMap    = {};
        this.mMenuIdChildrenMap     = {};
        this.mRootId                = null;
        this.mServiceList           = null;
        this.mServiceCodeObjectMap  = {};
        this.mMenu                  = {};
        CSUtil.mLayoutMapIdIconMap  = {};
        CSUtil.mNameIconMap         = {};
        CSUtil.mNameImageMap        = {};
        CSUtil.mDashboard           = [];
        this.mApplicationHeight   = null;
        this.mApplicationWidth    = null;
    }

    cfSetActionPrivilegeList(lActionPrivList)
    {
        if(lActionPrivList)
        {
            var lList = Object.values(lActionPrivList);
            for(var ind=0; ind<lList.length; ind++)
            {
                var lActionObject = lList[ind];
                if(lActionObject.app_id_)
                {
                    var app_act_privList = this.mActionPrivilegeList[lActionObject.app_id_];
                    if(!app_act_privList)
                    {
                        app_act_privList = [];
                        this.mActionPrivilegeList[lActionObject.app_id_] = app_act_privList;
                    }

                    app_act_privList.push(lActionObject);
                }
            }
        }
    }

    cfSetAccessPrivilegeList(lAccessPrivList)
    {
        this.mAccessPrivilegeList = lAccessPrivList;
    }

    cfIsAccessible(lAppId)
    {
        var lRetVal = false;
        if(lAppId)
        {
            if(this && this.mAccessPrivilegeList && (this.mAccessPrivilegeList instanceof Array) && this.mAccessPrivilegeList.includes(lAppId))
            {
                lRetVal = true;
            }
        }

        return lRetVal;
    }

    cfSetAppIcons(lMenuIcons)
    {
        if(lMenuIcons)
        {
            for(var ind=0; ind<lMenuIcons.length; ind++)
            {
                CSUtil.mNameIconMap[lMenuIcons[ind].cs_picture_code_] = lMenuIcons[ind].cs_picture_name_;
                if(lMenuIcons[ind].hasOwnProperty('cs_picture_image_') && lMenuIcons[ind].cs_picture_image_)
                {
                    CSUtil.mNameImageMap[lMenuIcons[ind].cs_picture_code_] = lMenuIcons[ind].cs_picture_image_;
                }

                if(lMenuIcons[ind].cs_layout_map_ref_id_)
                {
                    CSUtil.mLayoutMapIdIconMap[lMenuIcons[ind].cs_layout_map_ref_id_] = lMenuIcons[ind].cs_picture_name_;
                }
            }
        }
    }

    cfSetLayoutMap()
    {
        if(this.mLayoutList)
        {
            for(var ind=0; ind<this.mLayoutList.length; ind++)
            {
                this.mLayoutCodeTypeMap[this.mLayoutList[ind].code_] = this.mLayoutList[ind].type_;
            }
        }
    }

    cfSetComponentMap()
    {
        if(this.mComponentList)
        {
            for(var ind=0; ind<this.mComponentList.length; ind++)
            {
                this.mComponentCodeTypeMap[this.mComponentList[ind].code_] = this.mComponentList[ind].type_;
            }
        }
    }

    cfSetMenuCommand(fnClick)
    {
        if(this.mMenuIdObjectMap)
        {    
            let lKeys = Object.keys(this.mMenuIdObjectMap);

            for (let lInd = 0; lInd < lKeys.length; lInd++) {
                let lMenuObj = this.mMenuIdObjectMap[lKeys[lInd]];
                lMenuObj.command = fnClick;
                lMenuObj.icon = CSUtil.mLayoutMapIdIconMap[lMenuObj.layout_map_ref_id_];
                if(!lMenuObj.icon)
                {
                    lMenuObj.icon = <i className='pi pi-fw pi-bookmark' />
                }
                else
                {
                    lMenuObj.icon = <i className={CSUtil.mLayoutMapIdIconMap[lMenuObj.layout_map_ref_id_]} />
                }
            }
        }
    }

    cfSetMenuItems(lMenuItems)
    {
        this.mAppLayoutList = lMenuItems;
        CSUtil.cfSort(lMenuItems, 'parent_ref_id_', 'layout_item_sequence_');
        for(var ind=0; ind<lMenuItems.length; ind++)
        {
            lMenuItems[ind].label = lMenuItems[ind].layout_item_name_;
            if(!lMenuItems[ind].parent_ref_id_)
            {
                this.mRootId = lMenuItems[ind].layout_map_ref_id_;
            }

            this.mFeatureRefIdObjectMap[lMenuItems[ind].layout_map_ref_id_] = lMenuItems[ind];
            this.mFeatureCodeObjectMap[lMenuItems[ind].layout_item_code_] = lMenuItems[ind];
            
            if(lMenuItems[ind].include_in_menu_ === '1')
            {
                this.mMenuIdObjectMap[lMenuItems[ind].layout_map_ref_id_] = lMenuItems[ind];

                var lChildren = null;
                var lParentObject = this.mMenuIdObjectMap[lMenuItems[ind].parent_ref_id_];
                if(lParentObject)
                {
                    if(!lParentObject.items)
                    {
                        lParentObject.open = false;
                        lChildren = this.mMenuIdChildrenMap[lMenuItems[ind].parent_ref_id_];
                        if(lChildren)
                        {
                            lParentObject.items = lChildren;
                        }
                        else
                        {
                            lParentObject.items = [];    
                            lChildren = lParentObject.items;
                        }
                    }
                    else
                    {
                        lChildren = lParentObject.items;
                    }
                }
                else
                {
                    lChildren = this.mMenuIdChildrenMap[lMenuItems[ind].parent_ref_id_];
                    if(!lChildren)
                    {
                        lChildren = [];
                        this.mMenuIdChildrenMap[lMenuItems[ind].parent_ref_id_] = lChildren;
                    }
                }

                if(lChildren)
                {
                    lChildren.push(lMenuItems[ind]);
                }

                var lMenuObj = lMenuItems[ind];
                if(!lMenuObj.items)
                {
                    if(this.mMenuIdChildrenMap[lMenuObj.layout_map_ref_id_])
                    {
                        lMenuObj.items = this.mMenuIdChildrenMap[lMenuObj.layout_map_ref_id_];
                    }
                }
            }
        }
    }

    cfGetMenuItem(lId)
    {
        var lRetVal = null;
        if((lId) && (this.mFeatureRefIdObjectMap))
        {
            lRetVal = this.mFeatureRefIdObjectMap[lId];
        }

        return lRetVal;
    }

    cfGetFeatureRefId(lFeatureCode) {
        let lFeatureRefId = null;
        if(lFeatureCode)
        {
            let lObj = this.mFeatureCodeObjectMap[lFeatureCode];
            if(lObj) {
                lFeatureRefId = lObj.layout_item_ref_id_;
            }
        }

        return lFeatureRefId;
    }

    cfGetMenuItems()
    {
        var lMenuItems = null;
        if((this.mRootId) && (this.mMenuIdChildrenMap))
        {
            lMenuItems = this.mMenuIdChildrenMap[this.mRootId];
        }

        this.cfSetLevel(lMenuItems, 1);

        return lMenuItems;
    }

    cfSetLevel(lCollection, lInd)
    {
        if(lCollection != null)
        {
            for(var ind=0; ind<lCollection.length; ind++)
            {
                lCollection[ind].level_ = lInd;
                if(lCollection[ind].items != null)
                {
                    this.cfSetLevel(lCollection[ind].items, lInd + 1)
                }
            }
        }
    }
    
    cfGetLayoutType(lId)
    {
        var lLayoutType = null;

        if(lId !== "")
        {
            var lItem = this.mFeatureRefIdObjectMap[lId];
            if(lItem)
            {
                lLayoutType = this.mLayoutCodeTypeMap[lItem.layout_code_];
            }
        }

        return lLayoutType;
    }
    
    cfGetLayoutTypeFromCode(lLayoutCode)
    {
        var lLayoutType = null;

        if(lLayoutCode)
        {
            lLayoutType = this.mLayoutCodeTypeMap[lLayoutCode];
        }

        return lLayoutType;
    }
    
    cfGetLayoutCode(lId)
    {
        var lLayoutCode = null;

        if(lId !== "")
        {
            var lItem = this.mFeatureRefIdObjectMap[lId];
            if(lItem != null)
            {
                lLayoutCode = lItem.layout_code_;
            }
        }

        return lLayoutCode;
    }
    
    cfGetComponentType(lCode)
    {
        var lType = null;

        if(lCode && this.mComponentCodeTypeMap)
        {
            lType = this.mComponentCodeTypeMap[lCode];
        }

        return lType;
    }

    cfInitializeReceiptConfig()
    {
        if(CSConfigUtil.mReceiptHeadingList)
        {
            for(var lrInd=0; lrInd<CSConfigUtil.mReceiptHeadingList.length; lrInd++)
            {
                var lrObj = CSConfigUtil.mReceiptHeadingList[lrInd];
                if(lrObj.branch_code_)
                {
                    CSConfigUtil.mReceiptHeading[lrObj.branch_code_] = lrObj.value_;
                }
                else
                {
                    if(lrObj.name_ === "HEADING")
                    {
                        CSConfigUtil.mReceiptHeading = lrObj.value_;
                    }
                }
            }
        }

        if(CSConfigUtil.mReceiptSubHeading1List)
        {
            for(var lrs1Ind=0; lrs1Ind<CSConfigUtil.mReceiptSubHeading1List.length; lrs1Ind++)
            {
                var lrs1Obj = CSConfigUtil.mReceiptSubHeading1List[lrs1Ind];
                if(lrs1Obj.branch_code_)
                {
                    CSConfigUtil.mReceiptSubHeading1[lrs1Obj.branch_code_] = lrs1Obj.value_;
                }
/*                else
                {
                    if(lrs1Obj.name_ === "SUB_HEADING_1")
                    {
                        CSConfigUtil.mReceiptSubHeading1 = lrs1Obj.value_;
                    }
                }
*/
            }
        }

        if(CSConfigUtil.mReceiptSubHeading2List)
        {
            for(var lrs2Ind=0; lrs2Ind<CSConfigUtil.mReceiptSubHeading2List.length; lrs2Ind++)
            {
                var lrs2Obj = CSConfigUtil.mReceiptSubHeading2List[lrs2Ind];
                if(lrs2Obj.branch_code_)
                {
                    CSConfigUtil.mReceiptSubHeading2[lrs2Obj.branch_code_] = lrs2Obj.value_;
                }
/*                else
                {
                    if(lrs2Obj.name_ === "SUB_HEADING_2")
                    {
                        CSConfigUtil.mReceiptSubHeading2 = lrs2Obj.value_;
                    }
                }
*/
            }
        }
    }

    cfInitializeReportConfig()
    {
        if(CSConfigUtil.mReportHeadingList)
        {
            for(var lrInd=0; lrInd<CSConfigUtil.mReportHeadingList.length; lrInd++)
            {
                var lrObj = CSConfigUtil.mReportHeadingList[lrInd];
                if(lrObj.branch_code_)
                {
                    CSConfigUtil.mReportHeading[lrObj.branch_code_] = lrObj.value_;
                }
            }
        }

        if(CSConfigUtil.mReportSubHeading1List)
        {
            for(var lrs1Ind=0; lrs1Ind<CSConfigUtil.mReportSubHeading1List.length; lrs1Ind++)
            {
                var lrs1Obj = CSConfigUtil.mReportSubHeading1List[lrs1Ind];
                if(lrs1Obj.branch_code_)
                {
                    CSConfigUtil.mReportSubHeading1[lrs1Obj.branch_code_] = lrs1Obj.value_;
                }
            }
        }

        if(CSConfigUtil.mReportSubHeading2List)
        {
            for(var lrs2Ind=0; lrs2Ind<CSConfigUtil.mReportSubHeading2List.length; lrs2Ind++)
            {
                var lrs2Obj = CSConfigUtil.mReportSubHeading2List[lrs2Ind];
                if(lrs2Obj.branch_code_)
                {
                    CSConfigUtil.mReportSubHeading2[lrs2Obj.branch_code_] = lrs2Obj.value_;
                }
            }
        }
    }

    cfInitializeApplication()
    {
        if(CSConfigUtil.mUserType === 'STUDENT')
        {
            /**
             * Get Logged In Student Details and initialization details
             */
            var lRequestUtil        = new CSRequestUtil('GET', 'REQ_INIT_STUDENT', 'initialize', 'REQ_INIT_STUDENT');
            var lCriteriaObj        = {};
            lCriteriaObj.user_id_   = CSConfigUtil.mUserId;

            lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
            lRequestUtil.cfProcessRequest((lResponse) =>
                {
                    if(lResponse)
                    {
                        if(lResponse.hasOwnProperty('result') && lResponse.result)
                        {
                            var lCode;
                            var lResult = lResponse.result;
                            if(lResult instanceof Array)
                            {
                                lCode = lResult[0].code;
                            }
                            else
                            {
                                lCode = lResult.code;
                            }
            
                            if(lCode && (lCode === '1'))
                            {
                                if(lResponse.hasOwnProperty('collection_') && lResponse.collection_ && (lResponse.collection_.length > 0))
                                {
                                    var lStudentData = lResponse.collection_[0];
                                    CSConfigUtil.mAdmissionNo     = lStudentData.admission_no_;
                                    CSConfigUtil.mAYCode          = lStudentData.ay_code_;
                                    CSConfigUtil.mCurrentAYCode   = lStudentData.ay_code_;
                                    CSConfigUtil.mCurrentAYName   = lStudentData.ay_name_;
                                    CSConfigUtil.mClassCode       = lStudentData.class_code_;
                                    CSConfigUtil.mSectionCode     = lStudentData.section_code_;
                                    CSConfigUtil.mStudentName     = lStudentData.student_name_;
                                    if(lStudentData.hasOwnProperty('branch_code_'))
                                    {
                                        CSConfigUtil.mBranchCode      = lStudentData.branch_code_;
                                        CSConfigUtil.mBranchName      = lStudentData.branch_name_;
                                    }
                                }
                            }
                        }
                    }
                }
            ); 
        }
        else
        {
            /**
             * Get Logged In Employee Details and initialization details
             */
            var lRequestUtil0        = new CSRequestUtil('GET', 'REQ_INIT_EMPLOYEE', 'initialize', 'REQ_INIT_EMPLOYEE');
            var lCriteriaObj0        = {};
            lCriteriaObj0.user_id_   = CSConfigUtil.mUserId;

            lRequestUtil0.cfSetRequestCriteria(lCriteriaObj0);
            lRequestUtil0.cfProcessRequest((lResponse) =>
                {
                    if(lResponse)
                    {
                        if(lResponse.hasOwnProperty('result') && lResponse.result)
                        {
                            var lCode;
                            var lResult = lResponse.result;
                            if(lResult instanceof Array)
                            {
                                lCode = lResult[0].code;
                            }
                            else
                            {
                                lCode = lResult.code;
                            }
            
                            if(lCode && (lCode === '1'))
                            {
                                if(lResponse.hasOwnProperty('collection_') && lResponse.collection_ && (lResponse.collection_.length > 0))
                                {
                                    var lEmployeeData = lResponse.collection_[0];
                                    CSConfigUtil.mEmployeeId      = lEmployeeData.employee_id_;
                                    CSConfigUtil.mDeptCode        = lEmployeeData.dept_code_;
                                    CSConfigUtil.mDeptName        = lEmployeeData.dept_name_;
                                    CSConfigUtil.mEmployeeName    = lEmployeeData.employee_name_;
                                    CSConfigUtil.mEmployeePhoto   = lEmployeeData.picture_;
                                    CSConfigUtil.mManagerId       = lEmployeeData.manager_id_;
                                    CSConfigUtil.mManagerName     = lEmployeeData.manager_name_;
            
                                    if(lEmployeeData.hasOwnProperty('office_code_'))
                                    {
                                        CSConfigUtil.mOfficeCode      = lEmployeeData.office_code_;
                                        CSConfigUtil.mOfficeName      = lEmployeeData.office_name_;
                                        CSConfigUtil.mBranchCode      = lEmployeeData.office_code_;
                                        CSConfigUtil.mBranchName      = lEmployeeData.office_name_;
                                    }
            
                                    if(lEmployeeData.hasOwnProperty('branch_code_'))
                                    {
                                        CSConfigUtil.mBranchCode      = lEmployeeData.branch_code_;
                                        CSConfigUtil.mBranchName      = lEmployeeData.branch_name_;
                                    }
            
                                    if(lEmployeeData.hasOwnProperty('company_code_'))
                                    {
                                        CSConfigUtil.mBranchCode      = lEmployeeData.company_code_;
                                        CSConfigUtil.mBranchName      = lEmployeeData.company_name_;
                                        CSConfigUtil.mOfficeCode      = lEmployeeData.company_code_;
                                        CSConfigUtil.mOfficeName      = lEmployeeData.company_name_;
                                        CSConfigUtil.mCompanyCode     = lEmployeeData.company_code_;
                                        CSConfigUtil.mCompanyName     = lEmployeeData.company_name_;
                                    }
            
                                    CSConfigUtil.mEmployee        = lEmployeeData;
                
                                    var lRequestUtil        = new CSRequestUtil('SAVE', 'REQ_INIT_ACCOUNT_DETAILS', 'initialize', 'REQ_INIT_ACCOUNT_DETAILS');
                                    var lCriteriaObj        = {};
                                    lCriteriaObj.user_id_   = CSConfigUtil.mUserId;
                                    if(lEmployeeData.hasOwnProperty('office_code_'))
                                    {
                                        lCriteriaObj.office_code_   = lEmployeeData.office_code_;
                                        lCriteriaObj.branch_code_   = lEmployeeData.office_code_;
                                    }
                                    else if(lEmployeeData.hasOwnProperty('company_code_'))
                                    {
                                        lCriteriaObj.office_code_   = lEmployeeData.company_code_;
                                        lCriteriaObj.branch_code_   = lEmployeeData.company_code_;
                                        lCriteriaObj.company_code_  = lEmployeeData.company_code_;
                                    }
                                    else if(lEmployeeData.hasOwnProperty('branch_code_'))
                                    {
                                        lCriteriaObj.branch_code_   = lEmployeeData.branch_code_;
                                    }
            
                                    lRequestUtil.cfSetRequestData(lCriteriaObj);
                                    lRequestUtil.cfProcessRequest(()=>{})                        
                                }

                                if(lResponse && lResponse.hasOwnProperty("company_list_"))
                                {
                                    CSUtil.mCompanyList = lResponse.company_list_;
                                }

                                if(lResponse && lResponse.hasOwnProperty("financial_year_list_"))
                                {
                                    CSUtil.mFinancialYearList = lResponse.financial_year_list_;
                                    if(lResponse.financial_year_list_.length > 0)
                                    {
                                        for(let lFyInd=0; lFyInd<CSUtil.mFinancialYearList.length; lFyInd++)
                                        {
                                            let lFyObj = CSUtil.mFinancialYearList[lFyInd];
                                            if(lFyObj.is_active_ === '1')
                                            {
                                                CSConfigUtil.mActiveFinancialYearObj = lFyObj;
                                                CSConfigUtil.mFYCode                 = lFyObj;
                                                CSConfigUtil.mCurrentFYCode          = lFyObj.financial_year_code_;
                                                CSConfigUtil.mCurrentFYName          = lFyObj.financial_year_name_;
                                                break;
                                            }
                                        }

                                        if(lEmployeeData.company_code_ && CSUtil.mCompanyList)
                                        {
                                            for(let lbInd=0; lbInd<CSUtil.mCompanyList.length; lbInd++)
                                            {
                                                let lObj = CSUtil.mCompanyList[lbInd];
                                                if(lObj.company_code_ === CSConfigUtil.mCompanyCode)
                                                {
                                                    CSConfigUtil.mCompanyObj = lObj;
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                }
                                                        
                                if(lResponse.hasOwnProperty('general_configuration_') && lResponse.general_configuration_ && (lResponse.general_configuration_.length > 0))
                                {
                                    for(var lInd=0; lInd<lResponse.general_configuration_.length; lInd++)
                                    {
                                        var lConfigData = lResponse.general_configuration_[lInd];
                                        if(lConfigData.config_name_ === 'CURRENT_FY_CODE')
                                        {
                                            CSConfigUtil.mCurrentFYCode = lConfigData.config_value_;
                                        }
                                        else if(lConfigData.config_name_ === 'PETTYCASH_ACCOUNT')
                                        {
                                            CSConfigUtil.mPettycashAccount = lConfigData.config_value_;
                                        }
                                    }
                                }

                                if(lResponse && lResponse.hasOwnProperty("receipt_config_"))
                                {
                                    if(lResponse.receipt_config_.length > 0)
                                    {
                                        var lReceiptConfigList = lResponse.receipt_config_;
                                        for(var lrcInd=0; lrcInd<lReceiptConfigList.length; lrcInd++)
                                        {
                                            var lRcObj = lReceiptConfigList[lrcInd];
                                            if((lRcObj.type_ === 'RECEIPT') || (lRcObj.type_ === 'RECEIPT1') || (lRcObj.type_ === 'RECEIPT2'))
                                            {
                                                if(lRcObj.name_ === 'HEADING')
                                                {
                                                    if(!CSConfigUtil.mReceiptHeadingList)
                                                    {
                                                        CSConfigUtil.mReceiptHeadingList = []
                                                    }
            
                                                    CSConfigUtil.mReceiptHeadingList.push(lRcObj);
                                                }
                                                else if(lRcObj.name_ === 'SUB_HEADING_1')
                                                {
                                                    if(!CSConfigUtil.mReceiptSubHeading1List)
                                                    {
                                                        CSConfigUtil.mReceiptSubHeading1List = []
                                                    }
            
                                                    CSConfigUtil.mReceiptSubHeading1List.push(lRcObj);
                                                }
                                                else if(lRcObj.name_ === 'SUB_HEADING_2')
                                                {
                                                    if(!CSConfigUtil.mReceiptSubHeading2List)
                                                    {
                                                        CSConfigUtil.mReceiptSubHeading2List = []
                                                    }
            
                                                    CSConfigUtil.mReceiptSubHeading2List.push(lRcObj);
                                                }
                                            }
                                            else if(lRcObj.type_ === 'REPORT')
                                            {
                                                if(lRcObj.name_ === 'HEADING')
                                                {
                                                    if(!CSConfigUtil.mReportHeadingList)
                                                    {
                                                        CSConfigUtil.mReportHeadingList = []
                                                    }
            
                                                    CSConfigUtil.mReportHeadingList.push(lRcObj);
                                                }
                                                else if(lRcObj.name_ === 'SUB_HEADING_1')
                                                {
                                                    if(!CSConfigUtil.mReportSubHeading1List)
                                                    {
                                                        CSConfigUtil.mReportSubHeading1List = []
                                                    }
            
                                                    CSConfigUtil.mReportSubHeading1List.push(lRcObj);
                                                }
                                                else if(lRcObj.name_ === 'SUB_HEADING_2')
                                                {
                                                    if(!CSConfigUtil.mReportSubHeading2List)
                                                    {
                                                        CSConfigUtil.mReportSubHeading2List = []
                                                    }
            
                                                    CSConfigUtil.mReportSubHeading2List.push(lRcObj);
                                                }
                                            }
                                        }
            
                                        this.cfInitializeReceiptConfig();
                                        this.cfInitializeReportConfig();
                                    }
                                }

                                if(lResponse && lResponse.hasOwnProperty("system_config_"))
                                {
                                    var lFloatingMenuMap = {}
                                    if(lResponse.system_config_.length > 0)
                                    {
                                        var lSystemConfigList = lResponse.system_config_;
                                        for(var lscInd=0; lscInd<lSystemConfigList.length; lscInd++)
                                        {
                                            var lSysConfigObj = lSystemConfigList[lscInd];
                                            if((lSysConfigObj.config_group_ === 'FLOATING_MENU_ITEM') && (lSysConfigObj.config_name_ === 'FEATURE_REF_ID'))
                                            {
                                                lFloatingMenuMap[lSysConfigObj.config_code_] = lSysConfigObj.config_default_value_;
                                            }
                                            else if((lSysConfigObj.config_group_ === 'GENERAL') && (lSysConfigObj.config_name_ === 'DATA_FOLDER'))
                                            {
                                                CSConfigUtil.mDataFolder = lSysConfigObj.config_code_;
                                            }
                                            else if(lSysConfigObj.config_group_ === 'DATA_FOLDER')
                                            {
                                                CSConfigUtil.mDataFolderMap[lSysConfigObj.config_code_] = lSysConfigObj.config_name_;
                                            }
                                        }
                                    }
            
                                    this.mFloatingMenuMap = lFloatingMenuMap;
                                }
                            }
                        }
            
                        var lUtil = CSUtil.cfGetInstance();
            
                        var lDataProviderNames = Object.keys(lResponse);
                        var lDataProviderValues = Object.values(lResponse);
                        for(var ind=0; ind<lDataProviderNames.length; ind++)
                        {
                            if(lDataProviderNames[ind] !== "__request__")
                            {
                                lUtil.mDataProviderMap[lDataProviderNames[ind]] = lDataProviderValues[ind];
                            }
                        };
                    }
                }
            )
        }
    }   
}

export default CSUtil;