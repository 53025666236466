import React, { Component  } from "react"
import CSFormUtil from "../util/CSFormUtil"
import CSUtil from "../util/CSUtil";
import { cfGetConstantValue} from "../util/CSConstantUtil";

class CSCODetailsSection1 extends Component 
{
    mComponentCode      = 'CS_DETAILS_FORM_SECTION_1';
    mMaxSections        = 3;
    mMinSectionItems 	= 7;
    mFormUtil           = CSFormUtil.cfGetInstance();
    mPrevProps          = null;

    constructor(props)
    {
        super(props);
        this.state = 
        {
            mFormCode                   : null,
            mFormItems                  : null,
            mFormData                   : {},
            mWidth                      : this.props.pWidth,
            mHeight                     : this.props.pHeight,
            mFormProperties             : this.props.pFormProperties,
            mFormItemProperties         : this.props.pFormItemProperties,
            mParentFormItems            : [],
            mIdFieldObjectMap           : {},
            mDataFieldObjectMap         : {},
            mParentFormItemChildrenMap  : {},
            mIdParentObjectMap          : {},
            mSaveActionItem             : this.props.pSaveActionItem,
            mDefaultDataField           : 'selected_data_',
            mSelectedData               : this.props.pSelectedData
        }

        this.cfProcessChange            = this.cfProcessChange.bind(this);
        this.cfSetControlValue          = this.cfSetControlValue.bind(this);
        this.cfEnableSaveAction         = this.cfEnableSaveAction.bind(this);
        this.cfSetFormData              = this.cfSetFormData.bind(this);
        this.cfOnPopout                 = this.cfOnPopout.bind(this);

        if(!this.props.pFormData)
        {
            this.state.mFormData = {};
        }
        else
        {
            this.state.mFormData = CSUtil.cfGetObjectCopy(this.props.pFormData);
        }

        if(this.props.pSelectedData && (this.props.pSelectedData !== this.props.pFormData))
        {
            this.state.mFormData['selected_data_'] = this.props.pSelectedData;
        }

        if(this.props.pFormItems)
        {
            var lFormItemList = [];
            CSUtil.cfSort(this.props.pFormItems, 'parent_id_', 'sequence_')
            for(var lInd=0; lInd<this.props.pFormItems.length; lInd++)
            {
                var lFormItem = this.props.pFormItems[lInd];
                if(lFormItem.cs_form_component_code_ === this.mComponentCode)
                {
                    this.state.mIdFieldObjectMap[lFormItem.id_] = lFormItem;
                    this.state.mDataFieldObjectMap[lFormItem.data_field_] = lFormItem;
                    lFormItemList.push(lFormItem);
                    if(lFormItem.parent_id_)
                    {
                        var lChildList = this.state.mParentFormItemChildrenMap[lFormItem.parent_id_];
                        if(!lChildList)
                        {
                            lChildList = [];
                            this.state.mParentFormItemChildrenMap[lFormItem.parent_id_] = lChildList;
                        }

                        lChildList.push(lFormItem);
                    }
                    else
                    {
                        this.state.mParentFormItems.push(lFormItem);
                    }

                    if(!this.state.mFormCode)
                    {
                        this.state.mFormCode = lFormItem.cs_form_code_;
                    }
                }
            }

            this.state.mFormItems = lFormItemList;
        }

        if(this.props.pFormProperties)
        {
            for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
            {
                var lFormProperty = this.props.pFormProperties[lInd_0];
                if(lFormProperty.type_ === 'layout')
                {
                    if (lFormProperty.property_ === 'max_sections_')
                    {
                        this.mMaxSections = lFormProperty.value_;
                    }

                    if(lFormProperty.property_ === 'min_section_items_')
                    {
                        this.mMinSectionItems = lFormProperty.value_;
                    }
                }
                else if((lFormProperty.type_ === 'initialize') && ((lFormProperty.property_ === 'data_') || (lFormProperty.property_ === 'collection_data_')))
                {
                    if(this.props.pFormData)
                    {
                        var lDataObj = null;
                        var lData = null;
                        if(lFormProperty.property_ === 'data_')
                        {
                            if(!this.state.mFormData)
                            {
                                this.state.mFormData = {};
                            }
        
                            if(this.props.pFormData.hasOwnProperty(lFormProperty.value_))
                            {
                                lData = this.props.pFormData[lFormProperty.value_];
                                if(lData)
                                {
                                    if(lData instanceof Array && (lData.length > 0))
                                    {
                                        lDataObj = lData[0];
                                    }
                                    else
                                    {
                                        lDataObj = lData;
                                    }

                                    if(lDataObj)
                                    {
                                        this.state.mFormData[lFormProperty.value_] = lDataObj;
                                    }
                                    else
                                    {
                                        this.state.mFormData[lFormProperty.value_] = {};
                                    }
                                }
                                else
                                {
                                    this.state.mFormData[lFormProperty.value_] = {};
                                }
                            }
                            else
                            {
                                this.state.mFormData[lFormProperty.value_] = {};
                            }
                        }
                        else if(lFormProperty.property_ === 'collection_data_')
                        {
                            if(this.props.pFormData.hasOwnProperty(lFormProperty.value_))
                            {
                                lData = this.props.pFormData[lFormProperty.value_];
                                if(lData instanceof Array)
                                {
                                    this.state.mFormData[lFormProperty.value_] = lData;
                                }
                                else
                                {
                                    this.state.mFormData[lFormProperty.value_] = [];
                                    this.state.mFormData[lFormProperty.value_].push(lData)    
                                }
                            }
                            else
                            {
                                this.state.mFormData[lFormProperty.value_] = [];
                            }
                        }
                    }
                    else
                    {
                        if(lFormProperty.property_ === 'data_')
                        {
                            this.state.mFormData[lFormProperty.value_] = {};
                        }
                        else if(lFormProperty.property_ === 'collection_data_')
                        {
                            this.state.mFormData[lFormProperty.value_] = [];
                        }
                    }
                }
                else if((lFormProperty.type_ === 'initialize') && (this.state.mFormCode === lFormProperty.cs_form_code_))
                {
                    if (lFormProperty.property_ === 'default_data_field_')
                    {
                        this.state.mDefaultDataField = lFormProperty.value_;
                    }
                }
            }
        }
        else
        {
            this.state.mFormData = this.props.pFormData;
        }

        if(this.state.mFormData && this.props.pEnableSaveFn)
        {
            this.props.pEnableSaveFn(this.cfEnableSaveAction(this.state.mFormData), 2);
        }
    }

    componentDidMount()
    {
        this.cfSetFormData();
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            if(this.props.pFormData)
            {
                var lFormData = null;
                if(this.props.pFormProperties)
                {
                    for(var lFipInd=0; lFipInd<this.props.pFormProperties.length; lFipInd++)
                    {
                        if(!lFormData)
                        {
                            lFormData = {};
                        }
    
                        var lFipObj = this.props.pFormProperties[lFipInd];
                        if(lFipObj.cs_form_code_ === this.state.mFormCode)
                        {
                            var lDataObj = null;
                            if(lFipObj.type_ === 'initialize')
                            {
                                if ((lFipObj.property_ === 'data_') || (lFipObj.property_ === 'collection_data_'))
                                {
                                    if(this.props.pFormData.hasOwnProperty(lFipObj.value_))
                                    {
                                        var lData = this.props.pFormData[lFipObj.value_];
                                        if(lData)
                                        {
                                            if(lFipObj.property_ === 'data_')
                                            {
                                                if(lData instanceof Array && (lData.length > 0))
                                                {
                                                    lDataObj = lData[0];
                                                }
                                                else
                                                {
                                                    lDataObj = lData;
                                                }
        
                                                if(lDataObj)
                                                {
                                                    lFormData[lFipObj.value_] = lDataObj;
                                                }
                                            }
                                            else
                                            {
                                                lFormData[lFipObj.value_] = lData;
                                            }
                                        }
                                        else
                                        {
                                            if(lFipObj.property_ === 'data_')
                                            {
                                                lFormData[lFipObj.value_] = {};
                                            }
                                            else
                                            {
                                                lFormData[lFipObj.value_] = [];
                                            }
                                        }
                                    }
                                    else
                                    {
                                        lFormData[lFipObj.value_] = {};
                                    }
                                }
                                else if(lFipObj.property_ === 'default_data_field_')
                                {
                                    if(this.props.pFormData.hasOwnProperty(lFipObj.value_))
                                    {
                                        if(this.props.pFormData[lFipObj.value_])
                                        {
                                            lFormData[lFipObj.value_] = this.props.pFormData[lFipObj.value_];
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                else
                {
                    lFormData = this.props.pFormData;
                }

                this.setState({mFormData : lFormData});
            }
        }
    }

    cfSetFormData()
    {
        var lFormData = null;
        if(this.props.pFormData)
        {
            if(this.props.pFormProperties)
            {
                for(var lFipInd=0; lFipInd<this.props.pFormProperties.length; lFipInd++)
                {
                    if(!lFormData)
                    {
                        lFormData = {};
                    }

                    var lFipObj = this.props.pFormProperties[lFipInd];
                    if(lFipObj.cs_form_code_ === this.state.mFormCode)
                    {
                        var lDataObj = null;
                        if((lFipObj.type_ === 'initialize') && ((lFipObj.property_ === 'data_') || (lFipObj.property_ === 'collection_data_')))
                        {
                            if(this.props.pFormData.hasOwnProperty(lFipObj.value_))
                            {
                                var lData = this.props.pFormData[lFipObj.value_];
                                if(lData)
                                {
                                    if(lFipObj.property_ === 'data_')
                                    {
                                        if(lData instanceof Array && (lData.length > 0))
                                        {
                                            lDataObj = lData[0];
                                        }
                                        else
                                        {
                                            lDataObj = lData;
                                        }

                                        if(lDataObj)
                                        {
                                            lFormData[lFipObj.value_] = lDataObj;
                                        }
                                    }
                                    else
                                    {
                                        lFormData[lFipObj.value_] = lData;
                                    }
                                }
                                else
                                {
                                    if(lFipObj.property_ === 'data_')
                                    {
                                        lFormData[lFipObj.value_] = {};
                                    }
                                    else
                                    {
                                        lFormData[lFipObj.value_] = [];
                                    }
                                }
                            }
                            else
                            {
                                lFormData[lFipObj.value_] = {};
                            }
                        }
                    }
                }
            }
            else
            {
                lFormData = this.props.pFormData;
            }

            this.setState({mFormData : lFormData});

            if(lFormData)
            {
                this.setState({mFormData : lFormData});

                if(this.props.pEnableSaveFn)
                {
                    this.props.pEnableSaveFn(this.cfEnableSaveAction(lFormData), 2);
                }
            }
        }
    }

    cfProcessChange(lData)
    {
        if(lData)
        {
            var lFormItem = null;
            var lFormData = this.state.mFormData;
            var lSelectedData = null;
            var lDataField = this.state.mDefaultDataField;
            if(this.state.mDataFieldObjectMap && lFormData)
            {
                lFormItem = this.state.mDataFieldObjectMap[lData.data_field_];
                if(lFormItem)
                {
                    if(lData.hasOwnProperty('selected_data_'))
                    {
                        lFormItem.selected_data_ = lData.selected_data_;
                    }

                    var lParentObj = null;
                    if(lFormItem.parent_id_)
                    {
                        lParentObj = this.state.mIdFieldObjectMap[lFormItem.parent_id_];
                        if(lParentObj)
                        {
                            lDataField = lParentObj.data_field_;
                            var lPFormData = lFormData[lParentObj.data_field_];
                            if(!lPFormData)
                            {
                                lPFormData = {};
                                lFormData[lParentObj.data_field_] = lPFormData;
                            }
                             
                            lPFormData[lData.data_field_] = lData.value_;
                        }
                    }
                    else
                    {
                        lSelectedData = lFormData[lDataField];
                        if(lSelectedData)
                        {
                            lSelectedData[lData.data_field_] = lData.value_;
                        }
                    }

                    lData.data_object_ = lDataField;
                }
            }

            if(lFormData && this.props.pEnableSaveFn)
            {
                if(!lSelectedData)
                {
                    lSelectedData = lFormData;
                }

                this.props.pEnableSaveFn(this.cfEnableSaveAction(lSelectedData), 2);
            }

            if(this.props.pProcessChange)
            {
                this.props.pProcessChange(lData);
            }
        }
    }

    cfOnPopout(lEvent, lFormItem)
    {
        if(lEvent && lFormItem && this.props.pOnPopout)
        {
            this.props.pOnPopout(lEvent, lFormItem);
        }
    }

    cfSetControlValue()
    {
        if(this.state && this.state.mFormItems)
        {
            var lFormData = this.state.mFormData;
            var lFormItemChildMap = {};
            var lParentItemObjectMap = {};
            CSUtil.cfSort(this.state.mFormItems, 'parent_id_', 'sequence_')
            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var lFormItem = this.state.mFormItems[ind];
                lParentItemObjectMap[lFormItem.id_] = lFormItem;
                if(lFormItem.parent_id_)
                {
                    var lParentFormItem = lParentItemObjectMap[lFormItem.parent_id_];
                    if(lParentFormItem && (lParentFormItem.control_type_ !== 'cs_grid'))
                    {
                        var lParentItemCollection = lFormItemChildMap[lFormItem.parent_id_];
                        if(!lParentItemCollection)
                        {
                            lParentItemCollection = [];
                            lFormItemChildMap[lFormItem.parent_id_] = lParentItemCollection;
                        }

                        lParentItemCollection.push(lFormItem);
                    }
                }
                else if(lFormData)
                {
                    if(lFormItem.control_type_ === 'cs_grid')
                    {
                        lFormItem.mValue = lFormData[lFormItem.data_field_];
                    }
                    else
                    {
                        if(lFormItem.control_type_ !== 'heading')
                        {
                            var lSelectedData = lFormData[this.state.mDefaultDataField];
                            if(lSelectedData)
                            {
                                if(lFormItem.constant_ && ((lFormItem.control_type_ === 'constant') || (lFormItem.control_type_ === 'text_input')))
                                {
                                    if(lSelectedData[lFormItem.data_field_])
                                    {
                                        lFormItem.mValue = lSelectedData[lFormItem.data_field_];
                                    }
                                    else
                                    {
                                        lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                                    }
                                }
                                else
                                {
                                    if(lFormItem.dependent_id_)
                                    {
                                        this.mFormUtil.cfSetDependentFieldData(lFormItem, this.state.mIdFieldObjectMap);
                                        lSelectedData[lFormItem.data_field_] = lFormItem.mValue;
                                    }
                                    else if((lFormItem.control_type_ === 'reference') && (lFormItem.data_field_ === 'selected_data_'))
                                    {
                                        lFormItem.mValue = lSelectedData;
                                    }
                                    else if(lSelectedData[lFormItem.data_field_])
                                    {
                                        lFormItem.mValue = lSelectedData[lFormItem.data_field_];
                                    }
                                }
                            }
                        }                        
                        else
                        {
                            if(lFormItem.constant_ && ((lFormItem.control_type_ === 'constant') || (lFormItem.control_type_ === 'text_input')))
                            {
                                if(lFormData[lFormItem.data_field_])
                                {
                                    lFormItem.mValue = lFormData[lFormItem.data_field_];
                                }
                                else
                                {
                                    lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                                }
                            }
                            else
                            {
                                lFormItem.mValue = lFormData[lFormItem.data_field_];
                            }
                        }
                    }
                }
                else
                {
                    lFormItem.mValue = '';
                }
            }

            var lKeys = Object.keys(lFormItemChildMap);
            var lValues = Object.values(lFormItemChildMap);
            for(var lInd=0; lInd<lKeys.length; lInd++)
            {
                var lParentObj = lParentItemObjectMap[lKeys[lInd]];
                if(lParentObj)
                {
                    var lDataObj = null;
                    if(lFormData && lFormData[lParentObj.data_field_])
                    {
                        lDataObj = lFormData[lParentObj.data_field_];
                    }

                    if(lValues[lInd].length > 0)
                    {
                        var lDItemObj = lValues[lInd];
                        for(var lDInd=0; lDInd<lDItemObj.length; lDInd++)
                        {
                            var lFormItemObject = lDItemObj[lDInd];
                            if(lDataObj)
                            {
                                var lDataField = lFormItemObject.data_field_;
                                if(lDataField)
                                {
                                    lFormItemObject.mValue = lDataObj[lDataField];
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    cfEnableSaveAction(lFormData)
    {
        var lEnabled = true;
        if(this.state.mFormItems && lFormData)
        {
            var lFlag = 1;
            for(var lInd=0; lInd<this.state.mParentFormItems.length; lInd++)
            {
                var lParentObj = this.state.mParentFormItems[lInd];
                var lChildList = this.state.mParentFormItemChildrenMap[lParentObj.id_];
                if(lChildList && (lChildList.length > 0))
                {
                    if(lFormData && lFormData.hasOwnProperty(lParentObj.data_field_))
                    {
                        if(lFlag === 1)
                        {
                            lFlag = 2;
                        }
                        else if(lFlag !== 2)
                        {
                            lFlag = 3;
                        }

                        var lParentFormData = lFormData[lParentObj.data_field_];
                        if(lParentFormData)
                        {
                            lEnabled = CSFormUtil.cfEnableAction(this.state.mSaveActionItem, lParentFormData, lChildList);
                            if(!lEnabled)
                            {
                                break;
                            }
                        }
                    }
                }
                else
                {
                    if(lFlag === 2)
                    {
                        lFlag = 3;
                    }
                    else if(lFlag === 1)
                    {
                        lFlag = 0;
                    }
                }
            }

            if(lEnabled)
            {
                if(lFlag !== 2)
                {
                    lEnabled = CSFormUtil.cfEnableAction(this.state.mSaveActionItem, lFormData, this.state.mParentFormItems);
                }
            }
        }

        return lEnabled;
    }

    render() 
    {
        this.cfSetControlValue();
        var lFormItemList = [];
        if(this.state.mFormItems && (this.state.mFormItems.length > 0))
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if(lFormItem.visible_ === '1')
                {
                    lFormItemList.push(lFormItem);
                }
            }
        }

        var lSection = null;
        if(lFormItemList.length > 0)
        {
            lSection = 
                <div className="form-item-1">
                    { this.mFormUtil.cfGetSections(lFormItemList, this.mMinSectionItems, this.mMaxSections, this.cfProcessChange, null, null, null, this.cfOnPopout, true) }
                </div>
        }

        return lSection;
    }
}

export default CSCODetailsSection1;
