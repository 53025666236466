import React from "react"

import CSCOHorizontalActionBar from "../../components/CSCOHorizontalActionBar"
import CSUtil from "../../util/CSUtil";
import { fnGetForm } from "../../util/CSLayoutUtil"
import CSCOBaseDetailsForm from "./CSCOBaseDetailsForm";
import CSCODetailsHeader1 from "../../components/CSCODetailsHeader1";
import CSCOGrid from "../../components/CSCOGrid";
import CSFormUtil from "../../util/CSFormUtil"
import { cfGetConstantValue} from "../../util/CSConstantUtil";

class CSCODetailsForm11 extends CSCOBaseDetailsForm
{
    mComponentCode      = 'CS_DETAILS_FORM_LAYOUT_11';
    mMaxSections        = 4;
    mMinSectionItems 	= 6;

    constructor(props)
    {
        super(props);

        this.process_add_row            = this.process_add_row.bind(this);
        this.process_delete_row         = this.process_delete_row.bind(this);
        this.process_save               = this.process_save.bind(this);
        this.process_cancel             = this.process_cancel.bind(this);
        this.cfInitializeLayout         = this.cfInitializeLayout.bind(this);
        this.cfSetControlValue          = this.cfSetControlValue.bind(this);
        this.cfProcessChange            = this.cfProcessChange.bind(this);
        this.cfOnGridItemChange         = this.cfOnGridItemChange.bind(this);
        this.cfSetDependentFieldValues  = this.cfSetDependentFieldValues.bind(this);
        this.cfSetComputedFieldValues   = this.cfSetComputedFieldValues.bind(this);
        this.cfGetDependentValue        = this.cfGetDependentValue.bind(this);
        
        if(this.props.pFormProperties && this.props.pFormData)
        {
            for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
            {
                var lFormProperty = this.props.pFormProperties[lInd_0];
                if(lFormProperty.type_ === 'initialize')
                {
                    if (lFormProperty.property_ === 'data_')
                    {
                        if(this.props.pFormData.hasOwnProperty(lFormProperty.value_))
                        {
                            this.state.mSectionData = this.props.pFormData[lFormProperty.value_];
                        }
                    }
                    else if(lFormProperty.property_ === 'collection_data_')
                    {
                        if(this.props.pFormData.hasOwnProperty(lFormProperty.value_))
                        {
                            this.state.mDataCollection = this.props.pFormData[lFormProperty.value_];
                        }
                    }
                }
            }
        }
    }

    componentDidMount()
    {
        this.cfInitializeLayout();
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            super.cfInitializeLayout();
        }
    }

    cfInitializeLayout()
    {
        super.cfInitializeLayout();
    }

    cfGetDependentValue(lDependentId)
    {
        var lRetValue = null;
        if(lDependentId)
        {
            var lDepdendentObj = this.state.mIdFieldObjectMap[lDependentId];
            if(lDepdendentObj)
            {
                lRetValue = lDepdendentObj.mValue;;
            }
        }

        return lRetValue;
    }

    process_save(lEvent, lParent, lActionItem)
    {
        var lDataObj = null;
        this.setState({mSelectedActionItem : lActionItem});
        if(this.state.mSectionData)
        {
            lDataObj = CSUtil.cfGetObjectCopy(this.state.mSectionData);
        }

        var lParentIdItemCollection = {}
        for(var lInd=0; lInd<this.state.mFormItems.length; lInd++)
        {
            var lFormItem = this.state.mFormItems[lInd];
            if(!lFormItem.parent_id_)
            {
                if(!lDataObj)
                {
                    lDataObj = {}
                }

                if((lFormItem.control_type_ === 'reference') && lFormItem.dependent_id_)
                {
                    lDataObj[lFormItem.data_field_] = this.cfGetDependentValue[lFormItem.dependent_id_];
                    lFormItem.mValue = lDataObj[lFormItem.data_field_];
                }
                else if(lFormItem.control_type_ === 'state')
                {
                    lDataObj[lFormItem.data_field_] = lActionItem.action_code_;
                }
            }
            else
            {
                var lCollection = lParentIdItemCollection[lFormItem.parent_id_];
                if(!lCollection)
                {
                    lCollection = [];
                    lParentIdItemCollection[lFormItem.parent_id_] = lCollection;
                }

                lCollection.push(lFormItem);
            }
        }

        var lParentIdKeys = Object.keys(lParentIdItemCollection);
        if(lParentIdKeys)
        {
            for(var lPind=0; lPind<lParentIdKeys.length; lPind++)
            {
                var lParentObj = this.state.mIdFieldObjectMap[lParentIdKeys[lPind]];
                if(lParentObj)
                {
                    var lChildList = lParentIdItemCollection[lParentIdKeys[lPind]];
                    for(var lCind=0; lCind<lChildList.length; lCind++)
                    {
                        var lChildObj = lChildList[lCind];
                        var lDataField = lChildObj.data_field_;
                        var lValue = this.cfGetDependentValue(lChildObj.dependent_id_);
                        if((lChildObj.control_type_ === 'reference') && lChildObj.dependent_id_)
                        {
                            if(lParentObj.control_type_ === 'cs_grid')
                            {
                                if(!lDataObj[lParentObj.data_field_])
                                {
                                    lDataObj[lParentObj.data_field_] = this.state.mDataCollection;
                                }

                                for(var lpcInd=0; lpcInd<lDataObj[lParentObj.data_field_].length; lpcInd++)
                                {
                                    var lcObj = lDataObj[lParentObj.data_field_][lpcInd];
                                    lcObj[lDataField] = lValue;
                                }
                            }
                            else if(lParentObj.control_type_ === 'aggregation')
                            {
                                if(!lDataObj[lParentObj.data_field_])
                                {
                                    lDataObj[lParentObj.data_field_] = {};
                                }

                                lDataObj[lParentObj.data_field_][lDataField] = lValue;
                            }
                        }
                    }
                }
            }
        }

        this.mFormUtil.cfProcessSave(lActionItem, lDataObj, this.cfProcessResponse);
    }

    process_cancel(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_add_row(event)
    {
        var lDataCollection = this.state.mDataCollection;
        if(!lDataCollection)
        {
            lDataCollection = [];
        }

        var lNewRecord = {}
        if(this.state.mFormGrid)
        {
            for(var lInd=0; lInd<this.state.mFormGrid.length; lInd++)
            {
                lNewRecord[this.state.mFormGrid[lInd].data_field_] = '';
            }
        }

        lDataCollection.push(lNewRecord);

        this.setState({mDataCollection : lDataCollection});
    }

    process_delete_row(event)
    {
        if(event)
        {
            
        }
    }

    cfProcessChange(lData)
    {
        if(lData)
        {
            var lFormData = this.state.mSectionData;

            if(!lFormData)
            {
                lFormData = {};
            }

            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var lFormItem = this.state.mFormItems[ind];
                if(!lFormItem.parent_id_ && !(lFormItem.control_type_ === 'cs_grid'))
                {
                    if(!lFormData.hasOwnProperty(lFormItem.data_field_))
                    {
                        if(lFormItem.control_type_ === 'check_box')
                        {
                            lFormData[lFormItem.data_field_] = '0';
                        }
                        else if(lFormItem.data_field_)
                        {
                            lFormData[lFormItem.data_field_] = '';
                        }
                    }

                    if(lFormItem.control_type_ === 'constant')
                    {
                        if(lFormItem.data_field_)
                        {
                            lFormData[lFormItem.data_field_] = cfGetConstantValue(lFormItem.constant_);
                        }
                    }
                    else if(lFormItem.control_type_ === 'check_box')
                    {
                        if(lData.control_type_ === 'check_box')
                        {
                            lFormData[lData.data_field_] = (lData.value_ === '1') ? lFormItem.constant_ : '';
                        }        
                    }
                }
            }

            if((lData.control_type_ !== 'check_box') && (lData.control_type_ !== 'constant'))
            {
                lFormData[lData.data_field_] = lData.value_;
            }

            if(this.state.mDataFieldObjectMap)
            {
                var lChangedFormItem = this.state.mDataFieldObjectMap[lData.data_field_];
                if(lChangedFormItem)
                {
                    var lSelectedData = null;
                    if(lData.hasOwnProperty('selected_data_'))
                    {
                        lSelectedData = lData.selected_data_;
                    }

                    lFormData = CSFormUtil.cfSetDependentFieldsData(this.state.mFormItems, lFormData, lChangedFormItem, lSelectedData, this.state.mIdFieldObjectMap);
                    
                    var lFormItems = this.state.mFormItems.filter(lFormItem => (!lFormItem.parent_id_));
                    var lCompData = CSFormUtil.cfGetComputedFieldsData(lFormData, lFormItems, this.state.mIdFieldObjectMap);
                    if(lCompData)
                    {
                        var lKeys = Object.keys(lCompData);
                        for(var lKind=0; lKind<lKeys.length; lKind++)
                        {
                            var lKey = lKeys[lKind];
                            if(lFormData.hasOwnProperty(lKey))
                            {
                                lFormData[lKey] = lCompData[lKey];
                            }
                        }
                    }
                }
            }

            this.setState ({mSectionData : lFormData});
        }
    }

    cfOnGridItemChange(lDataList, lRowData, lRowIndex, lChangeObject, lColumn)
    {
        if(lDataList.length > lRowIndex)
        {
            var lData = this.cfSetDependentFieldValues(lRowData, lChangeObject);
            var lCompData = CSFormUtil.cfGetComputedFieldsData(lRowData, this.state.mFormGrid, this.state.mIdFieldObjectMap);
            if(lCompData)
            {
                var lKeys = Object.keys(lCompData);
                for(var lKind=0; lKind<lKeys.length; lKind++)
                {
                    var lKey = lKeys[lKind];
                    if(lData.hasOwnProperty(lKey))
                    {
                        lData[lKey] = lCompData[lKey];
                    }
                }
            }

            lDataList[lRowIndex] = lData;
        }

        this.setState({mDataCollection : lDataList});
    }

    cfSetDependentFieldValues(lRowData, lChangeObject)
    {
        if(lRowData && lChangeObject)
        {
            var lIdFormItemMap = {}
            var lDependentIdFormItemMap = {}
            for(var lInd=0; lInd<this.state.mFormGrid.length; lInd++)
            {
                var lFormItem = this.state.mFormGrid[lInd];
                lIdFormItemMap[lFormItem.id_] = lFormItem;
                if(lFormItem && lFormItem.dependent_id_ && lFormItem.dependent_field_)
                {
                    lDependentIdFormItemMap[lFormItem.dependent_id_] = lFormItem;
                }
            }

            var lKeys = Object.keys(lIdFormItemMap);
            if(lKeys && (lKeys.length > 0))
            {
                for(var kInd=0; kInd<lKeys.length; kInd++)
                {
                    var lFrmItem = lIdFormItemMap[lKeys[kInd]];
                    if(lFrmItem.dependent_id_ && lFrmItem.dependent_field_)
                    {
                        if(lChangeObject.hasOwnProperty(lFrmItem.dependent_field_))
                        {
                            lRowData[lFrmItem.data_field_] = lChangeObject[lFrmItem.dependent_field_];
                        }
                    }
                }
            }
        }

        return lRowData;
    }

    cfSetComputedFieldValues(lRowData, lChangeObject)
    {
        var lVariableValueMap = {};
        if(lRowData && lChangeObject)
        {
            var lIdFormItemMap = {}
            for(var lInd=0; lInd<this.state.mFormGrid.length; lInd++)
            {
                var lFormItem = this.state.mFormGrid[lInd];
                lIdFormItemMap[lFormItem.id_] = lFormItem;
                if(lFormItem.hasOwnProperty('cs_form_item_computation_'))
                {
                    var lFICompList = lFormItem.cs_form_item_computation_;
                    if(lFICompList && this.state.mIdFieldObjectMap)
                    {
                        for(var lFicInd=0; lFicInd<lFICompList.length; lFicInd++)
                        {
                            var lFICompObj = lFICompList[lFicInd];
                            var lComputedValue = null;
                            var lField1Obj;
                            var lField2Obj;
                            var lField1Value;
                            var lField2Value;
                            var lDataVal;
                                    
                            if(lFICompObj.field_1_type_ && lFICompObj.field_1_data_field_ )
                            {
                                if(lFICompObj.field_1_type_ === 'control')
                                {
                                    lField1Obj = this.state.mIdFieldObjectMap[lFICompObj.field_1_ref_id_];
                                    if(lField1Obj)
                                    {
                                        lField1Value = lRowData[lFICompObj.field_1_data_field_];
                                    }
                                }
                                else if(lFICompObj.field_1_type_ === 'computed')
                                {
                                    lField1Value = lVariableValueMap[lFICompObj.field_1_data_field_];
                                }

                                if(lFICompObj.field_1_constant_)
                                {
                                    lDataVal = lFICompObj.field_1_constant_;
                                }
                            }
                                    
                            if(lFICompObj.field_2_type_)
                            {
                                if(lFICompObj.field_2_data_field_)
                                {
                                    if(lFICompObj.field_2_type_ === 'control')
                                    {
                                        lField2Obj = this.state.mIdFieldObjectMap[lFICompObj.field_2_ref_id_];
                                        if(lField2Obj)
                                        {
                                            lField2Value = lRowData[lFICompObj.field_2_data_field_];
                                        }
                                    }
                                    else if(lFICompObj.field_2_type_ === 'computed')
                                    {
                                        lField2Value = lVariableValueMap[lFICompObj.field_2_data_field_];
                                    }
                                }
                            }
                            else if(lFICompObj.field_2_type_ === 'constant')
                            {
                                lField2Value = lFICompObj.field_2_constant_;
                                if(lFICompObj.data_val_)
                                {
                                    lDataVal = lFICompObj.data_val_;
                                }
                            }

                            lComputedValue = CSFormUtil.cfGetComputedValue(lField1Value, lField2Value, lFICompObj.operation_, lDataVal);
                            lVariableValueMap[lFICompObj.variable_] = lComputedValue;
                        }

                        if(lVariableValueMap.hasOwnProperty(lFormItem.data_field_))
                        {
                            lFormItem.mValue = lVariableValueMap[lFormItem.data_field_];
                        }
                    }
                }
            }
        }

        return lVariableValueMap;
    }

    cfSetControlValue()
    {
        if((this.state) && (this.state.mFormItems))
        {
            var form_data = this.state.mSectionData;
            if(!form_data)
            {
                form_data = {};
            }

            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var form_item = this.state.mFormItems[ind];
                if(form_item.control_type_ === 'constant')
                {
                    form_item.mValue = cfGetConstantValue(form_item.constant_);
                    form_data[form_item.data_field_] = form_item.mValue;
                }
                else if(form_item.control_type_ === 'check_box')
                {
                    if(form_item.constant_ && form_data && ((form_item.constant_ === form_data[form_item.data_field_])))
                    {
                        form_item.mValue = '1';
                    }
                    else
                    {
                        form_item.mValue = '0';
                    }
                }
                else if(form_data)
                {   
                    if(form_item.control_type_ === 'text_input')
                    {
                        if(form_data[form_item.data_field_])
                        {
                            form_item.mValue = form_data[form_item.data_field_];        
                        }
                        else if(form_item.constant_)
                        {
                            form_item.mValue = cfGetConstantValue(form_item.constant_);
                        }
                    }
                    else
                    {
                        form_item.mValue = form_data[form_item.data_field_];
                    }
                }
                else
                {
                    if(form_item.control_type_ === 'text_input')
                    {
                        form_item.mValue = cfGetConstantValue(form_item.constant_);
                    }
                    else
                    {
                        form_item.mValue = '';
                    }
                }
            }
        }
    }

    render() 
    {
        this.cfSetControlValue();
        let lFormItemList = [];
        let lFormGridList = [];
        let lFormGridObj;
        let lFormIdObjMap = {};
        let lFormActions = null;
        let lGridHeading = null;
        let lSummaryColumns = null;
        if(this.state.mFormItems && this.state.mFormItems.length > 0)
        {
            CSUtil.cfSort(this.state.mFormItems, 'parent_id_');
            for(let lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                let lFormItem = this.state.mFormItems[lInd];
                lFormIdObjMap[lFormItem.id_] = lFormItem;
                if(lFormItem.visible_ === '1')
                {
                    lFormItemList.push(lFormItem);
                }

                if(lFormItem.control_type_ === 'cs_grid')
                {
                    if(!lFormGridObj)
                    {
                        lFormGridObj = {};
                    }
                    
                    lFormGridObj = lFormItem;
                    lFormGridObj.column_name_ = lFormGridObj.display_label_; 
                    lGridHeading = lFormGridObj.display_label_;
                    lFormGridList.push(lFormItem);

                    if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                    {
                        lSummaryColumns = lFormItem.cs_form_item_properties_;
                    }
                }

                if(lFormGridObj)
                {
                    if(lFormItem.parent_id_ === lFormGridObj.id_)
                    {
                        lFormItem.column_name_ = lFormItem.display_label_;
                        if(lFormItem.grid_display_ === "1")
                        {
                            lFormItem.visible_ = '1';
                        }
                        else
                        {
                            lFormItem.visible_ = '0';
                        }

                        lFormGridList.push(lFormItem);
                    }
                }
            }

            if(lFormGridList.length > 0)
            {
                CSUtil.cfSort(lFormGridList, 'sequence_');
            }
        }

        if(this.state.mFormActions && (this.state.mFormActions.length > 0))
        {
            var lFormActionList = this.state.mFormActions.map((lActionItem) => 
                {
                    if(lActionItem.action_type_ === '1')
                    {
                        lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, this.state.mFormData, this.state.mFormItems);
                    }
                    else
                    {
                        lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                    }

                    return lActionItem;
                }
            );
 
            lFormActions = 
                <CSCOHorizontalActionBar 
                    align       = "left"
                    pType       = 'SAVE_ACTION_BAR'
                    parent      = {this}
                    actionList  = {lFormActionList}
                />;
        }

        var lRetValue = null;
        if(lFormItemList.length > 0)
        {
            var lDetailsForm    = null;            
            if(this.state.mDetailsFlag)
            {
                var lDetailsData = (this.state.mAddFlag) ? null : this.state.mSelectedData;
                lDetailsForm = fnGetForm(this.state.mDetailsMetaData, lDetailsData, this.cfCloseForm);
            }

            var lSearchFormItemActions = [];
            var lSearchActionBar;
            if(this.state.mFormItemsActions)
            {
                for(var lFInd=0; lFInd<this.state.mFormItemsActions.length; lFInd++)
                {
                    var lAction = this.state.mFormItemsActions[lFInd];
                    if(lAction.action_type_ === '1')
                    {
                        lAction.mEnabled = CSFormUtil.cfEnableAction(lAction, this.state.mFormData, this.state.mFormItems);
                    }
                    else
                    {
                        lAction.mEnabled = (lAction.enabled_ === '1') ? true : false;
                    }
                
                    lSearchFormItemActions.push(lAction);
                }

                if((lSearchFormItemActions.length > 0) || lGridHeading)
                {
                    lSearchActionBar =                             
                        <CSCOHorizontalActionBar
                            align           = "left"
                            pType           = 'SEARCH_ACTION_BAR'
                            pHeading        = {lGridHeading}
                            parent          = {this}
                            actionList      = {lSearchFormItemActions}
                            />
                }
            }

            var lDetailsSection = null;

            lRetValue = 
                <>
                    <CSCODetailsHeader1 pFormItems          = {lFormItemList} 
                                        pFormData           = {this.state.mSectionData} 
                                        pFormProperties     = {this.state.mFormProperties} 
                                        pFormItemProperties = {this.state.mFormItemProperties}
                                        process_change_     = {this.cfProcessChange}
                                        />

                    { lSearchActionBar }

                    <div className="form-item-1">
                        <CSCOGrid 
                            pData               = {this.state.mDataCollection}
                            pFeatureRefId       = {this.state.mFeatureRefId}
                            pColumns            = {lFormGridList}
                            pageSize            = {15}
                            pSummaryColumns     = {lSummaryColumns}
                            OnItemChange        = {this.cfOnGridItemChange}/>

                        { lDetailsSection }
                    </div>

                    { lFormActions }

                    { lDetailsForm }
                </>
        }

        return lRetValue;
    }
}

export default CSCODetailsForm11;
