import React, {Component} from "react"
import "react-datepicker/dist/react-datepicker.css";
import {Checkbox} from 'primereact/checkbox';
import CSDropDown from './CSDropDown';
import CSMultiselect from './CSMultiselect';
import CSDateField from "./CSDateField";
import CSTimeField from "./CSTimeField";
import CSTextInput from "./CSTextInput";
import CSTextArea from "./CSTextArea";
import CSCOGrid from "../components/CSCOGrid"
import CSUtil from "../util/CSUtil";
import CSPassword from "./CSPassword";
import {Button} from 'primereact/button';
import CSInputNumber from "./CSInputNumber";
import CSCOGoogleMap from "../components/CSCOGoogleMap";
import CSImage from "./CSImage";
import CSFileUpload from "./CSFileUpload";
import CSHyperlink from "./CSHyperlink";
import CSQRCode from "./CSQRCode";

const gLabelWidth = '100px';

class CSControl extends Component
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            mValue     : props.mValue,
            mClassName : (this.props.pClassName) ? this.props.pClassName : 'form-control'
        }

        this.cfGetControl   = this.cfGetControl.bind(this);
        this.cfGetButton    = this.cfGetButton.bind(this);
        this.cfHandleChange = this.cfHandleChange.bind(this);
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
        }
    }

    cfHandleChange(lData)
    {
        if(this.props.process_change_)
        {
            var lDataObj = null;
            if(this.props.control_type_ === 'check_box')
            {
                lDataObj                = {};
                lDataObj.control_type_  = this.props.control_type_;
                lDataObj.data_field_    = this.props.data_field_;
                lDataObj.id_            = this.props.id_;
                lDataObj.value_         = (lData.checked) ? '1' : '0';
            }
            else
            {
                lDataObj = lData;
            }

            this.props.process_change_(lDataObj);
        }
    }

    cfGetButton()
    {
        var lButton = null;
        if(this.props.popout_button_ === 'Y')
        {
            lButton = (
                <Button
                    icon={CSUtil.mNameIconMap[this.props.popout_button_icon_]} 
                    style=
                    {
                        {
                            fontSize        : '13.2px', 
                            width           : '22px', 
                            height          : '22px'
                        }
                    }
                    key = '1'
                    id = 'ID_POPOUT'
                    onClick = 
                    {
                        (event) => 
                        {
                            if(this.props.pOnPopout)
                            {
                                this.props.pOnPopout(event, this.props.pFormItem);
                            }
                        }
                    }
                />
            );
        }
        
        return lButton;
    }

    cfGetControl()
    {
        var ctl = null;
        if(this.props && this.props.control_type_)
        {
            var lFormItem = this.props.pFormItem;
            var lButton = this.cfGetButton();

            switch(this.props.control_type_)
            {
                case 'text_input':
                case 'state':
                    ctl = (
                        <CSTextInput 
                            pId             = {this.props.id_}
                            pDisplayField   = {(!this.props.display_label_) ? this.props.display_field_ : this.props.display_label_}
                            pDataField      = {this.props.data_field_}
                            pControlType    = {this.props.control_type_}
                            pType           = {this.props.type_}
                            pEditable       = {(this.props.editable_ === '1')}
                            pWidth          = {this.props.width_}
                            pMaxWidth       = {(this.props.pMaxWidth === undefined) ? 0 : this.props.pMaxWidth}
                            pValue          = {this.props.mValue}
                            pProcessChange  = {this.cfHandleChange}
                            pLabelWidth     = {(!this.props.pLabelWidth) ? gLabelWidth : this.props.pLabelWidth}
                            pRequired       = {(this.props.required_ === '1')}
                            {...this.props}
                        />
                    )
                break;

                case 'input_number':
                    ctl = (
                        <CSInputNumber 
                            pId             = {this.props.id_}
                            pDisplayField   = {(!this.props.display_label_) ? this.props.display_field_ : this.props.display_label_}
                            pDataField      = {this.props.data_field_}
                            pControlType    = {this.props.control_type_}
                            pType           = {this.props.type_}
                            pEditable       = {(this.props.editable_ === '1')}
                            pWidth          = {this.props.width_}
                            pMaxWidth       = {(this.props.pMaxWidth === undefined) ? 0 : this.props.pMaxWidth}
                            pValue          = {this.props.mValue}
                            pProcessChange  = {this.cfHandleChange}
                            pLabelWidth     = {(!this.props.pLabelWidth) ? gLabelWidth : this.props.pLabelWidth}
                            pRequired       = {(this.props.required_ === '1')}
                            {...this.props}
                        />
                    )
                break;

                case 'color_picker':
                break;

                case 'google_map':
                    ctl = (
                        <CSCOGoogleMap 
                            {...this.props} />
                    )
                break;

                case 'constant':
                    if(this.props.type_ === 'data')
                    {
                        ctl = (
                            <CSTextInput 
                                pId             = {this.props.id_}
                                pDisplayField   = {(!this.props.display_label_) ? this.props.display_field_ : this.props.display_label_}
                                pDataField      = {this.props.data_field_}
                                pControlType    = {this.props.control_type_}
                                pType           = {this.props.type_}
                                pEditable       = {(this.props.editable_ === '1')}
                                pWidth          = {this.props.width_}
                                pMaxWidth       = {(this.props.pMaxWidth === undefined) ? 0 : this.props.pMaxWidth}
                                pValue          = {this.props.mValue}
                                pProcessChange  = {this.cfHandleChange}
                                pLabelWidth     = {(!this.props.pLabelWidth) ? gLabelWidth : this.props.pLabelWidth}
                                pRequired       = {(this.props.required_ === '1')}
                                {...this.props}
                            />
                        )
                    }
                    else if(this.props.type_ === 'section_heading')
                    {
                        var lLabelWidth = this.props.width_;
                        if(lLabelWidth === '-1')
                        {
                            lLabelWidth = this.props.pLabelWidth;
                        }

                        ctl = (
                            <div className="form-item-row" style={{padding: '0.25em'}}>
                                <label style = 
                                    {
                                        {
                                            display     : 'block', 
                                            fontSize    : '13.5px', 
                                            fontWeight  : 550, 
                                            width       : (!lLabelWidth) ? gLabelWidth : lLabelWidth,
                                            textAlign   : 'center'
                                        }
                                    }>
                                    {(!this.props.display_label_) ? this.props.display_field_ : this.props.display_label_}
                                </label>
                            </div>
                        )
                    }
                break;
                
                case 'label':
                case 'reference':
                    ctl = (
                        <div style={{padding: '0.25em', minheight: '40px'}}>
                            <label style = 
                                {
                                    {
                                        display     : 'block', 
                                        fontSize    : '12.5px', 
                                        fontWeight  : 500, 
                                        width       : (!this.props.pLabelWidth) ? gLabelWidth : this.props.pLabelWidth,
                                        textAlign   : 'center'
                                    }
                                }>
                                {(!this.props.display_label_) ? this.props.display_field_ : this.props.display_label_}
                            </label>
                        </div>
                    )
                break;

                case 'password':
                    ctl = (
                        <CSPassword 
                            pId             = {this.props.id_}
                            pDisplayField   = {(!this.props.display_label_) ? this.props.display_field_ : this.props.display_label_}
                            pDataField      = {this.props.data_field_}
                            pControlType    = {this.props.control_type_}
                            pEditable       = {(this.props.editable_ === '1')}
                            pWidth          = {this.props.width_}
                            pMaxWidth       = {(this.props.pMaxWidth === undefined) ? 0 : this.props.pMaxWidth}
                            pValue          = {this.props.mValue}
                            pProcessChange  = {this.cfHandleChange}
                            pLabelWidth     = {(!this.props.pLabelWidth) ? gLabelWidth : this.props.pLabelWidth}
                            pRequired       = {(this.props.required_ === '1')}
                            {...this.props}
                        />
                    )
                break;

                case 'text_area':
                    ctl = (
                        <CSTextArea
                            pId             = {this.props.id_}
                            pDisplayField   = {(!this.props.display_label_) ? this.props.display_field_ : this.props.display_label_}
                            pDataField      = {this.props.data_field_}
                            pControlType    = {this.props.control_type_}
                            pEditable       = {(this.props.editable_ === '1')}
                            pMaxWidth       = {(this.props.pMaxWidth === undefined) ? 0 : this.props.pMaxWidth}
                            pHeight         = {this.props.height_}
                            pValue          = {this.props.mValue}
                            pProcessChange  = {this.cfHandleChange}
                            pLabelWidth     = {(!this.props.pLabelWidth) ? gLabelWidth : this.props.pLabelWidth}
                            pRequired       = {(this.props.required_ === '1')}
                            {...this.props}
                        />
                    )
                break;

                case 'combo_box':
                    ctl = (this.props.popout_button_ === 'Y') ?
                    (
                        <div className="form-item-row-3">
                            <CSDropDown 
                                pId             = {this.props.id_}
                                pDisplayField   = {(!this.props.display_label_) ? this.props.display_field_ : this.props.display_label_}
                                pDataField      = {this.props.data_field_}
                                pControlType    = {this.props.control_type_}
                                pEditable       = {(this.props.editable_ === '1')}
                                pWidth          = {(this.props.pMaxWidth === undefined) ? 0 : this.props.pMaxWidth}
                                pMaxWidth       = {(this.props.pMaxWidth === undefined) ? 0 : this.props.pMaxWidth}
                                pValue          = {this.props.mValue}
                                pProcessChange  = {this.cfHandleChange}
                                pLabelWidth     = {(!this.props.pLabelWidth) ? gLabelWidth : this.props.pLabelWidth}
                                pDataProviderId = {this.props.data_provider_id_}
                                pRequired       = {(this.props.required_ === '1')}
                                pPopoutButton   = {this.props.popout_button_}
                                {...this.props}
                            />
                            {lButton}
                        </div>
                    ) :
                    (
                        <CSDropDown 
                            pId             = {this.props.id_}
                            pDisplayField   = {(!this.props.display_label_) ? this.props.display_field_ : this.props.display_label_}
                            pDataField      = {this.props.data_field_}
                            pControlType    = {this.props.control_type_}
                            pEditable       = {(this.props.editable_ === '1')}
                            pWidth          = {(this.props.pMaxWidth === undefined) ? 0 : this.props.pMaxWidth}
                            pMaxWidth       = {(this.props.pMaxWidth === undefined) ? 0 : this.props.pMaxWidth}
                            pValue          = {this.props.mValue}
                            pProcessChange  = {this.cfHandleChange}
                            pLabelWidth     = {(!this.props.pLabelWidth) ? gLabelWidth : this.props.pLabelWidth}
                            pDataProviderId = {this.props.data_provider_id_}
                            pRequired       = {(this.props.required_ === '1')}
                            pPopoutButton   = {this.props.popout_button_}
                            {...this.props}
                        />
                    )
                break;

                case 'date_field':
                    ctl = (
                        <CSDateField 
                            pId             = {this.props.id_}
                            pDisplayField   = {(!this.props.display_label_) ? this.props.display_field_ : this.props.display_label_}
                            pDataField      = {this.props.data_field_}
                            pControlType    = {this.props.control_type_}
                            pEditable       = {(this.props.editable_ === '1')}
                            pMaxWidth       = {(this.props.pMaxWidth === undefined) ? 0 : this.props.pMaxWidth}
                            pValue          = {this.props.mValue}
                            pProcessChange  = {this.cfHandleChange}
                            pLabelWidth     = {(!this.props.pLabelWidth) ? gLabelWidth : this.props.pLabelWidth}
                            pRequired       = {(this.props.required_ === '1')}
                            {...this.props}
                        />
                    )
                break;
                
                case 'time_field':
                    ctl = (
                        <CSTimeField 
                            pId             = {this.props.id_}
                            pDisplayField   = {(!this.props.display_label_) ? this.props.display_field_ : this.props.display_label_}
                            pDataField      = {this.props.data_field_}
                            pControlType    = {this.props.control_type_}
                            pEditable       = {(this.props.editable_ === '1')}
                            pMaxWidth       = {(this.props.pMaxWidth === undefined) ? 0 : this.props.pMaxWidth}
                            pValue          = {this.props.mValue}
                            pProcessChange  = {this.cfHandleChange}
                            pLabelWidth     = {(!this.props.pLabelWidth) ? gLabelWidth : this.props.pLabelWidth}
                            pRequired       = {(this.props.required_ === '1')}
                            {...this.props}
                        />
                    )
                break;

                case 'check_box':
                    ctl = (
                        <div className="flex mt-2">
                            <div style={{padding: '0.25em', height: '100%'}}>
                                <label htmlFor = { this.props.pId } className="form_control_label" style = {{ width : this.state.mLabelWidth }}>
                                    {(!this.props.display_label_) ? this.props.display_field_ : this.props.display_label_}
                                </label>
                            </div>
                            <Checkbox
                                className   = "cs-checkbox ml-2"
                                id          = {this.props.id_}
                                name        = {this.props.data_field_}
                                onChange    = {this.cfHandleChange}
                                checked     = {(this.props.mValue === '1')}/>
                        </div>
                    )
                break;

                case 'list':
                break;

                case 'multi_select_combo_box':
                    ctl = 
                    (
                        <CSMultiselect 
                            pId             = {this.props.id_}
                            pDisplayField   = {(!this.props.display_label_) ? this.props.display_field_ : this.props.display_label_}
                            pDataField      = {this.props.data_field_}
                            pControlType    = {this.props.control_type_}
                            pEditable       = {(this.props.editable_ === '1')}
                            pWidth          = {this.props.width_}
                            pMaxWidth       = {(this.props.pMaxWidth === undefined) ? 0 : this.props.pMaxWidth}
                            pValue          = {this.props.mValue}
                            pProcessChange  = {this.cfHandleChange}
                            pLabelWidth     = {(!this.props.pLabelWidth) ? gLabelWidth : this.props.pLabelWidth}
                            pDataProviderId = {this.props.data_provider_id_}
                            pRequired       = {(this.props.required_ === '1')}
                            {...this.props}
                        /> 
                    )
                break;

                case 'cs_grid':
                    var lData = null;
                    if(this.props.mValue)
                    {
                        if(this.props.mValue instanceof Array)
                        {
                            CSUtil.cfSort(this.props.mValue, 'sequence_')
                            lData = this.props.mValue;
                        }
                        else
                        {
                            lData = [];
                            lData.push(this.props.mValue);
                        }
                    }

                    ctl = (
                        <CSCOGrid 
                            pData           = { lData }
//                            pFeatureRefId   = { this.state.mFeatureRefId }
                            pColumns        = { this.props.pFormGrid }
                            pMinHeight      = { this.props.pMinHeight }
                            pWidth          = {this.props.width_}
                            pEnableFilter   = { false }
                            pAlignHeading   = { 'left' }
//                            rowIndex        = { this.state.mRowIndex } 
                            pageSize        = { 15 }
                            pHeading        = { (!this.props.display_label_) ? this.props.display_field_ : this.props.display_label_ }
                            onClick         = { this.cfOnDataSelection }
                        />    
                    )
                break;

                case 'image':
                    var lEnableCamera = false;
                    var lEnableBrowse = false;
                    if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                    {
                        var lProperties = lFormItem.cs_form_item_properties_;
                        for(var lpInd=0; lpInd<lProperties.length; lpInd++)
                        {
                            var lProperty = lProperties[lpInd];
                            if((lProperty.type_ === 'property') && (lProperty.property_ === 'enable_camera'))
                            {
                                lEnableCamera = (lProperty.value_ === 'true')
                            }
                            else if((lProperty.type_ === 'property') && (lProperty.property_ === 'enable_browse'))
                            {
                                lEnableBrowse = (lProperty.value_ === 'true')
                            }
                        }
                    }
                    ctl = (
                        <CSImage {...this.props } 
                            pProcessChange = { this.cfHandleChange }
                            pDisplayLabel  = { (lFormItem.display_label_) ? lFormItem.display_label_ : lFormItem.display_field_ } 
                            pEnableCamera =  { lEnableCamera } 
                            pEnableBrowse =  { lEnableBrowse }/>
                    )
                break;

                case 'qr_code':
                    ctl = 
                        <CSQRCode { ...this.props } pValue = { this.props.mValue } />
                break;

                case 'hyperlink':
                    ctl = <CSHyperlink {...this.props } 
                        pId             = {this.props.id_}
                        pDisplayField   = {(!this.props.display_label_) ? this.props.display_field_ : this.props.display_label_}
                        pValue          = {this.props.mValue}/>
                break;

                case 'file':
                    ctl = <CSFileUpload {...this.props } 
                            pId             = {this.props.id_}
                            pDisplayField   = {(!this.props.display_label_) ? this.props.display_field_ : this.props.display_label_}
                            pDataField      = {this.props.data_field_}
                            pControlType    = {this.props.control_type_}
                            pValue          = {this.props.mValue}
                            pProcessChange  = {this.cfHandleChange}
                            pLabelWidth     = {(!this.props.pLabelWidth) ? gLabelWidth : this.props.pLabelWidth}
                            pRequired       = {(this.props.required_ === '1')} />
                break;

                case 'heading':
                    ctl = (
                        <div className="form-item-row-2">
                            <label style={{fontSize : '13.5px', fontWeight : 'bold'}}>{(!this.props.display_label_) ? this.props.display_field_ : this.props.display_label_}</label>
                        </div>
                    )
                break;

                default:
                break;
            }
        }

        return ctl;
    }

    render()
    {
        var lControl = this.cfGetControl();
        return (
            <div className = {this.state.mClassName}>
                { lControl }
            </div>
        )
    }
}

export default CSControl;
