import React from "react"
import QRCode from "qrcode.react"

const CSQRCode = (lProps) =>
{
    return (
        <div className="qrcode">
            <QRCode
                bgColor = "#FFFFFF"
                level   = "L"
                style   = {{ width: '72px', height: '72px' }}
                value   = {(lProps.mValue) ? lProps.mValue : ''}
            />
        </div>
    );
}

export default CSQRCode;
