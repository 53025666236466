import React, { useState, useEffect, useRef } from 'react';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';

import { fnGetForm } from "../../util/CSLayoutUtil"
import CSServiceUtil from "../../util/CSServiceUtil";
import CSRequestUtil from "../../util/CSRequestUtil"

import CSCOTreeGrid from "../../components/CSCOTreeGrid"
import CSCOBackdrop from "../../components/CSCOBackdrop";
import CSCODetailsHeader from "../../components/CSCODetailsHeader";
import CSCOHorizontalActionBar from "../../components/CSCOHorizontalActionBar"

import CSUtil from "../../util/CSUtil";
import CSFormUtil from "../../util/CSFormUtil"
import { cfGetConstantValue} from "../../util/CSConstantUtil";
import CSCOBaseActionBar from "../../components/CSCOBaseActionBar";

const mFormUtil = CSFormUtil.cfGetInstance();
const mServiceUtil = CSServiceUtil.cfGetInstance();

const CSCOAdvancedDetailsForm3 = (gProps) => {
    const mMinSectionItems = 7;
    const mNotificationState = {
        enabled_              : false,
        trigger_state_        : '',
        contact_service_code_ : '',
        service_handler_      : '',
        event_service_code_   : '',
        type_                 : '',
        params_               : {},
        criteria_             : {},
    };

    const [mFormData, setFormData] = useState((gProps.pFormData) ? gProps.pFormData : gProps.pSelectedData);
    const [mDataChange, setDataChange] = useState(false);
    const [mDisplay, setDisplay]       = useState(gProps.pShow);
    const [mShowBackdrop, setBackdrop] = useState(false);
    const [mFormGrid, setFormGrid]     = useState(null);

    const [mDetailsFlag, setDetailsFlag]         = useState(false);
    const [mDetailsMetaData, setDetailsMetaData] = useState(null);
    const [mSectionData, setSectionData]         = useState(null);
    const [mSelectedRecord, setSelectedRecord]   = useState(null);
    const [mDataCollection, setDataCollection]   = useState(null);

    const [mDataFieldObjectMap, setDataFieldObjectMap] = useState(gProps.pDataFieldObjectMap);
    const [mFormDetailsActions, setDetailsActions]     = useState(null);
    const [mSearchActionList, setSearchActionList]     = useState(null);
    const [mCollectionFormItem, setCollectionFormItem] = useState(null);
    
    const [mFormNameDatafieldMap, setFormNameDatafieldMap]     = useState(null);
    const [mFormItemSearchActions, setFormItemSearchActions]   = useState(null);
    const [mFormItemDetailsActions, setFormItemDetailsActions] = useState(null);
         
    const [mEnablePreview, setEnablePreview] = useState(false);
    const [mEnablePrint, setEnablePrint]     = useState(false);
    const [mPrintForm, setPrintForm]         = useState(false);
    const [mNewRecord, setNewRecord]         = useState(null);
    
    const [mSaveActionItem, setSaveActionItem] = useState(null);
    const [mNotification, setNotification]     = useState(mNotificationState);

    const mDataFieldTypeMap     = useRef({});
    const mIdFormItemMap        = useRef({});

    let mDefaultDataField     = useRef('selected_data_');
    let mParentChildrenMap    = useRef(null);
    let mFormActions          = useRef(null);
    let mFormItems            = useRef(null);
    let mFormProperties       = useRef(null);

    useEffect(() =>
        {
            const fnInitializeActions = () =>
            {
                let lDetailsActionList = null;
                let lFormItemsSearchActions = [];
                let lFormItemsDetailsActions = [];

                if(gProps.pFormActions && (gProps.pFormActions.length > 0)) {
                    for(let lDaInd=0; lDaInd<gProps.pFormActions.length; lDaInd++) {
                        let lFormAction = gProps.pFormActions[lDaInd]; 
                                
                        if(lFormAction.action_) {
                            lFormAction.fnProcessClick = fnProcessClick;
                        }

                        if(lFormAction.component_type_ === 'DETAILS_ACTION') {
                            if(!lDetailsActionList) {
                                lDetailsActionList = [];
                            }
                            
                            if(gProps.pSelectedActionItem && (!lFormAction.parent_ref_id_ || (lFormAction.parent_ref_id_ === gProps.pSelectedActionItem.id_))) {
                                if(lFormAction.action_type_ === '0') {
                                    lFormAction.mEnabled = (lFormAction.enabled_ === '1') ? true : false;
                                }
        
                                lDetailsActionList.push(lFormAction);
                                
                                if(!mFormActions.current) {
                                    mFormActions.current = [];
                                }

                                mFormActions.current.push(lFormAction);
                            }
                        }
                        else if(lFormAction.component_type_ === 'DETAILS_ITEM_SEARCH_ACTION') {
                            if(!lFormAction.parent_ref_id_ || (lFormAction.parent_ref_id_ === gProps.pSelectedActionItem.id_))
                                {
                                    lFormItemsSearchActions.push(lFormAction);
                                }
                        }
                        else if (lFormAction.component_type_ === 'DETAILS_ITEM_DETAILS_ACTION') {
                            if(!lFormAction.parent_ref_id_ || (lFormAction.parent_ref_id_ === gProps.pSelectedActionItem.id_)) {
                                lFormItemsDetailsActions.push(lFormAction);
                            }
                        }
                    }
        
                    CSUtil.cfSort(lDetailsActionList, 'sequence_');

                    setDetailsActions(lDetailsActionList);
                    setFormItemSearchActions(lFormItemsSearchActions);
                    setFormItemDetailsActions(lFormItemsDetailsActions);
                }
            }

            fnInitializeActions();
        }, []
    );

    useEffect(() => 
        {
            const fnInitializeForm = () => {
                let lSectionData = null;

                let lFormItemList = [];
                let lFormCodeList = null;
                let lFormNameDatafieldMap = {}
                let lComponentCodeFormItemsMap = {};
                let lFormItemsWithoutParent = null;
                mParentChildrenMap.current = null;

                let lProperties = CSUtil.cfClone(gProps);
                if(lProperties.pFormItems && Array.isArray(lProperties.pFormItems) && lProperties.pFormItems.length > 0) {
                    let lSelectedActionItem = null;
                    if(lProperties.pParentActionItem) {
                        lSelectedActionItem = lProperties.pParentActionItem;
                    }
                    else {
                        lSelectedActionItem = lProperties.pSelectedActionItem;
                    }
                    
                    if(lProperties.pFormProperties) {
                        if(!lSelectedActionItem) {
                            lSelectedActionItem = {}
                            mFormProperties.current = lProperties.pFormProperties;
                            for(let lpfpInd=0; lpfpInd<lProperties.pFormProperties.length; lpfpInd++) {
                                let lpfpProperty = lProperties.pFormProperties[lpfpInd];
                                if(lpfpProperty.property_ )
                                if(lpfpProperty.property_ === 'selected_action_item_ref_id_') {
                                    lSelectedActionItem.id_ = lpfpProperty.value_;
                                }
                                else if(lpfpProperty.property_ === 'on_click_service_code_') {
                                    lSelectedActionItem.on_click_service_code_ = lpfpProperty.value_;
                                }
                                    
                                if(lpfpProperty.type_ === 'criteria') {
                                    if(!lProperties.pSelectedCriteria) {
                                        lProperties.pSelectedCriteria = {}
                                    }
    
                                    lProperties.pSelectedCriteria[lpfpProperty.property_] = cfGetConstantValue(lpfpProperty.value_);
                                }
                            }
                        }
                    }
                        
                    if(lSelectedActionItem) {
                        lProperties.pSelectedActionItem = lSelectedActionItem;
                        let lFormGrid = [];
                        CSUtil.cfSort(lProperties.pFormItems, 'sequence_');
                        for(let lInd=0; lInd < lProperties.pFormItems.length; lInd++) {
                            let lFormItem = lProperties.pFormItems[lInd];
                            lFormItem.mValue = '';
                            mIdFormItemMap.current[lFormItem.id_] = lFormItem;
                            if(lFormItem.grid_display_ === '1')  {
                                let lItemEditor    = null;
                                let lItemRenderer  = null;
                                let lAlign         = 'center';
                                let lColumn = CSUtil.cfGetObjectCopy(lFormItem);
                                if(lColumn.hasOwnProperty('cs_form_item_properties_')) {
                                    let lProperties = lColumn.cs_form_item_properties_;
                                    for(let lInd=0; lInd<lProperties.length; lInd++)
                                    {
                                        var lProperty = lProperties[lInd];
                                        if(lProperty.property_ === 'item_editor')
                                        {
                                            lItemEditor = lProperty.value_;
                                        }

                                        if(lProperty.property_ === 'item_renderer')
                                        {
                                            lItemRenderer = lProperty.value_;
                                        }

                                        if(lProperty.property_ === 'alignment')
                                        {
                                            lAlign = lProperty.value_;
                                        }

                                        if(lProperty.property_ === 'grouping_field')
                                        {
                                            lColumn.grouping_field_ = '1';
                                        }
                                    }
                                }

                                lColumn.column_name_ = lColumn.display_label_;
                                if(lItemEditor) {
                                    lColumn.item_editor_ = lItemEditor;
                                    if(lColumn.control_type_ === 'combo_box') {
                                        if(lColumn.data_provider_source_)
                                        {
                                            this.mCSServiceUtil.cfGetDataFromDataProvider (lColumn.data_provider_source_, 
                                                (lDataProviderId, lCollection) => {
                                                    if( lDataProviderId === lColumn.data_provider_source_) {
                                                        lColumn.data_provider_ = lCollection;
                                                    }
                                                }
                                            );
                                        }
                                    }
                                }
                                else if(lItemRenderer)
                                {
                                    lColumn.item_renderer_ = lItemRenderer;
                                }

                                if(!lColumn.item_editor_){
                                    lColumn.editable_ = '0';
                                }

                                lColumn.alignment_ = lAlign;
                                lFormGrid.push(lColumn);
                            }
                            else if(lFormItem.hasOwnProperty('cs_form_item_properties_')) {
                                for(let lfiIndex=0; lfiIndex<lFormItem.cs_form_item_properties_.length; lfiIndex++) {
                                    let lfiProperty = lFormItem.cs_form_item_properties_[lfiIndex];
                                    if((lfiProperty.type_ === 'data_selection') 
                                            && (lfiProperty.property_ === 'form_item') && (lfiProperty.value_ === 'selected_criteria_')
                                            && (lProperties.hasOwnProperty('pFormMetaData') && lProperties.pFormMetaData.hasOwnProperty('pFormCriteria'))) {
                                        let lFormCriteria = lProperties.pFormMetaData.pFormCriteria;
                                        if(lFormCriteria.length > 0)
                                        {
                                            for(let lfcInd=0; lfcInd<lFormCriteria.length; lfcInd++)
                                            {
                                                let lfcObj = lFormCriteria[lfcInd];
                                                if((lFormItem.data_field_ === lfiProperty.selection_property_) && (lfcObj.criteria_field_ === lfiProperty.selection_value_))
                                                {
                                                    lFormItem.mCriteriaValue = lfcObj.mValue;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            let lActionRefId = (lFormItem.details_action_ref_id_) ? lFormItem.details_action_ref_id_ : lFormItem.action_ref_id_;
                            if(lActionRefId === lSelectedActionItem.id_) {
                                if(!lFormCodeList) {
                                    lFormCodeList = [];
                                }

                                if(!lFormCodeList.includes(lFormItem.cs_form_code_)) {
                                    lFormCodeList.push(lFormItem.cs_form_code_);
                                }

                                let lCompCode = null;
                                if(lProperties.pSelectedActionItem && lProperties.pSelectedActionItem.navigate_to_form_component_code_) {
                                    lCompCode = lProperties.pSelectedActionItem.navigate_to_form_component_code_;
                                }

                                if(!lCompCode) {
                                    if(lFormItem.cs_form_component_code_ === 'CS_DETAILS_FORM_3'){
                                        lCompCode = 'CS_DETAILS_FORM_1';
                                    }
                                    else {
                                        lCompCode = lFormItem.cs_form_component_code_;
                                    }        
                                }

                                let lComponentFormItems = lComponentCodeFormItemsMap[lCompCode];
                                if(!lComponentFormItems) {
                                    lComponentFormItems = [];
                                    lComponentCodeFormItemsMap[lCompCode] = lComponentFormItems;
                                }
                                
                                if(lFormItem.parent_id_) {
                                    if(!mParentChildrenMap.current) {
                                        mParentChildrenMap.current = {}
                                    }

                                    let lChildren = mParentChildrenMap.current[lFormItem.parent_id_];
                                    if(!lChildren) {
                                        lChildren = [];
                                        mParentChildrenMap.current[lFormItem.parent_id_] = lChildren;
                                    }

                                    lChildren.push(lFormItem);
                                }
                                else {
                                    if(!lFormItemsWithoutParent) {
                                        lFormItemsWithoutParent = [];
                                    }

                                    lFormItemsWithoutParent.push(lFormItem);
                                }

                                if((lFormItem.visible_ === '1') && !lFormItem.hasOwnProperty('is_dependent_item_')) {
                                    lComponentFormItems.push(lFormItem);
                                }
                                
                                lFormItemList.push(lFormItem);
                            }
                        }

                        setFormGrid(lFormGrid);
                    }
                }

                let lFormData = lProperties.pFormData;
                if(!lFormData) {
                    lFormData = {}
                }

                let lDataFieldObjectMap = mDataFieldObjectMap;
                let lDefaultDataField = mDefaultDataField.current;

                if(lProperties.pFormProperties) {
                    for(let lInd_0=0; lInd_0<lProperties.pFormProperties.length; lInd_0++) {
                        let lFormProperty = lProperties.pFormProperties[lInd_0];
                        if(lFormCodeList) {
                            if((lFormProperty.type_ === 'initialize') && (lFormCodeList.includes(lFormProperty.cs_form_code_))) {
                                lFormNameDatafieldMap[lFormProperty.cs_form_code_] = lFormProperty.value_;
                                if (lFormProperty.property_ === 'default_data_field_') {
                                    mDefaultDataField.current = lFormProperty.value_;
                                    lDefaultDataField = lFormProperty.value_;
                                }
                                else if(lFormProperty.property_ === 'data_') {
                                    mDataFieldTypeMap.current[lFormProperty.value_] = 'OBJECT';
                                    if(!lFormData.hasOwnProperty(lFormProperty.value_)) {
                                        lFormData[lFormProperty.value_] = {};
                                    }
                                    else {
                                        let lData = lFormData[lFormProperty.value_];
                                        if((lData instanceof Array) && (lData.length > 0)) {
                                            lFormData[lFormProperty.value_] = lData[0];
                                        }
                                        else {
                                            lFormData[lFormProperty.value_] = lData;
                                        }
                                    }
                                }
                                else if(lFormProperty.property_ === 'collection_data_') {
                                    mDataFieldTypeMap.current[lFormProperty.value_] = 'COLLECTION';
                                }
                            }
                        }

                        if (lFormProperty.property_ === 'enable_print') {
                            setEnablePrint(true);
                        }
                        else if (lFormProperty.property_ === 'print_form') {
                            setEnablePreview(true);
                            setPrintForm(lFormProperty.value_);
                        }        
                    }
                }

                if(gProps.pFormProperties && gProps.pFormData) {
                    for(let lInd_0=0; lInd_0<gProps.pFormProperties.length; lInd_0++) {
                        let lFormProperty = gProps.pFormProperties[lInd_0];
                        if(lFormProperty.type_ === 'initialize') {
                            if (lFormProperty.property_ === 'data_') {
                                lSectionData = gProps.pFormData[lFormProperty.value_]
                                setSectionData(lSectionData);
                            }
                            else if(lFormProperty.property_ === 'collection_data_') {
                                setDataCollection(gProps.pFormData[lFormProperty.value_]);
                            }
                        }
                    }
                }

                if(lProperties.pSelectedData) {
                    if(lProperties.pSelectedData.hasOwnProperty('selected_data_')) {
                        lFormData[lDefaultDataField] = lProperties.pSelectedData.selected_data_;
                    }
                    else {
                        lFormData[lDefaultDataField] = lProperties.pSelectedData;
                    }
                }
                else {
                    lFormData[lDefaultDataField] = {}
                }

                let lParentList = null;
                if(mParentChildrenMap.current) {
                    lParentList = Object.keys(mParentChildrenMap.current);
                    for(let lInd0=0; lInd0<lParentList.length; lInd0++) {
                        let lParentId       = lParentList[lInd0];
                        let lParentFormItem = mIdFormItemMap.current[lParentId];
                        let lChildren0      = mParentChildrenMap.current[lParentId];
                        let lDataObj        = null;
                        if(lChildren0 && (lChildren0.length > 0)) {
                            if(lParentFormItem) {
                                lDataObj = lFormData[lParentFormItem.data_field_];
                                if(!lDataObj) {
                                    if(mDataFieldTypeMap.current[lParentFormItem.data_field_]) {
                                        if(mDataFieldTypeMap.current[lParentFormItem.data_field_] === 'COLLECTION') {
                                            lDataObj = []
                                        }
                                        else {
                                            lDataObj = {}
                                        }
                                    }
                                    else {
                                        lDataObj = {}
                                    }

                                    lFormData[lParentFormItem.data_field_] = lDataObj;
                                }
                            }

                            if(!lDataObj) {
                                let lDatafieldName = (lParentFormItem) ? lFormNameDatafieldMap[lParentFormItem.cs_form_code_] : null;
                                if(lDatafieldName) {
                                    lDataObj = lFormData[lDatafieldName];
                                }
                                else {
                                    lDataObj = lFormData[lDefaultDataField];
                                }
                            }

                            for(let lcInd=0; lcInd<lChildren0.length; lcInd++) {
                                let lChild = lChildren0[lcInd];
                                if(!lDataFieldObjectMap) {
                                    lDataFieldObjectMap = {};
                                }
                
                                if(lChild.data_field_) {
                                    lDataFieldObjectMap[lChild.data_field_] = lDataObj;
                                }
                            }
                        }
                    }
                }

                if(lFormItemsWithoutParent) {
                    for(let lpInd=0; lpInd<lFormItemsWithoutParent.length; lpInd++) {
                        let lpFormItem  = lFormItemsWithoutParent[lpInd];
                        let lDObj       = null;
                        let lDfieldName = lFormNameDatafieldMap[lpFormItem.cs_form_code_]
                        if(lDfieldName) {
                            lDObj = lFormData[lDfieldName];
                        }
                        else {
                            lDObj = lFormData[lDefaultDataField];
                        }
        
                        if(!lParentList || (lParentList && !lParentList.includes(lpFormItem.id_))) {
                            if(!lDataFieldObjectMap) {
                                lDataFieldObjectMap = {}
                            }
                        
                            lDataFieldObjectMap[lpFormItem.data_field_] = lDObj;
                        }
                    }
                }

                if(lProperties.pSelectedCriteria) {
                    lFormData['selected_criteria_'] = lProperties.pSelectedCriteria;
                }
                else if (lProperties.pSelectedData && lProperties.pSelectedData.hasOwnProperty('selected_criteria_')) {
                    lFormData['selected_criteria_'] = lProperties.pSelectedData['selected_criteria_'];
                    lProperties.pSelectedCriteria = lProperties.pSelectedData['selected_criteria_'];
                }

                let lNotificationData = mNotificationState;
                for(let lpfpInd=0; lpfpInd<lProperties.pFormProperties.length; lpfpInd++) {
                    let lpfpProperty = lProperties.pFormProperties[lpfpInd];
                    if(lpfpProperty.type_ === 'notification') {
                        if((lpfpProperty.property_ === 'enable_') && lpfpProperty.value_) {
                            lNotificationData.enabled_ = (lpfpProperty.value_ === '1');
                        }
                        else if((lpfpProperty.property_ === 'trigger_state_') && lpfpProperty.value_) {
                            lNotificationData.trigger_state_ = lpfpProperty.value_;
                        }
                        else if((lpfpProperty.property_ === 'contact_service_code_') && lpfpProperty.value_) {
                            lNotificationData.contact_service_code_ = lpfpProperty.value_;
                        }
                        else if((lpfpProperty.property_ === 'type_') && lpfpProperty.value_) {
                            lNotificationData.type_ = lpfpProperty.value_;
                        }
                        else if((lpfpProperty.property_ === 'category_') && lpfpProperty.value_) {
                            lNotificationData.category_ = lpfpProperty.value_;
                        }
                        else if((lpfpProperty.property_.includes('param_') || (lpfpProperty.group_ === 'param')) && lpfpProperty.value_) {
                            lNotificationData.params_[lpfpProperty.property_] = lpfpProperty.value_;
                        }
                        else if(lpfpProperty.property_.includes('service_handler_') && lpfpProperty.value_) {
                            lNotificationData.service_handler_ = lpfpProperty.value_;
                        }
                        else if(lpfpProperty.property_.includes('event_service_code_') && lpfpProperty.value_) {
                            lNotificationData.event_service_code_ = lpfpProperty.value_;
                        }
                        
                        if(lpfpProperty.group_ === 'criteria') {
                            lNotificationData.criteria_[lpfpProperty.property_] = lpfpProperty.value_;
                        }                                
                    }
                }

                mFormItems.current = lFormItemList;

                setNotification(lNotificationData);
                setFormData(lFormData);
                setDataFieldObjectMap(lDataFieldObjectMap);
                setFormNameDatafieldMap(lFormNameDatafieldMap);
                setDisplay(true);
                fnInitializeData.current(lFormItemList, lDataFieldObjectMap, lProperties, lFormData, lFormNameDatafieldMap);
            }

            fnInitializeForm();
        }, []
    );

    const fnInitializeData = useRef((lFormItemList, lDataFieldObjectMap, lProperties, lFormData, lFormNameDatafieldMap) => {
            let lCriteria;
            let lServiceCode;
            let lRequestObject;
            if(lProperties.pSelectedCriteria) {
                lCriteria = lProperties.pSelectedCriteria;
            }

            if(lProperties.pSelectedActionItem) {
                let lRequestRefId = (lProperties.pSelectedTabActionItem) ? lProperties.pSelectedTabActionItem.on_click_request_ref_id_ : lProperties.pSelectedActionItem.on_click_request_ref_id_;
                if(lRequestRefId) {
                    lRequestObject = mServiceUtil.cfGetRequestObject(lRequestRefId);
                }

                lServiceCode = (lProperties.pSelectedTabActionItem) ? lProperties.pSelectedTabActionItem.on_click_service_code_ : lProperties.pSelectedActionItem.on_click_service_code_;
                if(lServiceCode || lRequestObject) {
                    let lServiceObject = mServiceUtil.cfGetServiceObject(lServiceCode);
                    if(lServiceObject) {
                        let lRequestId = null;
                        if(lRequestObject && lRequestObject.hasOwnProperty('request_id_')) {
                            lRequestId = lRequestObject.request_id_;
                        }
        
                        if(lCriteria) {
                            let lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, lRequestId, lServiceObject);
                            if(lServiceObject.hasOwnProperty('cs_service_properties_')) {
                                let lServiceProperties = lServiceObject.cs_service_properties_;
                                if(lServiceProperties) {
                                    for(let lInd=0; lInd<lServiceProperties.length; lInd++) {
                                        if(lServiceProperties[lInd].type_ === 'criteria') {
                                            if(!lCriteria) {
                                                lCriteria = {}
                                            }

                                            lCriteria[lServiceProperties[lInd].property_] = cfGetConstantValue(lServiceProperties[lInd].value_);
                                        }
                                    }
                                }
                            }

                            setBackdrop(true);
                            lRequestUtil.cfSetRequestCriteria(lCriteria);
                            lRequestUtil.cfProcessRequest((lResponse) => {
                                    if(lResponse) {
                                        let lKeys = Object.keys(lResponse);
                                        if(!lFormData) {
                                            lFormData = {}
                                        }

                                        for (let lKeyInd=0; lKeyInd<lKeys.length; lKeyInd++) {
                                            let lKey = lKeys[lKeyInd];
                                            if(!lFormData.hasOwnProperty(lKey)) {
                                                lFormData[lKey] = {}
                                            }

                                            let lDataObj = null;
                                            if(Array.isArray(lFormData[lKey])) {
                                                lDataObj = lResponse[lKey];
                                            }
                                            else {
                                                if(Array.isArray(lResponse[lKey])) {
                                                    lDataObj = lResponse[lKey][0];
                                                }
                                                else {
                                                    lDataObj = lResponse[lKey];
                                                }
                                            }

                                            if((lKey === 'collection_') && lDataObj) {
                                                if(!lFormData.hasOwnProperty(mDefaultDataField.current)) {
                                                    if(Array.isArray(lDataObj)) {
                                                        lFormData[mDefaultDataField.current] = lDataObj[0];
                                                    }
                                                    else {
                                                        lFormData[mDefaultDataField.current] = lDataObj;
                                                    }
                                                }
                                                else {
                                                    lFormData[lKey] = lDataObj;
                                                }
                                            }
                                            else if((lKey !== 'status') && lDataObj) {
                                                let ldKeys = Object.keys(lDataObj);
                                                if(ldKeys) {
                                                    for(let ldInd=0; ldInd<ldKeys.length; ldInd++) {
                                                        lFormData[lKey][ldKeys[ldInd]] = lDataObj[ldKeys[ldInd]];
                                                    }
                                                }
                                                else {
                                                    lFormData[lKey] = lDataObj;
                                                }
                                            }
                                        }

                                        if(mParentChildrenMap.current) {
                                            let lpKeys = Object.keys(mParentChildrenMap.current);
                                            for(let lpInd=0; lpInd<lpKeys.length; lpInd++) {
                                                let lpKey = lpKeys[lpInd];
                                                let pFormItem = mIdFormItemMap.current[lpKey];
                                                if(pFormItem && pFormItem.data_field_) {
                                                    let lpDataObj = (!lFormData[pFormItem.data_field_]) ? {} : lFormData[pFormItem.data_field_];
                                                    let lChildren = mParentChildrenMap.current[lpKey];
                                                    for(let lcInd=0; lcInd<lChildren.length; lcInd++) {
                                                        let cFormItem = lChildren[lcInd];
//                                                        lDataFieldObjectMap[cFormItem.data_field_] = lpDataObj;
                                                    }
                                                }
                                            }
                                        }
                                        else if(mFormItems) {
                                            for(let lfiInd=0; lfiInd<mFormItems.current.length; lfiInd++)
                                            {
                                                let lfItem = mFormItems.current[lfiInd];
                                                let lfDataObj = null;
                                                let lDatafieldName = lFormNameDatafieldMap[lfItem.cs_form_code_];
                                                if(lDatafieldName) {
                                                    lfDataObj = lFormData[lDatafieldName];
                                                }
                                                else {
                                                    lfDataObj = lFormData[mDefaultDataField.current];
                                                }

                                                lDataFieldObjectMap[lfItem.data_field_] = lfDataObj;
                                            }
                                        }

                                        setFormData(lFormData);
//                                        fnSetControlValue(lFormItemList, lDataFieldObjectMap, mFormActions.current);
                                    }
                                    
                                    setDataChange(!mDataChange);
                                    setBackdrop(false);
                                }
                            );
                        }
                        else {
                            fnSetControlValue(lFormItemList, lDataFieldObjectMap, mFormActions.current);
                        }
                    }
                }
                else
                {
                    fnSetControlValue(lFormItemList, lDataFieldObjectMap, mFormActions.current);
                }
            }
        }
    );

    const fnProcessNotification = (lSelectedData, lActionItem) => {
        if(mNotification.enabled_ && ((mNotification.trigger_state_ === 'ALL') || (lActionItem.action_code_ === mNotification.trigger_state_))) {
            if(lSelectedData) {
                let lNotificationData = {};
                let lKeys = Object.keys(mNotification.params_);
                if(lKeys) {
                    for(let lkInd=0; lkInd<lKeys.length; lkInd++) {
                        let lKey = lKeys[lkInd];
                        if(lSelectedData.hasOwnProperty(mNotification.params_[lKey])) {
                            lNotificationData[lKey] = lSelectedData[mNotification.params_[lKey]];
                        }
                        else {
                            lNotificationData[lKey] = cfGetConstantValue(mNotification.params_[lKey]);
                        }
                    }
                }

                let lNotificationCriteria = {};
                let lcKeys = Object.keys(mNotification.criteria_);
                if(lcKeys) {
                    for(let lckInd=0; lckInd<lcKeys.length; lckInd++) {
                        let lcKey = lcKeys[lckInd];
                        if(lSelectedData.hasOwnProperty(mNotification.criteria_[lcKey])) {
                            lNotificationCriteria[lcKey] = lSelectedData[mNotification.criteria_[lcKey]];
                        }
                        else {
                            lNotificationCriteria[lcKey] = cfGetConstantValue(mNotification.criteria_[lcKey]);
                        }
                    }
                }

                let lRequestUtil = null;
                if(mNotification.service_handler_ === 'CS_NOTFN') {
                    if(mNotification.event_service_code_ && lNotificationData) {
                        let lServiceUtil = CSServiceUtil.cfGetInstance();
                        let lServiceObject = lServiceUtil.cfGetServiceObject(mNotification.event_service_code_);
                        if(lServiceObject) {
                            lRequestUtil = new CSRequestUtil('SAVE', mNotification.event_service_code_, lServiceObject.type_, null, lServiceObject);
                            lRequestUtil.cfSetRequestData(lNotificationData);
                            lRequestUtil.cfProcessRequest();
                        }
                    }
                }
                else {
                    lRequestUtil = new CSRequestUtil();
                    lRequestUtil.cfNotification(mNotification.type_, mNotification.category_, '', mNotification.contact_service_code_, lNotificationCriteria, lNotificationData, (lResult)=> {console.log(lResult)});
                }
            }
        }
    }

    useEffect(() =>
        {
            fnSetControlValue(mFormItems.current, mDataFieldObjectMap, mFormActions.current);
            setDataChange(true);
        }, [mDataChange, mDataFieldObjectMap, mFormData]
    );

    const fnSetControlValue = (lFormItems, lDataFieldObjectMap, lSaveActionItems) => {
        if(!lFormItems) {
            lFormItems = mFormItems.current;
        }

        if(!lDataFieldObjectMap) {
            lDataFieldObjectMap = mDataFieldObjectMap;
        }

        if(lFormItems && lDataFieldObjectMap) {
            for(let lInd=0; lInd<lFormItems.length; lInd++) {
                let lFormItem = lFormItems[lInd];
                let lFormData = lDataFieldObjectMap[lFormItem.data_field_];
                if(lFormData) {
                    if(lFormItem.hasOwnProperty('cs_form_item_properties_')){
                        let lProperties = lFormItem.cs_form_item_properties_;
                        let lSequence = '0';
                        let lRequired = '0';
                        let lEditable = '0';
                        let lVisible = '0';
                        for(let lPInd=0; lPInd<lProperties.length; lPInd++) {
                            let lProperty = lProperties[lPInd];
                            if(lProperty.type_ === 'form_item') {
                                if(lProperty.property_ === 'visible') {
                                    if(lFormData.hasOwnProperty(lProperty.selection_property_)) {
                                        if(lFormData[lProperty.selection_property_] === cfGetConstantValue(lProperty.selection_value_)) {
                                            lFormItem.visible_ = lProperty.value_;
                                            lVisible = lProperty.value_;
                                        }
                                        else {
                                            lFormItem.visible_ = lVisible;
                                        }
                                    }
                                }
                            
                                if(lProperty.property_ === 'sequence') {
                                    if(lFormData.hasOwnProperty(lProperty.selection_property_)) {
                                        if(lFormData[lProperty.selection_property_] === cfGetConstantValue(lProperty.selection_value_)) {
                                            lFormItem.sequence_ = lProperty.value_;
                                            lSequence = lProperty.value_;
                                        }
                                        else {
                                            lFormItem.sequence_ = lSequence;
                                        }
                                    }
                                }
                            
                                if(lProperty.property_ === 'required') {
                                    if(lFormData[lProperty.selection_property_] === cfGetConstantValue(lProperty.selection_value_)) {
                                        lFormItem.required_ = lProperty.value_;
                                        lRequired = lProperty.value_;
                                    }
                                    else {
                                        lFormItem.required_ = lRequired;
                                    }
                                }
                            
                                if(lProperty.property_ === 'editable') {
                                    if(lFormData[lProperty.selection_property_] === cfGetConstantValue(lProperty.selection_value_)) {
                                        lFormItem.editable_ = lProperty.value_;
                                        lEditable = lProperty.value_;
                                    }
                                    else {
                                        lFormItem.editable_ = lEditable;
                                    }
                                }
                            }
                        }
                    }

                    if(lFormItem.constant_ && ((lFormItem.control_type_ === 'constant') || (lFormItem.control_type_ === 'text_input'))) {
                        if(lFormData[lFormItem.data_field_]) {
                            lFormItem.mValue = lFormData[lFormItem.data_field_];
                        }
                        else {
                            lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                            lFormData[lFormItem.data_field_] = lFormItem.mValue;
                        }
                    }
                    else if(lFormItem.control_type_ === 'check_box') {
                        lFormItem.mValue = (lFormData[lFormItem.data_field_] === lFormItem.constant_) ? '1' : '0';
                    }
                    else if(lFormItem.control_type_ === 'multi_select_combo_box') {
                        if(lFormItem.cs_form_item_properties_) {
                            for(let lfipInd=0; lfipInd<lFormItem.cs_form_item_properties_.length; lfipInd++) {
                                let lfipObj = lFormItem.cs_form_item_properties_[lfipInd];
                                if(lfipObj.type_ === 'data_selection') {
                                    if(lfipObj.property_ === 'selection_key_') {
                                        if(lFormData.hasOwnProperty(lfipObj.selection_property_) && (lFormData[lfipObj.selection_property_] instanceof Array) && lfipObj.selection_value_) {
                                            let lDataCol = lFormData[lfipObj.selection_property_];
                                            let lDataVal = null;
                                            for(let lInd=0; lInd<lDataCol.length; lInd++) {
                                                if(lInd === 0) {
                                                    lDataVal = "'" + lDataCol[lInd][lfipObj.selection_value_] + "'";
                                                }
                                                else {
                                                    lDataVal = lDataVal + ", '" + lDataCol[lInd][lfipObj.selection_value_] + "'";
                                                }
                                            }

                                            lFormItem.pData = lDataVal;
                                            lFormData[lFormItem.data_field_] = lDataVal;
                                        }
                                        else
                                        {
                                            lFormItem.pData = lFormData[lfipObj.selection_property_];
                                        }

                                        lFormItem.pSelectionKey = lfipObj.selection_value_;
                                    }
                                }
                            }
                        }
                        else {
                            lFormItem.mValue = lFormData[lFormItem.data_field_];
                        }
                        
                        mFormUtil.cfSetDependentFieldData(lFormItem, mIdFormItemMap.current);
                    }
                    else {
                        if(!lFormItem.hasOwnProperty('is_dependent_item_')) {
                            if(lFormItem.dependent_id_ && mFormUtil) {
                                if(lFormItem.control_type_ === 'combo_box') {
                                    if(lFormItem.data_provider_source_ === '#COLLECTION#') {
                                        lFormItem.mDataProvider = mDataCollection;
                                    }

                                    lFormItem.mValue = lFormData[lFormItem.data_field_];
                                }
                                else {
                                    lFormData[lFormItem.data_field_] = lFormItem.mValue;
                                }
                            }
                            else {
                                if(lFormItem.hasOwnProperty('mClearSelection') && (lFormItem.mClearSelection === true)) {
                                    lFormItem.mValue = null;
                                }
                                else {
                                    if(lFormItem.control_type_ === 'combo_box') {
                                        if(lFormItem.data_provider_source_ === '#COLLECTION#') {
                                            lFormItem.mDataProvider = mDataCollection;
                                        }

                                        if(lFormItem.mValue) {
                                            lFormData[lFormItem.data_field_] = lFormItem.mValue;
                                        }
                                        else if(lFormItem.constant_) {
                                            lFormData[lFormItem.data_field_] = cfGetConstantValue(lFormItem.constant_);
                                        }
                                    }

                                    if(!lFormData[lFormItem.data_field_]) {
                                        lFormItem.mValue = lFormItem.mCriteriaValue;
                                        lFormData[lFormItem.data_field_] = lFormItem.mValue;
                                    }
                                    else {
                                        lFormItem.mValue = lFormData[lFormItem.data_field_];
                                    }
                                }
                            }

                            mFormUtil.cfSetDependentFieldData(lFormItem, mIdFormItemMap.current);
                        }
                        else {
                            lFormData[lFormItem.data_field_] = lFormItem.mValue;
                        }

                        let lCompData = CSFormUtil.cfGetComputedFieldsData(lFormData, mFormItems.current, mIdFormItemMap.current);
                        if(lCompData) {
                            let lKeys = Object.keys(lCompData);
                            for(let lKind=0; lKind<lKeys.length; lKind++) {
                                let lKey = lKeys[lKind];
                                if(lFormData.hasOwnProperty(lKey)) {
                                    lFormData[lKey] = lCompData[lKey];
                                }
                            }
                        }
                    }
                }
                else
                {
                    if(!lFormItem.mCriteriaValue) {
                        lFormItem.mValue = '';
                    }
                    else {
                        lFormItem.mValue = lFormItem.mCriteriaValue;
                    }                        
                }
            }
        }

        if(lSaveActionItems) {
            for(let lInd1=0; lInd1<lSaveActionItems.length; lInd1++) {
                let lSaveActionItem = lSaveActionItems[lInd1];
                fnEnableSave(lSaveActionItem, lFormItems, lDataFieldObjectMap);
            }    
        }
    }

    const fnEnableSave = (lSaveActionItem, lFormItems, lDataFieldObjectMap) => {
        if(lSaveActionItem && lFormItems && lDataFieldObjectMap) {
            if(lSaveActionItem.action_type_ === '1') {
                let lEnable = true;
                for(let lFiInd0=0; lFiInd0< lFormItems.length; lFiInd0++) {
                    let lFormItem0 = lFormItems[lFiInd0];
                    let lDataObj = lDataFieldObjectMap[lFormItem0.data_field_];
                    if(lSaveActionItem.cs_form_action_properties_) {
                        let lIsEnabled = true;
                        for(let lFapInd0 = 0; lFapInd0<lSaveActionItem.cs_form_action_properties_.length; lFapInd0++) {
                            let lFapObj0 = lSaveActionItem.cs_form_action_properties_[lFapInd0];
                            if((lFapObj0.type_ === 'item_selection') && (lFapObj0.selection_property_) && (lFapObj0.selection_value_)) {
                                if(lDataObj && lDataObj.hasOwnProperty(lFapObj0.selection_property_)) {
                                    let lSelectedProperty = lDataObj[lFapObj0.selection_property_];
                                    if(lFapObj0.selection_value_ === '#NOT_NULL#') {
                                        if(lSelectedProperty) {
                                            lIsEnabled = (lFapObj0.value_ === 'true');
                                        }
                                        else {
                                            lIsEnabled = !(lFapObj0.value_ === 'true');
                                        }
                                    }
                                    else if(lFapObj0.selection_value_ === '#NULL#') {
                                        if(!lSelectedProperty) {
                                            lIsEnabled = (lFapObj0.value_ === 'true');
                                        }
                                        else {
                                            lIsEnabled = !(lFapObj0.value_ === 'true');
                                        }
                                    }
                                    else if(lFapObj0.selection_value_ === '#POSITIVE#') {
                                        if(lFapObj0.value_ === 'true') {
                                            lIsEnabled = (Number(lSelectedProperty) > 0) ? true : false;
                                        }
                                        else {
                                            lIsEnabled = (Number(lSelectedProperty) > 0) ? false : true;
                                        }
                                    }
                                    else {
                                        if(lFapObj0.selection_value_) {
                                            if(lSelectedProperty) {
                                                let lEqual0 = (lSelectedProperty === lFapObj0.selection_value_);
                                                if(lEqual0) {
                                                    if(lFapObj0.value_ === 'true') {
                                                        lIsEnabled = true;
                                                    }
                                                    else {
                                                        lIsEnabled = false;
                                                    }
                                                }
                                                else {
                                                    if(lFapObj0.value_ === 'true') {
                                                        lIsEnabled = false;
                                                    }
                                                    else {
                                                        lIsEnabled = true;
                                                    }
                                                }
                                            }
                                            else {
                                                lIsEnabled = false;
                                            }
                                        }
                                    }
                                }
                            }

                            if(lFapInd0 === 0) {
                                lEnable = lIsEnabled;
                            }
                            else {
                                lEnable = lEnable && lIsEnabled;
                            }
                        }
                                    
                        if(!lEnable) {
                            break;
                        }
                    }

                    if(lFormItem0.required_ === '1') {
                        if(lDataObj && lEnable) {
                            if(!lDataObj[lFormItem0.data_field_]) {
                                if(lFormItem0.constant_ && (lFormItem0.control_type_ !== 'file')) {
                                    lDataObj[lFormItem0.data_field_] = cfGetConstantValue(lFormItem0.constant_);
                                    lEnable = true;
                                }
                                else {
                                    lEnable = false;
                                    break;
                                }
                            }
                            else if((lDataObj[lFormItem0.data_field_] instanceof Array) && (lDataObj[lFormItem0.data_field_].length === 0)) {
                                lEnable = false;
                                break;
                            }
                        }
                        else {
                            lEnable = false;
                            break;
                        }
                    }
                }

                lSaveActionItem.mEnabled = lEnable;
            }
            else
            {
                lSaveActionItem.mEnabled = (lSaveActionItem.enabled_ === '1') ? true : false;
            }
        }
    }

    const cfSetFormData = (lCollection) => {
        if(gProps)
        {
            let lCSUtil = CSUtil.cfGetInstance();
            let lMenuItem = lCSUtil.cfGetMenuItem(gProps.pFeatureRefId);
            if(lMenuItem) {
                let lSearchActionsList = [];
                let lFormActions = gProps.pFormActions;
                if(lFormActions) {
                    for(let ind=0; ind<lFormActions.length; ind++) {
                        if(lFormActions[ind].component_type_ === 'SEARCH_ACTION') {
                            lSearchActionsList.push(lFormActions[ind]);
                        }
                    }
                }

                setSearchActionList(lSearchActionsList);
                setDataCollection(lCollection);
            }
        }
    }

    const cfSearch = (lServiceCode, lCriteria) => {
        if(lServiceCode) {
            let lServiceObject = mServiceUtil.cfGetServiceObject(lServiceCode);
            if(lServiceObject) {
                let lCriteriaObj = (lCriteria) ? lCriteria : {};
                let lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, null, lServiceObject);
                if(lServiceObject.hasOwnProperty('cs_service_properties_')) {
                    let lServiceProperties = lServiceObject.cs_service_properties_;
                    if(lServiceProperties) {
                        for(let lInd=0; lInd<lServiceProperties.length; lInd++) {
                            lCriteriaObj[lServiceProperties[lInd].property_] = cfGetConstantValue(lServiceProperties[lInd].value_);
                        }
                    }
                }
                
                lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
                lRequestUtil.cfProcessRequest((lResponse) => {
                        let lCollection = [];
                        if(lResponse && lResponse.hasOwnProperty("result") && lResponse.result.hasOwnProperty("collection_")) {
                            if(lResponse.result.collection_) {
                                if(lResponse.result.collection_ instanceof Array) {
                                    lCollection = lResponse.result.collection_;
                                }
                                else {
                                    lCollection.push(lResponse.result.collection_);
                                }
                            }
                        }
                
                        cfSetFormData(lCollection);
                    }
                );
            }
        }
    }

    useEffect(()=>
        {
            if(mNewRecord) {
                let lCollection = mDataCollection;
                if(!lCollection) {
                    lCollection = [];
                }

                lCollection.push(mNewRecord);
                setDataCollection(lCollection);
                setDataChange(!mDataChange);
            }
        }, [mNewRecord]
    );

    const fnAddToCollection = (lRecord, lCloseFlag) => {
        if(lRecord) {
            setNewRecord(lRecord);
        }

        if(lCloseFlag) {
            fnCloseForm();
        }
    }

    const fnUpdateCollection = (lRecord, lCloseFlag) => {
        if(lRecord) {
            setSelectedRecord(lRecord);
        }

        if(lCloseFlag) {
            fnCloseForm();
        }
    }

    const fnRemoveFromCollection = () => {
    }

    const cfCloseForm = (lDetailsFlag) => {
        setDetailsFlag(lDetailsFlag);        
        if(gProps.fnProcessClose) {
            gProps.fnProcessClose();
        }
    }

    const cfOnDataSelection = (sel_data) => {
        if(sel_data) {
            setSelectedRecord(sel_data);
        }
    }

    const fnProcessChange = (lData) => {
        if(lData && mDataFieldObjectMap && lData.hasOwnProperty('data_field_') && lData.data_field_) {
            let lDColumns = null;
            let lDataObj = mDataFieldObjectMap[lData.data_field_];
            if(lDataObj) {
                if(lData.hasOwnProperty('data_object_')) {
                    let lDataObj1 = lDataObj[lData.data_object_];
                    if(!lDataObj1) {
                        lDataObj1 = {}
                        lDataObj[lData.data_object_] = lDataObj1;
                    }

                    lDataObj1[lData.data_field_] = lData.value_;
                }
                else {
                    if(lData.control_type_ !== 'check_box') {
                        lDataObj[lData.data_field_] = lData.value_;
                    }
                }

                let lChangedFormItem = mIdFormItemMap.current[lData.id_];
                if(lChangedFormItem) {
                    if(lData.hasOwnProperty('selected_data_')) {
                        lChangedFormItem.selected_data_ = lData.selected_data_;
                    }

                    if(lChangedFormItem.control_type_ === 'constant') {
                        lDataObj[lChangedFormItem.data_field_] = cfGetConstantValue(lChangedFormItem.constant_);
                        lChangedFormItem.mValue = cfGetConstantValue(lChangedFormItem.constant_);
                    }
                    else if(lChangedFormItem.control_type_ === 'check_box') {
                        if(lData.control_type_ === 'check_box') {
                            lDataObj[lData.data_field_] = (lData.value_ === '1') ? lChangedFormItem.constant_ : '';
                            lChangedFormItem.mValue = lData.value_;
                        }        
                    }
                    else if(lData.control_type_ === 'file') {
                        lChangedFormItem.mValue = lData.value_;
                        lChangedFormItem.mFileList = lData.file_list_;
                    }
                    else {
                        lChangedFormItem.mValue = lData.value_;
                    }

                    lDataObj = CSFormUtil.cfSetDependentFieldsData(mFormItems, lDataObj, lChangedFormItem, lData.selected_data_, mIdFormItemMap.current);
                    let lCompData = CSFormUtil.cfGetComputedFieldsData(lDataObj, gProps.pFormItems, mIdFormItemMap, mFormData);
                    if(lCompData) {
                        let lKeys = Object.keys(lCompData);
                        for(let lKind=0; lKind<lKeys.length; lKind++) {
                            let lKey = lKeys[lKind];
                            lDataObj[lKey] = lCompData[lKey];
                        }
                    }

                    if(lChangedFormItem.hasOwnProperty('cs_form_item_properties_')) {
                        let lServiceCode = null;
                        let lCriteria =  null;
                        let lProperties = lChangedFormItem.cs_form_item_properties_;
                        let lDynamicColumns = null;
                        for(let lpInd=0; lpInd<lProperties.length; lpInd++) {
                            let lProperty = lProperties[lpInd];
                            if(lProperty.type_ === 'filter_collection') {
                                if(lProperty.property_ && (lProperty.property_ === 'service_code_')) {
                                    lServiceCode = lProperty.value_;
                                }
                            }
                            else if(lProperty.type_ === 'dynamic_column') {
                                if(lProperty.property_ && lProperty.value_) {
                                    if(!lDynamicColumns) {
                                        lDynamicColumns = {}
                                    }

                                    let lDynamicColumnObj = lDynamicColumns[lProperty.cs_form_item_ref_id_];
                                    if(!lDynamicColumnObj) {
                                        lDynamicColumnObj = {}
                                        lDynamicColumns[lProperty.cs_form_item_ref_id_] = lDynamicColumnObj;
                                    }

                                    lDynamicColumnObj[lProperty.property_] = lProperty.value_;
                                }
                            }
                            else if(lProperty.type_ === 'criteria') {
                                if(lProperty.property_ && lProperty.selection_property_ && lProperty.selection_value_) {
                                    if(!lCriteria) {
                                        lCriteria = {}
                                    }
                    
                                    if(lProperty.selection_property_) {
                                        if(lProperty.property_ === 'control') {
                                            lCriteria[lProperty.selection_property_] = lData.value_;
                                        }
                                        else if(lProperty.property_ === 'constant') {
                                            lCriteria[lProperty.selection_property_] = cfGetConstantValue(lProperty.selection_value_);
                                        }
                                        else if(lDataObj && (lProperty.property_ === 'form_data')) {
                                            lCriteria[lProperty.selection_property_] = lDataObj[lProperty.selection_value_];
                                        }
                                        else if(lDataObj && (lProperty.property_ === 'concat_data') && lProperty.dependent_item_ref_id_) {
                                            let lDepDataField = null;
                                            let lDependentFormItem = this.state.mIdFieldObjectMap[lProperty.dependent_item_ref_id_];
                                            if(lDependentFormItem) {
                                                lDepDataField = lDependentFormItem.reference_field_;
                                                if(lDepDataField) {
                                                    let lFiData = lDataObj[lProperty.selection_value_];
                                                    if(lFiData instanceof Array) {
                                                        let lFidataVal = null;
                                                        for(let lfidIn=0; lfidIn<lFiData.length; lfidIn++) {
                                                            if(!lFidataVal) {
                                                                lFidataVal = "'" + lFiData[lfidIn][lDepDataField] + "'";
                                                            }
                                                            else {
                                                                lFidataVal = lFidataVal + ", '" + lFiData[lfidIn][lDepDataField] + "'";
                                                            }
                                                        }
                    
                                                        lCriteria[lProperty.selection_property_] = lFidataVal;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        if(lDynamicColumns && lDataObj && lFormGrid) {
                            let lKeyList = Object.keys(lDynamicColumns);
                            if(lKeyList) {
                                let lGridColumn0 = lFormGrid[0];
                                for(let lkInd=0; lkInd<lKeyList.length; lkInd++) {
                                    let lDynColObj = lDynamicColumns[lKeyList[lkInd]];
                                    let lFItem = this.state.mIdFieldObjectMap[lKeyList[lkInd]];
                                    if(lFItem && lDynColObj) {
                                        let lFiData = lDataObj[lFItem.data_field_];
                                        if(lFiData instanceof Array) {
                                            for(let lfidInd=0; lfidInd<lFiData.length; lfidInd++) {
                                                let lFiObj = lFiData[lfidInd];
                                                let lGridColumn = {}
                                                lGridColumn.id_ = Date.now();
                                                lGridColumn.action_ref_id_          = lGridColumn0.action_ref_id_;
                                                lGridColumn.app_code_               = lGridColumn0.app_code_;
                                                lGridColumn.app_id_                 = lGridColumn0.app_id_;
                                                lGridColumn.cs_form_code_           = lGridColumn0.cs_form_code_;
                                                lGridColumn.type_                   = lGridColumn0.type_;
                                                lGridColumn.cs_form_component_code_ = lGridColumn0.cs_form_component_code_;
                                                lGridColumn.column_name_            = lFiObj[lDynColObj.heading];
                                                lGridColumn.data_field_             = lDynColObj.data_field;
                                                lGridColumn.visible_                = '1';
                                                lGridColumn.editable_               = lGridColumn0.editable_;
                                                lGridColumn.width_                  = '-1';
                                                lGridColumn.height_                 = '-1';
                                                if(!lDColumns) {
                                                    lDColumns = [];
                                                }
                    
                                                lGridColumn.sequence_ = lFormGrid.length + 1;
                                                lDColumns.push(lGridColumn);
                                            }
                                        }
                                    }
                                }
                            }
                        }                        

                        if(lServiceCode && lCriteria) {
                            let lServiceObject = mServiceUtil.cfGetServiceObject(lServiceCode);
                            if(lServiceObject) {                    
                                let lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, null, lServiceObject);     
                                let lCriteriaObj = (lCriteria) ? lCriteria : {};
                                if(lServiceObject.hasOwnProperty('cs_service_properties_')) {
                                    let lServiceProperties = lServiceObject.cs_service_properties_;
                                    if(lServiceProperties !== null) {
                                        for(let lInd=0; lInd<lServiceProperties.length; lInd++) {
                                            lCriteriaObj[lServiceProperties[lInd].property_] = cfGetConstantValue(lServiceProperties[lInd].value_);
                                        }
                                    }
                                }
                                    
                                setBackdrop(true);
                                lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
                                lRequestUtil.cfProcessRequest((lResponse)=>
                                    {
                                        let lCollection = [];
                                        if(lResponse) {
                                            if (lResponse.hasOwnProperty("result") && lResponse.result.hasOwnProperty("collection_")) {
                                                if(lResponse.result.collection_ instanceof Array) {
                                                    lCollection = lResponse.result.collection_;
                                                }
                                                else {
                                                    lCollection.push(lResponse.result.collection_);
                                                }
                                            }
                                            else if(lResponse.collection_) {
                                                if(lResponse.collection_ instanceof Array) {
                                                    lCollection = lResponse.collection_;
                                                }
                                                else {
                                                    lCollection.push(lResponse.collection_);
                                                }
                                            }
                                        }
                                        
                                        let lParentObj = null;
                                        if(lCollection && mFormGrid)
                                        {
                                            for(let lcInd=0 ; lcInd<lCollection.length; lcInd++) {
                                                let lcObj = lCollection[lcInd];
                                                for(let lgInd=0; lgInd<mFormGrid.length; lgInd++) {
                                                    let lColumn = mFormGrid[lgInd];
                                                    if(lColumn.data_field_) {
                                                        if(!lParentObj && lColumn.parent_id_) {
                                                            lParentObj = mIdFormItemMap[lColumn.parent_id_];
                                                        }
                    
                                                        if(!lcObj.hasOwnProperty(lColumn.data_field_)) {
                                                            lcObj[lColumn.data_field_] = '';
                                                        }
                                                    }
                                                }
                                            }
                                        }
                    
                                        let lFormData = mFormData;
                                        if(lParentObj && lFormData) {
                                            lFormData[lParentObj.data_field_] = lCollection;
                                        }
                    
                                        setDataCollection(lCollection);
                                        setFormData(lFormData);
                                    }
                                );
                                
                                setBackdrop(false);
                            }                    
                        }                        
                    }
                }

                setDataChange(!mDataChange);
            }
        }
    }

    useEffect(()=>
        {
            const fnProcessSave = () => {
                let lActionItem = mSaveActionItem;
                if(lActionItem) {
                    mFormUtil.cfPromptSave(lActionItem, (lResult) =>{
                            if(lResult && lResult.value) {
                                let lRequestData = null;
                                let lStatus = null;
                                let lStatusDataField = null;
                                let lFileUploadRetVal = true;
                                if(gProps.pFormItems) {
                                    for(let lInd=0; lInd<gProps.pFormItems.length; lInd++) {
                                        let lFormItem = gProps.pFormItems[lInd];
                                        if(lFormItem.control_type_ === 'file') {
                                            if(lFormItem.hasOwnProperty('mFileList') && lFormItem.mFileList) {
                                                let lRequestUtil = new CSRequestUtil();
                                                lRequestUtil.cfUploadFile(lFormItem.mFileList, lFormItem.constant_, (lResponseData) => {
                                                        if(lResponseData === -1) {
                                                            lFileUploadRetVal = false;
                                                        }
                                                    }
                                                )
                                            }
                                        }

                                        if(lFileUploadRetVal) {
                                            if(lFormItem.action_ref_id_ === lActionItem.parent_ref_id_) {
                                                if(lFormItem.hasOwnProperty('cs_form_item_properties_')) {
                                                    let lRequestObj = mFormUtil.fnGetRequestObject(lFormItem, mDataCollection, mFormData, lRequestData, mIdFormItemMap.current, lActionItem);
                                                    if(lRequestObj) {
                                                        if(!lRequestData) {
                                                            lRequestData = {}
                                                        }
                                
                                                        lRequestData[lFormItem.data_field_] = lRequestObj;
                                                    }
                                                }
                                                else if(lFormItem.control_type_ === 'state') {
                                                    lStatus = lActionItem.action_code_;
                                                    lStatusDataField = lFormItem.data_field_;
                                                }
                                            }
                                        }
                                    }
                                }

                                if(lFileUploadRetVal) {
                                    if(!lRequestData) {
                                        if(mFormData && mFormData.hasOwnProperty(mDefaultDataField.current) && mFormData[mDefaultDataField.current]) {
                                            lRequestData = mFormData[mDefaultDataField.current];
                                        }
                                        else {
                                            lRequestData = mFormData;
                                        }

                                        if(lStatus){
                                            lRequestData[lStatusDataField] = lStatus;
                                        }
                                    }

                                    let lCriteria = null;
                                    for (let lInd=0; lInd<mFormItems.current.length; lInd++) {
                                        let lFormItem = mFormItems.current[lInd];
                                        if(lFormItem.criteria_field_ && (lFormItem.criteria_field_ === 'Y')) {
                                            if(!lCriteria) {
                                                lCriteria = {}
                                            }

                                            lCriteria[lFormItem.data_field_] = lFormItem.mValue;
                                        }
                                    }

                                    setBackdrop(true);
                                    mFormUtil.cfProcessSave(lActionItem, lRequestData, 
                                        (lResponse, lResponseData, lReqData) => {
                                            setBackdrop(false);
                                            let lCode = null;
                                            let lMsg = null;
                                            if(lResponse && lResponse.code) {
                                                if(lResponse.code instanceof Array) {
                                                    lCode = lResponse.code[0];
                                                    if(lResponse.message) {
                                                        lMsg = lResponse.message[0];
                                                    }
                                                }
                                                else {
                                                    lCode = lResponse.code;
                                                    if(lResponse.message) {
                                                        lMsg = lResponse.message;
                                                    }
                                                }
                                            }
                                            else {
                                                lCode = -1;
                                            }
            
                                            let lPostSaveData = mFormUtil.cfProcessSaveResponse(lCode, lMsg, lActionItem, lResponse)
                                            if((lCode === 1) || (lCode === '1')) {
                                                let lSelectedData = null;
                                                if(mFormData) {
                                                    lSelectedData = mFormData['selected_data_'];
                                                }

                                                let lNavigateToForm = lActionItem.navigate_to_form_;
                                                if( lNavigateToForm && lPostSaveData && mFormData) {
                                                    let lSelectedCriteria = null;
                                                    let lDetailsMetaData = {
                                                        pFormType           : lActionItem.navigate_to_form_component_code_,
                                                        pFormHeading        : lActionItem.title_,
                                                        pFormActions        : mFormUtil.cfGetChildActionItems(gProps.pFormActions, lActionItem.id_),
                                                        pFormItems          : mFormUtil.cfGetFormItems(gProps.pFormItems, lActionItem.id_),
                                                        pFormProperties     : gProps.pFormProperties,
                                                        pSelectedActionItem : lActionItem,
                                                        pSelectedData       : lSelectedData,
                                                        pSelectedCriteria   : lSelectedCriteria,
                                                        pCriteria           : lSelectedCriteria,
                                                        fnProcessClose      : fnCloseForm,
                                                        pProcessClose       : fnProcessClose,
                                                        pParentFlag         : true
                                                    };

                                                    for(let lFv_0=0; lFv_0<lPostSaveData.length; lFv_0++) {
                                                        let lP1Obj = lPostSaveData[lFv_0];
                                                        if(lP1Obj.data_field_1_type_) {
                                                            if(lP1Obj.data_field_1_type_ === 'data') {
                                                                if(lP1Obj.data_object_ && mFormData.hasOwnProperty(lP1Obj.data_object_)) {
                                                                    lSelectedData = mFormData[lP1Obj.data_object_]
                                                                }
                                                            }
                                                        }

                                                        if(lP1Obj.data_field_1_type_ === 'data') {
                                                            if(lP1Obj.data_object_ && mFormData.hasOwnProperty(lP1Obj.data_object_)) {
                                                                lDetailsMetaData[lP1Obj.data_field_1_] = mFormData[lP1Obj.data_object_]
                                                            }
                                                        }
                                                        else if(lP1Obj.data_field_1_type_ === 'collection') {
                                                            if(lP1Obj.data_object_ && mFormData.hasOwnProperty(lP1Obj.data_object_)) {
                                                                lDetailsMetaData[lP1Obj.data_field_1_] = mFormData[lP1Obj.data_object_]
                                                            }
                                                        }
                                                        else if(lP1Obj.data_field_1_type_ === 'request') {
                                                            if(lP1Obj.data_object_ && lReqData && lReqData.hasOwnProperty(lP1Obj.data_object_)){
                                                                lDetailsMetaData[lP1Obj.data_field_1_] = lReqData[lP1Obj.data_object_]
                                                            }
                                                        }
                                                        else if(lP1Obj.data_field_1_type_ === 'response') {
                                                            if(lP1Obj.data_object_ && lP1Obj.data_field_2_ && lResponseData.hasOwnProperty(lP1Obj.data_object_)) {                                                           
                                                                if(lDetailsMetaData.hasOwnProperty(lP1Obj.data_field_1_)) {
                                                                    let lSelData1 = lDetailsMetaData[lP1Obj.data_field_1_];
                                                                    let lRespVal = lResponseData[lP1Obj.data_object_];
                                                                    if(lRespVal && lRespVal.hasOwnProperty(lP1Obj.data_field_2_) && Array.isArray(lRespVal[lP1Obj.data_field_2_]))
                                                                    {
                                                                        lSelData1[lP1Obj.data_field_2_] = lRespVal[lP1Obj.data_field_2_][0];
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    if(lSelectedData) {
                                                        for(let lFv_1=0; lFv_1<lPostSaveData.length; lFv_1++) {    
                                                            let lP2Obj = lPostSaveData[lFv_1];
                                                            if(lP2Obj.data_field_1_type_ === 'criteria') {
                                                                if(lP2Obj.data_field_1_ && lSelectedData.hasOwnProperty(lP2Obj.data_field_1_))
                                                                {
                                                                    if(!lSelectedCriteria) {
                                                                        lSelectedCriteria = {}
                                                                    }

                                                                    lSelectedCriteria[lP2Obj.data_field_1_] = lSelectedData[lP2Obj.data_field_1_]
                                                                }
                                                            }
                                                        }
                                                    }

                                                    fnProcessNotification(lSelectedData, lActionItem);
                                                    setDetailsMetaData(lDetailsMetaData);
                                                    setDetailsFlag('DETAILS');
                                                }
                                                else {
                                                    fnProcessNotification(lSelectedData, lActionItem);
                                                    
                                                    let lCloseFlag = (lActionItem && (lActionItem.new_state_ === 'list')) ? true : false;
                                                    if(lCloseFlag)
                                                    {
                                                        gProps.fnProcessClose(lCloseFlag, lCloseFlag);
                                                    }
                                                }
                                            }
                                        }, lCriteria
                                    );
                                }
                            }
                        }
                    );
                }
            }

            fnProcessSave();
        }, [mSaveActionItem]
    );

    const fnCloseForm = (lCloseFlag) => {
        setDetailsFlag(lCloseFlag);
        setDetailsMetaData(null);      
    }

    const fnProcessClose = (lCloseFlag) => {
        if(lCloseFlag) {
            setDetailsFlag(lCloseFlag);
            setDetailsMetaData(null);

            if(gProps.fnProcessClose) {
                gProps.fnProcessClose(lCloseFlag);
            }
        }
        else {
            fnInitializeData.current(mFormItems, mDataFieldObjectMap, gProps, mFormData, mFormNameDatafieldMap);
        }
    }
        
    const fnProcessClick = (lEvent, lActionItem) => {
        if(lActionItem) {
            if((lActionItem.action_ === 'process_cancel') || (lActionItem.action_ === 'process_close')) {
                if(lActionItem.action_ === 'process_cancel') {
                    setDisplay(false);
                }
                else {
                    if(gProps.fnProcessClose) {
                        gProps.fnProcessClose();
                    }
                }
            }
            else if(lActionItem.action_ === 'process_save') {
                setSaveActionItem(lActionItem);
            }
            else if(lActionItem.action_ === 'generate_progress_card') {
                setSaveActionItem(lActionItem);
            }
            else if(lActionItem.action_ === 'process_update') {
                if(gProps.pSelectedActionItem && (gProps.pSelectedActionItem.action_ === 'process_add')) {
                    fnAddToCollection(gProps.pSelectedActionItem);
                }
                else if(gProps.pSelectedActionItem && (gProps.pSelectedActionItem.action_ === 'process_modify')) {
                    fnUpdateCollection(gProps.pSelectedActionItem);
                }
                else if(gProps.pSelectedActionItem && (gProps.pSelectedActionItem.action_ === 'process_delete')) {
                    fnRemoveFromCollection(gProps.pSelectedActionItem);
                }
            }
            else {
                let lFormItems = null;
                let lFormActions = null;
                if(gProps.hasOwnProperty('pFormMetaData')) {
                    if(gProps.pFormMetaData.hasOwnProperty('pFormItems')) {
                        lFormItems = gProps.pFormMetaData.pFormItems;
                    }

                    if(gProps.pFormMetaData.hasOwnProperty('pFormActions')) {
                        lFormActions = gProps.pFormMetaData.pFormActions;
                    }
                    else {
                        lFormActions = gProps.pFormActions;
                    }
                }
                else {
                    lFormItems = gProps.pFormItems;
                    lFormActions = gProps.pFormActions;
                }                    

                let lDetailsMetaData = {
                    pParent                 : this,
                    pFormType               : lActionItem.navigate_to_form_component_code_,
                    pFormHeading            : lActionItem.title_,
                    pFormActions            : mFormUtil.cfGetChildActionItems(lFormActions, lActionItem.id_),
                    pFormProperties         : gProps.pFormProperties,
                    pSelectedActionItem     : lActionItem,
                    fnProcessClose          : fnProcessClose,
                    pParentFlag             : true
                };

                if(lActionItem.component_type_ === "DETAILS_ITEM_SEARCH_ACTION") {
                    let lGridItems = []
                    for(let lInd=0; lInd<lFormItems.length; lInd++) {
                        if(lFormItems[lInd].grid_display_ === '1') {
                            lFormItems[lInd].details_action_ref_id_ = lActionItem.id_;
                            lGridItems.push(lFormItems[lInd]);
                        }
                    }
                    lDetailsMetaData.pFormItems = mFormUtil.cfGetFormItems(lGridItems, lActionItem.parent_ref_id_);
                }
                else {
                    lDetailsMetaData.pFormItems = mFormUtil.cfGetFormItems(lFormItems, lActionItem.parent_ref_id_);
                }

                if(lActionItem.action_ === 'process_add') {
                    lDetailsMetaData.pResponseFn = fnAddToCollection;
                    setDetailsFlag('ADD');
                }
                else if((lActionItem.action_ === 'process_modify') || (lActionItem.action_ === 'process_details')) {
                    lDetailsMetaData.pResponseFn = fnUpdateCollection;
                    setDetailsFlag('MODIFY');
                }

                setDetailsMetaData(lDetailsMetaData);
            }
        }
    }

    let lFormItemSection                = null;
    let lFormHeading                    = null;
    let lFormDetailsItemSearchActions   = null;
    let lGroupFields                    = [];
    let lTreeGrid                       = null;
    let lDetailsActions                 = null;
    let lDetailsForm                    = null;
    let lGroupFieldGridItemObjectMap    = {};

    if(gProps.pFormItems && (gProps.pFormItems.length > 0)) {
        let lFormItems = [];
        CSUtil.cfSort(gProps.pFormItems, 'sequence_');
        for(let lInd=0; lInd<gProps.pFormItems.length; lInd++) {
            let lFormItem = gProps.pFormItems[lInd];
            if(!lFormItem.parent_id_ && (lFormItem.visible_ === '1') && (lFormItem.control_type_ !== 'collection')) {
                lFormItems.push(lFormItem);
            }
            else if((lFormItem.control_type_ === 'collection'))
            {
                lFormHeading = lFormItem.display_label_;
            }

            if(lFormItem.hasOwnProperty('cs_form_item_properties_')) {
                for(let lfipInd=0; lfipInd<lFormItem.cs_form_item_properties_.length; lfipInd++) {
                    let lfipObj = lFormItem.cs_form_item_properties_[lfipInd];
                    if(lfipObj.property_ === 'grouping_field') {
                        lGroupFields.push(lFormItem.data_field_);
                        lGroupFieldGridItemObjectMap[lFormItem.data_field_] = lFormItem;
                    }
                }
            }
        }

        if(lFormItems.length > 0) {
            CSUtil.cfSort(lFormItems, 'sequence_');
            let lFISectionFields = mFormUtil.cfGetDetailsSections(lFormItems, mMinSectionItems, fnProcessChange, "form_criteria_label");
            if(lFISectionFields) {
                lFormItemSection = 
                    <CSCODetailsHeader
                        pFormItems          = { lFormItems }
                        pFormProperties     = { gProps.pFormProperties }
                        pProcessChange      = { fnProcessChange }/>
            }
        }
    }

    if(mFormItemSearchActions && (mFormItemSearchActions.length > 0)) {
        let lFormDetailsItemSearchActionList = mFormItemSearchActions.map((lActionItem) => 
            {
                if(lActionItem.action_type_ === '1') {
                    lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, mSelectedRecord);
                }
                else {
                    lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                }

                return lActionItem;
            }
        );

        lFormDetailsItemSearchActions = 
            <CSCOBaseActionBar
                align       = "right"
                pType       = 'SEARCH_ACTION_BAR'
                actionList  = {lFormDetailsItemSearchActionList}
            />;
    }

    let lFormGrid = [];
    if(mFormGrid) {
        let lFormCode = null;
        for(let lGInd=0; lGInd<mFormGrid.length; lGInd++) {
            let lGridItem = CSUtil.cfGetObjectCopy(mFormGrid[lGInd]);
            if(lGridItem.group_ === '1') {
                lGroupFields.push(lGridItem.data_field_);

                if(!lFormCode) {
                    lFormCode = lGridItem.cs_form_code_;
                }
            }

            lFormGrid.push(lGridItem);
        }

        let lKeyField = null;
        if((lGroupFields.length > 0) && (gProps.pFormProperties)) {
            for(let lInd_0=0; lInd_0 < gProps.pFormProperties.length; lInd_0++) {
                let lGridProperties = gProps.pFormProperties[lInd_0];
                if((lGridProperties.type_ === 'other') && (lGridProperties.group_ === 'group') && (lFormCode === lGridProperties.cs_form_code_) && (lGridProperties.property_ === 'key')) {
                  lKeyField = lGridProperties.value_;
                }
            }
        }

        if(mDataCollection && (mDataCollection.length > 0)) {
            for(let lkInd=0; lkInd<mDataCollection.length; lkInd++) {
                let ldObj = mDataCollection[lkInd];
                if(ldObj) {
                    ldObj['_key_'] = lkInd + 1;
                    ldObj.key = lkInd + 1;
                }
            }
        }

        let lGroupObj = null;
        if(lKeyField) {
            lGroupObj = mFormUtil.cfGetTreeCollection(mDataCollection, lGroupFields, lKeyField);
        }
        else {
            lGroupObj = mFormUtil.cfGetGroupedCollection(mDataCollection, lGroupFields, lGroupFieldGridItemObjectMap);
        }

        let lCollection = null;
        let lKeyObjectMap = null;
        if(lGroupObj) {
            lCollection = lGroupObj.collection;
            lKeyObjectMap = lGroupObj.keyObjectMap;
        }
            
        if(!lCollection){
            lCollection = mDataCollection;
        }
    
        if(!lCollection){
            lCollection = [];
        }
    
        lTreeGrid = 
            <CSCOTreeGrid 
                pData            = { lCollection }
                pKeyObjectMap    = { lKeyObjectMap }
                pColumns         = { lFormGrid }
                pGroupFields     = { lGroupFields }
                pageSize         = { 15 }
                pHeading         = { lFormHeading }
                pExpandAll       = { true }
                pSearchActionBar = { lFormDetailsItemSearchActions }
                onClick          = { cfOnDataSelection }
            />
    }

    let lHeaderActions = 
        <div style={{verticalAlign: 'middle'}}>
            <Button
                key = "21"
                style=
                    {
                        {
                            verticalAlign   : 'middle'
                        }
                    } 
                type="Button" 
                icon={CSUtil.mNameIconMap['PI_TIMES']}
                onClick= { () => cfCloseForm(false) }
            />
        </div>

    let lHeader = 
        <div className='flex justify-content-between'>
            <div className="p-toolbar-group-left">
                <h2>{ gProps.pFormHeading || '.' }</h2>
            </div>

            <div className="p-toolbar-group-right">
                { lHeaderActions }
            </div>
        </div>

    if(mFormDetailsActions && (mFormDetailsActions.length > 0)) {
        let lFormDetailsItemActionsList = mFormDetailsActions.map((lDetailsActionItem) => 
            {
                if(lDetailsActionItem.action_type_ === '1') {
                    lDetailsActionItem.mEnabled = CSFormUtil.cfEnableAction(lDetailsActionItem, mSectionData);
                    if(lDetailsActionItem.mEnabled) {
                        if(mCollectionFormItem && (mCollectionFormItem.required_ === '1')) {
                            lDetailsActionItem.mEnabled = (mDataCollection && (mDataCollection.length > 0));
                        }
                    }
                }
                else {
                    lDetailsActionItem.mEnabled = (lDetailsActionItem.enabled_ === '1') ? true : false;
                }

                return lDetailsActionItem;
            }
        );

        lDetailsActions = 
            <CSCOHorizontalActionBar 
                align       = "right"
                pType       = 'DETAILS_ACTION_BAR'
                parent      = {this}
                actionList  = {lFormDetailsItemActionsList}
            />
    }

    if(mDetailsFlag) {
        let lDetailsMetaData = mDetailsMetaData;
        if(lDetailsMetaData && lDetailsMetaData.hasOwnProperty('pFormType')) {
            lDetailsMetaData.pDataCollection = mDataCollection;
                
            if(lDetailsMetaData.pFormItems) {
                for(let lInd=0; lInd<lDetailsMetaData.pFormItems.length; lInd++) {
                    let lItem = lDetailsMetaData.pFormItems[lInd];
                    if(lItem.hasOwnProperty('cs_form_item_properties_')) {
                        for (let lfipInd=0; lfipInd<lItem.cs_form_item_properties_.length; lfipInd++) {
                            let lProperty = lItem.cs_form_item_properties_[lfipInd];
                            if(lProperty.type_ === 'child') {
                                let lRequired = '0';
                                let lEditable = '0';

                                if((lProperty.selection_property_ === 'details_flag' ) && (lProperty.selection_value_ === mDetailsFlag)) {
                                    if(lProperty.property_ === 'required') {
                                        lRequired = (lProperty.value_ === 'true') ? '1' : '0';
                                    }
    
                                    if(lProperty.property_ === 'editable') {
                                        lEditable = (lProperty.value_ === 'true') ? '1' : '0';
                                    }
                                }

                                lItem.required_ = lRequired;
                                lItem.editable_ = lEditable;
                            }
                        }
                    }
                }
            }

            let lDetailsData = (mDetailsFlag === 'ADD') ? null : mSelectedRecord;
            lDetailsForm = fnGetForm(lDetailsMetaData, lDetailsData, fnCloseForm);
        }
    }

    return(
        <Dialog visible={mDisplay}
                modal={true}
                style = { {
                        minheight     : 'auto', 
                        paddingBottom : -40,
                        maxWidth      : '1200px'
                    }
                }
                appendTo    = { document.body }
                maximizable = { gProps.pMaximizable }
                header      = { lHeader }
                closable    = { false }
                padding     = '5px'
                onHide      = { () => cfCloseForm(false) }
                footer      = { lDetailsActions }
            >
            <CSCOBackdrop pShow = {mShowBackdrop}/>
            { lFormItemSection }
            { lTreeGrid }
            { lDetailsForm }
        </Dialog>
    );
}

export default CSCOAdvancedDetailsForm3;