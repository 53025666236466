import React, { Component } from "react";
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
//import { QRCode } from "react-qr-svg";
import { Calendar } from "primereact/calendar";
import {fnGetDateToString, fnGetTimeString} from "../util/CSDateTimeUtil";

import CSUtil from "../util/CSUtil"
import CSFormUtil from "../util/CSFormUtil";
import CSServiceUtil from '../util/CSServiceUtil'
import CSCOPrint from "./CSCOPrint";
import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";
import CSCOColumnChooser from "./CSCOColumnChooser";
import { cfGetConstantValue } from "../util/CSConstantUtil";
import CSConfigUtil from "../util/CSConfigUtil";
import { Button } from "primereact/button";

class CSCOGrid extends Component 
{
    mPaginatorHeight        = 15;
    mDataTableHeaderHeight  = 25;
    mScrollableHeaderHeight = 25;
    mSelectionMode          = 'single';
    mCheckboxRenderer       = '';
    mKeyObjectMap           = {}
 
    constructor(props) 
    {
        super(props);
        this.mServiceUtil       = CSServiceUtil.cfGetInstance();
        this.mDataFieldColumnMap = {};
        this.state =
            {
                mColumns            : this.props.pColumns,
                mColumnChooser      : this.props.pColumnChooser,
                mFeatureRefId       : this.props.pFeatureRefId,
                mPageSize           : (!this.props.pPageSize) ? 50 : this.props.pPageSize,
                mPagination         : this.props.pPagination,
                mMinPageSize        : this.props.minPageSize,
                mMinHeight          : (this.props.pMinHeight) ? (this.props.pMinHeight) : '200px',
                mColumnWidth        : (this.props.pColumnWidth) ? (this.props.pColumnWidth) : '100px',
                mEnableScroll       : (this.props.hasOwnProperty('pEnableScroll') && (this.props.pEnableScroll === false)) ? false : true,
                mEnableExport       : false,
                mSearchActionBar    : this.props.pSearchActionBar,
                mFooter             : this.props.pFooter,
                mFooterData         : null,
                mSummaryColumns     : this.props.pSummaryColumns,
                mGridHeading        : this.props.pHeading,
                mAlignHeading       : (this.props.pAlignHeading) ? (this.props.pAlignHeading) : 'center',
                mScrollHeight       : (this.props.pScrollHeight) ? (this.props.pScrollHeight) : '400px',
                mEditableGrid       : false,
                mSortableColumns    : (this.props.hasOwnProperty('pSortableColumns')) ? this.props.pSortableColumns : true,
                mFooterHeight       : '0px',
                mPrintFlag          : this.props.pPrintFlag,
                fnClosePrintForm    : this.props.pClosePrintForm,
                mHeight             : (this.props.pHeight) ? this.props.pHeight : '100%',

                mFirst              : 0,
                mTotalRecords       : 0,
                mPageData           : null,

                mData               : null,
                mSummaryData        : (this.props.pSummaryData) ? this.props.pSummaryData : {},
                mSelectedData       : this.props.pSelectedData,
                mExpandedRows       : null,
                mGroupByField       : null,
                mResizeableColumns  : true,
                mResetFlag          : (this.props.pClearSelection === null) ? false : this.props.pClearSelection,

                cfOnClick           : this.props.onClick,
                cfOnDoubleClick     : this.props.onDoubleClick,
                cfSetSummary        : this.props.onSummaryChange,
                cfOnItemChange      : this.props.OnItemChange
            }

        this.cfOnPage                             = this.cfOnPage.bind(this);
        this.cfOnClick                            = this.cfOnClick.bind(this);
        this.cfOnDoubleClick                      = this.cfOnDoubleClick.bind(this);
        this.cfInitializeGrid                     = this.cfInitializeGrid.bind(this);
        this.cfOnRowToggle                        = this.cfOnRowToggle.bind(this);
        this.cfHeaderTemplate                     = this.cfHeaderTemplate.bind(this);
        this.cfFooterTemplate                     = this.cfFooterTemplate.bind(this);
                  
        this.cfDropdownRendererTemplate           = this.cfDropdownRendererTemplate.bind(this);
        this.cfTextinputRendererTemplate          = this.cfTextinputRendererTemplate.bind(this);
        this.cfCheckboxRendererTemplate           = this.cfCheckboxRendererTemplate.bind(this);
        this.cfHyperlinkRenderer                  = this.cfHyperlinkRenderer.bind(this);
        this.cfQRCodeTemplate                     = this.cfQRCodeTemplate.bind(this);
                  
        this.cfGetEditor                          = this.cfGetEditor.bind(this);
        this.cfDropdownEditorTemplate             = this.cfDropdownEditorTemplate.bind(this);
        this.cfMultiselectDropdownEditorTemplate  = this.cfMultiselectDropdownEditorTemplate.bind(this);
        this.cfInputTextEditorTemplate            = this.cfInputTextEditorTemplate.bind(this);
        this.cfDatefieldEditorTemplate            = this.cfDatefieldEditorTemplate.bind(this);
        this.cfTimefieldEditorTemplate            = this.cfTimefieldEditorTemplate.bind(this);
          
        this.cfOnColumnSelection                  = this.cfOnColumnSelection.bind(this);
        this.cfSaveColumns                        = this.cfSaveColumns.bind(this);
        this.cfOnMultiselectColumnSelection       = this.cfOnMultiselectColumnSelection.bind(this);
        this.cfOnCheckboxSelection                = this.cfOnCheckboxSelection.bind(this);
        this.cfHeaderColumnGroup                  = this.cfHeaderColumnGroup.bind(this);
        this.cfFooterColumnGroup                  = this.cfFooterColumnGroup.bind(this);
        this.cfSummaryColumns                     = this.cfSummaryColumns.bind(this);
        this.cfClosePrintForm                     = this.cfClosePrintForm.bind(this);
        this.cfCloseColumnChooser                 = this.cfCloseColumnChooser.bind(this);
        this.cfOnExport                           = this.cfOnExport.bind(this);

        if(props.pData instanceof Array)
        {
            this.state.mData = this.props.pData;
            this.state.mTotalRecords = this.props.pData.length;
//            this.state.mData.slice(0, this.state.mPageSize);
            this.state.mPageData = this.props.pData;
        }

        var lScrollHeight;
        if(this.props.pScrollHeight)
        {
            lScrollHeight = this.props.pScrollHeight;
        }
        else if(this.props.pHeight)
        {
            lScrollHeight = this.props.pHeight;
        }

        if(lScrollHeight)
        {
            lScrollHeight = lScrollHeight - this.mScrollableHeaderHeight - this.mDataTableHeaderHeight - this.mPaginatorHeight ;
            this.state.mScrollHeight = lScrollHeight.toFixed(2) + 'px';
        }
        else
        {
            if(this.state.mGroupByField)
            {
                this.state.mScrollHeight = '75%';
            }
            else
            {
                this.state.mScrollHeight = '100%';
            }
        }
    }

    componentDidUpdate(lPrevProps, lPrevState) 
    {
        if ((lPrevProps.pFeatureRefId !== this.props.pFeatureRefId) ||
            (lPrevProps.pColumns !== this.props.pColumns) ||
            (lPrevProps.pData !== this.props.pData) ||
            (lPrevProps.pResetFlag !== this.props.pResetFlag) ||
            (lPrevProps.pSearchActionBar !== this.props.pSearchActionBar) ||
            (lPrevProps.minPageSize !== this.props.minPageSize) ||
            (lPrevProps.pSelectedData !== this.props.pSelectedData) ||
            (lPrevProps.pFooter !== this.props.pFooter) ||
            (lPrevProps.pEnableFilter !== this.props.pEnableFilter)) 
        {
            var lSelectedData = null;
            if (lPrevProps.pFeatureRefId === this.props.pFeatureRefId)
            {
                if(this.props.pSelectedData)
                {
                    lSelectedData = this.props.pSelectedData;
                }
                else
                {
                    lSelectedData = this.state.mSelectedData;
                }
            }

            var lData = null;
            var lPageData = null;
            var lTotalRecords = this.state.mTotalRecords;
            if(this.props.pData instanceof Array)
            {
                lData = this.props.pData;
                lPageData = lData;
                lTotalRecords = this.props.pData.length;
//                lPageData = lData.slice(0, this.state.mPageSize);
            }
    
            var lGroupByField = null;
            var lResizeableColumns = true;
            var lEditable = false;
            if(this.props.pColumns)
            {
                for(var lCInd=0; lCInd<this.props.pColumns.length; lCInd++)
                {
                    var lColumn = this.props.pColumns[lCInd];
                    lColumn.key = lCInd + 1;
                    if(lColumn.item_editor_)
                    {
                        lResizeableColumns = false;
                    }

                    if(lColumn.grouping_field_ === '1')
                    {
                        lGroupByField = lColumn.data_field_;
                        lColumn.visible_ = '1';
                        break;
                    }

                    if(lColumn.editable_ === '1')
                    {
                        if(this.props.hasOwnProperty('pEditableGrid'))
                        {
                            if(this.props.pEditableGrid)
                            {
                                lEditable = true;
                            }
                        }
                        else
                        {
                            lEditable = true;
                        }
                    }

                    if(lColumn.hasOwnProperty('cs_form_grid_item_properties_'))
                    {
                    }
                }
            }

            if(this.props.pScrollHeight)
            {
                var lScrollHeight = this.props.pScrollHeight;
                lScrollHeight = lScrollHeight - this.mScrollableHeaderHeight - this.mDataTableHeaderHeight - this.mPaginatorHeight;
                if(lData && (lData.length < 25))
                {
                    lScrollHeight = lScrollHeight + this.mPaginatorHeight;
                }

                if(this.state.mGroupByField)
                {
                    lScrollHeight = lScrollHeight - 70;
                }

                lScrollHeight = lScrollHeight.toFixed(2) + 'px'
            }
            else
            {
                if(this.state.mGroupByField)
                {
                    lScrollHeight = '70%';
                }
                else
                {
                    lScrollHeight = '100%';
                }
            }
    
            this.setState(
                {
                    mData               : lData,
                    mPageData           : lPageData,
                    mTotalRecords       : lTotalRecords,
                    mColumns            : this.props.pColumns,
                    mSummaryColumns     : this.props.pSummaryColumns,
                    mFeatureRefId       : this.props.pFeatureRefId,
                    mMinHeight          : (this.props.pMinHeight) ? (this.props.pMinHeight) : '200px',
                    mPrintFlag          : this.props.pPrintFlag,
                    mMinPageSize        : this.props.minPageSize,
                    mSummaryData        : (this.props.pSummaryData) ? this.props.pSummaryData : {},
                    mSelectedData       : lSelectedData,
                    mSearchActionBar    : this.props.pSearchActionBar,
                    mGridHeading        : this.props.pHeading,
                    mAlignHeading       : (this.props.pAlignHeading) ? (this.props.pAlignHeading) : 'center',
                    mEnableFilter       : this.props.pEnableFilter,
                    mGroupByField       : lGroupByField,
                    mScrollHeight       : lScrollHeight,
                    mEnableScroll       : this.props.pEnableScroll,
                    mResetFlag          : (this.props.pClearSelection === null) ? false : this.props.pClearSelection,
                    mResizeableColumns  : lResizeableColumns,
                    mFooter             : this.props.pFooter,
                    mEditableGrid       : lEditable,
                    mColumnChooser      : this.props.pColumnChooser,

                    cfOnClick           : this.props.onClick,
                    cfOnDoubleClick     : this.props.onDoubleClick,
                    cfOnItemChange      : this.props.OnItemChange
                }
            );
        }
    }

    cfInitializeGrid() 
    {
        let lColumnList = [];
        let lRowSelectionMode   = 'single';
        let lEditableGrid       = false;
        let lVisibleColumns = [];
        if (this.state.mColumns)
        {
            let lEditable = false;
            for(let lCInd=0; lCInd<this.state.mColumns.length; lCInd++)
            {
                let lColumn0 = this.state.mColumns[lCInd];
                if(lColumn0.editable_ === '1')
                {
                    lEditable = true;
                    break;
                }
            }

            for(let lInd=0; lInd<this.state.mColumns.length; lInd++)
            {
                let lColumn = this.state.mColumns[lInd];
                if(lColumn.visible_ === '1')
                {
                    lVisibleColumns.push(lColumn);
                }
            }

            for(let lCInd1=0; lCInd1<lVisibleColumns.length; lCInd1++)
            {
                let lColumn = lVisibleColumns[lCInd1];                
                let lVisible = (lColumn.visible_ === '1') ? '' : 'none';
                if(lColumn.hasOwnProperty('cs_form_grid_item_properties_'))
                {
                    for(let lfgipInd=0; lfgipInd<lColumn.cs_form_grid_item_properties_.length; lfgipInd++)
                    {
                        let lProperty = lColumn.cs_form_grid_item_properties_[lfgipInd];
                        if((lProperty.property_ === 'visible') && (lProperty.type_ === 'data_field'))
                        {
                            let lDataObj0 = null;
                            if(this.state.mData && (this.state.mData.length > 0))
                            {
                                lDataObj0 = this.state.mData[0];

                                if(lDataObj0.hasOwnProperty(lProperty.param_))
                                {
                                    lVisible = true;
                                }
                                else
                                {
                                    lVisible = false;
                                }
                            }
                        }
                    }
                }

                this.mDataFieldColumnMap[lColumn.data_field_] = lColumn;
                let lCol = null;
                let lEditor = null;
                let lRenderer = null;
                let lSelectionMode  = null;
                if(lEditable)
                {
                    if(lColumn.item_editor_)
                    {
                        lEditor = this.cfGetEditor(lColumn.item_editor_);
                    }
                    else if(lColumn.editable_ === '1')
                    {
                        lEditableGrid = true;
                        lEditor = this.cfGetEditor('DEFAULT');
                    }
                }

                if(lColumn.item_renderer_)
                {
                    switch(lColumn.item_renderer_)
                    {
                        case 'DROP_DOWN_RENDERER_1':
                            lRenderer = this.cfDropdownRendererTemplate;
                        break;
                        
                        case 'CHK_BOX_RENDERER_1':
                            lRenderer = this.cfCheckboxRendererTemplate;
                            this.mCheckboxRenderer = true;
                        break;
                        
                        case 'TEXT_INPUT_RENDERER_1':
                            lRenderer = this.cfTextinputRendererTemplate;
                        break;
                        
                        case 'HYPERLINK_RENDERER_1':
                            lRenderer = this.cfHyperlinkRenderer;
                        break;
                        
                        case 'QR_CODE_RENDERER_1':
                            lRenderer = this.cfQRCodeTemplate;
                        break;
                        
                        case 'DATE_RENDERER_1':
                        break;
                        
                        case 'MULTI_SELECT_RENDERER_1':
                            lSelectionMode = 'multiple';
                            this.mSelectionMode = 'multiple';
                        break;
                        
                        case 'SINGLE_SELECT_RENDERER_1':
                            lSelectionMode = 'single';
                            this.mSelectionMode = 'single';
                        break;
        
                        default: 
                            lSelectionMode = 'single';
                            this.mSelectionMode = 'single';
                        break;
                    }
                }

                if(lColumn.grouping_field_ === '1')
                {
                    lRowSelectionMode = 'single';
                }

                let lAlignment = 'center';
                if(lColumn.alignment_ === 'right')
                {
                    lAlignment = 'right';
                }
                else if(lColumn.alignment_ === 'left')
                {
                    lAlignment = 'left';
                }

                lCol = 
                    <Column
                        selectionMode   = { lSelectionMode }
                        field           = { lColumn.data_field_ } 
                        header          = { lColumn.column_name_ } 
                        editor          = { lEditor }
                        body            = { lRenderer }
                        key             = { lCInd1 + 1 }
//                      frozen          = {((lColumn.width_ === '-1') ? false : true)}
                        style           = 
                        {
                            {
                                display     : lVisible,
                                textAlign   : 'lAlignment',
                                width       : (lColumn.width_ === '-1') ? this.state.mColumnWidth : lColumn.width_
                            }
                        }
                        headerStyle = {{className : 'p-datatable.p-datatable-sm .p-datatable-thead > tr > th'}}
                        sortable    = {this.state.mSortableColumns}
                    />

                lColumnList.push(lCol);
            }
        }

        var lFooterColGroup = this.cfFooterColumnGroup();
        if(!lFooterColGroup)
        {
            lFooterColGroup = this.cfSummaryColumns();
        }

        return [lColumnList, lRowSelectionMode, lEditableGrid, lFooterColGroup, this.cfHeaderColumnGroup()] ;
    }

    cfHeaderColumnGroup()
    {
        var lSummaryColGroup = null;
        if(this.props.pHeaderColumns)
        {
            CSUtil.cfSort(this.props.pHeaderColumns, 'sequence_');
            var lHeaderColumnSpanMap = {};
            for(var lInd=0; lInd<this.props.pHeaderColumns.length; lInd++)
            {
                var lhColumn = this.props.pHeaderColumns[lInd];
                if(lhColumn)
                {
                    var lhColumnList = lHeaderColumnSpanMap[lhColumn.property_];
                    if(!lhColumnList)
                    {
                        lhColumnList = [];
                        lHeaderColumnSpanMap[lhColumn.property_] = lhColumnList;
                    }

                    lhColumnList.push(lhColumn);
                }
            }

            var lungroupedColumns = [];
            var lgroupedColumns = []
            for(var lcInd=0; lcInd<this.state.mColumns.length; lcInd++)
            {
                var lColumn = this.state.mColumns[lcInd];
                if(lColumn.visible_ === '1')
                {     
                    if(lColumn.hasOwnProperty('property_') && lColumn.hasOwnProperty('colSpan'))
                    {
                        var lExists = false;
                        for(var lugcInd=0; lugcInd<lungroupedColumns.length; lugcInd++)
                        {
                            var lElement = lungroupedColumns[lugcInd];
                            if(lElement.props.header === lColumn.property_)
                            {
                                lExists = true;
                                break;
                            }
                        }

                        if(!lExists)
                        {
                            var lClm1 = 
                                <Column
                                    headerStyle = 
                                    {
                                        {
                                            fontSize            : '13px',
                                            fontWeight          : 'normal',
                                            color               : '#ffffff',
                                            backgroundColor     : 'var(--primary-color)',
                                            sortableColumnIcon  :
                                            {
                                                backgroundColor : '#ffffff',
                                                backgroundImage : 'none',
                                                color           : '#ffffff' 
                                            }
                                        }
                                    }
                                    header={lColumn.property_} 
                                    colSpan={(lColumn.colSpan) ? Number(lColumn.colSpan) : 1} />

                            lungroupedColumns.push(lClm1);

                            var lgColumns = lHeaderColumnSpanMap[lColumn.property_];
                            if(lgColumns)
                            {
                                for(var lgcInd=0; lgcInd<lgColumns.length; lgcInd++)
                                {
                                    var lClm2 = 
                                        <Column
                                            headerStyle = 
                                            {
                                                {
                                                    fontSize            : '13px',
                                                    fontWeight          : 'normal',
                                                    color               : '#ffffff',
                                                    backgroundColor     : 'var(--primary-color)',
                                                    sortableColumnIcon  :
                                                    {
                                                        backgroundColor : '#ffffff',
                                                        backgroundImage : 'none',
                                                        color           : '#ffffff' 
                                                    }
                                                }
                                            }
                                            header={(lgColumns[lgcInd].name_) ? lgColumns[lgcInd].name_ : lgColumns[lgcInd].column_name_} />

                                    lgroupedColumns.push(lClm2);        
                                }
                            }
                        }
                    }
                    else
                    {
                        var lClm0 = 
                            <Column
                                headerStyle = {'grid-header-style'}
                                header      = {lColumn.column_name_} 
                                rowSpan     = {2} />

                        lungroupedColumns.push(lClm0);
                    }
                }
            }

            if(lungroupedColumns.length > 0)
            {
                lSummaryColGroup = 
                    <ColumnGroup>
                        <Row>
                            {lungroupedColumns}
                        </Row>

                        <Row>
                            {lgroupedColumns}
                        </Row>
                    </ColumnGroup>;
            }
            else
            {
                lSummaryColGroup = 
                    <ColumnGroup>
                        <Row>
                            {lgroupedColumns}
                        </Row>
                    </ColumnGroup>;
            }
        }

        return lSummaryColGroup;
    }

    cfFooterColumnGroup()
    {
        var lSummaryColGroup = null;
        if(this.state.mSummaryColumns)
        {
            CSUtil.cfSort(this.state.mSummaryColumns, 'sequence_');
            var lSequenceColumnsMap = {};
            for(var lInd=0; lInd<this.state.mSummaryColumns.length; lInd++)
            {
                var lColumn = this.state.mSummaryColumns[lInd];
                if(lColumn)
                {
                    var lColumnList = lSequenceColumnsMap[lColumn.sequence_];
                    if(!lColumnList)
                    {
                        lColumnList = [];
                        lSequenceColumnsMap[lColumn.sequence_] = lColumnList;
                    }

                    lColumnList.push(lColumn);
                }
            }

            var lColumnGroup = null;
            var lKeys = Object.keys(lSequenceColumnsMap);
            if(lKeys && (lKeys.length > 0))
            {
                if(!lColumnGroup)
                {
                    lColumnGroup = []
                }

                for(var lInd0=0; lInd0<lKeys.length; lInd0++)
                {
                    var lValues = lSequenceColumnsMap[lKeys[lInd0]];
                    if(lValues && (lValues.length > 0))
                    {
                        var lColumnData = null
                        for(var lInd1=0; lInd1<lValues.length; lInd1++)
                        {
                            if(lValues[lInd1].type_ === 'summary_column_footer')
                            {
                                if(!lColumnData)
                                {
                                    lColumnData = {}
                                }

                                lColumnData[lValues[lInd1].property_] = lValues[lInd1].value_;
                            }
                        }
 
                        if(lColumnData)
                        {
                            if(lColumnData.hasOwnProperty('footer'))
                            {
                                let lFooter = lColumnData.footer;
                                let lHeaderColumn = 
                                    <Column  
                                        style   = { {fontSize : '13px', fontWeight : 'bold', textAlign: "right"} }
                                        footer  = { lFooter } 
                                        colSpan = { (lColumnData.hasOwnProperty('colSpan')) ? Number(lColumnData.colSpan) : 1} />
                                        
                                lColumnGroup.push(lHeaderColumn);
                                
                                let lDataValue = 0;
                                if((lColumnData.hasOwnProperty('data_field') && lColumnData.operation && this.state.mData))
                                {
                                    if(lColumnData.operation === 'SUM')
                                    {
                                        for(var lInd2=0; lInd2<this.state.mData.length; lInd2++)
                                        {
                                            var lData = this.state.mData[lInd2];
                                            if(lData.hasOwnProperty(lColumnData.data_field))
                                            {
                                                lDataValue += Number(lData[lColumnData.data_field]);
                                            }
                                        }

                                        if(lColumnData.hasOwnProperty('summary_field'))
                                        {
                                            for(var lInd3=0; lInd3<this.state.mData.length; lInd3++)
                                            {
                                                var lData3 = this.state.mData[lInd3];
                                                if(!lData3.hasOwnProperty(lColumnData.summary_field))
                                                {
                                                    lData3[lColumnData.summary_field] = lDataValue;
                                                }
                                            }    
                                        }
                                    }
                                }

                                var lDataColumn = 
                                    <Column footer={lDataValue.toFixed(2)} colSpan={1} style={{fontSize : '13px'}}/>
                                        
                                lColumnGroup.push(lDataColumn);
                            }
                        }
                    }
                }

                if(lColumnGroup.length > 0)
                {
                    lSummaryColGroup = 
                        <ColumnGroup>
                            <Row>
                                {lColumnGroup}
                            </Row>
                        </ColumnGroup>;
                }
            }
        }

        return lSummaryColGroup;
    }

    cfSummaryColumns()
    {
        var lRetValue = null;
        if(this.state.mColumns)
        {
            var lSummaryColumnGroup = null;
			for(var lInd=0; lInd<this.state.mColumns.length; lInd++)
            {
                var lColumn = this.state.mColumns[lInd];
				if(lColumn.summary_field_ && lColumn.operation_)
				{
					if(!lSummaryColumnGroup)
					{
						lSummaryColumnGroup = [];
					}
					
					lSummaryColumnGroup.push(lColumn);
				}
            }

            var lSummaryDataObj = this.state.mSummaryData;
			if(lSummaryColumnGroup && this.state.mData)
			{
                var lDataCollection = this.state.mData;
				for(var ldInd=0; ldInd<lDataCollection.length; ldInd++)
				{
					var lDataObj = lDataCollection[ldInd];
					for(var lcInd=0; lcInd<lSummaryColumnGroup.length; lcInd++)
					{
                        var lSummaryColumn = lSummaryColumnGroup[lcInd];
                        if(ldInd === 0)
                        {
                            lSummaryDataObj[lSummaryColumn.summary_field_] = 0;
                        }

						if(lSummaryDataObj.hasOwnProperty(lSummaryColumn.summary_field_))
						{
							if(lSummaryColumn.operation_ === 'SUM')
							{
                                var lInclude = true;
                                if(lSummaryColumn.hasOwnProperty('cs_form_item_properties_'))
                                {
                                    var lcsiProperties = lSummaryColumn.cs_form_item_properties_;
                                    for(var lcsiInd=0; lcsiInd<lcsiProperties.length; lcsiInd++)
                                    {
                                        var lcsiProperty = lcsiProperties[lcsiInd];
                                        if(lcsiProperty.property_ === 'exclude_in_summary_')
                                        {
                                            if(lDataObj.hasOwnProperty(lcsiProperty.selection_property_))
                                            {
                                                if(lDataObj[lcsiProperty.selection_property_] === lcsiProperty.selection_value_)
                                                {
                                                    lInclude = false;
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                }

                                if(lInclude)
								{
                                    if(isNaN(lDataObj[lSummaryColumn.data_field_]))
                                    {
                                        lDataObj[lSummaryColumn.data_field_] = 0;
                                    }

                                    if(isNaN(lSummaryDataObj[lSummaryColumn.summary_field_]))
                                    {
                                        lSummaryDataObj[lSummaryColumn.summary_field_] = 0;
                                    }

                                    lSummaryDataObj[lSummaryColumn.summary_field_] = Number(lSummaryDataObj[lSummaryColumn.summary_field_]) + Number(lDataObj[lSummaryColumn.data_field_]);
                                }
							}
                        }
                        else
                        {
                            lSummaryDataObj[lSummaryColumn.summary_field_] = Number(lDataObj[lSummaryColumn.data_field_]);
                        }

                        lSummaryColumn.summary_data_ = lSummaryDataObj[lSummaryColumn.summary_field_];
					}
                }
                
                if(this.props.onSummaryChange)
                {
                    this.props.onSummaryChange(lSummaryDataObj);
                }

                var lColumnGroup = [];
                for(var lInd1=0; lInd1<this.state.mColumns.length; lInd1++)
                {
                    var lColumn1 = this.state.mColumns[lInd1];
                    var lDataColumn = null;
                    var lVisible = (lColumn1.visible_ === '1') ? '' : 'none';
                    var lDataValue = lSummaryDataObj[lColumn1.summary_field_];
                    if(lDataValue)
                    {
                        lDataColumn = <Column 
                            style = 
                                {
                                    {
                                        fontSize    : '13px',
                                        display     : lVisible, 
                                        textAlign   : 'right',
                                        width       : (lColumn1.width_ === '-1') ? this.state.mColumnWidth : lColumn.width_
                                    }
                                }
                            footer={lDataValue.toFixed(2)} />
                    }
                    else
                    {
                        lDataColumn = <Column
                            style = 
                            {
                                {
                                    fontSize    : '13px',
                                    display     : lVisible, 
                                    textAlign   : 'right',
                                    width       : (lColumn1.width_ === '-1') ? this.state.mColumnWidth : lColumn.width_
                                }
                            }/>
                    }

                    lColumnGroup.push(lDataColumn);
                }

                if(lColumnGroup.length > 0)
                {
                    lRetValue = 
                        <ColumnGroup>
                            <Row>
                                {lColumnGroup}
                            </Row>
                        </ColumnGroup>;
                }
			}
        }


        return lRetValue;
    }

    cfOnPage(lEvent)
    {
        const lStartIndex = lEvent.first;
        const lEndIndex = lEvent.first + this.state.mPageSize;
        var lPageData = this.state.mData.slice(lStartIndex, lEndIndex);
        this.setState({ mFirst : lStartIndex, mPageData : lPageData });          
    }
    
    cfOnClick(event) 
    {
        if(event && event.value)
        {
            if (this.state.cfOnClick && (this.state.cfOnClick instanceof Function)) 
            {
                if(this.mSelectionMode === 'single')                
                {
                    this.setState({mSelectedData: event.value});
                    this.state.cfOnClick(event.value);
                }
                else
                {
                    if(event.value instanceof Array)
                    {
                        this.setState({mSelectedData: event.value});
                        this.state.cfOnClick(event.value);
                    }
                }
            }
        }
    }

    cfOnDoubleClick(event) 
    {
        if(event && event.data)
        {
            if (this.state.cfOnDoubleClick && (this.state.cfOnDoubleClick instanceof Function)) 
            {
                if(this.mSelectionMode === 'single')                
                {
                    this.setState({mSelectedData: event.data});
                    this.state.cfOnDoubleClick(event.data);
                }
                else
                {
                    if(event.data instanceof Array)
                    {
                        this.setState({mSelectedData: event.data});
                        this.state.cfOnDoubleClick(event.data);
                    }
                }
            }
        }
    }
    
    cfOnRowToggle(lEvent)
    {
        if(lEvent)
        {
            this.setState({mExpandedRows: lEvent.data});
        }
    }

    cfHeaderTemplate(lData)
    {
        var lRetValue = null;
        if(lData && this.state.mGroupByField && lData.hasOwnProperty(this.state.mGroupByField))
        {
            lRetValue = lData[this.state.mGroupByField];
        }

        return lRetValue;
    }

    cfFooterTemplate(lData, lIndex)
    {
        let lRetValue = null;
        if(lData)
        {
            if(this.props.hasOwnProperty('pFormGridProperties') && this.props.pFormGridProperties)
            {
                let lColSpan = null;
                let lSummaryDataField = null;
                let lSummaryDataValue = null;
                let lSummaryLabel = null;
                let lgProperties = this.props.pFormGridProperties;
                for(let lgpInd=0; lgpInd<lgProperties.length; lgpInd++)
                {
                    let lgpObj = lgProperties[lgpInd];
                    if(lgpObj.property_ === 'summary_data')
                    {
                        if(lgpObj.type_ === 'data_field_1')
                        {
                            lSummaryDataField = lgpObj.name_;
                            lSummaryDataValue = lgpObj.value_;
                        }
                        else if(lgpObj.type_ === 'data_field_1_label')
                        {
                            lSummaryLabel = lgpObj.value_;
                        }
                        else if(lgpObj.type_ === 'data_field_1_col_span')
                        {
                            lColSpan = lgpObj.value_;
                        }
                    }
                }

                if(lColSpan && lSummaryDataField && lSummaryLabel)
                {
                    lRetValue = 
                        <React.Fragment>
                            <td key={lData[lSummaryDataField] + '_footerTotalLabel'} colSpan={lColSpan} style={{fontWeight: 'bold', textAlign: 'right'}}>{lSummaryLabel}</td>
                            <td key={lData[lSummaryDataField] + '_footerTotalValue'} style={{fontWeight: 'bold', textAlign: 'right'}}>{this.cfCalculateGroupTotal(lSummaryDataField, lData[lSummaryDataField], lSummaryDataValue)}</td>
                        </React.Fragment>
                }
            }
            else if(this.props.pColumns)
            {
                let seq = 1001;
                let lColSpan1 = null;
                let lSummaryDataField1 = null;
                let lSummaryDataValue1 = null;
                let lSummaryLabel1 = null;

                let lColSpan2 = null;
                let lSummaryDataField2 = null;
                let lSummaryDataValue2 = null;
                let lSummaryLabel2 = null;

                let lColSpan3 = null;
                let lSummaryDataField3 = null;
                let lSummaryDataValue3 = null;
                let lSummaryLabel3 = null;

                let lColSpan4 = null;
                let lSummaryDataField4 = null;
                let lSummaryDataValue4 = null;
                let lSummaryLabel4 = null;

                let lColSpan5 = null;
                let lSummaryDataField5 = null;
                let lSummaryDataValue5 = null;
                let lSummaryLabel5 = null;

                let lColSpan6 = null;
                let lSummaryDataField6 = null;
                let lSummaryDataValue6 = null;
                let lSummaryLabel6 = null;

                let lColSpan7 = null;
                let lSummaryDataField7 = null;
                let lSummaryDataValue7 = null;
                let lSummaryLabel7 = null;

                for(let lcInd=0; lcInd<this.props.pColumns.length; lcInd++)
                {
                    let lColumn = this.props.pColumns[lcInd];
                    if(lColumn.hasOwnProperty('cs_form_item_properties_'))
                    {
                        let lProperties = lColumn.cs_form_item_properties_;
                        for(let lpInd=0; lpInd<lProperties.length; lpInd++)
                        {
                            let lfipObj = lProperties[lpInd];
                            if(lfipObj.property_ === 'summary_data')
                            {
                                if(lfipObj.value_ === 'data_field_1')
                                {
                                    lSummaryDataField1 = lfipObj.selection_property_;
                                    lSummaryDataValue1 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_1_label')
                                {
                                    lSummaryLabel1 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_1_col_span')
                                {
                                    lColSpan1 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_2')
                                {
                                    lSummaryDataField2 = lfipObj.selection_property_;
                                    lSummaryDataValue2 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_2_label')
                                {
                                    lSummaryLabel2 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_2_col_span')
                                {
                                    lColSpan2 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_3')
                                {
                                    lSummaryDataField3 = lfipObj.selection_property_;
                                    lSummaryDataValue3 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_3_label')
                                {
                                    lSummaryLabel3 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_3_col_span')
                                {
                                    lColSpan3 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_4')
                                {
                                    lSummaryDataField4 = lfipObj.selection_property_;
                                    lSummaryDataValue4 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_4_label')
                                {
                                    lSummaryLabel4 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_4_col_span')
                                {
                                    lColSpan4 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_5')
                                {
                                    lSummaryDataField5 = lfipObj.selection_property_;
                                    lSummaryDataValue5 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_5_label')
                                {
                                    lSummaryLabel5 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_5_col_span')
                                {
                                    lColSpan5 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_6')
                                {
                                    lSummaryDataField6 = lfipObj.selection_property_;
                                    lSummaryDataValue6 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_6_label')
                                {
                                    lSummaryLabel6 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_6_col_span')
                                {
                                    lColSpan6 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_7')
                                {
                                    lSummaryDataField7 = lfipObj.selection_property_;
                                    lSummaryDataValue7 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_7_label')
                                {
                                    lSummaryLabel7 = lfipObj.selection_value_;
                                }
                                else if(lfipObj.value_ === 'data_field_7_col_span')
                                {
                                    lColSpan7 = lfipObj.selection_value_;
                                }
                            }
                        }
                    }
                }

                let lComp1Label = null;
                let lComp1Data = null;
                let lComp2Label = null;
                let lComp2Data = null;
                let lComp3Label = null;
                let lComp3Data = null;
                let lComp4Label = null;
                let lComp4Data = null;
                let lComp5Label = null;
                let lComp5Data = null;
                let lComp6Label = null;
                let lComp6Data = null;
                let lComp7Label = null;
                let lComp7Data = null;
                if(lColSpan1 && lSummaryLabel1)
                {
                    lComp1Label =
                        <td key={seq++ + '_footerTotalLabel'} colSpan={lColSpan1} style={{fontWeight: 'bold', textAlign: 'right'}}>{lSummaryLabel1}</td>
                }

                if(lSummaryDataField1)
                {
                    lComp1Data = 
                        <td key={seq++ + '_footerTotalValue'} style={{fontWeight: 'bold', textAlign: 'right'}}>{this.cfCalculateGroupTotal(lSummaryDataField1, lData[lSummaryDataField1], lSummaryDataValue1)}</td>
                }

                if(lColSpan2 && lSummaryLabel2)
                {
                    lComp2Label =
                        <td key={seq++ + '_footerTotalLabel'} colSpan={lColSpan2} style={{fontWeight: 'bold', textAlign: 'right'}}>{lSummaryLabel2}</td>
                }

                if(lSummaryDataField2)
                {
                    lComp2Data = 
                        <td key={seq++ + '_footerTotalValue'} style={{fontWeight: 'bold', textAlign: 'right'}}>{this.cfCalculateGroupTotal(lSummaryDataField2, lData[lSummaryDataField2], lSummaryDataValue2)}</td>
                }

                if(lColSpan3 && lSummaryLabel3)
                {
                    lComp3Label =
                        <td key={seq++ + '_footerTotalLabel'} colSpan={lColSpan3} style={{fontWeight: 'bold', textAlign: 'right'}}>{lSummaryLabel3}</td>
                }

                if(lSummaryDataField3)
                {
                    lComp3Data = 
                        <td key={seq++ + '_footerTotalValue'} style={{fontWeight: 'bold', textAlign: 'right'}}>{this.cfCalculateGroupTotal(lSummaryDataField3, lData[lSummaryDataField3], lSummaryDataValue3)}</td>
                }

                if(lColSpan4 && lSummaryLabel4)
                {
                    lComp4Label =
                        <td key={seq++ + '_footerTotalLabel'} colSpan={lColSpan4} style={{fontWeight: 'bold', textAlign: 'right'}}>{lSummaryLabel4}</td>
                }

                if(lSummaryDataField4)
                {
                    lComp4Data = 
                        <td key={seq++ + '_footerTotalValue'} style={{fontWeight: 'bold', textAlign: 'right'}}>{this.cfCalculateGroupTotal(lSummaryDataField4, lData[lSummaryDataField4], lSummaryDataValue4)}</td>
                }

                if(lColSpan5 && lSummaryLabel5)
                {
                    lComp5Label =
                        <td key={seq++ + '_footerTotalLabel'} colSpan={lColSpan5} style={{fontWeight: 'bold', textAlign: 'right'}}>{lSummaryLabel5}</td>
                }

                if(lSummaryDataField5)
                {
                    lComp5Data = 
                        <td key={seq++ + '_footerTotalValue'} style={{fontWeight: 'bold', textAlign: 'right'}}>{this.cfCalculateGroupTotal(lSummaryDataField5, lData[lSummaryDataField5], lSummaryDataValue5)}</td>
                }

                if(lColSpan6 && lSummaryLabel6)
                {
                    lComp6Label =
                        <td key={seq++ + '_footerTotalLabel'} colSpan={lColSpan6} style={{fontWeight: 'bold', textAlign: 'right'}}>{lSummaryLabel6}</td>
                }

                if(lSummaryDataField6)
                {
                    lComp6Data = 
                        <td key={seq++ + '_footerTotalValue'} style={{fontWeight: 'bold', textAlign: 'right'}}>{this.cfCalculateGroupTotal(lSummaryDataField6, lData[lSummaryDataField6], lSummaryDataValue6)}</td>
                }

                if(lColSpan7 && lSummaryLabel7)
                {
                    lComp7Label =
                        <td key={seq++ + '_footerTotalLabel'} colSpan={lColSpan7} style={{fontWeight: 'bold', textAlign: 'right'}}>{lSummaryLabel7}</td>
                }

                if(lSummaryDataField7)
                {
                    lComp7Data = 
                        <td key={seq++ + '_footerTotalValue'} style={{fontWeight: 'bold', textAlign: 'right'}}>{this.cfCalculateGroupTotal(lSummaryDataField7, lData[lSummaryDataField7], lSummaryDataValue7)}</td>
                }

                if(lComp1Label && lComp1Data)
                {
                    lRetValue = 
                        <React.Fragment>
                            {lComp1Label}
                            {lComp1Data}
                            {lComp2Label}
                            {lComp2Data}
                            {lComp3Label}
                            {lComp3Data}
                            {lComp4Label}
                            {lComp4Data}
                            {lComp5Label}
                            {lComp5Data}
                            {lComp6Label}
                            {lComp6Data}
                            {lComp7Label}
                            {lComp7Data}
                        </React.Fragment>
                }
            }
        }

        return lRetValue;
    }

    cfCalculateGroupTotal(lDataField, lProperty, lSummaryVal)
    {
        let lTotal = 0;
        if(this.state.mData)
        {
            for (let lDataObj of this.state.mData)
            {
                if(lDataObj[lDataField] === lProperty)
                {
                    if(lDataObj.hasOwnProperty(lSummaryVal))
                    {
                        lTotal += Number(lDataObj[lSummaryVal]);
                    }
                }
            }
        }

        return Number(lTotal).toFixed(2);
    }

    cfGetEditor(lItemEditor)
    {
        var lRetValue;
        if(lItemEditor)
        {
            switch(lItemEditor)
            {
                case 'COMBO_BOX_EDITOR_1':
                    lRetValue = this.cfDropdownEditorTemplate;
                break;
                
                case 'MULTI_SELECT_COMBO_BOX_EDITOR_1':
                    lRetValue = this.cfMultiselectDropdownEditorTemplate;
                break;
                
                case 'DATE_FIELD_EDITOR_1':
                    lRetValue = this.cfDatefieldEditorTemplate;
                break;
                
                case 'TIME_FIELD_EDITOR_1':
                    lRetValue = this.cfTimefieldEditorTemplate;
                break;
                
                case 'TEXT_FIELD_EDITOR_1':
                    lRetValue = this.cfInputTextEditorTemplate;
                break;

                default: 
                    lRetValue = this.cfInputTextEditorTemplate;
                break;
            }
        }

        return lRetValue;
    }

    cfDropdownEditorTemplate(lProps)
    {
        var lRowData = lProps.rowData;
        var lColumn = this.mDataFieldColumnMap[lProps.field];
        var lDpSource = null;
        if(lColumn && lColumn.hasOwnProperty('cs_form_grid_item_properties_') && lColumn.cs_form_grid_item_properties_)
        {
            var lDependentFields = [];
            var lDependentFieldObjectMap = {};
            var lDependentMap = {}
            for(var lInd=0; lInd< lColumn.cs_form_grid_item_properties_.length; lInd++)
            {
                const lfgipObj = lColumn.cs_form_grid_item_properties_[lInd];
                if(lfgipObj.property_ === 'item_editor_properties')
                {
                    if(lfgipObj.type_ === 'data_field')
                    {
                        if((lfgipObj.param_ === 'data_provider_source_') && lfgipObj.value_)
                        {
                            lDpSource = lfgipObj.value_;
                            if(lfgipObj.selection_property_)
                            {
                                lDependentFields.push(lfgipObj.selection_property_);
                            }
                        }
                        else if((lfgipObj.param_ === 'dependent_field_') && lfgipObj.selection_property_)
                        {
                            lDependentFieldObjectMap[lfgipObj.selection_property_] = lfgipObj;
                            lDependentFields.push(lfgipObj.selection_property_);
                        }
                        else if((lfgipObj.param_ === 'dependent_constant_') && lfgipObj.selection_property_)
                        {
                            lDependentFieldObjectMap[lfgipObj.selection_property_] = lfgipObj;
                            lDependentFields.push(lfgipObj.selection_property_);
                            lDependentMap[lfgipObj.selection_property_] = cfGetConstantValue(lfgipObj.value_);
                        }
                        else if((lfgipObj.param_ === 'label_field_') && lfgipObj.value_)
                        {
                            lColumn.data_provider_display_field_ = lfgipObj.value_;
                        }
                        else if(lfgipObj.param_ === 'data_provider_id_')
                        {
                            lColumn.data_provider_id_ = lfgipObj.value_;;
                        }
                    }
                }
            }
        }
        else if(lColumn.hasOwnProperty('data_provider_source_'))
        {
            lDpSource = lColumn.data_provider_source_;
        }

        if(lDpSource)
        {
            this.mServiceUtil.cfGetDataFromDataProvider (lDpSource, 
                (lDataProviderSource, lCollection)=>
                {
                    if(lDataProviderSource === lDpSource)
                    {
                        var lDataProvider = null; 
                        if(lDependentFields && (lDependentFields.length > 0) && lCollection && lRowData)
                        {
                            var lDpField = lColumn.group_;
                            for(var lInd=0; lInd<lCollection.length; lInd++)
                            {
                                var lcObj = lCollection[lInd];
                                var lIncFlag = false;
                                if(lDependentFields.length > 0)
                                {
                                    for(var ldInd=0; ldInd<lDependentFields.length; ldInd++)
                                    {
                                        if(lRowData.hasOwnProperty(lDependentFields[ldInd]))
                                        {
                                            var lDepObj = lDependentFieldObjectMap[lDependentFields[ldInd]];
                                            if(lDepObj && (lDepObj.param_ === 'dependent_field_'))
                                            {
                                                if(!lDepObj.value_)
                                                {
                                                    if(lcObj[lDependentFields[ldInd]] === lRowData[lDependentFields[ldInd]])
                                                    {
                                                        lIncFlag = true;
                                                    }
                                                    else
                                                    {
                                                        lIncFlag = false;
                                                        break;
                                                    }
                                                }
                                                else
                                                {
                                                    if(lcObj[lDependentFields[ldInd]] === lRowData[lDepObj.value_])
                                                    {
                                                        lIncFlag = true;
                                                    }
                                                    else
                                                    {
                                                        lIncFlag = false;
                                                        break;
                                                    }
                                                }
                                            }
                                            else if(lDpField)
                                            {
                                                if(lRowData[lDependentFields[ldInd]] === lcObj[lDpField])
                                                {
                                                    lIncFlag = true;
                                                }
                                                else
                                                {
                                                    lIncFlag = false;
                                                    break;
                                                }    
                                            }
                                        }
                                        else
                                        {
                                            lIncFlag = false;
                                        }
                                    }
                                }
                                else if(lDpField)
                                {
                                    if(lcObj[lDpField] === lRowData[lDpField])
                                    {
                                        lIncFlag = true;
                                    }
                                }

                                if(lIncFlag)
                                {
                                    if(!lDataProvider)
                                    {
                                        lDataProvider = [];
                                    }

                                    lDataProvider.push(lcObj);
                                }

                                /*                                                  
                                if(lCollection[lInd][lDpField] === lRowData[lfgipObj.selection_property_])
                                {
                                    if(!lDataProvider)
                                    {
                                        lDataProvider = [];
                                    }

                                    lDataProvider.push(lCollection[lInd]);
                                }*/
                            }
                        }
                        else
                        {
                            lDataProvider = lCollection;
                        }

                        lColumn.data_provider_ = lDataProvider;
                    }
                }
            );
        }

        var lDataProvider = null;
        if(lColumn && lColumn.hasOwnProperty('data_provider_'))
        {
            lDataProvider = lColumn.data_provider_;
        }

        let lValue = null;
        if(lRowData && lRowData.hasOwnProperty(lProps.field)) {
            lValue = lRowData[lProps.field];
        }

        return (
            <Dropdown
                key         = { lProps.rowIndex}
                appendTo    = { document.body }
                //value       = { lProps.value[lProps.rowIndex][lProps.field] }
                value       = { lValue }
                options     = { lDataProvider }
                optionLabel = { (lColumn && lColumn.hasOwnProperty('data_provider_display_field_')) ? lColumn.data_provider_display_field_ : null }
                onChange    = { (e) => this.cfOnColumnSelection(lProps, e.target.value) }
            />
        );
    }

    cfMultiselectDropdownEditorTemplate(lProps)
    {
        let lRowData = lProps.rowData;
        let lColumn = this.mDataFieldColumnMap[lProps.field];
        let lSelection = [];
        let lFilterList = null;
        if(lColumn.hasOwnProperty('cs_form_grid_item_properties_') && lColumn.cs_form_grid_item_properties_)
        {
            for(let lInd=0; lInd< lColumn.cs_form_grid_item_properties_.length; lInd++)
            {
                const lfgipObj = lColumn.cs_form_grid_item_properties_[lInd];
                if(lfgipObj.property_ === 'item_editor_properties')
                {
                    if(lfgipObj.type_ === 'data_field')
                    {
                        if((lfgipObj.param_ === 'data_provider_source_') && lfgipObj.value_)
                        {
                            const lTmpVal = lfgipObj.value_;
                            this.mServiceUtil.cfGetDataFromDataProvider (lTmpVal, (lDataProviderSource, lCollection)=>
                                {
                                    if(lDataProviderSource === lTmpVal)
                                    {
                                        let lFilterCollection = []
                                        if(lFilterList && lCollection && lRowData)
                                        {
                                            for(let lcInd=0; lcInd<lCollection.length; lcInd++)
                                            {
                                                let lColObj = lCollection[lcInd];
                                                let lIncFlag = true;
                                                for(let lfInd=0; lfInd<lFilterList.length; lfInd++)
                                                {
                                                    if(lColObj[lFilterList[lfInd]] !== lRowData[lFilterList[lfInd]])
                                                    {
                                                        lIncFlag = false;
                                                        break;
                                                    }
                                                }

                                                if(lIncFlag)
                                                {
                                                    lFilterCollection.push(lColObj);
                                                }
                                            }

                                            lColumn.data_provider_ = lFilterCollection;
                                        }
                                        else
                                        {
                                            lFilterCollection = lCollection;
                                            lColumn.data_provider_ = lCollection;
                                        }

                                        if(lfgipObj.selection_property_ && lFilterCollection && lRowData)
                                        {
                                            var lColumnData = lRowData[lColumn.data_field_];
                                            if(lColumnData)
                                            {
                                                if(lColumnData instanceof Array)
                                                {
                                                }
                                                else if(typeof(lColumnData) === 'string')
                                                {
                                                    const lKeys = lColumnData.split(",");
                                                    if(lKeys)
                                                    {
                                                        for(var lKeyInd=0; lKeyInd<lKeys.length; lKeyInd++)
                                                        {
                                                            for(var lInd=0; lInd<lFilterCollection.length; lInd++)
                                                            {
                                                                var lDataObj = lFilterCollection[lInd];
                                                                if(lDataObj.hasOwnProperty(lfgipObj.selection_property_) && (lDataObj[lfgipObj.selection_property_] === lKeys[lKeyInd]))
                                                                {
                                                                    lSelection.push(lDataObj);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }  
                                            }
                                        }
                                    }
                                }
                            );
                        }
                        else if((lfgipObj.param_ === 'filter_field_') && lfgipObj.value_)
                        {
                            if(!lFilterList)
                            {
                                lFilterList = []
                            }

                            lFilterList.push(lfgipObj.value_);
                        }
                        else if((lfgipObj.param_ === 'label_field_') && lfgipObj.value_)
                        {
                            lColumn.data_provider_display_field_ = lfgipObj.value_;
                        }
                        else if(lfgipObj.param_ === 'data_provider_id_')
                        {
                            lColumn.data_provider_id_ = lfgipObj.value_;;
                        }
                    }
                }
            }
        }
        else if(lColumn.hasOwnProperty('data_provider_source_'))
        {
            this.mServiceUtil.cfGetDataFromDataProvider (lColumn.data_provider_source_, 
                (lDataProviderSource, lCollection)=>
                {
                    if(lDataProviderSource === lColumn.data_provider_source_)
                    {
                        let lFcList = null;
                        if(lColumn.hasOwnProperty('dependent_field_') && lRowData && lRowData.hasOwnProperty(lColumn.dependent_field_))
                        {
                            for(var lcInd=0; lcInd<lCollection.length; lcInd++)
                            {
                                let lColObj0 = lCollection[lcInd];
                                if(lColObj0[lColumn.dependent_field_] === lRowData[lColumn.dependent_field_])
                                {
                                    if(!lFcList)
                                    {
                                        lFcList = []
                                    }

                                    lFcList.push(lColObj0);
                                }
                            }

                            lColumn.data_provider_ = lFcList;
                        }
                        else
                        {
                            lFcList = lCollection
                            lColumn.data_provider_ = lCollection;
                        }

                        let lCData = lRowData[lColumn.data_field_];
                        if(lCData)
                        {
                            if(lCData instanceof Array)
                            {
                            }
                            else if(typeof(lCData) === 'string')
                            {
                                const lKeys = lCData.split(",");
                                if(lKeys && lColumn.data_provider_id_)
                                {
                                    for(let lKeyInd0=0; lKeyInd0<lKeys.length; lKeyInd0++)
                                    {
                                        for(let lInd=0; lInd<lFcList.length; lInd++)
                                        {
                                            let lDataObj = lFcList[lInd];
                                            if(lDataObj.hasOwnProperty(lColumn.data_provider_id_) && (lDataObj[lColumn.data_provider_id_] === lKeys[lKeyInd0]))
                                            {
                                                lSelection.push(lDataObj);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            );
        }

        let lLabel =  (lColumn && lColumn.hasOwnProperty('data_provider_display_field_')) ? lColumn.data_provider_display_field_ : null;
        let lDataProvider = (lColumn && lColumn.hasOwnProperty('data_provider_')) ? lColumn.data_provider_ : null;

        if(!lDataProvider)
        {
            lDataProvider = []
        }

        return (
            <MultiSelect
                key         = { lProps.rowIndex}
                appendTo    = { document.body }
                value       = { lSelection } 
                options     = { lDataProvider }
                optionLabel = { lLabel }
                onChange    = { (e) => this.cfOnMultiselectColumnSelection(lProps, e.target.value) }
                display     = "chip"
                filter      = {true}
            />
        );
    }

    cfDatefieldEditorTemplate(lProps)
    {
        let lRowData = lProps.rowData;
        return (
            <Calendar
                style           = {{height : '28px', width : '100%'}}
                appendTo        = { document.body }
                inputStyle      = {{fontSize : '12px', height : '28px', width : '100%'}}
                onChange        = {(e) => lRowData[lProps.field] = fnGetDateToString(e.value)}
                yearNavigator   = { true }
                yearRange       = '1900:2050'
                dateFormat      = "dd-mm-yy"
                showIcon        = { false }
                />
        );
    }

    cfTimefieldEditorTemplate(lProps)
    {
        let lRowData = lProps.rowData;
        return (
            <Calendar
                inputClassName = "cs-time-field"
                hourFormat  = "24"
                timeOnly    = {true}
                showTime    = {true} 
                style       = {{height : '28px', width : '100%'}}
                appendTo    = { document.body }
                inputStyle  = {{fontSize : '12px', height : '28px', width : '100%'}}
                onChange    = {(e) => lRowData[lProps.field] = fnGetTimeString(e.value)}
                showIcon    = {false}
                />
        );
    }

    cfInputTextEditorTemplate(lProps) 
    {
        let lRowData = lProps.rowData;
        let lField = lProps.field;
        let lValue = null;
        if(lRowData && lField)
        {
            lValue = lRowData[lField];

            let lAllow = true;            
            let lColumn = this.mDataFieldColumnMap[lProps.field];
            if(lColumn && lColumn.hasOwnProperty('cs_form_grid_item_properties_') && lColumn.cs_form_grid_item_properties_)
            {
                let lStrFlag = false;
                for(let lInd=0; lInd< lColumn.cs_form_grid_item_properties_.length; lInd++)
                {
                    const lfgipObj = lColumn.cs_form_grid_item_properties_[lInd];
                    if(lfgipObj.property_ === 'validate')
                    {
                        if(lfgipObj.type_ === 'data_field')
                        {
                            if(lfgipObj.param_ === 'allow')
                            {
                                if (lfgipObj.selection_property_ === lValue)
                                {
                                    lStrFlag = true;
                                    lAllow = true;
                                }

                                if(!lStrFlag && lfgipObj.value_)
                                {
                                    if(!lValue.match(lfgipObj.value_))
                                    {
                                        lAllow = false;
                                    }
                                }
                            }
                            else if(!lStrFlag && (lfgipObj.param_ === 'min_val'))
                            {
                                if(lfgipObj.value_)
                                {
                                    lAllow = Number(lValue) >= Number(lfgipObj.value_)
                                }
                                else if(lfgipObj.selection_property_ && lRowData[lfgipObj.selection_property_])
                                {
                                    lAllow = Number(lValue) >= Number(lRowData[lfgipObj.selection_property_])
                                }
                            }
                            else if(!lStrFlag && (lfgipObj.param_ === 'max_val'))
                            {
                                if(lfgipObj.value_)
                                {
                                    lAllow = Number(lValue) <= Number(lfgipObj.value_)
                                }
                                else if(lfgipObj.selection_property_ && lRowData[lfgipObj.selection_property_])
                                {
                                    lAllow = Number(lValue) <= Number(lRowData[lfgipObj.selection_property_])
                                }
                            }

                            if(!lAllow)
                            {
                                break;
                            }
                        }
                    }
                }
            }

            if(!lAllow)
            {
                lValue = '';
                lRowData[lField] = lValue;
            }    
        }

        return (
            <InputText 
                type        = "text" 
                value       = { lValue } 
                onChange={(e) => this.cfOnColumnSelection(lProps, e.target.value)} />
        );
    }

    cfOnColumnSelection(lProps, lValue)
    {
        let lRowData = lProps.rowData;
        let lColumn = this.mDataFieldColumnMap[lProps.field];
        let lData = null;
        if(lValue instanceof Object)
        {
            if(lColumn && lColumn.data_provider_id_)
            {
                lRowData[lProps.field] = lValue[lColumn.data_provider_id_];
                lRowData[lColumn.data_provider_display_field_] = lValue[lColumn.data_provider_display_field_];
                lData = (this.state.mData) ? this.state.mData : this.props.pData;
                lData[lProps.rowIndex][lProps.field] = lRowData[lProps.field];
            }
        }
        else
        {
            if(lRowData) {
                lRowData[lProps.field] = lValue;
            }
        }

        if(!lData) {
            lData = (this.state.mData) ? this.state.mData : this.props.pData;
        }

        if(this.state.cfOnItemChange)
        {
            this.state.cfOnItemChange(this.state.mData, lProps.rowData, lProps.rowIndex, lValue, lColumn);
        }
        
        this.setState({mData : lData});
    }

    cfOnMultiselectColumnSelection(lProps, lValue)
    {
        let lRowData = lProps.rowData;
        let lColumn = this.mDataFieldColumnMap[lProps.field];
        if(lValue instanceof Object)
        {
            if(lColumn && lColumn.data_provider_id_)
            {
                let lSelection = null;
                if(lColumn.hasOwnProperty('cs_form_grid_item_properties_') && lColumn.cs_form_grid_item_properties_)
                {
                    for(let lInd=0; lInd< lColumn.cs_form_grid_item_properties_.length; lInd++)
                    {
                        const lfgipObj = lColumn.cs_form_grid_item_properties_[lInd];
                        if((lfgipObj.param_ === "data_provider_source_") && (lValue instanceof Array))
                        {
                            for(let lSelInd=0; lSelInd<lValue.length; lSelInd++)
                            {
                                let lSelObj = lValue[lSelInd];
                                if(lSelInd === 0)
                                {
                                    lSelection = lSelObj[lfgipObj.selection_property_] 
                                }
                                else
                                {
                                    lSelection = lSelection + "," + lSelObj[lfgipObj.selection_property_];
                                }
                            }

                            lRowData[lProps.field] = lSelection;
                        }
                    }
                }
                else
                {
                    for(let lSelInd=0; lSelInd<lValue.length; lSelInd++)
                    {
                        var lSelObj = lValue[lSelInd];
                        if(lSelInd === 0)
                        {
                            lSelection = lSelObj[lColumn.data_provider_id_] 
                        }
                        else
                        {
                            lSelection = lSelection + "," + lSelObj[lColumn.data_provider_id_];
                        }
                    }

                    lRowData[lProps.field] = lSelection;
                }

                if(this.state.cfOnItemChange)
                {
                    this.state.cfOnItemChange(this.state.mData, lProps.rowData, lProps.rowIndex, lValue, lColumn);
                }
            }
        }
    }

    cfOnCheckboxSelection(lValue, lRowData, lCol)
    {
        if(lRowData && lCol)
        {
            if(lValue)
            {
                lRowData[lCol.field] = "1";
            }
            else
            {
                lRowData[lCol.field] = "0";
            }
        }
    }

    cfQRCodeTemplate(lRowData, lCol)
    {  
        let lQRCode = null;      
        let lColumn = this.mDataFieldColumnMap[lCol.field];
        if(lColumn)
        {
            let lText = CSFormUtil.cfGetGridItemInputString(lColumn, lRowData);
            if(lText)
            {
//                lQRCode = 
                    // <QRCode
                    //     bgColor = "#FFFFFF"
                    //     fgColor = "#007be5"
                    //     level   = "L"
                    //     style   = {{ width: '72px', height: '72px',  }}
                    //     value   = {lText}
                    // />
            }
        }

        return lQRCode;
    }
    
    cfDropdownRendererTemplate(lRowData, lProps)
    {
        let lDataProvider = null;
        let lColumn = this.mDataFieldColumnMap[lProps.field];
        if(lColumn)
        {
            if(lColumn.hasOwnProperty('data_provider_'))
            {
                lDataProvider = lColumn.data_provider_;
            }
            else if(lColumn.hasOwnProperty('data_provider_source_') && lColumn.hasOwnProperty('data_provider_id_') && lColumn.hasOwnProperty('data_provider_display_field_'))
            {
                this.mServiceUtil.cfGetDataFromDataProvider (lColumn.data_provider_source_, (lDpSource, lCollection) =>
                    {
                        if(lColumn.data_provider_source_ === lDpSource)
                        {
                            lDataProvider = lCollection;
                            lColumn.data_provider_ = lCollection;
                        }
                    }
                );
            }
        }

        return (
            <Dropdown
                style       = {{width:'100%'}} 
                key         = { lProps.rowIndex}
                appendTo    = { document.body }
                value       = { lProps.value[lProps.rowIndex][lProps.field] }
                options     = { lDataProvider }
                optionLabel = { (lColumn && lColumn.hasOwnProperty('data_provider_display_field_')) ? lColumn.data_provider_display_field_ : null }
                onChange    = { (e) => this.cfOnColumnSelection(lProps, e.target.value) }
            />
        );
    }
    
    cfTextinputRendererTemplate(lRowData, lProps)
    {
        let lField = lProps.field;
        let lValue = null;
        if(lRowData && lField)
        {
            lValue = lRowData[lField];

            let lAllow = true;            
            let lColumn = this.mDataFieldColumnMap[lProps.field];
            if(lColumn && lColumn.hasOwnProperty('cs_form_grid_item_properties_') && lColumn.cs_form_grid_item_properties_)
            {
                let lStrFlag = false;
                for(let lInd=0; lInd< lColumn.cs_form_grid_item_properties_.length; lInd++)
                {
                    const lfgipObj = lColumn.cs_form_grid_item_properties_[lInd];
                    if(lfgipObj.property_ === 'validate')
                    {
                        if(lfgipObj.type_ === 'data_field')
                        {
                            if(lfgipObj.param_ === 'allow')
                            {
                                if (lfgipObj.selection_property_ === lValue)
                                {
                                    lStrFlag = true;
                                    lAllow = true;
                                }

                                if(!lStrFlag && lfgipObj.value_)
                                {
                                    if(!lValue.match(lfgipObj.value_))
                                    {
                                        lAllow = false;
                                    }
                                }
                            }
                            else if(!lStrFlag && (lfgipObj.param_ === 'min_val'))
                            {
                                if(lfgipObj.value_)
                                {
                                    lAllow = Number(lValue) >= Number(lfgipObj.value_)
                                }
                                else if(lfgipObj.selection_property_ && lRowData[lfgipObj.selection_property_])
                                {
                                    lAllow = Number(lValue) >= Number(lRowData[lfgipObj.selection_property_])
                                }
                            }
                            else if(!lStrFlag && (lfgipObj.param_ === 'max_val'))
                            {
                                if(lfgipObj.value_)
                                {
                                    lAllow = Number(lValue) <= Number(lfgipObj.value_)
                                }
                                else if(lfgipObj.selection_property_ && lRowData[lfgipObj.selection_property_])
                                {
                                    lAllow = Number(lValue) <= Number(lRowData[lfgipObj.selection_property_])
                                }
                            }

                            if(!lAllow)
                            {
                                break;
                            }
                        }
                    }
                }
            }

            if(!lAllow)
            {
                lValue = '';
                lRowData[lField] = lValue;
            }    
        }

        return (
            <InputText
                style       = {{width:'100%'}} 
                value       = { lValue } 
                onChange    = {(e) => this.cfOnColumnSelection(lProps, e.target.value)}>
            </InputText>
        );
    }

    cfHyperlinkRenderer(lRowData, lCol)
    {  
        let lctl = null;
        let lColumn = this.mDataFieldColumnMap[lCol.field];
        if(lColumn)
        {
            if(lRowData && lCol && (lRowData[lCol.field]))
            {
                let lLink = lRowData[lCol.field];
                if(lColumn.constant_)
                {
                    let lDataFolder = CSConfigUtil.mDataFolderMap[lColumn.constant_];
                    lLink = lDataFolder + "/" + lLink;
                }

                lctl =
                    <a href={ lLink } target="_blank" rel="noreferrer">
                        { lColumn.column_name_ }
                    </a>
            }
        }        

        return lctl;
    }
    
    cfCheckboxRendererTemplate(lRowData, lCol)
    {
        return (
            <Checkbox 
                checked     = {(lRowData && lCol && (lRowData[lCol.field] === '1')) ? true : false}
                onChange    = {(e) => this.cfOnCheckboxSelection(e.checked, lRowData, lCol)}>
            </Checkbox>
        );
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    cfClosePrintForm()
    {
        if(this.state.fnClosePrintForm)
        {
            this.setState({ mPrintFlag : false });
            this.state.fnClosePrintForm();
        }
    }

    cfCloseColumnChooser()
    {
        if(this.props.pCloseColumnChooser)
        {
            this.props.pCloseColumnChooser(this.state.mColumns);
        }
    }

    cfSaveColumns(lColumns)
    {
        this.setState({mColumns : lColumns});
    }

    cfOnExport()
    {
        this.dt.exportCSV();
    }

    render() 
    {
        let lTableData = this.cfInitializeGrid();
        let lTable = null;
        if (lTableData[0]) 
        {
            let lFilter = null;
            if(this.state.mEnableFilter) 
            {
                lFilter = 
                    <div className="p-inputgroup">
                        <span className     = "p-inputgroup-addon" >
                            <i className='ics-filter'> </i>
                        </span>
                        
                        <InputText 
                            style       = {{ fontSize: '12px' }}
                            type        = "search" 
                            onInput     = {(e) => this.setState({mGlobalFilter: e.target.value})} 
                            placeholder = "" 
                            size        = "30"/>
                    </div>
            }

            let lHeading = null;
            if(this.state.mGridHeading)
            {
                lHeading = 
                    <div style={{display: "inline-block", float: this.state.mAlignHeading}}>
                        <h3 className="m-0">{this.state.mGridHeading}</h3>
                    </div>
            }
            
            let lSearchActions = null;
            if(this.state.mSearchActionBar)
            {
                lSearchActions = 
                    <div  style={{float: 'right'}}>
                        {this.state.mSearchActionBar}
                    </div>
            }

            let lExport = null;
            let lEnableExport = false;
            if(this.props.pFormGridProperties && (this.props.pFormGridProperties.length > 0))
            {
                for(let pfgInd=0; pfgInd<this.props.pFormGridProperties.length; pfgInd++)
                {
                    let lFormGridProperty = this.props.pFormGridProperties[pfgInd];
                    if((lFormGridProperty.property_ === 'excel_export') && (lFormGridProperty.name_ === 'enable') && (lFormGridProperty.value_ === '1'))
                    {
                        lEnableExport = true;
                    }
                }
            }

            if(lEnableExport)
            {
                lExport = 
                    <div style={{textAlign:'left'}}>
                        <Button 
                            type="button" 
                            icon="pi pi-external-link" 
                            iconPos="left"
                            onClick={this.cfOnExport}>
                        </Button>
                    </div>;
            }

            let lHeader = null;
            if(lTableData[0] && ((lTableData[0]).length > 0) && (lFilter || lHeading || lSearchActions))
            {
                let lExportComponent = null;
                if(lExport) {
                    lExportComponent = 
                        <div align    = "left"
                            key       = {this.state.mId}
                            className = "col-12 md:col-6 lg:col-1">
                            { lExport }
                        </div>                    
                }

                let lHeadingClassName = "col-12 md:col-5 lg:col-5 ";
                if(!lExport) {
                    lHeadingClassName = "col-12 md:col-6 lg:col-6 align-content-center";
                }

                lHeader = 
                    <div className="fluid formgrid grid">
                        <div align    = "center"
                            key       = {this.state.mId}
                            className = "col-12 md:col-6 lg:col-3">
                            { lFilter }
                        </div>
                        
                        <div align    = "center"
                            key       = { this.state.mId }
                            className = { lHeadingClassName }>
                            { lHeading }
                        </div>
                        
                        <div align    = "right"
                            key       = {this.state.mId}
                            className = "col-12 md:col-6 lg:col-3">
                            { lSearchActions }
                        </div>
                        { lExportComponent }
                    </div>
            }

            let lGridData = [];
            if(this.state.mPageData)
            {
                lGridData = this.state.mPageData.map((lObject, lIndex) => 
                    {
                        lObject.key = lIndex + 1; 
                        this.mKeyObjectMap[lObject.key] = lObject;
                        return lObject;
                    }
                );
            }

            let lColumnChooser = null;
            if(this.state.mColumnChooser === true)
            {
                lColumnChooser = 
                    <CSCOColumnChooser 
                        pFormGrid      = { this.state.mColumns } 
                        pShow          = { this.state.mColumnChooser }
                        fnProcessSave  = { this.cfSaveColumns }
                        fnProcessClose = { this.cfCloseColumnChooser }/>
            }

            lTable =
                <div className="grid-card">
                    <DataTable
                        className = "cs-grid-data"
                        style =
                        {
                            {
                                overflow  : 'inherit',
                               // width     : (this.props.pWidth) ? this.props.pWidth : CSUtil.mApplicationWidth,
                                height    : this.state.mHeight,
                                minheight : this.state.mMinHeight
                            }
                        }
                         
                        ref                     = { (el) => { this.dt = el; }}
                        scrollable              = { this.state.mEnableScroll }
                        scrollHeight            = { this.state.mScrollHeight }
                        editMode                = { (lTableData[2]) ? 'cell' : null }
                        tableStyle              = { {tableLayout: (this.state.mGroupByField) ? 'auto' : 'fixed'} }
                        paginator               = { ((lGridData.length >= this.state.mPageSize) || (this.state.mPagination && (this.state.mPagination === true))) ? true : false }
                        paginatorPosition       = "top"
                        header                  = { lHeader }
                        footerColumnGroup       = { lTableData[3] }
                        headerColumnGroup       = { lTableData[4] }
                        footer                  = { this.state.mFooter }
                        globalFilter            = { this.state.mGlobalFilter } 
                        resizableColumns        = { this.state.mResizeableColumns }
                        selection               = { (this.state.mResetFlag || this.mCheckboxRenderer) ? null : this.state.mSelectedData }
                        selectionMode           = { lTableData[1] }
                        onSelectionChange       = { this.cfOnClick }
                        onRowDoubleClick        = { this.cfOnDoubleClick }
                        rows                    = { this.state.mPageSize }
                        sortField               = { this.state.mGroupByField }
                        groupRowsBy             = { this.state.mGroupByField }
                        sortOrder               = { (this.state.mGroupByField) ? 1 : null }
                        rowGroupMode            = { (this.state.mGroupByField) ? 'subheader' : null }
                        rowGroupHeaderTemplate  = { this.cfHeaderTemplate }
                        rowGroupFooterTemplate  = { this.cfFooterTemplate }
                        expandableRowGroups     = { (this.state.mGroupByField) ? true : false }
                        expandedRows            = { this.state.mExpandedRows }
                        onRowToggle             = { this.cfOnRowToggle }
                        value                   = { lGridData }
                        size                    = "small"
                        stripedRows             >
                        
                        {lTableData[0]}

                    </DataTable>

                    { lColumnChooser }
                </div>

            var lPrintForm = 
                <CSCOPrint 
                    pComponent  = { lTable } 
                    pCloseForm  = { this.cfClosePrintForm }
                    pDisplay    = { this.state.mPrintFlag }
                    pHeading    = "Print Form"/>

        }

        return (!this.state.mPrintFlag) ? lTable : lPrintForm;
    }
}

export default CSCOGrid;
